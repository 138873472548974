import { Box } from "@chakra-ui/react";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);
interface EmployeeCategoryProps {
  fullTime?: string;
  partTime?: string;
  dailyWager?: string;
}
export default function EmployeeCategory({
  fullTime,
  partTime,
  dailyWager,
}: EmployeeCategoryProps) {
  const category = {
    labels: ["Full Time", "Part Time", "Daily Wager"],
    datasets: [
      {
        data: [fullTime, partTime, dailyWager],
        backgroundColor: [
          "rgba(255, 205, 86, 0.2)", // Light yellow color with some transparency
          "rgba(255, 159, 64, 0.2)", // Light orange color with some transparency
          "rgba(191, 147, 204, 0.2)", // Light lavender color with some transparency
        ],
        borderColor: [
          "rgba(255, 205, 86, 1)", // Solid yellow color
          "rgba(255, 159, 64, 1)", // Solid orange color
          "rgba(191, 147, 204, 1)", // Solid lavender color
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,

        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
      },
    },
  };
  return (
    <Box w="60px" h="60px">
      {/* <CircularProgress
                  value={42}
                  color="orange.400"
                  thickness="11px"
                  size="40px"
                /> */}
      <Doughnut data={category} options={options} />
    </Box>
  );
}
