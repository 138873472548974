import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";

interface EditModal {
  isOpen: boolean;
  onClose: () => void;
}

export function EditModal({ isOpen, onClose }: EditModal) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"470px"} h={"auto"}>
        <ModalHeader
          fontSize={"18px"}
          _light={{
            color: "#444444",
          }}
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Edit Tracking
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <Text
            _light={{
              color: "customColor.black4",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"15px"}
            fontWeight={"600"}
            padding={"0px 0px 3.5px"}
            marginBottom={"5px"}
          >
            Region tracking
          </Text>
          <Text
            _light={{
              color: "customColor.black6",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"12px"}
            margin={"0px 0px 8px"}
            padding={"0px 5px 0px 0px"}
          >
            Rename an option in the Region tracking category
          </Text>
          <CustomInput
            withValidation
            validationMessage="This field is required"
            input={{
              w: "100%",

              fontSize: "12px",
              backgroundColor: "#fff",
              variant: "outline",
              // color: "#ccc",
              fontWeight: "500",
              lineHeight: "1.2",
              h: "19px",
              padding: "0px 0px 0px 3px",
              type: "text",
              border: "1px solid",
              borderColor: "gray.200",
              borderRadius: "1px",
            }}
            placeholder=""
            label="Option:"
            labelProps={{
              fontSize: "12px",
              fontWeight: "700",

              _light: {
                color: "customColor.black6",
              },
              _dark: {
                color: "customColor.white",
              },
            }}
          />
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            padding={"7.52px 12px"}
            fontSize={"12px"}
            colorScheme="primary"
            w={"94px"}
            h={"34px"}
            borderRadius={"3px"}
            border={"1px solid #3a9101"}
            bg={"linear-gradient(#4fc601,#44aa01)"}
            _hover={{
              border: "1px solid #327d01",
              bg: "linear-gradient(#47b201,#3c9601)",
            }}
          >
            Rename
          </Button>
          <Button
            fontSize={"12px"}
            // bg={"linear-gradient(#bbbec2,#949a9e)"}
            colorScheme="customGray"
            fontWeight={"700"}
            margin={"0px 0px 0px 10px"}
            padding={"6px 18px 5px"}
            w="86px"
            h={"34px"}
            boxShadow={"inset 0 0 0 1px hsla(0,0%,100%,.3)"}
            borderRadius={"3px"}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
