import {
  Box,
  Button,
  Checkbox,
  Container,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  ListItem,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillCaretDown } from "react-icons/ai";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { AddNewTax } from "./Modal/AddNewTax";
import { AdvancedTaxRate } from "./Modal/AdvancedTaxRate";
import DeleteBulk from "./Modal/BulkDelete";
import DeleteModal from "./Modal/DeleteModal";

export function TaxRates() {
  const advancedTaxRate = useDisclosure();
  const addNewTax = useDisclosure();
  const [taxRate, setTaxRate] = useState<BusinessTaxRate.FetchTaxRate[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const bulkDelete = useDisclosure();
  const deleteModal = useDisclosure();

  const form = useForm({
    defaultValues: {
      id: "",
      name: "",
      tax_type: "",
      tax_components: [
        {
          name: "",
          tax: 0,
        },
      ],
    },
  });
  const toast = useToast();

  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRate(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  useEffect(() => {
    const initialCheckboxes = taxRate.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [taxRate]);

  const handleCheckboxChange = (id: string) => {
    const updatedCheckboxes = {
      ...checkboxes,
      [id]: !checkboxes[id],
    };
    setCheckboxes(updatedCheckboxes);

    // Add or remove ID from selectedIds
    if (updatedCheckboxes[id]) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = Object.keys(checkboxes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: !checkboxes[key],
      }),
      {}
    );
    setCheckboxes(updatedCheckboxes);
  };
  const length = Object.values(checkboxes).filter((value) => value).length;
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.gray.400",
          }}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Container maxW={"100%"} padding={"10px 20px 0px"}>
            <Stack h={"50px"} marginBottom={"10px"}>
              <Link
                color={"#048fc2"}
                fontWeight={"normal "}
                fontSize={"11px"}
                href="advancedSettings"
              >
                Advanced accounting
              </Link>
              <Heading
                marginTop={"-10px"}
                fontSize="24px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="700"
              >
                Tax Rates
              </Heading>
            </Stack>
          </Container>
        </Stack>
        <Container maxW={"100%"} padding={"0px 20px"}>
          <Stack
            padding={"10px 0px 0px"}
            width={"100%"}
            gap={0}
            marginBottom={"20px"}
          >
            <Stack
              h={"26.8px"}
              padding={"5px 5px 5px 10px"}
              bg={"#64B23B"}
              direction={"row"}
              borderRadius={"5px 5px 0px 0px"}
            >
              <Heading
                fontSize={"14px"}
                fontWeight={"700"}
                color={"#fff"}
                display={"flex"}
                flex={1}
              >
                Getting Started with Tax Rates
              </Heading>
              {/* <Button
                justifyContent={"flex-end"}
                h={"16px"}
                maxW={"17px"}
                color={"black"}
                bgColor={"#fff"}
                border={"1px solid lightgrey"}
                _hover={{
                  color: "black",
                  bgColor: "#fff",
                  border: "1px solid lightgrey",
                }}
              >
                X
              </Button> */}
            </Stack>
            <Stack
              bg={"#f9fef6"}
              border={"1px solid #c1c1c1"}
              borderRadius={"0 0 5px 5px"}
            >
              <HStack
                borderTop={"1px solid #cfd2d4"}
                padding="20px 10px 20px 10px"
              >
                <Image
                  borderRadius="0"
                  w="299px"
                  h="167px"
                  src="https://go.xero.com/assets/4033e49f3ebd118beb8ad50c65fc777a.png"
                  alt="Payroll Settings"
                />
                <VStack
                  _light={{ color: "customColor.black4" }}
                  _dark={{ color: "customColor.white" }}
                  marginLeft={"5px"}
                  fontSize="18px"
                  align="left"
                >
                  <Text
                    paddingBottom={"10px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"11px"}
                  >
                    Every transaction line item you enter into HRIS-360 needs a
                    Tax Rate. The Tax Rates you use will depend on the location
                    of your organisation. Tax Rates can have multiple Tax
                    Components. For instance, you can have an item that is
                    charged a Tax Rate called "City Import Tax (8%)" that has
                    two components: a city tax of 5% and an import tax of 3%.
                  </Text>
                  <Text
                    paddingBottom={"10px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"11px"}
                  >
                    You can generate reports on any Tax Rate and Tax Components.
                  </Text>

                  <Text
                    paddingBottom={"10px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"11px"}
                  >
                    If you need to report on income separately to expenses, or
                    capital purchases separately to expenses, then you should
                    create a separate Tax Rate for each.
                  </Text>
                  <UnorderedList color={"#3A9C00"} fontSize={"11px"}>
                    <ListItem>View more help for Tax Rates</ListItem>
                  </UnorderedList>
                </VStack>
              </HStack>
            </Stack>
          </Stack>

          <Stack marginBottom={"15px"}>
            <Stack direction={"row"} padding={0} gap={0}>
              <Button
                leftIcon={<BsFillPlusSquareFill size={"13px"} />}
                margin={"0px 10px 0px 0px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  form.reset({
                    name: "",
                    tax_type: "",
                    tax_components: [
                      {
                        name: "",
                        tax: 0,
                      },
                    ],
                  });
                  setSelectedId(undefined);
                  addNewTax.onOpen();
                }}
              >
                New Tax Rate
              </Button>
              <Button
                margin={"0px 10px 0px 0px"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                background={"#fff"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => advancedTaxRate.onOpen()}
              >
                Change to Advanced Tax Rate
              </Button>
            </Stack>

            {/* <Accmodal isOpen={isOpen} onClose={onClose} /> */}
          </Stack>
          <AdvancedTaxRate
            isOpen={advancedTaxRate.isOpen}
            onClose={advancedTaxRate.onClose}
          />
          <AddNewTax
            isOpen={addNewTax.isOpen}
            onClose={addNewTax.onClose}
            setLastUpdated={setLastUpdated}
            selectedId={selectedId}
          />
          <Stack
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            borderColor="#f6f6f6"
            borderWidth="1px"
            borderStyle={"solid"}
            gap={0}
          >
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
              h={"36px"}
              padding={"6px 10px"}
            >
              <Stack direction={"row"} gap={0} marginBottom={"10px"}>
                <Button
                  margin={"0px 10px 0px 0px "}
                  variant="solid"
                  fontSize={"13px"}
                  padding={"10px 15px"}
                  borderRadius={"3px"}
                  border={"1px solid #FDEDED"}
                  bg={"#FDEDED"}
                  color={"#EA4D4D"}
                  minW={"75px"}
                  _hover={{
                    border: "1px solid #EA4D4D",
                    bg: "#EA4D4D",
                    color: "#fff",
                  }}
                  onClick={() => bulkDelete.onOpen()}
                >
                  Delete
                </Button>

                <Text
                  fontSize={"11px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  margin={"6px 0px 0px"}
                >
                  {length} item(s) selected
                </Text>
              </Stack>
            </Stack>
            <Stack
              padding={"5px"}
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
            ></Stack>
            <Skeleton height="100%" isLoaded={!isLoading}>
              <Box
                overflowX="auto"
                p="0px"
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
              >
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    <Tr
                      borderTopWidth={"1px"}
                      borderTopColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={Object.values(checkboxes).every(
                            (value) => value
                          )}
                          onChange={handleCheckAll}
                        />
                      </Th>
                      {[
                        {
                          name: "Name",
                          value: "35%",
                          paddingValue: "8px 26px 8px 15px",

                          icon: <AiFillCaretDown />,
                        },

                        {
                          name: "Tax Rate",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: "Accounts using this Tax Rate",
                          value: "25%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                        {
                          name: " Action",
                          value: "15%",
                          paddingValue: "8px 26px 8px 15px",
                        },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding={item.paddingValue}
                            borderBottomColor="borders.tableBorder"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                            width={item.value}
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>

                  <Tbody padding={0}>
                    {taxRate &&
                      taxRate.map((item: any, i: any) => {
                        const itemTotalTax = item.tax_components?.reduce(
                          (total: any, component: any) => {
                            return total + component.tax;
                          },
                          0
                        );
                        return (
                          <Tr
                            key={i}
                            _hover={{
                              bgColor: "#f2f3f8",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              addNewTax.onOpen();
                              setSelectedId(item?.id);
                              form.reset({
                                name: item.name,
                                tax_type: item.tax_type,
                                tax_components: item.tax_components.map(
                                  (component: any) => {
                                    return {
                                      name: component.name,
                                      tax: component.tax,
                                    };
                                  }
                                ),
                              });
                            }}
                          >
                            <Td
                              padding="15px 15px 15px 30px"
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              whiteSpace="nowrap"
                              fontWeight={500}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Checkbox
                                size="md"
                                borderColor="#adb5bd"
                                bgColor="white"
                                isChecked={checkboxes[item.id]}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                            </Td>

                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              {item.name}
                            </Td>

                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <Text h={"13.5px"}>{`${itemTotalTax} %`}</Text>
                            </Td>
                            <Td
                              padding="15px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <Text h={"13.5px"}>
                                {item.chartOfAccountsCount}
                              </Text>
                            </Td>
                            <Td
                              padding="10px 21px 10px 10px"
                              fontWeight={500}
                              fontSize="13.44px"
                              color={"customColor.black7"}
                              _dark={{
                                color: "customColor.dark.150",
                              }}
                              lineHeight={1.5}
                              borderTopWidth="1px"
                              borderBottomWidth="0px"
                              borderTopColor={"borders.tableBorder"}
                            >
                              <HStack>
                                <Icon
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdOutlineRemoveRedEye}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={() => {
                                    addNewTax.onOpen();
                                    setSelectedId(item?.id);
                                    form.reset({
                                      name: item.name,
                                      tax_type: item.tax_type,
                                      tax_components: item.tax_components.map(
                                        (component: any) => {
                                          return {
                                            name: component.name,
                                            tax: component.tax,
                                          };
                                        }
                                      ),
                                    });
                                  }}
                                />
                                <Icon
                                  borderRadius="50%"
                                  borderStyle={"solid"}
                                  borderWidth={"1px"}
                                  borderColor={"borders.tableBorder"}
                                  bg={"#fff"}
                                  w="25px"
                                  h="25px"
                                  px="5px"
                                  py="5px"
                                  as={MdDelete}
                                  fontSize="22px"
                                  color="#6B7280"
                                  _hover={{
                                    color: "#3454d1",
                                  }}
                                  onClick={(e) => {
                                    setSelectedId(item?.id);
                                    e.stopPropagation();
                                    deleteModal.onOpen();
                                  }}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
            </Skeleton>
          </Stack>
        </Container>
      </Stack>
      <DeleteModal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        id={selectedId}
        setLastUpdated={setLastUpdated}
      />
      <DeleteBulk
        isOpen={bulkDelete.isOpen}
        onClose={bulkDelete.onClose}
        selectedIds={selectedIds}
        setLastUpdated={setLastUpdated}
        length={length}
      />
    </FormProvider>
  );
}
