import {
  Button,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";
import { PayItemDeductionApi } from "../../../../Api/PayItemDeduction";
import { PayItemEarningApi } from "../../../../Api/PayItemEarning";
import BenefitsModal from "./AddButtonModal/BenefitsModal";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import OtherModal from "./AddButtonModal/OtherModal";
import UnionFeesModal from "./AddButtonModal/UnionFeesModal";
import WorkplaceGivingModal from "./AddButtonModal/WorkplaceGivingModal";
import { DeleteDeduction } from "./DeleteDeduction";
interface DeductionsProps {
  showInactive: boolean | undefined;
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Deductions({
  showInactive,
  setSelectedTab,
  selectedTab,
}: DeductionsProps) {
  const workplaceModal = useDisclosure();
  const otherModal = useDisclosure();
  const unionFeeModal = useDisclosure();
  const deleteModal = useDisclosure();
  const benefitsModal = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [deductionAdded, setDeductionAdded] = useState<Array<any>>([]);
  const toast = useToast();
  const [selectedDeductionId, setSelectedDeductionId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const form = useForm<PayItemDeductionApi.AddNewDeduction>({
    defaultValues: {
      id: undefined,
      category: "",
      chartAccountId: "",
      name: "",
      isTaxable: false,
      showOnPayslip: false,
      earningPayItems: [],
      percentage: undefined,
      minimumWage: undefined,
    },
  });

  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setAccount(res);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      },
      "LIABILITY"
    );
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(deductionAdded);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  const filteredData = showInactive
    ? deductionAdded.filter((item) => !item.active)
    : deductionAdded.filter((item) => item.active);
  useEffect(() => {
    PayItemDeductionApi.FetchDeduction(
      (data) => {
        setDeductionAdded(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [lastUpdated]);
  const [account, setAccount] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [earningAdded, setEarningAdded] = useState<
    Array<PayItemEarningApi.EarningById>
  >([]);
  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (data) => {
        setEarningAdded(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setAccount(res);
      },
      (err) => {
        toast({
          title: "Failed to fetch Chart of Account",
          status: "error",
          description: err,
        });
      },
      "LIABILITY"
    );
  }, []);
  // const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  // useEffect(() => {
  //   const initialCheckboxes = deductionAdded.reduce((acc: any, item) => {
  //     acc[item.id] = false;
  //     return acc;
  //   }, {});
  //   setCheckboxes(initialCheckboxes);
  // }, [deductionAdded]);

  // const handleCheckboxChange = (id: string) => {
  //   setCheckboxes({
  //     ...checkboxes,
  //     [id]: !checkboxes[id],
  //   });
  // };

  // const handleCheckAll = () => {
  //   const updatedCheckboxes = Object.keys(checkboxes).reduce(
  //     (acc, key) => ({
  //       ...acc,
  //       [key]: !checkboxes[key],
  //     }),
  //     {}
  //   );
  //   setCheckboxes(updatedCheckboxes);
  // };
  return (
    <FormProvider {...form}>
      <Skeleton height={"100%"} isLoaded={!isLoading}>
        <Stack color={"#32456A"}>
          <HStack justifyContent="space-between" px={"12px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              py="20px"
              as="h2"
              size="md"
              _dark={{
                color: "customColor.white",
              }}
              _light={{
                color: "customColor.black7",
              }}
            >
              Deduction
            </Heading>

            <Menu>
              <Tooltip
                label="Deductions allows users to include or input various subtractive amounts, such as taxes or insurance premiums, from an individual's or entity's earnings."
                fontSize="sm"
                padding={"10px"}
                bg={"#fff"}
                border={"1px solid #ddd"}
                _light={{
                  color: "customColor.black7",
                }}
                maxW={"300px"}
                placement="auto"
              >
                <MenuButton
                  as={Button}
                  rightIcon={<RiArrowDownSFill fontSize={"20px"} />}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  _active={{
                    bgColor: "#3454D1",
                  }}
                >
                  Add
                </MenuButton>
              </Tooltip>
              <MenuList width={"250px"} maxH={"180px"} overflowX={"auto"}>
                {[
                  {
                    name: "Workplace Giving",
                    onClick: () => {
                      form.reset({
                        category: "WORKPLACE_GIVING",
                        id: "",
                        name: "",
                        chartAccountId: "",
                        earningPayItems: [],
                        percentage: undefined,
                        minimumWage: undefined,
                        isTaxable: false,
                        showOnPayslip: false,
                      });
                      setSelectedDeductionId(undefined);

                      workplaceModal.onOpen();
                    },
                  },
                  {
                    name: "Union / Association Fees",
                    onClick: () => {
                      form.reset({
                        category: "UNION_ASSOCIATION_FEES",
                        id: "",
                        name: "",
                        chartAccountId: "",
                        earningPayItems: [],
                        isTaxable: false,
                        minimumWage: undefined,
                        percentage: undefined,
                        showOnPayslip: false,
                      });
                      setSelectedDeductionId(undefined);
                      unionFeeModal.onOpen();
                    },
                  },
                  {
                    name: "Other / None",
                    onClick: () => {
                      form.reset({
                        category: "OTHER_NONE",
                        id: "",
                        name: "",
                        chartAccountId: "",
                        earningPayItems: [],
                        isTaxable: false,
                        minimumWage: undefined,
                        percentage: undefined,
                        showOnPayslip: false,
                      });
                      setSelectedDeductionId(undefined);
                      otherModal.onOpen();
                    },
                  },
                  {
                    name: "Employee Provident Fund",
                    onClick: () => {
                      form.reset({
                        category: "EMPLOYEE_PROVIDENT_FUND",
                        id: undefined,
                        name: "",
                        chartAccountId: "",
                        earningPayItems: [],
                        minimumWage: undefined,
                        percentage: undefined,
                        isTaxable: false,
                        showOnPayslip: false,
                      });
                      setSelectedDeductionId(undefined);
                      benefitsModal.onOpen();
                    },
                  },
                  {
                    name: "Employee EOBI",
                    onClick: () => {
                      form.reset({
                        category: "EMPLOYEE_EOBI",
                        id: undefined,
                        name: "",
                        chartAccountId: "",
                        earningPayItems: [],
                        minimumWage: undefined,
                        percentage: undefined,
                        isTaxable: false,
                        showOnPayslip: false,
                      });
                      setSelectedDeductionId(undefined);
                      benefitsModal.onOpen();
                    },
                  },
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"1px 0px 1px 20px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      onClick={item.onClick}
                    >
                      <Button
                        variant={"ghost"}
                        fontWeight={"normal"}
                        fontSize={"13px"}
                        padding={"0px 20px 0px 0px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        onClick={item.onClick}
                        _hover={{
                          bg: "none",
                        }}
                      >
                        {" "}
                        {item.name}
                      </Button>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>
          <Stack overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Deduction Name",
                      value: "10%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Deduction Category",
                      value: "20%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Account",
                      value: "20%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Reduces PAYG",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Reduces SGC",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Excluded from W1",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "View Details",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody padding={0}>
                {filteredData &&
                  filteredData.map((item: any, i: any) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.name}
                          </Text>
                        </Td>
                        <Td
                          padding="15px "
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.category === "WORKPLACE_GIVING"
                              ? "Workplace Giving"
                              : item.category === "UNION_ASSOCIATION_FEES"
                              ? "Union / Association Fees"
                              : item.category === "EMPLOYEE_PROVIDENT_FUND"
                              ? "Employee Provident Fund"
                              : item.category === "EMPLOYEE_EOBI"
                              ? "Employee EOBI"
                              : "Other / None"}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item?.chartOfAccount?.code}:
                            {item?.chartOfAccount?.name}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.reduceWithholdingTax ? "Yes" : "No"}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.reduceRetirementContribution ? "Yes" : "No"}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.excludeActivityStatement ? "Yes" : "No"}
                          </Text>
                        </Td>

                        <Td
                          padding="10px"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={MdOutlineRemoveRedEye}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                              }}
                              onClick={() => {
                                setSelectedDeductionId(item.id);

                                form.reset({
                                  ...item,
                                  chartAccountId: item?.chartOfAccount?.id,
                                });
                                item.category === "WORKPLACE_GIVING"
                                  ? workplaceModal.onOpen()
                                  : item.category === "UNION_ASSOCIATION_FEES"
                                  ? unionFeeModal.onOpen()
                                  : item.category === "EMPLOYEE_PROVIDENT_FUND"
                                  ? benefitsModal.onOpen()
                                  : item.category === "EMPLOYEE_EOBI"
                                  ? benefitsModal.onOpen()
                                  : otherModal.onOpen();
                              }}
                            />
                          </HStack>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Menu>
                            <MenuButton
                              as={Button}
                              padding={0}
                              backgroundColor={"transparent"}
                              h={"34.5px"}
                              marginLeft={"8px"}
                              _light={{
                                color: "customColor.black7",
                              }}
                              display={{
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              }}
                              _hover={{
                                backgroundColor: "primary.50",
                              }}
                              _active={{
                                backgroundColor: "primary.100",
                              }}
                              borderRadius={"full"}
                            >
                              <Icon as={FiMoreVertical} />
                            </MenuButton>
                            <MenuList style={{ minWidth: "140px" }}>
                              {[
                                {
                                  name: "Edit",
                                  onClick: () => {
                                    form.reset({
                                      ...item,
                                      chartAccountId: item?.chartOfAccount?.id,
                                    });

                                    item.category === "WORKPLACE_GIVING"
                                      ? workplaceModal.onOpen()
                                      : item.category ===
                                        "UNION_ASSOCIATION_FEES"
                                      ? unionFeeModal.onOpen()
                                      : item.category ===
                                        "EMPLOYEE_PROVIDENT_FUND"
                                      ? benefitsModal.onOpen()
                                      : item.category === "EMPLOYEE_EOBI"
                                      ? benefitsModal.onOpen()
                                      : otherModal.onOpen();
                                  },
                                },
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    setSelectedDeductionId(item.id);
                                    deleteModal.onOpen();
                                  },
                                },
                                {
                                  name: item.active
                                    ? "Mark as Inactive"
                                    : "Mark as Active",
                                  onClick: () => {
                                    PayItemDeductionApi.IsActive(
                                      item.id,
                                      !item.active,
                                      (res) => {
                                        setLastUpdated(Date.now());
                                        toast({
                                          title: "Deduction is inactive",
                                          status: "success",
                                        });
                                      },
                                      (error) => {
                                        toast({
                                          title: "Failed to inactive Deduction",
                                          status: "success",
                                        });
                                      }
                                    );
                                  },
                                },
                              ].map((item, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    padding={"1px 0px 1px 20px"}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.white",
                                    }}
                                    onClick={item.onClick}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      fontWeight={"normal"}
                                      fontSize={"13px"}
                                      padding={"0px 20px 0px 0px"}
                                      _light={{
                                        color: "customColor.black2",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                      _hover={{
                                        bg: "none",
                                      }}
                                    >
                                      {" "}
                                      {item.name}
                                    </Button>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>

              <WorkplaceGivingModal
                isOpen={workplaceModal.isOpen}
                onClose={workplaceModal.onClose}
                selectedDeductionId={selectedDeductionId}
                setLastUpdated={setLastUpdated}
                allAccounts={account}
              />
              <OtherModal
                isOpen={otherModal.isOpen}
                onClose={otherModal.onClose}
                selectedDeductionId={selectedDeductionId}
                setLastUpdated={setLastUpdated}
                allAccount={account}
              />
              <UnionFeesModal
                isOpen={unionFeeModal.isOpen}
                onClose={unionFeeModal.onClose}
                selectedDeductionId={selectedDeductionId}
                setLastUpdated={setLastUpdated}
                allAccount={account}
              />
              <DeleteDeduction
                isOpen={deleteModal.isOpen}
                onClose={deleteModal.onClose}
                selectedId={selectedDeductionId}
                setLastUpdated={setLastUpdated}
              />
              <BenefitsModal
                isOpen={benefitsModal.isOpen}
                onClose={benefitsModal.onClose}
                selectedDeductionId={selectedDeductionId}
                setLastUpdate={setLastUpdated}
                chartOfAccounts={account}
                earningAdded={earningAdded}
              />
            </Table>
            {/* <Stack
              direction="row"
              alignItems="center"
              gap={0}
              paddingLeft={"12px"}
              paddingRight={"5px"}
            >
              <Stack
                direction="row"
                alignItems="center"
                marginTop="10px"
                flex={1}
                gap={0}
              >
                <Stack direction={"row"} gap={0}>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                  >{`Page`}</Text>
                  <Select
                    h={"25px"}
                    w={"50px"}
                    paddingLeft={"3px"}
                    paddingRight={"3px"}
                    style={{
                      borderRadius: "3px",
                      fontWeight: "normal",
                      padding: "0px 0px 0px 3px",
                    }}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                    fontSize={"12px"}
                    placeholder=""
                    value={selectedPage}
                    onChange={(event: any) => {
                      const newPage = parseInt(event.target.value, 10);
                      setSelectedPage(newPage);
                      setCurrentPage(newPage);
                    }}
                  >
                    {getPageNumbers(
                      Math.ceil((deductionAdded?.length ?? 0) / itemsPerPage)
                    ).map((pageNumber) => (
                      <option
                        key={pageNumber}
                        value={pageNumber}
                        style={{
                          width: "70px",
                          height: "42px",
                          padding: "13px 10px 12px",
                          fontSize: "12px",
                          color: "#048ABB",
                        }}
                      >
                        {pageNumber}
                      </option>
                    ))}
                  </Select>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                  >{` of ${Math.ceil(
                    deductionAdded?.length ?? 0 / itemsPerPage
                  )}`}</Text>
                  <Text
                    color={"customColor.gray.800"}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    padding={"5px 0px"}
                    fontSize={"12px"}
                    marginLeft={"3px"}
                  >
                    ({deductionAdded?.length ?? 0} total items)
                  </Text>
                </Stack>

                <Text
                  marginLeft="10px"
                  color={"customColor.gray.800"}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  padding={"5px 0px"}
                  fontSize={"12px"}
                  marginRight={"5px"}
                >
                  Showing
                </Text>
               
                <Select
                  h={"25px"}
                  w={"50px"}
                  style={{
                    borderRadius: "3px",
                    fontWeight: "normal",
                    padding: "0px 0px 0px 3px",
                  }}
                  borderWidth={"1px"}
                  borderStyle={"solid"}
                  borderColor={"#b5b8bb #e2e4e7 #e8eaec #bdbfc3"}
                  fontSize={"12px"}
                  placeholder=""
                  value={itemsPerPage}
                  onChange={(event: any) => {
                    const newValue = parseInt(event.target.value, 10);
                    setItemsPerPage(newValue);
                    setCurrentPage(1);
                  }}
                >
                  {[25, 50, 100, 200, 500, 1000].map((value) => (
                    <option
                      key={value}
                      value={value}
                      style={{
                        width: "70px",
                        height: "42px",
                        padding: "13px 10px 12px",
                        fontSize: "12px",
                        color: "#048ABB",
                      }}
                    >
                      {String(value)}
                    </option>
                  ))}
                </Select>

                <Text
                  color={"customColor.gray.800"}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  padding={"5px 0px"}
                  fontSize={"12px"}
                  marginLeft={"5px"}
                >
                  Items per page:
                </Text>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingY="10px"
                gap={0}
              >
                {currentPage > 1 && (
                  <>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() => setCurrentPage(1)}
                    >
                      <Icon as={MdKeyboardDoubleArrowLeft} />
                      Start
                    </Button>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <Icon as={MdKeyboardArrowLeft} />
                      Prev
                    </Button>
                  </>
                )}
                {getPageNumbers(
                  Math.ceil((deductionAdded?.length ?? 0) / itemsPerPage)
                ).map((pageNumber) => (
                  <Button
                    key={pageNumber}
                    variant={"link"}
                    color={"#3454d1"}
                    fontSize={"12px"}
                    fontWeight={"normal"}
                    padding={0}
                    margin={"0px 0px 0px 5px"}
                    onClick={() => {
                      setSelectedPage(pageNumber);
                      setCurrentPage(pageNumber);
                    }}
                    isActive={pageNumber === selectedPage}
                    _active={{
                      color: "black",
                    }}
                  >
                    {pageNumber}
                  </Button>
                ))}
                {currentPage <
                  Math.ceil((deductionAdded?.length ?? 0) / itemsPerPage) && (
                  <>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(
                              (deductionAdded?.length ?? 0) / itemsPerPage
                            )
                          )
                        )
                      }
                    >
                      Next
                      <Icon as={MdKeyboardArrowRight} />
                    </Button>
                    <Button
                      variant={"link"}
                      color={"#3454d1"}
                      fontSize={"12px"}
                      fontWeight={"normal"}
                      padding={0}
                      margin={"0px 0px 0px 5px"}
                      onClick={() =>
                        setCurrentPage(
                          Math.ceil(
                            (deductionAdded?.length ?? 0) / itemsPerPage
                          )
                        )
                      }
                    >
                      End
                      <Icon as={MdKeyboardDoubleArrowRight} />
                    </Button>
                  </>
                )}
              </Stack>
            </Stack> */}
          </Stack>
          <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
            <Button
              colorScheme={"green"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.white"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </Skeleton>
    </FormProvider>
  );
}
