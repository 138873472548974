import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Stack,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { EmployeeBulkUploadPreviewApi } from "../../Api/EmployeeBulkUploadPreviewApi";
import { Employees } from "../../Api/Employees";
import { Preview } from "./Preview";

// Define an enum for form field keys
enum FormFieldKey {
  EmployeeId = "uniqueId",
  Title = "title",
  FirstName = "fname",
  LastName = "lname",
  CNIC = "cnic",
  DateOfBirth = "dob",
  DateOfJoining = "dateOfJoining",
  Position = "position",
  Department = "department",
  Gender = "gender",
  Email = "email",
  MobileNumber = "mobileNumber",
  Address = "address",
  City = "city",
  Province = "province",
  Country = "country",
  PostalCode = "postalCode",
  BankName = "bankName",
  AccountTitle = "accountName",
  IBAN = "iban",
  GROSSSALARY = "grossSalary",
}
type ColumnMappingType = {
  [key in keyof EmployeeBulkUploadPreviewApi.EmployeeBulkUploadData]?: number;
};
export function EmployeeBulkUploadPreview() {
  let Location = useLocation();
  const columns = Location.state.columns;
  const rows = Location.state.rows;
  const [payLoad, setPayLoad] = useState([]);
  const [columnMapping, setColumnMapping] = useState<ColumnMappingType>({});
  const form = useForm<EmployeeBulkUploadPreviewApi.EmployeeBulkUploadData>({
    defaultValues: {
      title: undefined,
      fname: undefined,
      lname: undefined,
      cnic: undefined,
      dob: undefined,
      dateOfJoining: undefined,
      position: undefined,
      department: undefined,
      gender: undefined,
      email: undefined,
      mobileNumber: undefined,
      address: undefined,
      city: undefined,
      province: undefined,
      country: undefined,
      bankName: undefined,
      iban: undefined,
      postalCode: undefined,
      grossSalary: undefined,
      uniqueId: undefined,
      accountName: undefined,
    },
  });
  const handleColumnChange = (selectedOption: any, fieldName: any) => {
    setColumnMapping((prev) => ({
      ...prev,
      [fieldName]: selectedOption.value,
    }));
  };
  const data: {
    name: string;
    formNameValue: keyof EmployeeBulkUploadPreviewApi.EmployeeBulkUploadData;
  }[] = [
    { name: "Employee Id", formNameValue: FormFieldKey.EmployeeId },
    { name: "Title", formNameValue: FormFieldKey.Title },
    {
      name: "First Name",
      formNameValue: FormFieldKey.FirstName,
    },
    { name: "Last Name", formNameValue: FormFieldKey.LastName },
    { name: "Cnic", formNameValue: FormFieldKey.CNIC },
    {
      name: "Date of Birth",
      formNameValue: FormFieldKey.DateOfBirth,
    },
    {
      name: "Date of Joining",
      formNameValue: FormFieldKey.DateOfJoining,
    },
    { name: "Position", formNameValue: FormFieldKey.Position },
    {
      name: "Department",
      formNameValue: FormFieldKey.Department,
    },
    { name: "Gender", formNameValue: FormFieldKey.Gender },
    { name: "Email", formNameValue: FormFieldKey.Email },
    {
      name: "Mobile Number",
      formNameValue: FormFieldKey.MobileNumber,
    },
    { name: "Address", formNameValue: FormFieldKey.Address },
    { name: "City", formNameValue: FormFieldKey.City },
    { name: "Province", formNameValue: FormFieldKey.Province },
    { name: "Country", formNameValue: FormFieldKey.Country },
    { name: "Postal Code", formNameValue: FormFieldKey.PostalCode },

    { name: "Bank Name", formNameValue: FormFieldKey.BankName },
    { name: "Account Title", formNameValue: FormFieldKey.AccountTitle },
    { name: "Iban", formNameValue: FormFieldKey.IBAN },
    { name: "Gross Salary", formNameValue: FormFieldKey.GROSSSALARY },
  ];
  // const matchedDataOptions = [
  //   { value: "TITLE", label: "Title" },
  //   { value: "FIRST_NAME", label: "First Name" },
  //   { value: "LAST_NAME", label: "Last Name" },
  //   { value: "CNIC", label: "CNIC" },
  //   { value: "DATE_OF_BIRTH", label: "Date of Birth" },
  //   {
  //     value: "DATE_OF_JOINING",
  //     label: "Date of Joining",
  //   },
  //   { value: "POSITION", label: "Position" },
  //   { value: "DEPARTMENT", label: "Department" },
  //   { value: "GENDER", label: "Gender" },
  //   { value: "EMAIL", label: "Email" },
  //   {
  //     value: "MOBILE_NUMBER",
  //     label: "Mobile Number",
  //   },
  //   { value: "ADDRESS", label: "Address" },
  //   { value: "CITY", label: "City" },
  //   { value: "PROVINCE", label: "Province" },
  //   { value: "COUNTRY", label: "Country" },
  //   { value: "POSTAL_CODE", label: "Gross Salary" },
  //   { value: "BANK_NAME", label: "Bank Name" },
  //   { value: "IBAN", label: "IBAN" },
  //   { value: "BASE_SALARY", label: "Base Salary" },
  // ];
  useEffect(() => {
    // This function transforms the input rows based on the current column mapping
    const transformPayload = () => {
      return rows.map((row: any) => {
        const obj: { [key: string]: any } = {};
        Object.entries(columnMapping).forEach(([key, value]) => {
          // Ensure the value is defined and within the row's bounds before assigning
          if (typeof value === "number" && value >= 0 && value < row.length) {
            obj[key] = row[value];
          } else {
            obj[key] = undefined; // or any default value you consider appropriate
          }
        });
        return obj;
      });
    };

    const newPayload = transformPayload();
    setPayLoad(newPayload); // Update the payload state with the new transformed data
  }, [columnMapping, rows]);
  const auth = useAuthHeader();
  function convertExcelDateToStandard(excelDate: number): string {
    const baseDate = new Date(1899, 11, 30);
    const jsDate = new Date(
      baseDate.getTime() + excelDate * 24 * 60 * 60 * 1000
    );
    const formattedDate = moment(jsDate).format("YYYY-MM-DD");
    return formattedDate;
  }
  const { setLastUpdated } = useBusinessContext();
  const [isUploading, setIsUploading] = useBoolean(false);
  const navigate = useNavigate();
  const toast = useToast();
  const BulkUploadData = () => {
    const decodedToken = jwtDecode<any>(auth());
    const formattedData = {
      employees: payLoad?.map((item: any) => ({
        uniqueId: item?.uniqueId,
        title: String(item?.title).toUpperCase(),
        firstName: item?.fname,
        lastName: item?.lname,
        cnic: String(item.cnic).replace(/-/g, "").replace(/\s/g, ""),
        dateOfBirth: convertExcelDateToStandard(Number(item.dob)),
        gender: String(item.gender).toUpperCase(),
        email: item.email,
        mobileNumber: String(item.mobileNumber)
          .replace("+92", "0")
          .replace(/-/g, "")
          .replace(/\s/g, ""),
        address: item.address,
        city: item.city,
        province: item.province,
        country: item.country,
        postalCode: String(item.postalCode),
        payTemplate: {
          grossSalary: Number(String(item.grossSalary).replace(/,/g, "")),
        },
        // baseSalary: item.baseSalary,
        businessId: decodedToken.businessId,
        employeeBankDetails: {
          bankName: item.bankName,
          iban: String(item.iban),
          accountNumber: " ",
          branchCode: " ",
          accountName: item.accountName,
        },
        employeeEmployementDetails: {
          startDate: convertExcelDateToStandard(Number(item.dateOfJoining)), // Assuming startDate is provided in Excel date format.
          department: item.department,
          jobTitle: item.position,
        },
      })),
    };
    setIsUploading.on();

    Employees.BulkUploadEmployee(
      formattedData,
      (res) => {
        toast({
          title: "Employees added successfully",
          status: "success",
        });

        setLastUpdated(Date.now());
        // setLastUpdatedEmployees(Date.now());
        setIsUploading.off();
        // handleDelete();
        // onClose();
        navigate("/app/payroll/PayrollEmployee/?page=1&limit=25");
      },
      (err) => {
        setIsUploading.off();
        toast({
          title: "Something went wrong",
          description: err,
          status: "error",
        });
      }
    );
  };
  return (
    <FormProvider {...form}>
      <Stack
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack
          boxShadow={"0 1px 0 0 rgba(0,10,30,.2)"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          h={{ sm: "100px", md: "60px" }}
        >
          <Container maxW={"940px"} padding={0}>
            <HStack justifyContent="space-between">
              <Stack flex={1} direction={{ xs: "column", md: "row" }}>
                <Breadcrumb
                  spacing="8px"
                  separator={<ChevronRightIcon color="gray.500" />}
                >
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      href="PayrollEmployee"
                      color={"primary.950"}
                      fontWeight={"700"}
                    >
                      Employees
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem>
                    <Text
                      fontWeight={"bold"}
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black7",
                      }}
                      padding={"16px 0px"}
                      fontSize={"17px"}
                      margin={"0px 4px 0px 0px"}
                    >
                      Bulk Import
                    </Text>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Stack>
              <HStack>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  marginRight={"8px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isUploading}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    BulkUploadData();
                  }}
                >
                  Add Employees
                </Button>
              </HStack>
            </HStack>
          </Container>
        </Stack>
        <Container maxW={"940"} padding={0}>
          <Stack
            marginTop={"10px"}
            marginBottom={"20px"}
            alignItems="stretch"
            gap={0}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <Stack flex={1} padding={"0px 12px 0px 0px"}>
              <Stack
                border={"1px solid #d6dade"}
                borderTopRadius={"3px"}
                borderBottomRadius={"3px"}
                padding={"0px"}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
                gap={0}
              >
                <HStack padding={"15px"} gap={0}>
                  <Text
                    flex={1}
                    _dark={{
                      color: "customColor.white",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"15px"}
                    fontWeight={"700"}
                  >
                    Match Hris360 data
                  </Text>
                  <Text
                    flex={1}
                    _dark={{
                      color: "customColor.white",
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"15px"}
                    fontWeight={"700"}
                  >
                    ...with your data
                  </Text>
                </HStack>
                {data.map((item, i) => {
                  return (
                    <Stack
                      direction={"row"}
                      padding={"15px"}
                      key={i}
                      gap={0}
                      borderTop={"1px solid #d6dade"}
                    >
                      <Text
                        flex={1}
                        _dark={{
                          color: "customColor.white",
                        }}
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontSize={"13.44px"}
                        fontWeight={"600"}
                      >
                        {item.name}
                      </Text>

                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message:
                                "No Matched Value from Your Uploaded File",
                            },
                          }}
                          control={form.control}
                          name={item.formNameValue}
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={columns?.map((col: any, i: number) => ({
                                label: col,
                                value: i,
                              }))}
                              withValidation
                              isInvalid={fieldState.invalid}
                              ErrorMessage="Please Select Values That Matches With Your Uploaded File "
                              placeholder="Select..."
                              value={
                                columns
                                  .map((col: any, idx: number) => ({
                                    label: col,
                                    value: idx,
                                  }))
                                  .find(
                                    (option: any) =>
                                      option.value ===
                                      columnMapping[item.formNameValue]
                                  ) || { label: "Select...", value: "" }
                              }
                              onChange={(option) =>
                                handleColumnChange(option, item.formNameValue)
                              }
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            <Stack flex={1} padding={"0px 12px 0px 0px"}>
              <Preview payloadData={payLoad} />
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </FormProvider>
  );
}
