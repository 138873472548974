import { Box } from "@chakra-ui/react";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement, Tooltip, Legend);
interface TurnOverChartProps {
  newHiring?: string;
  separation?: string;
}
export default function TurnOverChart({
  newHiring,
  separation,
}: TurnOverChartProps) {
  const turnover = {
    labels: ["New Hiring", "Separation"],
    datasets: [
      {
        data: [newHiring, separation],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)", // Light green color with some transparency
          "rgba(54, 162, 235, 0.2)", // Light blue color with some transparency
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)", // Solid green color
          "rgba(54, 162, 235, 1)", // Solid blue color
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,

        bodyFont: {
          size: 10,
        },
        titleFont: {
          size: 10,
        },
      },
    },
  };
  return (
    <Box w="60px" h="60px">
      {/* <CircularProgress
                  value={42}
                  color="orange.400"
                  thickness="11px"
                  size="40px"
                /> */}
      <Doughnut data={turnover} options={options} />
    </Box>
  );
}
