import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdDelete, MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { EmployeeReferences } from "../../../../Api/EmployeeReferences";
import DeleteReference from "./Modals/DeleteModal";
import ReferencesModal from "./Modals/referencesModal";

interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function References({ setSelectedTab, selectedTab }: TabProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  const toast = useToast();
  const [referencesData, setReferencesData] = useState<
    Array<EmployeeReferences.FetchReferences>
  >([]);
  useEffect(() => {
    const initialCheckboxes = referencesData.reduce((acc: any, item) => {
      acc[item.id] = false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [referencesData]);

  const handleCheckboxChange = (id: string) => {
    setCheckboxes({
      ...checkboxes,
      [id]: !checkboxes[id],
    });
  };

  const handleCheckAll = () => {
    const updatedCheckboxes = Object.keys(checkboxes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: !checkboxes[key],
      }),
      {}
    );
    setCheckboxes(updatedCheckboxes);
  };
  const form = useForm<EmployeeReferences.AddReferences>({
    defaultValues: {
      name: "",
      position: "",
      email: "",
      contact: "",
      referenceType: "PERSONAL",
    },
  });
  const ReferenceType: Array<{ label: string; value: string }> = [
    {
      label: "Personal",
      value: "PERSONAL",
    },
    {
      label: "Professional",
      value: "PROFESSIONAL",
    },
    {
      label: "Others",
      value: "OTHERS",
    },
  ];

  const [deleteId, setDeleteId] = useState<string>("");
  const deleteModal = useDisclosure();
  useEffect(() => {
    EmployeeReferences.GetReferencesDetails(
      id!,
      (data) => {
        setReferencesData(data);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, [lastUpdated]);
  return (
    <FormProvider {...form}>
      <Fragment>
        <Stack
          marginBottom={"30px"}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderRadius={"5px"}
          border={"1px solid #f6f6f6"}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            p="0px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <HStack justifyContent="space-between">
              <Heading
                textAlign="left"
                fontSize=".9975rem"
                _light={{
                  color: "customColor.black7",
                }}
                fontWeight="bold"
                padding="20px"
                as="h2"
                size="md"
              >
                References
              </Heading>
              <Button
                variant="link"
                _light={{
                  color: "primary.950",
                }}
                mr="20px"
                onClick={() => {
                  form.reset({
                    name: "",
                    position: "",
                    email: "",
                    contact: "",
                    referenceType: "PERSONAL",
                  });
                  onOpen();
                }}
              >
                Add Reference
              </Button>
            </HStack>

            <Skeleton isLoaded={!isLoading} height="100%">
              <Box overflowX="auto" p="0px">
                <Table variant="simple" w="100%" overflowX="auto">
                  <Thead h={"36.8px"}>
                    {/* <Tr
                        backgroundColor="#f5f6f8"
                        py="12px"
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        _light={{
                          color: "customColor.black",
                        }}
                      > */}
                    <Tr
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      borderTopWidth={"1px"}
                      borderTopColor="borders.tableBorder"
                    >
                      <Th
                        padding="8px 26px 8px 30px"
                        borderBottomColor="borders.tableBorder"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={Object.values(checkboxes).every(
                            (value) => value
                          )}
                          onChange={handleCheckAll}
                        />
                      </Th>
                      {[
                        { name: "Name" },
                        { name: "Position/Relation" },
                        { name: "Email" },

                        { name: "Contact" },
                        { name: "Reference Type" },

                        { name: "Action" },
                      ].map((item, i) => {
                        return (
                          <Th
                            key={i}
                            padding="8px 26px 8px 15px"
                            borderBottomColor="borders.tableBorder"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            fontWeight={700}
                            whiteSpace="nowrap"
                            fontSize="12px"
                            textTransform="none"
                          >
                            {item.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {referencesData.map((item, i) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            onOpen();
                            form.reset(item);
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              size="md"
                              borderColor="#adb5bd"
                              bgColor="white"
                              isChecked={checkboxes[item.id]}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.name}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.position}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.email}
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.contact}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {
                              ReferenceType.find(
                                (ref) => ref.value === item.referenceType
                              )?.label
                            }
                          </Td>

                          <Td
                            padding="10px"
                            fontWeight={500}
                            fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <HStack alignItems={"center"}>
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                borderWidth={"1px"}
                                as={MdEdit}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={(e) => {
                                  onOpen();
                                  e.stopPropagation();
                                  form.reset(item);
                                }}
                              />
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                borderWidth={"1px"}
                                as={MdDelete}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={(e) => {
                                  setDeleteId(item.id);
                                  e.stopPropagation();
                                  deleteModal.onOpen();
                                }}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Stack alignItems={"flex-end"} margin={"20px 20px 20px 0px"}>
                <Button
                  colorScheme="green"
                  border={"1px solid green"}
                  textColor={"customColor.white"}
                  borderRadius={"3px"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  marginRight={"5px"}
                  fontWeight={600}
                  onClick={() => setSelectedTab(selectedTab + 1)}
                >
                  Next
                </Button>
              </Stack>
            </Skeleton>
          </Box>
        </Stack>

        <ReferencesModal
          isOpen={isOpen}
          onClose={onClose}
          setLastUpdated={setLastUpdated}
          empId={id}
          referenceType={ReferenceType}
        />
        <DeleteReference
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={deleteId}
          setLastUpdated={setLastUpdated}
        />
      </Fragment>
    </FormProvider>
  );
}
