import {
  Box,
  Button,
  Checkbox,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { PayItemLeaveApi } from "../../../../../Api/PayItemLeave";
interface UnpaidLeaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedLeaveId: string | undefined;
}
export default function UnpaidLeaveModal({
  isOpen,
  onClose,
  setLastUpdated,
  selectedLeaveId,
}: UnpaidLeaveModalProps) {
  const form = useFormContext<PayItemLeaveApi.AddNewLeave>();
  const toast = useToast();
  const unusedBalanceOptions = [
    { value: "NOT_PAID_OUT", label: "Not Paid Out" },
    { value: "PAID_OUT", label: "Paid Out" },
  ];
  const [isLoading, setIsLoading] = useState(false);
  return (
    <FormProvider {...form}>
      <Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            maxW="360px"
            maxH={"650px"}
          >
            <ModalHeader
              fontSize={"15.21px"}
              margin={"15.21px 0pxs"}
            ></ModalHeader>
            <ModalCloseButton />
            <Box overflowY="auto">
              <ModalBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={
                    "https://edge.xero.com/people/payroll/assets/images/settings/pay-items/timeoff.svg"
                  }
                />
                <Heading
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black2",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"15px 0px"}
                  textAlign={"center"}
                >
                  Unpaid Leave
                </Heading>
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  mb={"5px"}
                >
                  Leave Name
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Leave Name is required",
                    },
                  }}
                  control={form.control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  mb={"5px"}
                >
                  Type of Units
                </Text>
                {/* <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Type of Units is required",
                    },
                  }}
                  control={form.control}
                  name="unitType"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                /> */}
                <Controller
                  control={form.control}
                  name="unitType"
                  rules={{
                    required: {
                      value: true,
                      message: "Type of Unit is required",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select unit type"
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={error !== undefined}
                      ErrorMessage="Select unit type"
                      withValidation
                      options={[
                        { value: "DAYS", label: "Days" },
                        { value: "HOURS", label: "Hours" },
                      ]}
                      value={
                        field.value && {
                          value: field.value,
                          label: field.value,
                        }
                      }
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                <Text
                  fontSize={"11px"}
                  margin={"5px 0px 0px"}
                  lineHeight={"16px"}
                >
                  The type of units by which leave entitlements are normally
                  tracked. These are typically the same as the type of units
                  used for the employee's ordinary earnings rate.
                </Text>
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Normal Entitlement (optional)
                </Text>
                <Controller
                  control={form.control}
                  name="normalEntitlement"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "number",
                        onChange: (e) => {
                          field.onChange(Number(e.target.value));
                        },
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
                <Text
                  fontSize={"11px"}
                  margin={"5px 0px 0px"}
                  lineHeight={"16px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  The number of units the employee is entitled to each year.
                </Text>
              </ModalBody>

              <ModalBody marginTop={"10px"}>
                <Stack>
                  <Controller
                    control={form.control}
                    name="showBalancePayslip"
                    render={({
                      field: { value, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        isChecked={value}
                        isInvalid={error !== undefined}
                        colorScheme="rgb(50,70,90,.35)"
                        margin={"1px 7px 0px 0px"}
                        iconColor={"#000a1e"}
                        // isChecked={value}
                      >
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Show Balance on Payslip
                        </Text>
                      </Checkbox>
                    )}
                  />
                  <Text
                    lineHeight={"16px"}
                    fontSize={"11px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding={"0px 0px 15px 15px"}
                  >
                    Check this box if you want a balance for this leave type to
                    be shown on your employee's payslips.
                  </Text>
                </Stack>
              </ModalBody>
            </Box>

            <ModalFooter>
              <Button
                onClick={onClose}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                marginLeft={"8px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedLeaveId) {
                      PayItemLeaveApi.UpdateLeave(
                        selectedLeaveId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayItemLeaveApi.NewLeaveAdded(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedLeaveId ? "Edit" : "Add"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </FormProvider>
  );
}
