import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { AiFillCloseSquare } from "react-icons/ai";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import { PiArrowSquareRightFill } from "react-icons/pi";
import { CustomInput } from "../../../Common/CustomInput";
import { DeleteModal } from "./DeleteModal";
import { EditModal } from "./EditModal";
export function TrackingCategories() {
  const form = useForm({
    defaultValues: {
      category: "",

      category_options: [
        {
          options: "",
        },
      ],
    },
  });
  const deleteModal = useDisclosure();
  const editModal = useDisclosure();
  const [data, setData] = useState("");
  const categoryOptions = useFieldArray({
    control: form.control,
    name: "category_options",
  });
  return (
    <FormProvider {...form}>
      <Box
        paddingBottom={"50px"}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Container
          h="64px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          maxW={"100%"}
          padding={"0px 20px"}
          justifyContent={"center"}
          borderBottom={"1px solid #ddd"}
        >
          <Container maxW={"940px"} padding={"10px 0px 0px"}>
            <Breadcrumb
              separator={<MdChevronRight color="gray.500" />}
              paddingTop={"5px"}
            >
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="/app/payroll/advancedSettings"
                  fontSize={"11px"}
                  color={"#3454d1"}
                >
                  Advanced Accounting
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href=""
                  fontSize={"11px"}
                  _light={{
                    color: "customColor.black6",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  padding={"0px 20px 0px 0px"}
                ></BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Heading
              fontWeight={"bold"}
              _light={{
                color: "customColor.black",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"24px"}
              margin={"0px 4px 0px 0px"}
            >
              Tracking Categories
            </Heading>
          </Container>
        </Container>

        <Container maxW={"980px"} padding={"21.12px"}>
          <Button
            leftIcon={<BsFillPlusSquareFill size={"15px"} />}
            padding={"7.52px 12px"}
            cursor={"pointer"}
            color={"#048fc2"}
            size={"sm"}
            fontSize={"12px"}
            fontWeight={"700"}
            textAlign={"center"}
            border={"1px solid #e6e6e6"}
            borderRadius={"3px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            _hover={{
              color: "#005e7b",
              bg: "#fff",
            }}
          >
            Add Tracking Category
          </Button>
          <Tabs variant="enclosed" marginTop={"30px"}>
            <TabList>
              <Tab
                _selected={{
                  _dark: {
                    bgColor: "customColor.dark.50",
                    color: "custom.white",
                  },
                  _light: {
                    bgColor: "customColor.white",
                    color: "customColor.black4",
                  },
                }}
                color="#048FC2"
                fontSize={"12px"}
                fontWeight={"bold"}
              >
                Region
              </Tab>
              <Tab
                _selected={{
                  _dark: {
                    bgColor: "customColor.dark.50",
                    color: "custom.white",
                  },
                  _light: {
                    bgColor: "customColor.white",
                    color: "customColor.black4",
                  },
                }}
                color="#048FC2"
                fontSize={"12px"}
                fontWeight={"bold"}
              >
                Untitled
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <Stack
                  marginTop={"20px"}
                  w={data ? "285px" : "350px"}
                  padding={"0"}
                  bgColor={"#E8F1FF"}
                  border={"1px solid #c1c1c1"}
                >
                  <Stack padding={"10px"}>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Tracking category name
                    </Text>
                    <Stack
                      padding={"5px"}
                      margin={"3px 0px 0px"}
                      bgColor={"#D7E7FF"}
                      gap={0}
                      marginBottom={"5px"}
                    >
                      {data ? (
                        <Stack direction={"row"} padding={"3px 0px 4px 0px"}>
                          <Text
                            _dark={{
                              color: "customColor.white",
                            }}
                            _light={{
                              color: "customColor.black6",
                            }}
                            fontSize={"12px"}
                            flex={1}
                          >
                            Region
                          </Text>
                          <HStack gap={0} alignItems={"center"}>
                            <Icon
                              fontSize={"20px"}
                              as={AiFillCloseSquare}
                              color={"grey"}
                              _hover={{
                                color: "red.500",
                              }}
                              onClick={() => deleteModal.onOpen()}
                            />
                            <Button
                              variant={"link"}
                              fontSize={"12px"}
                              margin={"0px 0px 0px 10px"}
                              onClick={() => editModal.onOpen()}
                            >
                              Rename
                            </Button>
                          </HStack>
                        </Stack>
                      ) : (
                        <Stack padding={"6px 0px 8px"}>
                          <Controller
                            name={`category`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message: "Category name is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",
                                  w: "100%",
                                  fontSize: "12px",
                                  backgroundColor: "#fff",
                                  variant: "outline",
                                  fontWeight: "500",
                                  lineHeight: "1.2",
                                  h: "19px",

                                  padding: "0px 0px 0px px",
                                  type: "text",
                                  border: "1px solid",
                                  borderColor: "gray.200",
                                  borderRadius: "1px",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Text
                      _dark={{
                        color: "customColor.white",
                      }}
                      _light={{
                        color: "customColor.black6",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Category options
                    </Text>
                    {data && data.length > 0 ? (
                      <Stack
                        border={"1px solid #d0d8e5"}
                        margin={"3px 0px 0px"}
                        padding={"5px"}
                      >
                        {["Eastside", "North", "South", "West Coast"].map(
                          (item, i) => {
                            return (
                              <Stack key={i} direction={"row"}>
                                <Text
                                  _dark={{
                                    color: "customColor.white",
                                  }}
                                  _light={{
                                    color: "customColor.black6",
                                  }}
                                  fontSize={"12px"}
                                  flex={1}
                                >
                                  {item}
                                </Text>
                                <HStack gap={0} alignItems={"center"}>
                                  <Icon
                                    fontSize={"20px"}
                                    as={AiFillCloseSquare}
                                    color={"grey"}
                                    _hover={{
                                      color: "red.500",
                                    }}
                                    onClick={() => deleteModal.onOpen()}
                                  />
                                  <Button
                                    variant={"link"}
                                    fontSize={"12px"}
                                    margin={"0px 0px 0px 10px"}
                                    onClick={() => editModal.onOpen()}
                                  >
                                    Rename
                                  </Button>
                                </HStack>
                              </Stack>
                            );
                          }
                        )}
                      </Stack>
                    ) : (
                      <Stack
                        border={"1px solid #d0d8e5"}
                        margin={"3px 0px 0px"}
                        padding={"5px"}
                        gap={0}
                      >
                        {categoryOptions.fields.map((field, index) => (
                          <Stack
                            padding={"6px 0px 8px"}
                            key={field.id}
                            direction={"row"}
                            alignContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Controller
                              name={`category_options.${index}.options`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "option name is required",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <CustomInput
                                  ref={field.ref}
                                  validationMessage={error?.message}
                                  withValidation
                                  input={{
                                    ...field,
                                    isInvalid: error !== undefined,
                                    placeholder: "",
                                    w: "100%",
                                    fontSize: "12px",
                                    backgroundColor: "#fff",
                                    variant: "outline",
                                    fontWeight: "500",
                                    lineHeight: "1.2",
                                    h: "19px",
                                    padding: "0px 0px 0px px",
                                    type: "text",
                                    border: "1px solid",
                                    borderColor: "gray.200",
                                    borderRadius: "1px",
                                  }}
                                />
                              )}
                            />
                            {categoryOptions.fields.length > 1 && (
                              <Icon
                                fontSize={"20px"}
                                as={IoIosCloseCircleOutline}
                                color={"grey"}
                                _hover={{
                                  color: "red.500",
                                }}
                                onClick={() => categoryOptions.remove(index)}
                              />
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    )}

                    <Stack padding={"10px 0px"}>
                      <Button
                        leftIcon={<PiArrowSquareRightFill size={"15px"} />}
                        padding={"7.52px 12px"}
                        cursor={"pointer"}
                        color={"#048fc2"}
                        size={"sm"}
                        fontSize={"12px"}
                        w={"75%"}
                        fontWeight={"700"}
                        textAlign={"left"}
                        border={"1px solid #e6e6e6"}
                        borderRadius={"3px"}
                        _dark={{
                          bgColor: "customColor.dark.50",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                        }}
                        _hover={{
                          color: "#005e7b",
                          bg: "#fff",
                        }}
                        onClick={() => {
                          categoryOptions.append({
                            options: "",
                          });
                        }}
                      >
                        Add another option
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack
                    borderTop={"1px solid #c1c1c1"}
                    bgColor={"#D7E7FF"}
                    padding={"8px 0px 10px"}
                  >
                    <HStack
                      justifyContent={{ xs: "flex-Start", md: "flex-end" }}
                      padding={"0px 10px"}
                      gap={0}
                    >
                      <Button
                        padding={"7.52px 12px"}
                        fontSize={"12px"}
                        colorScheme="primary"
                        w={"94px"}
                        h={"34px"}
                        borderRadius="6px"
                        border={"1px solid #3a9101"}
                        bg={"linear-gradient(#4fc601,#44aa01)"}
                        _hover={{
                          border: "1px solid #327d01",
                          bg: "linear-gradient(#47b201,#3c9601)",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        padding={"7.52px 12px"}
                        fontSize={"12px"}
                        marginLeft={"10px"}
                        marginRight={"10px"}
                        w="86px"
                        h={"34px"}
                        border={"1px solid #899094"}
                        bg={"linear-gradient(#b9bcc0,#9fa4a8)"}
                        borderRadius="6px"
                        _hover={{
                          border: "1px solid #899094",
                          bg: "linear-gradient(#aeb2b6,#949a9e)",
                        }}
                      >
                        Cancel
                      </Button>
                    </HStack>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
        <DeleteModal
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
        />
        <EditModal isOpen={editModal.isOpen} onClose={editModal.onClose} />
      </Box>
    </FormProvider>
  );
}
