import {
  Avatar,
  Box,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { jwtDecode } from "jwt-decode";
import { useAuthHeader, useAuthUser, useSignOut } from "react-auth-kit";
import { AiOutlineSetting } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdLogOut } from "react-icons/io";
import { MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Hrislogo from "../../assets/HRISMainLogo.png";
import MenuTab from "./Tabs/MenuTab";

interface SideBarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
}

export default function SideBar({
  isSidebarOpen,
  setIsSidebarOpen,
}: SideBarProps) {
  const isMedScreen = useBreakpointValue({
    xs: false,
    lg: true,
  });
  const auth = useAuthHeader();
  const decodedToken = jwtDecode<any>(auth());
  useEffect(() => {
    setIsSidebarOpen(isMedScreen!);
  }, [isMedScreen]);

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };
  const [userName, setUserName] = useState("");
  const toast = useToast();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const user = useAuthUser();
  const { isOpen, onToggle } = useDisclosure();
  useEffect(() => {
    const UserDetail = user();

    if (UserDetail) {
      const fullName = UserDetail.firstName + " " + UserDetail.lastName;
      setUserName(fullName); // Update the state variable
    } else {
      toast({
        title: "Name not found in the user object",
        status: "error",
      });
    }
  }, []);
  return (
    <Box
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "#fff",
      }}
    >
      <Stack h="100vh" justifyContent="space-between" spacing={0}>
        <motion.div
          animate={{ width: isSidebarOpen ? "253.88px" : 0 }}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            height: "100vh",
          }}
        >
          <VStack
            position="sticky"
            w={"253.88px"}
            shadow="md"
            gap={0}
            bg={"#fff"}
            zIndex={1}
            overflowY="auto"
            h="100%"
            sx={{
              "::-webkit-scrollbar": {
                width: "0px", // Initially, the scrollbar is hidden
                background: "transparent",
              },
              ":hover::-webkit-scrollbar": {
                width: "8px", // Scrollbar visible on hover
              },
              ":active::-webkit-scrollbar": {
                width: "8px", // Scrollbar visible when active
              },
              ":focus::-webkit-scrollbar": {
                width: "8px", // Scrollbar visible on focus
              },
              // Add for Firefox and other browsers if needed
              scrollbarWidth: "none",
              ":hover": {
                scrollbarWidth: "thin",
              },
              ":active": {
                scrollbarWidth: "thin",
              },
              ":focus": {
                scrollbarWidth: "thin",
              },
            }}
          >
            <Box
              w={"100%"}
              bg={"#fff"}
              h="70px"
              position="sticky"
              top={0}
              zIndex="9999"
            >
              <Flex
                justifyContent="space-between"
                align="center"
                justify="center"
                w="100%"
                h="70px"
                borderRight={"1px solid #e5e7eb"}
                borderBottom={"1px solid #e5e7eb"}
                // pl="24px"
                // pr="16px"
                // marginTop="10px"
                // marginBottom={"10px"}
                padding={"15px 30px"}
              >
                <Image
                  src={Hrislogo}
                  w="70%"
                  display={{ xs: "flex", lg: "flex" }}
                />
                {/* <IconButton
                  fontWeight="900"
                  fontStyle="normal"
                  color={"#A8B0C5"}
                  _hover={{ bg: "none" }}
                  aria-label={"Sidebar-button"}
                  icon={<LuMenu size="100%" />}
                  variant="outline"
                  borderWidth="0px"
                  h="24px"
                  w="20px"
                  onClick={toggleSidebar}
                /> */}
              </Flex>
            </Box>
            <Box w={"100%"} p={0} borderRight={"1px solid #e5e7eb"}>
              {/* <Tabs>
                <TabList>
                  <Tab
                    fontSize="11px"
                    fontWeight="600"
                    w={"100%"}
                    color={"customColor.gray.100"}
                    textTransform={"uppercase"}
                    cursor="pointer"
                    padding={"6px 16px"}
                    _selected={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#dee2e6 #dee2e6 #f4f7f6",
                      color: "#A27CE6",
                    }}
                    flex={3}
                  >
                    Menu
                  </Tab>

                  <Tab
                    flex={1}
                    display="none"
                    fontSize="11px"
                    fontWeight="600"
                    w={"100%"}
                    color={"customColor.gray.100"}
                    textTransform={"uppercase"}
                    cursor="pointer"
                    padding={"6px 16px"}
                    _selected={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "#dee2e6 #dee2e6 #f4f7f6",
                      color: "#A27CE6",
                    }}
                  >
                    <Icon
                      h={"14px "}
                      w={"14.4px"}
                      as={IoSettingsOutline}
                      padding={0}
                      bgColor={"transparent"}
                      _hover={{
                        bgColor: "transparent",
                      }}
                    />
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel padding={0}>
                    <MenuTab />
                  </TabPanel>

                  <TabPanel padding={0}>
                    <Setting />
                  </TabPanel>
                </TabPanels>
              </Tabs> */}
              <MenuTab />
            </Box>
          </VStack>

          <Stack
            gap={0}
            paddingBottom={"10px"}
            position="sticky"
            bottom="0"
            borderTop={"1px solid #f3f5f9"}
            display={{ base: "none", md: "none", lg: "block", xl: "block" }}
            _dark={{
              bgColor: "customColor.dark.50",
              color: "customColor.white",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            textAlign={"left"}
            bgColor="primary.light.500"
            w="230"
            zIndex="9999"
          >
            <HStack justifyContent={"space-between"} padding={"9.6px 17.6px"}>
              <Stack direction={"row"}>
                <Avatar
                  w="34px"
                  h="34px"
                  name={userName}
                  //   src="https://bit.ly/dan-abramov"
                />
                <Stack gap={0}>
                  <Text
                    fontSize={".9375rem"}
                    fontWeight={"700"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {userName}
                  </Text>
                  <Text
                    _light={{
                      color: "customColor.gray.50",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontSize={"12px"}
                    fontWeight={"400"}
                  >
                    {toCamelCase(decodedToken.accessLevel)}
                  </Text>
                </Stack>
              </Stack>
              <Stack>
                {" "}
                <IconButton
                  onClick={onToggle}
                  aria-label={"sum-button"}
                  icon={isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                  bg={"transparent"}
                  _light={{
                    color: "customColor.black2",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  _hover={{
                    bg: "transparent",
                  }}
                />
              </Stack>
            </HStack>

            <Collapse in={isOpen} animateOpacity>
              <Stack gap={0} padding={"10px 0px"}>
                <Stack
                  borderRadius={"5px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  margin={"0px 10px"}
                  padding={"10px 15px"}
                  _hover={{
                    _light: {
                      color: "customColor.black8",
                    },
                    bg: "#eaebef",
                    transition: "all .3s ease",
                  }}
                  direction={"row"}
                  onClick={() => navigate("/app/payroll/UserProfile")}
                >
                  <Icon as={CgProfile} size="16px" color={"#283C50"} />
                  <Text
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    whiteSpace={"nowrap"}
                    fontSize="13px"
                  >
                    View Profile
                  </Text>
                </Stack>
                <Stack
                  borderRadius={"5px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  margin={"0px 10px"}
                  padding={"10px 15px"}
                  _hover={{
                    _light: {
                      color: "customColor.black8",
                    },
                    bg: "#eaebef",
                    transition: "all .3s ease",
                  }}
                  direction={"row"}
                  onClick={() => {
                    //   navigate("/app/SwitchBusiness");
                    navigate("/app/payroll/ChangePasswordEmail");
                  }}
                >
                  <Icon as={MdPassword} size="16px" color={"#283C50"} />
                  <Text
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    whiteSpace={"nowrap"}
                    fontSize="13px"
                  >
                    Change Password
                  </Text>
                </Stack>

                <Stack
                  borderRadius={"5px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  margin={"0px 10px"}
                  padding={"10px 15px"}
                  _hover={{
                    _light: {
                      color: "customColor.black8",
                    },
                    bg: "#eaebef",
                    transition: "all .3s ease",
                  }}
                  direction={"row"}
                  onClick={() => {
                    //   navigate("/app/SwitchBusiness");
                    navigate("/app/SwitchBusiness");
                  }}
                >
                  <Icon
                    as={HiOutlineSwitchHorizontal}
                    size="16px"
                    color={"#283C50"}
                  />
                  <Text
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    whiteSpace={"nowrap"}
                    fontSize="13px"
                  >
                    Switch Business
                  </Text>
                </Stack>
                {decodedToken.accessLevel !== "EMPLOYEE" &&
                  decodedToken.accessLevel !== "CLIENT" && (
                    <Stack
                      borderRadius={"5px"}
                      cursor={"pointer"}
                      margin={"0px 10px"}
                      alignItems={"center"}
                      padding={"10px 15px"}
                      _hover={{
                        _light: {
                          color: "customColor.black8",
                        },
                        bg: "#eaebef",
                        transition: "all .3s ease",
                      }}
                      direction={"row"}
                      onClick={() => {
                        navigate("/app/payroll/organisationSettings");
                      }}
                    >
                      <Icon
                        as={AiOutlineSetting}
                        size="16px"
                        color={"#283C50"}
                      />
                      <Text
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        whiteSpace={"nowrap"}
                        fontSize="13px"
                      >
                        Settings
                      </Text>
                    </Stack>
                  )}
                <Stack
                  borderRadius={"5px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  margin={"0px 10px"}
                  padding={"10px 15px"}
                  _hover={{
                    _light: {
                      color: "customColor.black8",
                    },
                    bg: "#eaebef",
                    transition: "all .3s ease",
                  }}
                  direction={"row"}
                  onClick={() => {
                    signOut();
                    toast({
                      title: "Logout Successful",
                      status: "success",
                    });
                    navigate("/");
                  }}
                >
                  <Icon as={IoMdLogOut} size="16px" color={"#283C50"} />
                  <Text
                    fontWeight="600"
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    whiteSpace={"nowrap"}
                    fontSize="13px"
                  >
                    Sign out
                  </Text>
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </motion.div>
      </Stack>
    </Box>
  );
}
function toCamelCase(str: any) {
  return str
    .toLowerCase()
    .replace(/(?:^|\s)\w/g, (match: any) => match.toUpperCase());
}
