import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { AddEmployeeNotifications } from "../../../Api/EmployeeNotifications";
import { NotificationGroup } from "../../../Api/NotificationGroup";

interface EmployeeNotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
  selectedNotificationId?: string;
}

export default function EmployeeNotificationModal({
  isOpen,
  onClose,
  empId,
  setLastUpdated,
  selectedNotificationId,
}: EmployeeNotificationModalProps) {
  const { employeesList } = useBusinessContext();

  const form = useFormContext<AddEmployeeNotifications.AddNotification>();
  const toast = useToast();

  const allEmployeesOption = {
    id: "all",
    firstName: "All",
    lastName: "Employees",
  };
  const [notificationGroups, setNotificationGroups] = useState<any>([]);
  useEffect(() => {
    NotificationGroup.FetchNotificationGroups(
      {
        page: 1,
        limit: 1000,
      },
      (data) => {
        setNotificationGroups(data?.data);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    if (selectedNotificationId) {
      AddEmployeeNotifications.GetNotificationById(
        selectedNotificationId,
        (data) => {
          form.reset({
            action: data?.scheduledTime
              ? AddEmployeeNotifications.NotificationScheduleType.Schedule
              : AddEmployeeNotifications.NotificationScheduleType.Immediate,
            employeeIds: data?.employeeIds,
            message: data?.message,
            scheduledTime: data?.scheduledTime
              ? moment(data?.scheduledTime).format("YYYY-MM-DDTHH:mm")
              : undefined,
            title: data?.title,
            type: data?.type,
          });
        },
        (error) => {
          toast({
            title: error,
            status: "error",
            isClosable: true,
          });
          onClose();
        }
      );
    }
  }, [selectedNotificationId]);
  const watchEmployeeIds = useWatch({
    control: form.control,
    name: "employeeIds",
  });
  const watchgroup = useWatch({
    control: form.control,
    name: "notificationUserGroupId",
  });
  const actionOptions: Array<{ value: string; label: string }> = [
    {
      value: AddEmployeeNotifications.NotificationScheduleType.Immediate,
      label: "Immediate",
    },
    {
      value: AddEmployeeNotifications.NotificationScheduleType.Schedule,
      label: "Schedule",
    },
  ];
  const watchAction = useWatch({
    control: form.control,
    name: "action",
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Add Employee Notifications
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Stack gap={0} marginY={"8px"}>
            <Text
              fontSize={"13px"}
              padding={"1px 0px 4px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
              mb={"5px"}
            >
              Select Employees
            </Text>
            <Controller
              control={form.control}
              name="employeeIds"
              render={({ field, fieldState }) => {
                return (
                  <ReactSelect
                    {...field}
                    withValidation
                    isMulti
                    isDisabled={watchgroup != null || watchgroup !== undefined}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Please Select Employee"
                    placeholder="Select Employee"
                    options={[allEmployeesOption, ...(employeesList || [])]}
                    value={
                      field.value?.includes("all")
                        ? [allEmployeesOption]
                        : employeesList?.filter((emp: any) =>
                            field.value?.includes(emp.id)
                          ) ?? []
                    }
                    onChange={(val: any) => {
                      if (val.some((emp: any) => emp.id === "all")) {
                        // field.onChange(["all"]);
                        field.onChange(
                          employeesList &&
                            employeesList.map((emp: any) => emp.id)
                        );
                      } else {
                        field.onChange(val.map((emp: any) => emp.id));
                      }
                      form.setValue("notificationUserGroupId", undefined);
                    }}
                    getOptionLabel={(option: any) => {
                      return option.id === "all"
                        ? "All Employees"
                        : `${option?.firstName} ${option?.lastName}`;
                    }}
                    getOptionValue={(option: any) => option.id}
                  />
                );
              }}
            />
          </Stack>
          <Stack gap={0}>
            <Text
              fontSize={"13px"}
              padding={"1px 0px 4px"}
              fontWeight={"600"}
              _light={{
                color: "customColor.black7",
              }}
              mb={"5px"}
            >
              Select Notification Group
            </Text>
            <Controller
              control={form.control}
              name="notificationUserGroupId"
              render={({ field, fieldState }) => {
                return (
                  <ReactSelect
                    {...field}
                    placeholder="Select Notification Group"
                    isDisabled={(watchEmployeeIds?.length ?? 0) > 0}
                    options={notificationGroups?.map((grp: any) => ({
                      value: grp.id,
                      label: grp.name,
                    }))}
                    isClearable
                    value={
                      notificationGroups.find(
                        (grp: any) => grp.id === field.value
                      )
                        ? {
                            value: field.value,
                            label: notificationGroups?.find(
                              (grp: any) => grp.id === field.value
                            )?.name,
                          }
                        : null
                    }
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.value);
                      form.setValue("employeeIds", undefined);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                );
              }}
            />
          </Stack>
          <Controller
            name="title"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Title is required",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <CustomInput
                ref={field.ref}
                validationMessage={error?.message}
                withValidation
                label="Title"
                labelProps={{
                  fontSize: "13px",
                  marginTop: "8px",
                  fontWeight: "600",
                  _light: {
                    color: "customColor.black7",
                  },
                }}
                input={{
                  ...field,
                  isInvalid: error !== undefined,
                  w: "100%",
                  h: "38px",
                  fontSize: ".845rem",
                  variant: "outline",
                  type: "text",
                  padding: "12px 15px",
                  borderTopRadius: "3px",
                }}
                placeholder="Title"
              />
            )}
          />
          <Stack gap={0} marginTop={"8px"}>
            <FormLabel
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 8px"}
            >
              Action
            </FormLabel>
            <Controller
              name="action"
              rules={{
                required: {
                  value: true,
                  message: "Action is required",
                },
              }}
              control={form.control}
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  withValidation
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Please Select Action"
                  placeholder="Select Action"
                  options={actionOptions}
                  value={actionOptions.find(
                    (action) => action.value === field.value
                  )}
                  onChange={(val: any) => {
                    field.onChange(val.value);
                    form.setValue("scheduleDate", undefined);
                    form.setValue("scheduledTime", undefined);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />
          </Stack>
          {watchAction ===
            AddEmployeeNotifications.NotificationScheduleType.Schedule && (
            <Stack gap={0} marginTop={"8px"}>
              <Controller
                name="scheduledTime"
                control={form.control}
                render={({ field }) => (
                  <CustomInput
                    {...field}
                    withValidation
                    label="Schedule Date and Time"
                    labelProps={{
                      fontSize: "13px",
                      marginTop: "8px",
                      fontWeight: "600",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    input={{
                      ...field,
                      w: "100%",
                      h: "38px",
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "datetime-local",
                      padding: "12px 15px",
                      borderTopRadius: "3px",
                    }}
                    placeholder="Schedule Date and Time"
                  />
                )}
              />
            </Stack>
          )}
          <Stack gap={0} marginTop={"8px"}>
            <FormLabel
              fontSize=".845rem"
              fontWeight="500"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              margin={"0px 0px 8px"}
            >
              Message
            </FormLabel>
            <Controller
              name="message"
              rules={{
                required: {
                  value: true,
                  message: "Message is required",
                },
              }}
              control={form.control}
              render={({ field, fieldState }) => (
                <>
                  <Textarea
                    {...field}
                    placeholder="Add a message"
                    fontSize=".845rem"
                    isInvalid={fieldState.invalid}
                    fontWeight={400}
                    border="1px solid #e5e7eb"
                    _hover={{
                      border: "1px solid hsl(0, 0%, 70%)",
                    }}
                    _focus={{
                      borderWidth: "1px",

                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _focusVisible={{
                      borderWidth: "1px",
                      boxShadow: "0 0 0 1px #2684FF",
                      _hover: {
                        borderWidth: "1px",
                        borderColor: "#2684FF",
                        boxShadow: "0 0 0 1px #2684FF",
                      },
                    }}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding="12px 15px"
                  />
                </>
              )}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"8px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            onClick={form.handleSubmit((data) => {
              const payload = data;
              if (data?.employeeIds?.length === 0) {
                delete payload.employeeIds;
              }
              if (selectedNotificationId) {
                AddEmployeeNotifications.UpdateNotification(
                  selectedNotificationId,
                  {
                    ...payload,
                    type: AddEmployeeNotifications.NotificationTypes.Email,
                    scheduledTime: moment(payload.scheduledTime).utc().format(),
                    scheduleDate: moment(payload.scheduledTime).format(
                      "YYYY-MM-DD"
                    ),
                  },
                  (response) => {
                    toast({
                      title: "Notification updated successfully",
                      status: "success",
                      isClosable: true,
                    });
                    setLastUpdated(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: error,
                      status: "error",
                      isClosable: true,
                    });
                    onClose();
                  }
                );
              } else {
                AddEmployeeNotifications.AddNotification(
                  {
                    ...payload,
                    type: AddEmployeeNotifications.NotificationTypes.Email,
                    scheduledTime: moment(payload.scheduledTime).utc().format(),
                    scheduleDate: moment(payload.scheduledTime).format(
                      "YYYY-MM-DD"
                    ),
                  },
                  (response) => {
                    toast({
                      title: "Notification sent successfully",
                      status: "success",
                      isClosable: true,
                    });
                    setLastUpdated(Date.now());
                    onClose();
                  },
                  (error) => {
                    toast({
                      title: error,
                      status: "error",
                      isClosable: true,
                    });
                    onClose();
                  }
                );
              }
            })}
          >
            {selectedNotificationId ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
