import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AccessControl } from "../../../api/AccessControl";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { MappingModuleApi } from "../../Api/MappingModule";
import { PayrollCalendar } from "../../Api/PayRollCalendar";

interface EmploymentModalProps {
  isOpen: boolean;
  users: AccessControl.UserDetail[];
  earningAdded: any[];
  payOptions: PayrollCalendar.Calendar[];
  onClose: () => void;
  selectedEmployeeIds: any[] | undefined;
}
export function EmploymentModal({
  isOpen,
  onClose,
  users,
  payOptions,
  selectedEmployeeIds,
  earningAdded,
}: EmploymentModalProps) {
  const employeeOptions = [
    { value: "6561a85a-0aee-4f60-969d-21a90ddf2975", label: "None" },
  ];
  const incomeTypeOptions = [
    { value: "SALARY_AND_WAGES", label: "Monthly Salary" },
    { value: "CLOSELY_HELD_PAYEES", label: "Closely held payees" },
    { value: "WORKING_HOLIDAY_MAKER", label: "Working holiday maker" },
    {
      value: "PER_HOUR_RATE",
      label: "Per Hour Rate",
    },
    {
      value: "PER_DAY_RATE",
      label: "Per Day Rate",
    },
  ];

  const staffOption = [
    { value: "MANAGEMENT", label: "Management" },
    { value: "NON_MANAGEMENT", label: "Non Management" },
  ];

  const employeeBasicOptions = [
    { value: "FULL_TIME", label: "Full-time" },
    { value: "PART_TIME", label: "Part-time" },
    { value: "CASUAL", label: "Daily Wager" },
  ];
  const empType = [
    { value: "EMPLOYEE", label: "Permanent" },
    { value: "CONTRACTOR", label: "Contractual" },
  ];

  const toast = useToast();
  const form = useForm<MappingModuleApi.EmploymentDetailData>({
    defaultValues: {
      empType: undefined,
      empIncomeType: undefined,
      empBasis: undefined,
      payrollPayCalenderId: undefined,
      employeeGroupId: undefined,

      ratesId: undefined,
      startDate: undefined,
      department: undefined,
      jobTitle: undefined,
      isManagementStaff: undefined,
      manager: undefined,
      ids: selectedEmployeeIds,
    },
  });
  useEffect(() => {
    if (isOpen) {
      form.reset({
        empType: undefined,
        empIncomeType: undefined,
        empBasis: undefined,
        payrollPayCalenderId: undefined,
        employeeGroupId: undefined,
        ratesId: undefined,
        startDate: undefined,
        department: undefined,
        jobTitle: undefined,
        isManagementStaff: undefined,
        manager: undefined,
        ids: selectedEmployeeIds,
      });
    }
  }, [isOpen]);
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"980px"}>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Employment Modal
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            padding={"16px 24px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            border={"1px solid #E5E5E5"}
          >
            <SimpleGrid spacing={10} columns={{ xs: 2, md: 4 }}>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px 0px"}
                >
                  Employment Type
                </Text>
                <Controller
                  control={form.control}
                  name="empType"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      isInvalid={fieldState.invalid}
                      withValidation
                      options={empType}
                      value={empType.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value); // Update the event value
                        // Reset selected filters when the event changes
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px 0px"}
                >
                  Income Type
                </Text>
                <Controller
                  control={form.control}
                  name="empIncomeType"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select department type"
                      withValidation
                      options={incomeTypeOptions}
                      value={incomeTypeOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px 0px"}
                >
                  Is it a Management Staff
                </Text>
                <Controller
                  name="isManagementStaff"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup {...field} value={field?.value?.toString()}>
                      <HStack>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="lg"
                          value={"true"}
                          colorScheme="green"
                          lineHeight={"1.5rem"}
                          onChange={() =>
                            form.setValue("isManagementStaff", true)
                          }
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight="600"
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Yes
                          </Text>
                        </Radio>

                        <Radio
                          isInvalid={fieldState.invalid}
                          size="lg"
                          value={"false"}
                          colorScheme="red"
                          fontSize={"0.8125rem"}
                          fontWeight="600"
                          lineHeight={"1.5rem"}
                          onChange={() =>
                            form.setValue("isManagementStaff", false)
                          }
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight="600"
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            No
                          </Text>
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  margin={"0px 0px 8px"}
                >
                  Employment basis
                </Text>
                <Controller
                  control={form.control}
                  name="empBasis"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Employment basis Type"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account Type"
                      withValidation
                      options={employeeBasicOptions}
                      value={employeeBasicOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                        form.setValue("empBasis", newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>

              <Controller
                control={form.control}
                name="startDate"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "date",

                      borderTopRadius: "3px",
                    }}
                    placeholder="dd/mm/yyyy"
                    label="Employment Start Date"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={form.control}
                name="department"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder=""
                    label="Department"
                    labelProps={{
                      fontSize: ".845rem",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={form.control}
                name="jobTitle"
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    validationMessage={error?.message}
                    withValidation
                    ref={field.ref}
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      w: "100%",
                      h: "38px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                    }}
                    placeholder="Enter Job Title"
                    label="Job Title"
                    labelProps={{
                      fontSize: ".845rem",

                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                  />
                )}
              />
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black5",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  margin={"0px 0px 8px"}
                >
                  Line Manager
                </Text>
                <Controller
                  control={form.control}
                  name="manager"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder=" Line Manager Type"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account Type"
                      withValidation
                      options={users?.map((user: any) => ({
                        value: user?.user?.id,
                        label:
                          user?.user?.firstName + " " + user?.user?.lastName,
                      }))}
                      value={users
                        ?.map((user: any) => ({
                          value: user?.user?.id,
                          label:
                            user?.user?.firstName + " " + user?.user?.lastName,
                        }))
                        .filter((option: any) =>
                          field?.value?.includes(option.value)
                        )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px"}
                  fontWeight={"600"}
                >
                  Payroll Calendar
                </Text>
                <Controller
                  control={form.control}
                  name="payrollPayCalenderId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      defaultValue={"804: Wages Payable - Payroll"}
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select PayRoll Calendar type"
                      withValidation
                      options={payOptions}
                      value={{
                        id: field.value,
                        name:
                          payOptions.find(
                            (payOptions) => payOptions.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px"}
                  fontWeight={"600"}
                >
                  Employee group
                </Text>

                <Controller
                  control={form.control}
                  name="employeeGroupId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      // {...field}
                      placeholder="Select Employment Group"
                      // marginBottom="30px"
                      // width={"48em"}
                      // isInvalid={fieldState.invalid}
                      ErrorMessage="Select Employment Group "
                      withValidation
                      options={employeeOptions}
                      value={employeeOptions.find(
                        (op) => op.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
              <Stack gap={0}>
                <Text
                  textAlign={"left"}
                  fontSize=".845rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"0px 0px 8px"}
                  fontWeight={"600"}
                >
                  Ordinary earning rate
                </Text>
                <Controller
                  control={form.control}
                  name="ratesId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage=" Select Ordinary Earning"
                      placeholder="Select..."
                      options={earningAdded.map((earning) => ({
                        value: earning.id,
                        label: earning.name,
                      }))}
                      value={
                        earningAdded.find(
                          (earning) => earning.id === field.value
                        )
                          ? {
                              value: field.value,
                              label: earningAdded.find(
                                (earning) => earning.id === field.value
                              ).name,
                            }
                          : null
                      }
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </Stack>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter padding={"16px 24px"}>
            <ButtonGroup size="sm" alignItems={"flex-end"}>
              <Button
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={form.handleSubmit((data) => {
                  MappingModuleApi.UpdateBulkEmployeeDetails(
                    {
                      ...data,
                      ids: selectedEmployeeIds ?? [],
                    },
                    (data) => {
                      toast({
                        title: "Success",
                        description: "Employee details updated successfully",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      onClose();
                    },
                    (message) => {
                      toast({
                        title: "Error",
                        description: message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  );
                })}
              >
                Update
              </Button>
              <Button
                onClick={onClose}
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"8px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
