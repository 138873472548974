import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoCloseSharp, IoWarningOutline } from "react-icons/io5";

interface DeleteProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DeleteModal({ isOpen, onClose }: DeleteProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"400px"} h={"auto"}>
        <ModalHeader
          fontSize={"18px"}
          _light={{
            color: "#444444",
          }}
          _dark={{
            color: "customColor.white",
          }}
          padding={"12px 15px"}
          borderBottom={"1px solid #e6e6e6"}
        >
          Delete Tracking
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          _light={{
            bg: "customColor.gray.950",
          }}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          padding={"15px"}
        >
          <HStack alignItems={"center"} padding={"0px 0px 10px"}>
            <Icon
              fontSize={"20px"}
              as={IoWarningOutline}
              color={"red.500"}
              _hover={{
                color: "red.700",
              }}
            />
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black6",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              This option will be permanently deleted.
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter
          _light={{
            bg: "customColor.gray.950",
          }}
        >
          <Button
            leftIcon={<IoCloseSharp fontSize={"20px"} />}
            colorScheme="red"
            // bg={"linear-gradient(#7bd000,#6bb101)"}
            fontSize={"12px"}
            fontWeight={"700"}
            margin={"0px 0px 0px 10px"}
            padding={"6px 18px 5px"}
            w={"124.6px"}
            h={"27.6px"}
            borderRadius={"3px"}
            isLoading={isLoading}
          >
            Delete
          </Button>
          <Button
            fontSize={"12px"}
            // bg={"linear-gradient(#bbbec2,#949a9e)"}
            colorScheme="customGray"
            fontWeight={"700"}
            margin={"0px 0px 0px 10px"}
            padding={"6xp 18px 5px"}
            w={"104.6px"}
            h={"27.6px"}
            boxShadow={"inset 0 0 0 1px hsla(0,0%,100%,.3)"}
            borderRadius={"3px"}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
