import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { BsArrowReturnRight } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelectForAddNewTax } from "../../../../../Common/ReactSelectForAddNewTax";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";

interface AddNewTaxProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedId: string | undefined;
}
export function AddNewTax({
  isOpen,
  onClose,
  setLastUpdated,
  selectedId,
}: AddNewTaxProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const taxType = [
    { label: "Sales", value: BusinessTaxRate.Types["SALES"] },
    {
      label: "Purchases",
      value: BusinessTaxRate.Types.PURCHASES,
    },
    {
      value: BusinessTaxRate.Types.BAS_EXCLUDED,
      label: "BAS Excluded",
    },
    {
      value: BusinessTaxRate.Types.EXEMPT_INCOME,
      label: "Exempt Income",
    },
    {
      value: BusinessTaxRate.Types.GST_FREE_EXPENSES,
      label: "GST Free Expenses",
    },
    {
      value: BusinessTaxRate.Types.GST_FREE_SALES,
      label: "GST Free Sales",
    },
  ];
  const FormTaxRates = useFormContext<BusinessTaxRate.AddNewTax>();

  const taxComponents = useFieldArray({
    control: FormTaxRates.control,
    name: "tax_components",
  });

  const watchedTaxComponents = useWatch({
    control: FormTaxRates.control,
    name: "tax_components",
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={"550px"}>
        <ModalHeader
          padding={"12px 15px"}
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
          _dark={{
            color: "customColor.white",
          }}
          lineHeight={"1.75rem"}
          fontWeight={"bold"}
        >
          {selectedId ? "Edit Tax Rate" : "Add New Tax"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"15px"} borderTop={"1px solid #dfe8f6"}>
          <FormControl padding={0}>
            <Stack spacing={3} gap={0}>
              <FormLabel
                mb={0}
                fontSize="11px"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Tax Rate Display Name
              </FormLabel>
              <FormHelperText
                margin={"3px 0px 5px"}
                fontSize="11px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                The name as you would like it to appear in HRIS-360 (limited to
                50 characters)
              </FormHelperText>
              <Controller
                name="name"
                control={FormTaxRates.control}
                rules={{
                  required: {
                    value: true,
                    message: "Tax Rate Display name is required",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    ref={field.ref}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      placeholder: "",

                      h: "18.6px",
                      w: "155.6px",
                      padding: "12px 15px",
                      _light: {
                        color: "customColor.black7",
                      },
                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      bgColor: "#fff",
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={3} gap={0}>
              <FormLabel
                mb={0}
                fontSize="11px"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
              >
                Tax Type
              </FormLabel>
              <FormHelperText
                margin={"3px 0px 5px"}
                fontSize="11px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                Choose how this tax rate will be reported in your Activity
                Statement
              </FormHelperText>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Tax Type is required",
                  },
                }}
                control={FormTaxRates.control}
                name="tax_type"
                render={({ field, fieldState }) => (
                  <ReactSelectForAddNewTax
                    {...field}
                    defaultValue={{
                      label: "Sales",
                      value: "Sales",
                    }}
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Tax Type "
                    withValidation
                    options={taxType}
                    value={taxType.find(
                      (taxType) => taxType.value === field.value
                    )}
                    onChange={(newValue: any) =>
                      field.onChange(newValue?.value)
                    }
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </Stack>
            <Stack spacing={3} gap={0}>
              <FormLabel
                mb={0}
                fontSize="11px"
                fontWeight="600"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                paddingBottom={"10px"}
              >
                Tax Components
              </FormLabel>
              <Table variant="simple" w="100%">
                <Tbody
                  borderTop={"1px solid #ccc"}
                  borderBottom={"1px solid #ccc"}
                >
                  {taxComponents.fields.map((field, index) => (
                    <Tr key={field.id}>
                      <Td padding={"5px"} w={"130px"}>
                        <Stack padding={"7px 0px 10px"}>
                          <Controller
                            name={`tax_components.${index}.name`}
                            control={FormTaxRates.control}
                            rules={{
                              required: {
                                value: true,
                                message: "Tax Component's name is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",

                                  h: "18.6px",
                                  w: "118.6px",
                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",
                                  type: "text",

                                  borderTopRadius: "3px",
                                  bgColor: "#fff",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </Td>

                      <Td padding={"5px"} w={"280px"}></Td>
                      <Td padding={"5px"} w={"85px"}>
                        <Stack
                          padding={"7px 0px 10px"}
                          direction={"row"}
                          gap={0}
                        >
                          <Controller
                            name={`tax_components.${index}.tax`}
                            control={FormTaxRates.control}
                            rules={{
                              required: {
                                value: true,
                                message: "Tax Component's Tax is required",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                ref={field.ref}
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  placeholder: "",
                                  type: "number",
                                  h: "18.6px",
                                  w: "50px",

                                  padding: "12px 15px",
                                  _light: {
                                    color: "customColor.black7",
                                  },
                                  fontSize: ".845rem",
                                  variant: "outline",

                                  borderTopRadius: "3px",
                                  bgColor: "#fff",
                                }}
                              />
                            )}
                          />
                          <Text marginLeft={"5px"}>%</Text>
                        </Stack>
                      </Td>
                      {taxComponents.fields.length > 1 && (
                        <Td
                          padding={"5px"}
                          w={"25px"}
                          onClick={() => {
                            taxComponents.remove(index);
                          }}
                        >
                          <Icon
                            fontSize={"20px"}
                            as={IoIosCloseCircleOutline}
                            color={"grey"}
                            _hover={{
                              color: "red.500",
                            }}
                          />
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Stack direction={"row"} gap={0} paddingTop={"15px"}>
                <Button
                  leftIcon={<BsArrowReturnRight fontSize={"20px"} />}
                  margin={"0px 0px 0px 10px"}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    taxComponents.append({
                      name: "",
                      tax: 0,
                    });
                  }}
                >
                  Add a Component
                </Button>
                <Stack padding={"5px 0px"} direction={"row"} gap={0}>
                  <Text
                    paddingLeft={"170px"}
                    paddingRight={"90px"}
                    top={"5px"}
                    fontSize={"11px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    Total Tax Rate
                  </Text>
                  <Text
                    textAlign={"end"}
                    fontSize={"11px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    {watchedTaxComponents
                      .reduce((prev, curr) => {
                        return prev + Number(curr.tax);
                      }, 0)
                      .toFixed(2)}
                    %
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              FormTaxRates.handleSubmit(
                (data) => {
                  setIsLoading(true);
                  if (selectedId) {
                    BusinessTaxRate.UpdateTaxRate(
                      selectedId,
                      data,
                      (success) => {
                        toast({
                          title: "Business Tax Rate Updated",
                          status: "success",
                        });
                        setIsLoading(false);
                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Business Tax Rate Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  } else {
                    BusinessTaxRate.NewTaxAdded(
                      data,
                      (success) => {
                        toast({
                          title: "Business Tax Rate Added",
                          status: "success",
                        });
                        setIsLoading(false);
                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Business Tax Rate Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }
                },
                (error) => {
                  toast({
                    title: "Error",
                    status: "error",
                  });
                  setIsLoading(false);
                }
              )(e);
            }}
          >
            {selectedId ? "Update" : "Save"}
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            marginLeft={"10px"}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
