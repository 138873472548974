import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { MappingModuleApi } from "../../Api/MappingModule";
import { PayTemp } from "../../Api/PayTemp";
import { DeductionLineModal } from "../Employees/Modal/DeductionLine";
import { EarningLineModal } from "../Employees/Modal/EarningLineModal";
import { ReimbursementLineModal } from "../Employees/Modal/ReimbursementLine";
import { StaturyBenefitsModal } from "../Employees/Modal/StaturyBenefitsModal";
import { DeductionFile } from "./PayTempFiles/DeductionFile";
import { EarningFile } from "./PayTempFiles/EarningFile";
import { ReimbursementFile } from "./PayTempFiles/ReimbursementFile";
import { RetirementFile } from "./PayTempFiles/RetirementFile";

interface PayTemplateModalProps {
  isOpen: boolean;
  earningAdded: any[];
  deductionAdded: any[];
  reimbursementAdded: any[];
  statutoryAdded: any[];
  selectedEmployeeIds: any[] | undefined;
  onClose: () => void;
}
export function PayTemplateModal({
  isOpen,
  onClose,
  earningAdded,
  deductionAdded,
  selectedEmployeeIds,
  reimbursementAdded,
  statutoryAdded,
}: PayTemplateModalProps) {
  const toast = useToast();
  const form = useForm<MappingModuleApi.AddNewPayTemp>({
    defaultValues: {
      earnings: [],
      deductions: [],
      reimbursments: [],
      retirements: [],
      empIds: [],
    },
  });
  const earningLine = useDisclosure();
  // const [accountEarning, setAccountEarning] = useState<
  //   PayItemEarningApi.EarningById[]
  // >([]);
  const deductionLine = useDisclosure();
  // const [accountDeduction, setAccountDeduction] = useState<
  //   PayItemDeductionApi.DeductionById[]
  // >([]);
  const reimbursementLine = useDisclosure();
  const retirementModal = useDisclosure();
  // const [accountReimbursement, setAccountReimbursement] = useState<
  //   PayItemReimbursementApi.ReimbursementById[]
  // >([]);
  // const [accountRetirement, setAccountRetirement] = useState<
  //   Array<PayItemRetirement.FetchRetirementFund>
  // >([]);
  const earningFieldArray = useFieldArray({
    control: form.control,
    name: "earnings",
    keyName: "uniqueId",
  });
  const deductionFieldArray = useFieldArray({
    control: form.control,
    name: "deductions",
    keyName: "uniqueId",
  });
  const reimbursementFieldArray = useFieldArray({
    control: form.control,
    name: "reimbursments",
    keyName: "uniqueId",
  });
  const retirementFieldArray = useFieldArray({
    control: form.control,
    name: "retirements",
    keyName: "uniqueId",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [earningResetData, setEarningResetData] = useState<{
    payItemEarningId: string;
    calculationType: string;
    rateType: PayTemp.PayTemplateRateTypeEnum;
    index?: number;
  }>({
    payItemEarningId: "",
    calculationType: "",
    rateType: PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT,
    index: undefined,
  });
  const [deductionResetData, setDeductionResetData] = useState<{
    payItemDeductionId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemDeductionId: "",
    calculationType: "",
    index: undefined,
  });
  const [reimbursementResetData, setReimbursementResetData] = useState<{
    payItemReimbursementId: string;
    description: string;
    index?: number;
  }>({
    payItemReimbursementId: "",
    description: "",
    index: undefined,
  });
  const [retirementResetData, setRetirementResetData] = useState<{
    payItemRetirementId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemRetirementId: "",
    calculationType: "",
    index: undefined,
  });
  useEffect(() => {
    if (isOpen) {
      form.reset({
        earnings: [],
        deductions: [],
        reimbursments: [],
        retirements: [],
        empIds: selectedEmployeeIds,
      });
    }
  }, [isOpen]);
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"980px"}>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Pay Template Modal
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            padding={"16px 24px"}
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            border={"1px solid #E5E5E5"}
          >
            {" "}
            <Stack spacing="20px">
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor:
                    earningFieldArray.fields.length > 0
                      ? "customColor.gray.950"
                      : "#FFF",
                }}
                border={
                  earningFieldArray.fields.length > 0
                    ? "1px solid #e5e5e5"
                    : "none"
                }
                padding={
                  earningFieldArray.fields.length > 0
                    ? "15px 10px 20px"
                    : "none"
                }
                gap={0}
              >
                <EarningFile
                  form={form}
                  earningAdded={earningAdded}
                  earningFieldArray={earningFieldArray}
                  earningLine={earningLine}
                  setResetData={setEarningResetData}
                />
                <Button
                  variant="outline"
                  fontSize="12px"
                  bg="primary.50"
                  color="primary.950"
                  _hover={{
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                  w={"141px"}
                  bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                  onClick={() => {
                    setEarningResetData({
                      payItemEarningId: "",
                      calculationType: "",
                      rateType: PayTemp.PayTemplateRateTypeEnum.FIXED_AMOUNT,
                      index: undefined,
                    });
                    earningLine.onOpen();
                  }}
                >
                  + Add Earning Line
                </Button>
                <EarningLineModal
                  isOpen={earningLine.isOpen}
                  onClose={earningLine.onClose}
                  resetData={earningResetData}
                  form={form}
                  onPressOK={(data) => {
                    data?.index !== undefined
                      ? earningFieldArray.update(data?.index, data)
                      : earningFieldArray.append(data);
                  }}
                  account={earningAdded}
                />
              </Stack>
              <Stack
                gap={0}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor:
                    deductionFieldArray.fields.length > 0
                      ? "customColor.gray.950"
                      : "#FFF",
                }}
                border={
                  deductionFieldArray.fields.length > 0
                    ? "1px solid #e5e5e5"
                    : "none"
                }
                padding={
                  deductionFieldArray.fields.length > 0
                    ? "15px 10px 20px"
                    : "none"
                }
              >
                <DeductionFile
                  form={form}
                  deductionAdded={deductionAdded}
                  deductionFieldArray={deductionFieldArray}
                  deductionLine={deductionLine}
                  setResetData={setDeductionResetData}
                />
                <Button
                  variant="outline"
                  fontSize="12px"
                  bg="primary.50"
                  color="primary.950"
                  _hover={{
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                  w={"141px"}
                  bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                  onClick={() => {
                    setDeductionResetData({
                      payItemDeductionId: "",
                      calculationType: "",
                      index: undefined,
                    });
                    deductionLine.onOpen();
                  }}
                >
                  + Add Deduction Line
                </Button>
                <DeductionLineModal
                  isOpen={deductionLine.isOpen}
                  onClose={deductionLine.onClose}
                  resetData={deductionResetData}
                  onPressOK={(data) => {
                    data?.index !== undefined
                      ? deductionFieldArray.update(data?.index, data)
                      : deductionFieldArray.append(data);
                  }}
                  account={deductionAdded}
                />
              </Stack>
              <Stack
                gap={0}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor:
                    reimbursementFieldArray.fields.length > 0
                      ? "customColor.gray.950"
                      : "#FFF",
                }}
                border={
                  reimbursementFieldArray.fields.length > 0
                    ? "1px solid #e5e5e5"
                    : "none"
                }
                padding={
                  reimbursementFieldArray.fields.length > 0
                    ? "15px 10px 20px"
                    : "none"
                }
              >
                <ReimbursementFile
                  form={form}
                  reimbursementAdded={reimbursementAdded}
                  reimbursementFieldArray={reimbursementFieldArray}
                  reimbursementLine={reimbursementLine}
                  setResetData={setReimbursementResetData}
                />
                <Button
                  variant="outline"
                  fontSize="12px"
                  bg="primary.50"
                  color="primary.950"
                  _hover={{
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                  w={"181px"}
                  bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                  onClick={() => {
                    setReimbursementResetData({
                      payItemReimbursementId: "",
                      description: "",
                      index: undefined,
                    });
                    reimbursementLine.onOpen();
                  }}
                >
                  + Add Reimbursement Line
                </Button>
                <ReimbursementLineModal
                  isOpen={reimbursementLine.isOpen}
                  onClose={reimbursementLine.onClose}
                  resetData={reimbursementResetData}
                  onPressOK={(data) => {
                    data?.index !== undefined
                      ? reimbursementFieldArray.update(data?.index, data)
                      : reimbursementFieldArray.append(data);
                  }}
                  account={reimbursementAdded}
                />
              </Stack>
              <Stack
                gap={0}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor:
                    retirementFieldArray.fields.length > 0
                      ? "customColor.gray.950"
                      : "#FFF",
                }}
                border={
                  retirementFieldArray.fields.length > 0
                    ? "1px solid #e5e5e5"
                    : "none"
                }
                padding={
                  retirementFieldArray.fields.length > 0
                    ? "15px 10px 20px"
                    : "none"
                }
              >
                <RetirementFile
                  form={form}
                  statutoryAdded={statutoryAdded}
                  retirementFieldArray={retirementFieldArray}
                  retirementLine={retirementModal}
                  setResetData={setRetirementResetData}
                />
                <Button
                  variant="outline"
                  fontSize="12px"
                  bg="primary.50"
                  color="primary.950"
                  _hover={{
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                  w={"181px"}
                  bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                  onClick={() => {
                    setRetirementResetData({
                      payItemRetirementId: "",
                      calculationType: "",
                      index: undefined,
                    });
                    retirementModal.onOpen();
                  }}
                >
                  + Add Statuary Benefits Line
                </Button>
                <StaturyBenefitsModal
                  isOpen={retirementModal.isOpen}
                  onClose={retirementModal.onClose}
                  resetData={retirementResetData}
                  onPressOK={(data) => {
                    data?.index !== undefined
                      ? retirementFieldArray.update(data?.index, data)
                      : retirementFieldArray.append(data);
                  }}
                  account={statutoryAdded}
                />
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter padding={"16px 24px"}>
            <ButtonGroup size="sm" alignItems={"flex-end"}>
              <Button
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={form.handleSubmit((data) => {
                  MappingModuleApi.PayTemplateBulkUpdate(
                    {
                      ...data,
                      empIds: selectedEmployeeIds || [],
                    },
                    () => {
                      toast({
                        title: "Pay Template Updated",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      onClose();
                    },
                    () => {
                      toast({
                        title: "Failed to Update Pay Template",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  );
                })}
              >
                Update
              </Button>
              <Button
                onClick={onClose}
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"8px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
