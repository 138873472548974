import {
  Box,
  Checkbox,
  Container,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BusinessTaxRate } from "../../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";

interface AccountsTableProps {
  typeOfAccounts?: ChartOfAccounts.AccountCategory;
  setSelectedId: (selectedid: string[]) => void;
  selectedId: string[];
  downloadExcel: () => void;
  setLastUpdated: (timestamp: number) => void;
  onAccountUpdate: (item: ChartOfAccounts.FetchChartOfAccounts) => void;
  taxRateData: BusinessTaxRate.FetchTaxRate[];
  lastUpdated: number | undefined;
  filterData: () => ChartOfAccounts.FetchChartOfAccounts[];
  data: Array<ChartOfAccounts.FetchChartOfAccounts>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  allYTD: ChartOfAccounts.FetchChartOfAccountsYTD | undefined;
  count: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  pageLimit: number;
}

export function AccountsTable({
  typeOfAccounts,
  onAccountUpdate,
  setSelectedId,
  downloadExcel,
  taxRateData,
  lastUpdated,
  filterData,
  data,
  setIsLoading,
  isLoading,
  allYTD,
  count,
  setPageNumber,
  pageNumber,
  pageLimit,
}: AccountsTableProps) {
  const form = useForm({
    defaultValues: {
      accountCategory: "",
      accountType: "",
      code: 0,
      name: "",
      description: "",
      businesTaxRateId: "",
      showDashboardWatchlist: false,
      showExpenseClaims: false,
      enablePayments: false,
    },
  });

  const toast = useToast();

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAllChecked = event.target.checked;
    if (isAllChecked) {
      const allRowIds = data.map((item, i) => i);
      setCheckedRows(isCheckedAll ? [] : allRowIds);

      setSelectedId(data.map((item) => item.id));
    } else {
      setCheckedRows([]);
      setSelectedId([]);
    }

    setIsCheckedAll(isAllChecked);
  };

  const handleCheckRow = (rowIndex: number) => {
    const updatedRows = [...checkedRows];
    const rowIndexInCheckedRows = updatedRows.indexOf(rowIndex);

    if (rowIndexInCheckedRows !== -1) {
      updatedRows.splice(rowIndexInCheckedRows, 1);
    } else {
      updatedRows.push(rowIndex);
    }

    setCheckedRows(updatedRows);
    setIsCheckedAll(updatedRows.length === data.length);
    const selectedIds = updatedRows.map((index) => data?.[index]?.id);
    setSelectedId(selectedIds);
  };
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const pageCount = Math.ceil(count / pageLimit);
  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };
  return (
    <FormProvider {...form}>
      <Skeleton height={"100%"} isLoaded={!isLoading}>
        <Container
          maxW={"100%"}
          p={0}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          minH={"265.72px"}
        >
          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  borderTopWidth={"1px"}
                  borderTopColor="borders.tableBorder"
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  <Th
                    padding="8px 26px 8px 30px"
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    <Checkbox
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={isCheckedAll}
                      onChange={handleCheckAll}
                    ></Checkbox>
                  </Th>
                  {[
                    {
                      name: "Code",
                    },
                    {
                      name: "Name",
                    },
                    {
                      name: "Type",
                    },
                    {
                      name: "Tax Rate",
                    },
                    {
                      name: "YTD",
                    },
                    {
                      name: "View Details",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding="8px 26px 8px 15px"
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <>
                <Tbody>
                  {filterData()?.map((item: any, i: any) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onAccountUpdate(item);
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"
                            isChecked={checkedRows.includes(i)}
                            onChange={() => handleCheckRow(i)}
                          />
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.code}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          {item.name}

                          <Text lineHeight={"1.1"} fontSize={"11px"}>
                            {item.description}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>
                            {item.accountType === "CURRENT_ASSET"
                              ? "Current Asset"
                              : item.accountType === "FIXED_ASSET"
                              ? "Fixed Assets"
                              : item.accountType === "INVENTORY"
                              ? "Inventory"
                              : item.accountType === "NON_CURRENT_ASSET"
                              ? "Non Current Asset"
                              : item.accountType === "PREPAYMENT"
                              ? "Prepayment"
                              : item.accountType === "EQUITY"
                              ? "Equity"
                              : item.accountType === "DEPRECIATION"
                              ? "Depreciation"
                              : item.accountType === "DIRECT_COSTS"
                              ? "Direct Costs"
                              : item.accountType === "EXPENSE"
                              ? "Expense"
                              : item.accountType === "OVERHEAD"
                              ? "Overhead"
                              : item.accountType === "CURRENT_LIABILITY"
                              ? "Current Liability"
                              : item.accountType === "LIABILITY"
                              ? "Liability"
                              : item.accountType === "NON_CURRENT_LIABILITY"
                              ? "Non Current Liability"
                              : item.accountType === "OTHER_INCOME"
                              ? "Other Income"
                              : item.accountType === "REVENUE"
                              ? "Revenue"
                              : item.accountType === "SALES"
                              ? "Sales"
                              : ""}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>
                            {taxRateData
                              .find(
                                (taxRate) =>
                                  taxRate.id === item.businesTaxRateId
                              )
                              ?.name.toLowerCase()}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{allYTD?.[item.id] ?? 0}</Text>
                        </Td>
                        <Td
                          padding="10px 21px 10px 10px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <HStack
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={MdOutlineRemoveRedEye}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                              }}
                              onClick={() => {
                                onAccountUpdate(item);
                              }}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </>
            </Table>
          </Box>
          <Stack borderTop={"1px solid #dcdee4 "}>
            <HStack
              marginTop={"9.35px"}
              padding={"25px"}
              fontSize={"13px"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              justifyContent={"space-between"}
            >
              <Text
                fontWeight={"normal"}
                color={"customColor.6b7885"}
                _dark={{
                  color: "customColor.dark.200",
                }}
                fontSize={"11px"}
              >
                Showing {pageLimit * (pageNumber - 1) + 1} to{" "}
                {pageLimit + pageLimit * (pageNumber - 1)} of {data?.length}{" "}
                entries
              </Text>
              <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
                <IconButton
                  aria-label="back"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: "#3454D1",
                    color: "white",
                  }}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  icon={
                    <FaArrowLeft
                      size={15}
                      color={
                        isHovered2
                          ? "#FFFFFF"
                          : pageNumber <= 1 || pageNumber > pageCount
                          ? "#828ea0"
                          : "#283c50"
                      }
                    />
                  }
                  isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                  onClick={() => {
                    if (pageNumber > 1 && pageNumber <= pageCount) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber - 1
                        ));
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                />
                <HStack spacing="13px">{renderPageNumbers()}</HStack>
                <IconButton
                  aria-label="right"
                  backgroundColor="transparent"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  _hover={{
                    backgroundColor: "#3454D1",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  icon={
                    <FaArrowRight
                      size={15}
                      color={
                        isHovered
                          ? "#FFFFFF"
                          : pageNumber >= pageCount || pageNumber < 1
                          ? "#828ea0"
                          : "#283c50"
                      }
                      style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                    />
                  }
                  isDisabled={pageNumber >= pageCount || pageNumber < 1}
                  onClick={() => {
                    if (pageNumber < pageCount && pageNumber >= 1) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber + 1
                        ));
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                />
              </HStack>
            </HStack>
          </Stack>
        </Container>
      </Skeleton>
    </FormProvider>
  );
}
