import { Box, HStack, Icon, Stack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoLink } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayItemEarningApi } from "../../../Api/PayItemEarning";
import { PayItemLeaveApi } from "../../../Api/PayItemLeave";
import { PayRun } from "../../../Api/PayRun";
type PayItemFormatted = {
  [key: string]: string;
};
interface AddEarningProps {
  form: any;
  earningFieldArray: any;
  earningLine: any;
  accountEarning: PayItemEarningApi.EarningById[];
  setAccountEarning: (accountEarning: any) => void;
  setResetData: (resetData: any) => void;
  resetPaySlip: Function;
}
export default function AddEarning({
  form,
  earningFieldArray,
  earningLine,
  setAccountEarning,
  accountEarning,
  resetPaySlip,
  setResetData,
}: AddEarningProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const [PayItemEarningsFormatted, setPayItemEarningsFormatted] =
    useState<PayItemFormatted>({});
  const [PayItemLeavesFormatted, setPayItemLeavesFormatted] =
    useState<PayItemFormatted>({});
  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (res) => {
        setAccountEarning(res);
        setPayItemEarningsFormatted(
          res?.reduce((acc: any, item: any) => {
            acc[item?.id] = item?.name;
            return acc;
          }, {})
        );
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  useEffect(() => {
    PayItemLeaveApi.FetchLeave(
      (res) => {
        setPayItemLeavesFormatted(
          res?.reduce((acc: any, item: any) => {
            acc[item?.id] = item?.name;
            return acc;
          }, {})
        );
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      }
    );
  }, []);
  return (
    <Stack>
      {earningFieldArray.fields.map(
        (item: PayRun.PayrunItem & { uniqueId: string }, i: any) => {
          const account = accountEarning?.find(
            (accountEarning) => accountEarning?.id === item?.payItemEarningId
          );
          return (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="center"
              key={item.uniqueId}
            >
              <Stack flex={5} spacing={0}>
                <Stack
                  flex={5}
                  // display={
                  //   account?.rateType === "GROSS_SALARY" ? "none" : "flex"
                  // }
                >
                  <Text
                    fontSize={"12px "}
                    margin={"0px 0px 4px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"700"}
                  >
                    {item?.payItemEarningId !== null
                      ? "Earning Rate"
                      : "Leave Type"}
                  </Text>

                  <Text
                    color={"#3454d1"}
                    padding={"6px 0px 0px"}
                    fontSize={"12px"}
                    borderTop={"1px solid #CCC !important"}
                    borderBottom={"1px solid #E8EAEC !important"}
                    marginBottom={"5px"}
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // form.reset(item);
                      setResetData({ ...item, index: i });
                      earningLine.onOpen();
                    }}
                  >
                    {!!item?.payItemEarningId
                      ? PayItemEarningsFormatted[item?.payItemEarningId] ?? ""
                      : PayItemLeavesFormatted[item?.payItemLeaveId ?? ""] ??
                        ""}
                  </Text>
                </Stack>
                <Stack
                  flex={5}
                  display={
                    account?.rateType === "GROSS_SALARY" ? "flex" : "none"
                  }
                >
                  <Text
                    fontSize={"12px "}
                    margin={"0px 0px 4px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"700"}
                  >
                    Earnings Rate{" "}
                  </Text>

                  <Text
                    color={"#3454d1"}
                    padding={"6px 0px 0px"}
                    fontSize={"12px"}
                    borderTop={"1px solid #CCC !important"}
                    borderBottom={"1px solid #E8EAEC !important"}
                    marginBottom={"5px"}
                    _hover={{
                      cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   // form.reset(item);
                    //   setResetData({ ...item, index: i });
                    //   earningLine.onOpen();
                    // }}
                  >
                    Base Salary
                  </Text>
                </Stack>
                <Stack
                  flex={5}
                  display={
                    account?.rateType === "GROSS_SALARY" ? "flex" : "none"
                  }
                >
                  <Text
                    fontSize={"12px "}
                    margin={"0px 0px 4px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.dark.250",
                    }}
                    fontWeight={"700"}
                  >
                    Earnings Rate{" "}
                  </Text>

                  <Text
                    color={"#3454d1"}
                    padding={"6px 0px 0px"}
                    fontSize={"12px"}
                    borderTop={"1px solid #CCC !important"}
                    borderBottom={"1px solid #E8EAEC !important"}
                    marginBottom={"5px"}
                    _hover={{
                      cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   // form.reset(item);
                    //   setResetData({ ...item, index: i });
                    //   earningLine.onOpen();
                    // }}
                  >
                    Allowance
                  </Text>
                </Stack>
              </Stack>
              {["RATE_PER_UNIT", "MULTIPLE_OF_ORDINARY_EARNINGS"]?.includes(
                account?.rateType ?? ""
              ) && (
                <Stack alignItems={"flex-end"} flex={2}>
                  <HStack>
                    <Stack flex={1}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Units are required",
                          },
                          onBlur(event) {
                            resetPaySlip();
                          },
                        }}
                        control={form.control}
                        name={`earnings.${i}.hours`}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              fontSize: "12px",
                              variant: "outline",
                              type: "number",
                              onChange: (e) => {
                                field.onChange(Number(e.target.valueAsNumber));
                              },

                              height: "26.78px",

                              padding: "12px 15px",

                              placeholder: "",

                              isDisabled: item.timesheetId ? true : false,
                            }}
                            label="Units"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        )}
                      />
                    </Stack>

                    <Stack flex={1}>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "rate is required",
                          },
                          onBlur(event) {
                            resetPaySlip();
                          },
                        }}
                        control={form.control}
                        name={`earnings.${i}.rate`}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              fontSize: "12px",
                              variant: "outline",
                              type: "number",
                              value: `${form.watch(`earnings.${i}.rate`)}`,
                              onChange: (e) => {
                                field.onChange(Number(e.target.valueAsNumber));
                              },

                              height: "26.78px",

                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Rate"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack flex={1}>
                      <Controller
                        control={form.control}
                        name={`earnings.${i}.amount`}
                        render={({ field }) => (
                          <CustomInput
                            input={{
                              ...field,
                              fontSize: "12px",
                              variant: "outline",
                              type: "text",
                              isDisabled: true,

                              height: "26.78px",
                              value: Number(
                                (form.watch(`earnings.${i}.hours`) ?? 0) *
                                  (form.watch(`earnings.${i}.rate`) ?? 0)
                              ).toLocaleString(),
                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Total"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                </Stack>
              )}
              {account?.rateType === "GROSS_SALARY" &&
                item?.calculationType === "RATE" && (
                  <Stack>
                    <Stack
                      alignItems={"flex-end"}
                      flex={2}
                      // display={
                      //   account?.rateType === "GROSS_SALARY" ? "none" : "flex"
                      // }
                    >
                      <HStack>
                        <Stack flex={1}>
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Hour is required",
                              },
                              onBlur(event) {
                                resetPaySlip();
                              },
                            }}
                            control={form.control}
                            name={`earnings.${i}.hours`}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  fontSize: "12px",
                                  variant: "outline",
                                  type: "number",
                                  onChange: (e) => {
                                    field.onChange(
                                      Number(e.target.valueAsNumber)
                                    );
                                  },

                                  height: "26.78px",

                                  padding: "12px 15px",

                                  placeholder: "",

                                  isDisabled: item.timesheetId ? true : false,
                                }}
                                label="Units"
                                labelProps={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "customColor.black7",
                                  margin: "0px 0px 4px",
                                }}
                              />
                            )}
                          />
                        </Stack>

                        <Stack flex={1}>
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "rate is required",
                              },
                              onBlur(event) {
                                resetPaySlip();
                              },
                            }}
                            control={form.control}
                            name={`earnings.${i}.rate`}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  fontSize: "12px",
                                  variant: "outline",
                                  type: "number",
                                  value: `${form.watch(`earnings.${i}.rate`)}`,
                                  onChange: (e) => {
                                    field.onChange(
                                      Number(e.target.valueAsNumber)
                                    );
                                  },
                                  // isDisabled: true,
                                  height: "26.78px",

                                  padding: "12px 15px",
                                  boxShadow: "none",
                                  placeholder: "",
                                }}
                                label="Rate"
                                labelProps={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "customColor.black7",
                                  margin: "0px 0px 4px",
                                }}
                              />
                            )}
                          />
                        </Stack>
                        <Stack flex={1}>
                          <Controller
                            control={form.control}
                            name={`earnings.${i}.amount`}
                            render={({ field }) => (
                              <CustomInput
                                input={{
                                  ...field,
                                  fontSize: "12px",
                                  variant: "outline",
                                  type: "text",
                                  isDisabled: true,

                                  height: "26.78px",
                                  value: Number(
                                    (form.watch(`earnings.${i}.hours`) ?? 0) *
                                      (form.watch(`earnings.${i}.rate`) ?? 0)
                                  ).toLocaleString(),
                                  padding: "12px 15px",
                                  boxShadow: "none",
                                  placeholder: "",
                                }}
                                label="Total"
                                labelProps={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "customColor.black7",
                                  margin: "0px 0px 4px",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </HStack>
                    </Stack>
                    <Stack alignItems={"flex-end"} flex={2}>
                      <HStack>
                        <Stack flex={1}>
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Hour is required",
                              },
                              onBlur(event) {
                                resetPaySlip();
                              },
                            }}
                            control={form.control}
                            name={`earnings.${i}.hours`}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  fontSize: "12px",
                                  variant: "outline",
                                  type: "number",
                                  onChange: (e) => {
                                    field.onChange(
                                      Number(e.target.valueAsNumber)
                                    );
                                  },
                                  isDisabled: true,
                                  height: "26.78px",

                                  padding: "12px 15px",

                                  placeholder: "",
                                }}
                                label="Units"
                                labelProps={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "customColor.black7",
                                  margin: "0px 0px 4px",
                                }}
                              />
                            )}
                          />
                        </Stack>

                        <Stack flex={1}>
                          <CustomInput
                            withValidation
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "number",
                              value: `${(
                                (form.watch(`earnings.${i}.rate`) / 100) *
                                (account?.baseSalaryPercentage ?? 0)
                              ).toFixed(2)}`,

                              height: "26.78px",
                              isDisabled: true,
                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Rate"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Stack>
                        <Stack flex={1}>
                          <CustomInput
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "text",
                              isDisabled: true,

                              height: "26.78px",
                              value: Number(
                                (
                                  (form.watch(`earnings.${i}.hours`) ?? 0) *
                                  ((form.watch(`earnings.${i}.rate`) / 100) *
                                    (account?.baseSalaryPercentage ?? 0))
                                ).toFixed(2)
                              ).toLocaleString(),

                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Total"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Stack>
                      </HStack>
                    </Stack>
                    <Stack alignItems={"flex-end"} flex={2}>
                      <HStack>
                        <Stack flex={1}>
                          <Controller
                            rules={{
                              required: {
                                value: true,
                                message: "Hour is required",
                              },
                              onBlur(event) {
                                resetPaySlip();
                              },
                            }}
                            control={form.control}
                            name={`earnings.${i}.hours`}
                            render={({ field, fieldState: { error } }) => (
                              <CustomInput
                                validationMessage={error?.message}
                                withValidation
                                input={{
                                  ...field,
                                  isInvalid: error !== undefined,
                                  fontSize: "12px",
                                  variant: "outline",
                                  type: "number",
                                  onChange: (e) => {
                                    field.onChange(
                                      Number(e.target.valueAsNumber)
                                    );
                                  },

                                  height: "26.78px",

                                  padding: "12px 15px",

                                  placeholder: "",

                                  isDisabled: true,
                                }}
                                label="Units"
                                labelProps={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "customColor.black7",
                                  margin: "0px 0px 4px",
                                }}
                              />
                            )}
                          />
                        </Stack>

                        <Stack flex={1}>
                          <CustomInput
                            withValidation
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "number",
                              value: `${(
                                (form.watch(`earnings.${i}.rate`) / 100) *
                                (account?.allowancesPercentage ?? 0)
                              ).toFixed(2)}`,

                              height: "26.78px",
                              isDisabled: true,
                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Rate"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Stack>
                        <Stack flex={1}>
                          <CustomInput
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "text",
                              isDisabled: true,

                              height: "26.78px",
                              value: Number(
                                (
                                  (form.watch(`earnings.${i}.hours`) ?? 0) *
                                  ((form.watch(`earnings.${i}.rate`) / 100) *
                                    (account?.allowancesPercentage ?? 0))
                                ).toFixed(2)
                              ).toLocaleString(),
                              padding: "12px 15px",
                              boxShadow: "none",
                              placeholder: "",
                            }}
                            label="Total"
                            labelProps={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "customColor.black7",
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Stack>
                      </HStack>
                    </Stack>
                  </Stack>
                )}

              {!!item?.payItemLeaveId && item?.calculationType === "RATE" && (
                <Stack>
                  <Stack
                    alignItems={"flex-end"}
                    flex={2}
                    // display={
                    //   account?.rateType === "GROSS_SALARY" ? "none" : "flex"
                    // }
                  >
                    <HStack>
                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "Hour is required",
                            },
                            onBlur(event) {
                              resetPaySlip();
                            },
                          }}
                          control={form.control}
                          name={`earnings.${i}.hours`}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                fontSize: "12px",
                                variant: "outline",
                                type: "number",
                                onChange: (e) => {
                                  field.onChange(
                                    Number(e.target.valueAsNumber)
                                  );
                                },

                                height: "26.78px",

                                padding: "12px 15px",

                                placeholder: "",

                                isDisabled: item.timesheetId ? true : false,
                              }}
                              label="Units"
                              labelProps={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "customColor.black7",
                                margin: "0px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>

                      <Stack flex={1}>
                        <Controller
                          rules={{
                            required: {
                              value: true,
                              message: "rate is required",
                            },

                            onBlur(event) {
                              resetPaySlip();
                            },
                          }}
                          control={form.control}
                          name={`earnings.${i}.rate`}
                          render={({ field, fieldState: { error } }) => (
                            <CustomInput
                              validationMessage={error?.message}
                              withValidation
                              input={{
                                ...field,
                                isInvalid: error !== undefined,
                                fontSize: "12px",
                                variant: "outline",
                                type: "number",

                                onChange: (e) => {
                                  field.onChange(Number(e.target.value));
                                },
                                // isDisabled: true,
                                height: "26.78px",

                                padding: "12px 15px",
                                boxShadow: "none",
                                placeholder: "",
                              }}
                              label="Rate"
                              labelProps={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "customColor.black7",
                                margin: "0px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          control={form.control}
                          name={`earnings.${i}.amount`}
                          render={({ field }) => (
                            <CustomInput
                              input={{
                                ...field,
                                fontSize: "12px",
                                variant: "outline",
                                type: "text",
                                isDisabled: true,

                                height: "26.78px",
                                value: Number(
                                  (form.watch(`earnings.${i}.hours`) ?? 0) *
                                    (form.watch(`earnings.${i}.rate`) ?? 0)
                                ).toLocaleString(),
                                padding: "12px 15px",
                                boxShadow: "none",
                                placeholder: "",
                              }}
                              label="Total"
                              labelProps={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "customColor.black7",
                                margin: "0px 0px 4px",
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </HStack>
                  </Stack>
                </Stack>
              )}

              {((account?.rateType === "FIXED_AMOUNT" &&
                (item?.calculationType === "RATE" ||
                  item?.calculationType === "USE_EARNINGS_RATE_MULTIPLY")) ||
                (account?.rateType === "GROSS_SALARY" &&
                  item?.calculationType === "ANNUAL_SALARY")) && (
                <Stack alignItems={"flex-end"} maxW={"91.30px"}>
                  <Box>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "amount is required",
                        },

                        onBlur: () => {
                          resetPaySlip();
                        },
                      }}
                      control={form.control}
                      name={`earnings.${i}.amount`}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            fontSize: "12px",

                            variant: "outline",
                            type: "text",

                            // value: `${form.watch(`earnings.${i}.amount`)}`,
                            // onChange: (e) => {
                            //   field.onChange(Number(e.target.valueAsNumber));
                            // },
                            // isDisabled: true,
                            value: field.value
                              ? Number(field.value).toLocaleString()
                              : "",
                            onChange: (e) => {
                              const value = e.target.value.replace(/,/g, "");
                              const parsedValue = Number(value);
                              field.onChange(parsedValue);
                            },

                            height: "26.30px",
                            textAlign: "right",
                            padding: "12px 15px",
                            boxShadow: "none",
                            placeholder: "",
                          }}
                          label="Fixed Amount"
                          labelProps={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "customColor.black7",
                            margin: "0px 0px 4px",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    display={
                      account?.rateType === "GROSS_SALARY" ? "flex" : "none"
                    }
                  >
                    <CustomInput
                      withValidation
                      input={{
                        fontSize: "12px",
                        variant: "outline",
                        type: "text",

                        isDisabled: true,
                        value: `${Number(
                          (
                            (form.watch(`earnings.${i}.amount`) / 100) *
                            (account?.baseSalaryPercentage ?? 0)
                          ).toFixed(2)
                        ).toLocaleString()}`,

                        height: "26.30px",
                        textAlign: "right",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                      label="Fixed Amount"
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "customColor.black7",
                        margin: "0px 0px 4px",
                      }}
                    />
                  </Box>
                  <Box
                    display={
                      account?.rateType === "GROSS_SALARY" ? "flex" : "none"
                    }
                  >
                    <CustomInput
                      withValidation
                      input={{
                        fontSize: "12px",
                        variant: "outline",
                        type: "text",
                        isDisabled: true,
                        value: `${Number(
                          (
                            (form.watch(`earnings.${i}.amount`) / 100) *
                            (account?.allowancesPercentage ?? 0)
                          ).toFixed(2)
                        ).toLocaleString()}`,

                        height: "26.30px",
                        textAlign: "right",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                      label="Fixed Amount"
                      labelProps={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "customColor.black7",
                        margin: "0px 0px 4px",
                      }}
                    />
                  </Box>
                </Stack>
              )}

              <Stack direction="row">
                {item.timesheetId ? (
                  <Icon
                    fontSize={"20px"}
                    as={IoLink}
                    marginTop={"15px"}
                    color={"blue.500"}
                    cursor={"pointer"}
                    _hover={{
                      color: "blue.800",
                    }}
                    onClick={() => {
                      navigate(
                        `/app/payroll/TimesheetsDetail/${item.timesheetId}`
                      );
                    }}
                  />
                ) : item?.payItemEarningId ? (
                  <Icon
                    fontSize={"20px"}
                    as={IoIosCloseCircleOutline}
                    marginTop={"15px"}
                    color={"grey"}
                    _hover={{
                      color: "red.500",
                    }}
                    onClick={() => {
                      earningFieldArray.remove(i);
                      resetPaySlip();
                    }}
                  />
                ) : (
                  <Box
                    w={"20px"}
                    marginTop={"15px"}
                    color={"grey"}
                    _hover={{
                      color: "red.500",
                    }}
                  ></Box>
                )}
              </Stack>
            </Stack>
          );
        }
      )}
    </Stack>
  );
}
