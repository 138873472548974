import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PayRun } from "../../Api/PayRun";

import { AddPayRun } from "./Modal/AddPayRun";
import PayEmployeeTable from "./PayEmployeeTable";

export default function PayEmployee() {
  const addPayRun = useDisclosure();

  const form = useForm<PayRun.PayPeriod>({
    defaultValues: {
      endDate: "",
      startDate: "",
      payrollCalendarId: "",
      string: "",
    },
  });
  const [DraftPayRunData, setDraftPayRun] = useState<Array<PayRun.PayRun>>([]);
  const [PostedPayRunData, setPostedPayRun] = useState<Array<PayRun.PayRun>>(
    []
  );
  const [count, setCount] = useState<number>(1);
  const [count2, setCount2] = useState<number>(1);
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  useEffect(() => {
    PayRun.FetchPayRun(
      {
        status: "POSTED",
        page: pageNumber,
        limit: pageLimit,
      },
      (response) => {
        setPostedPayRun(response?.data);
        setCount(response?.count);

        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [pageNumber, lastUpdated]);
  useEffect(() => {
    PayRun.FetchPayRun(
      {
        status: "DRAFT",
        page: pageNumber,
        limit: pageLimit,
      },
      (response) => {
        setDraftPayRun(response?.data);
        setCount2(response?.count);

        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, [pageNumber, lastUpdated, pageLimit]);

  return (
    <FormProvider {...form}>
      <Box
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
      >
        <Stack borderBottom={"1px solid #dee0e2"}>
          <Container
            justifyContent="center"
            alignItems="center"
            p="20px"
            maxW={"100%"}
            borderRadius="md"
            _dark={{
              bgColor: "customColor.dark.100",
            }}
            _light={{
              bgColor: "customColor.gray.400",
            }}
            paddingBottom={"40px"}
          >
            <Heading
              padding="10px"
              fontSize="24px"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{ color: "customColor.white" }}
              fontWeight="bold"
            >
              Payroll settings
            </Heading>
            <Divider orientation="horizontal" />
            <HStack paddingTop={"20px"}>
              <Image
                borderRadius="0"
                w="179px"
                h="114px"
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "customColor.white",
                }}
                src="https://edge.xero.com/people/payroll/assets/images/video-thumbs/settings-video.png"
                alt="Payroll Settings"
              />
              <VStack
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
                fontSize="18px"
                align="left"
              >
                <Text
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Add bank details, accounts, calendars & pay items via the tabs
                  below.
                </Text>
                <Divider orientation="horizontal" />
                <Text
                  fontSize=".9975rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  You can also set up your superannuation funds too.
                </Text>
                <Divider orientation="horizontal" />
                <Text
                  fontSize=".9975rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  There are plenty of handy tips in the{" "}
                  <Link href="#" color="primary.950">
                    help guide for HRIS 360 Payroll.
                  </Link>
                </Text>
              </VStack>
            </HStack>
          </Container>
        </Stack>
        <Container
          justifyContent="center"
          alignItems="center"
          p="20px"
          maxW={"100%"}
          borderRadius="md"
        >
          <HStack margin={"0px 0px 15px"}>
            <Heading
              textAlign="left"
              // fontSize="15px"
              fontSize=".9975rem"
              fontWeight="600"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              lineHeight={"44px"}
              padding={0}
              display={"flex"}
              flex={1}
            >
              Draft Pay Run
            </Heading>
          </HStack>
          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            <Stack
              borderRadius="4px"
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              border="1px"
              borderColor="#f6f6f6"
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
            >
              <Box overflowX="auto">
                <PayEmployeeTable
                  PayRunData={DraftPayRunData}
                  count={count2}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  pageLimit={pageLimit}
                  setPageLimit={setPageLimit}
                />
              </Box>
            </Stack>
          </Skeleton>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            margin={"20px 10px 15px 0px"}
            onClick={() => {
              form.reset({
                endDate: "",
                startDate: "",
                payrollCalendarId: "",
                string: "",
              });
              addPayRun.onOpen();
            }}
          >
            Add Pay Run
          </Button>
        </Container>
        <Container
          justifyContent="center"
          alignItems="center"
          p="20px"
          maxW={"100%"}
          borderRadius="md"
        >
          <Heading
            textAlign="left"
            //   fontSize="15px"
            fontSize=".9975rem"
            fontWeight="600"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            minHeight={"1px"}
            lineHeight={"44px"}
            padding={0}
            margin={"0px 0px 15px"}
            display={"flex"}
            flex={1}
          >
            Pay Run History
          </Heading>
          <Skeleton height={"100%"} isLoaded={!isLoading} mb="2">
            <Stack
              borderRadius="4px"
              boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              border="1px"
              borderColor="#f6f6f6"
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "customColor.white",
              }}
            >
              <Box overflowX="auto">
                <PayEmployeeTable
                  PayRunData={PostedPayRunData}
                  count={count}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  pageLimit={pageLimit}
                  setPageLimit={setPageLimit}
                />
              </Box>
            </Stack>
          </Skeleton>
        </Container>
        <AddPayRun
          isOpen={addPayRun.isOpen}
          onClose={addPayRun.onClose}
          setLastUpdated={setLastUpdated}
        />
      </Box>
    </FormProvider>
  );
}
