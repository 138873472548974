import {
  Box,
  Button,
  Checkbox,
  Container,
  CSSReset,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { ChevronRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { AddressInput } from "../../../Common/AddressInput";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { BusinessDetail } from "../../Api/BusinessDetail";
import { loadGoogleMapsScript } from "../TimeSheet/utils";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
const libraries = ["places"];
export default function Contact({ setSelectedTab, selectedTab }: TabProps) {
  const form = useForm<BusinessDetail.BusinessDetails>({
    defaultValues: {
      contactName: "",
      contactEmail: undefined,
      contactNumber: "",
      website: "",
      streetAddress: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      postalStreetAddress: "",
      postalCity: "",
      postalState: "",
      postalPostcode: "",
      postalCountry: "",
      mobileNumber: "",
      faxNumber: "",
      landlineNumber: "",
      facebookLink: "",
      twitterLink: "",
      linkedinLink: "",
      invoiceInclusionFields: [],
      logo: "",
    },
  });
  const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,\-]+$/;
  const toast = useToast();
  let countryData = Country.getAllCountries();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find((country) => country.name === form.getValues("country"))
        ?.isoCode
    );
    const province = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setProvinces(province);
  }, [form.watch("country")]);
  const [postalProvinces, setPostalProvinces] = useState<
    Array<{
      name: string;
    }>
  >([]);
  useEffect(() => {
    const provinceOptions = State.getStatesOfCountry(
      countryData.find(
        (country) => country.name === form.getValues("postalCountry")
      )?.isoCode
    );
    const postalProvinces = provinceOptions.map((province: any) => {
      return { name: province?.name };
    });
    setPostalProvinces(postalProvinces);
  }, [form.watch("postalCountry")]);
  useEffect(() => {
    BusinessDetail.GetBusinessDetail(
      (success) => {
        form.reset(success);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);
  const [sameAsPhysical, setSameAsPhysical] = useState(false);
  const [pyhsicalAddressManually, setPyhsicalAddressManually] = useState(false);
  const [postalAddressManually, setPostalAddressManually] = useState(false);
  useEffect(() => {
    loadGoogleMapsScript(() => {});
  }, []);
  return (
    <FormProvider {...form}>
      <Stack
        marginTop={"15px"}
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          // border="1px solid #d6dade"
          // borderRadius="md"
        >
          <HStack gap={0}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              _light={{
                color: "customColor.black7",
              }}
              as="h2"
              flex={1}
              size="md"
            >
              Contact
            </Heading>
            <Link
              color="primary.950"
              fontSize=" var(--x-font-size-small, .8125rem"
              paddingBottom="1px"
              href="/app/payroll/organisationSettings"
            >
              Organisation Settings <Icon as={ChevronRightIcon} />
            </Link>
          </HStack>
          <Divider orientation="horizontal" />

          <Box
            paddingTop="15px"
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            w="100%"
            display={"none"}
          >
            <HStack justifyContent="center">
              <Image
                borderRadius="full"
                paddingTop="20px"
                boxSize="167px"
                src="https://edge.xero.com/cim/organisation-details/prod/5196840050ff4a998949.png"
                alt="Phone"
              />
              <VStack>
                <Text
                  marginLeft="-100px"
                  padding="16px"
                  fontSize="1.3125rem"
                  fontWeight="bold"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Verify your mobile number
                </Text>
                <Text
                  paddingTop="10px"
                  padding="16px"
                  marginLeft="90px"
                  fontSize="1.0625rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  By verifying your mobile number in the new Business contact
                  <br /> section, it will ensure your contact details are
                  up-to-date.
                </Text>
                <Button
                  marginBottom="30px"
                  marginLeft="-240px"
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={onOpen}
                >
                  Add number
                </Button>
              </VStack>
            </HStack>
          </Box>

          {/* Box 2 */}
          {/* <Divider orientation="horizontal" /> */}
          <Heading
            fontSize=".9975rem"
            _light={{
              color: "customColor.black7",
            }}
            fontWeight="bold"
            padding="20px"
            as="h2"
            size="md"
            mb={2}
            display={"none"}
          >
            Business Contact
          </Heading>

          <Container display={"none"}>
            <Text fontSize=".8125rem" fontWeight="bold" paddingTop="25px">
              Verify your mobile number
            </Text>
            <Text fontSize=".8125rem" paddingTop="20px">
              We’ll send you a text message with a 6-digit code. Verifying will
              <br /> ensure your details are up-to-date.
            </Text>

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Contact Name is required",
                },
              }}
              control={form.control}
              name="contactName"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  withValidation
                  ref={field.ref}
                  input={{
                    ...field,

                    padding: "12px 15px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    h: "38px",
                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=""
                  label="Contact Name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                    marginTop: "25px",
                  }}
                />
              )}
            />

            <Divider
              color="black"
              paddingTop="20px"
              h="4px"
              orientation="horizontal"
            />
            <HStack paddingTop="20px" justifyContent={"space-between"}>
              <FormLabel fontSize=".8125rem">Mobile&nbsp;number</FormLabel>

              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={onOpen}
              >
                Add
              </Button>
            </HStack>
            <Tag size="sm" fontSize=".8125rem" borderRadius="full">
              Unverified
            </Tag>
            <Divider
              border="black"
              paddingBottom="40px"
              orientation="horizontal"
            />
          </Container>

          {/* Add Button Modal */}
          <>
            <Modal size="md" isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize=".9975rem"
                >
                  Verify your mobile number
                </ModalHeader>

                <ModalCloseButton />
                <ModalBody>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    marginBottom={"10px"}
                  >
                    Enter your mobile number to receive a 6-digit code
                  </Text>
                  <FormLabel
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize=".8125rem"
                    fontWeight="600"
                  >
                    Mobile number
                  </FormLabel>
                  <Flex justifyContent="center" alignItems="center">
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "contact Number is required",
                        },
                      }}
                      control={form.control}
                      name="contactNumber"
                      render={({ field, fieldState }) => (
                        <>
                          <PhoneInput
                            inputProps={field}
                            country={"au"}
                            placeholder="Phone"
                            inputStyle={{
                              width: "100%",
                              height: "40px",

                              borderColor: fieldState.error ? "red" : "#e5e7eb",

                              borderTopLeftRadius: "3px",
                              borderTopRightRadius: "3px",
                            }}
                            buttonStyle={{
                              backgroundColor: "transparent",
                              borderRadius: "5px",
                              borderColor: fieldState.error ? "red" : "#e5e7eb",
                            }}
                          />
                          {fieldState.error && (
                            <Text color="red.500" fontSize="sm">
                              {fieldState.error.message}
                            </Text>
                          )}
                        </>
                      )}
                    />
                  </Flex>
                  <HStack>
                    <Text
                      marginTop="25px"
                      fontSize=".6875rem"
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      By selecting&nbsp;<strong>Send code</strong>&nbsp;, you
                      agree to receiving an SMS with a 6-digit code
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    h={"40px"}
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                  >
                    {" "}
                    Send code{" "}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>

          {/* <Divider orientation="horizontal" /> */}
          <Heading
            textAlign="left"
            fontSize=".9975rem"
            fontWeight="bold"
            _light={{
              color: "customColor.black7",
            }}
            padding="20px"
            as="h2"
            size="md"
            mb={2}
          >
            Contact Information
          </Heading>
          <Container>
            <Container p={0}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                paddingTop={"25px"}
                marginBottom={"18px"}
              >
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize=".8125rem"
                  fontWeight="700"
                >
                  Physical address
                </Text>
                <HStack alignItems={"center"}>
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize=".8125rem"
                    fontWeight="700"
                    marginLeft="auto"
                  >
                    Enter address manually
                  </Text>
                  <Checkbox
                    size="md"
                    onChange={(e) => {
                      setPyhsicalAddressManually(e.target.checked);
                      if (e.target.checked) {
                        form.setValue("streetAddress", "");
                        form.setValue("city", "");
                        form.setValue("country", "");
                        form.setValue("state", "");
                        form.setValue("postcode", "");
                      }
                    }}
                  />
                </HStack>

                {/* <Spacer /> */}
              </Stack>

              <>
                {!pyhsicalAddressManually && (
                  <Stack gap={0}>
                    <Text
                      color={"customColor.black7"}
                      fontSize={".845rem"}
                      fontWeight={"600"}
                    >
                      Search address
                    </Text>

                    <AddressInput
                      onPlaceSelected={(place) => {
                        form.setValue(
                          "streetAddress",
                          place?.formatted_address
                        );
                        form.setValue("city", place?.city);
                        form.setValue("country", place?.country);
                        form.setValue("state", place?.state);
                        form.setValue("postcode", place?.postcode);
                      }}
                      input={{
                        _light: {
                          color: "customColor.black7",
                        },
                        borderTopRadius: "3px",
                        border: "1px solid #e5e7eb",
                        _hover: {
                          border: "1px solid hsl(0, 0%, 70%)",
                        },
                        _focus: {
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        },
                        _focusVisible: {
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        },
                        fontSize: ".845rem",
                        marginTop: "10px",
                        padding: "12px 15px",
                        variant: "outline",
                        placeholder: "Search address",
                        type: "text",
                      }}
                    />
                  </Stack>
                )}
                <Controller
                  name="streetAddress"
                  control={form.control}
                  // rules={{
                  //   // maxLength: 30,
                  //   // validate: {
                  //   //   notOnlyNumbers: (value) =>
                  //   //     addressRegex.test(value) ||
                  //   //     "Address shouldn't only contain numbers",
                  //   // },
                  // }}
                  render={({ field }) => (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Stack flex={1} width={"100%"}>
                        <CustomInput
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,
                            marginTop: "18px",

                            padding: "12px 15px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            borderTopRadius: "3px",
                            alignContent: "center",
                            onChange: (e) => {
                              field.onChange(e.target.value);
                              if (sameAsPhysical) {
                                form.setValue(
                                  "postalStreetAddress",
                                  e.target.value
                                );
                              }
                            },
                          }}
                          placeholder="Street address or PO box"
                        />
                      </Stack>
                    </Stack>
                  )}
                />
                {pyhsicalAddressManually && (
                  <Box>
                    <Controller
                      name="city"
                      control={form.control}
                      // rules={{ maxLength: 20 }}
                      render={({ field }) => (
                        <CustomInput
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,

                            padding: "12px 15px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",

                            borderTopRadius: "3px",
                            marginTop: "1px",
                            alignContent: "center",
                            onChange: (e) => {
                              field.onChange(e.target.value);
                              if (sameAsPhysical) {
                                form.setValue("postalCity", e.target.value);
                              }
                            },
                          }}
                          placeholder="Town / City"
                        />
                      )}
                    />

                    <HStack gap={0} alignItems={"baseline"}>
                      <Stack flex={1}>
                        <Controller
                          control={form.control}
                          name="state"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={provinces}
                              ErrorMessage="Please Select State"
                              placeholder="Select..."
                              value={provinces?.find(
                                (op: any) => op.name === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.name);
                                if (sameAsPhysical) {
                                  form.setValue("postalState", newValue?.name);
                                }
                              }}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.name}
                            />
                          )}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          name="postcode"
                          control={form.control}
                          // rules={{ maxLength: 10 }}
                          render={({ field }) => (
                            <CustomInput
                              withValidation
                              ref={field.ref}
                              input={{
                                ...field,
                                h: "38px",
                                padding: "12px 15px",
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "number",
                                onChange: (e) => {
                                  field.onChange(e.target.value);
                                  if (sameAsPhysical) {
                                    form.setValue(
                                      "postalPostcode",
                                      e.target.value
                                    );
                                  }
                                },
                                borderRightRadius: "3px",
                                alignContent: "center",
                              }}
                              placeholder="Postal / Zip code"
                            />
                          )}
                        />
                      </Stack>
                    </HStack>
                    <CSSReset />
                    <Box
                      textAlign="left"
                      h={"48px"}
                      marginTop="10px"
                      borderTopRadius="3px"
                      placeholder="Country"
                    >
                      {/* <Countryselector onChange={handleCountryChange} /> */}
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Country is required",
                          },
                        }}
                        control={form.control}
                        name="country"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            placeholder="Select Country"
                            // marginBottom="30px"
                            // width={"48em"}
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select Country"
                            withValidation
                            options={countryData}
                            value={countryData.find(
                              (op) => op.name === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.name);
                              if (sameAsPhysical) {
                                form.setValue("postalCountry", newValue?.name);
                              }
                            }}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </>

              <Divider paddingTop="15px" orientation="horizontal" />
            </Container>
            <HStack alignItems={"center"} marginTop={"10px"}>
              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontSize=".8125rem"
                fontWeight="700"
                marginLeft="auto"
              >
                Same as Physical address
              </Text>
              <Checkbox
                size="md"
                onChange={(e) => {
                  setSameAsPhysical(e.target.checked);
                  if (e.target.checked) {
                    form.setValue(
                      "postalStreetAddress",
                      form.getValues("streetAddress")
                    );
                    form.setValue("postalCity", form.getValues("city"));
                    form.setValue("postalCountry", form.getValues("country"));
                    form.setValue("postalState", form.getValues("state"));
                    form.setValue("postalPostcode", form.getValues("postcode"));
                  } else if (!e.target.checked) {
                    form.setValue("postalStreetAddress", "");
                    form.setValue("postalCity", "");
                    form.setValue("postalCountry", "");
                    form.setValue("postalState", "");
                    form.setValue("postalPostcode", "");
                  }
                }}
                // isChecked={hideInputs}
                // onChange={handleCheckboxToggle}
              />
            </HStack>
            {!sameAsPhysical && (
              <>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginTop={"18px"}
                  marginBottom={"18px"}
                >
                  <Text
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize=".8125rem"
                    fontWeight="700"
                  >
                    Postal address
                  </Text>
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      fontSize=".8125rem"
                      fontWeight="700"
                      marginLeft="auto"
                    >
                      Enter address manually
                    </Text>
                    <Checkbox
                      size="md"
                      onChange={(e) => {
                        setPostalAddressManually(e.target.checked);
                        if (e.target.checked) {
                          form.setValue("postalStreetAddress", "");
                          form.setValue("postalCity", "");
                          form.setValue("postalCountry", "");
                          form.setValue("postalState", "");
                          form.setValue("postalPostcode", "");
                        }
                      }}
                    />
                  </HStack>
                </HStack>

                {!postalAddressManually && (
                  <Stack gap={0}>
                    <Text
                      color={"customColor.black7"}
                      fontSize={".845rem"}
                      fontWeight={"600"}
                    >
                      Search address
                    </Text>

                    <AddressInput
                      onPlaceSelected={(place) => {
                        form.setValue(
                          "postalStreetAddress",
                          place?.formatted_address
                        );
                        form.setValue("postalCity", place?.city);
                        form.setValue("postalCountry", place?.country);
                        form.setValue("postalState", place?.state);
                        form.setValue("postalPostcode", place?.postcode);
                      }}
                      input={{
                        _light: {
                          color: "customColor.black7",
                        },
                        border: "1px solid #e5e7eb",
                        _hover: {
                          border: "1px solid hsl(0, 0%, 70%)",
                        },
                        borderTopRadius: "3px",
                        _focus: {
                          borderWidth: "1px",

                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        },
                        _focusVisible: {
                          borderWidth: "1px",
                          boxShadow: "0 0 0 1px #2684FF",
                          _hover: {
                            borderWidth: "1px",
                            borderColor: "#2684FF",
                            boxShadow: "0 0 0 1px #2684FF",
                          },
                        },
                        fontSize: ".845rem",
                        placeholder: "Search address",
                        padding: "12px 15px",
                        variant: "outline",
                        marginTop: "10px",
                        type: "text",
                      }}
                    />
                  </Stack>
                )}
                <Controller
                  // rules={{
                  //   validate: {
                  //     notOnlyNumbers: (value) =>
                  //       addressRegex.test(value) ||
                  //       "Address shouldn't only contain numbers",
                  //   },
                  // }}
                  name="postalStreetAddress"
                  control={form.control}
                  render={({ field }) => (
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      gap={0}
                    >
                      <Stack flex={1} width={"100%"}>
                        <CustomInput
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,
                            w: "100%",
                            h: "38px",
                            padding: "12px 15px",
                            fontSize: ".845rem",
                            marginTop: "18px",
                            variant: "outline",
                            type: "text",
                            borderTopRadius: "3px",
                            alignContent: "center",
                          }}
                          placeholder="Street address or PO box"
                        />
                      </Stack>
                    </Stack>
                  )}
                />
                {postalAddressManually && (
                  <>
                    <Controller
                      name="postalCity"
                      control={form.control}
                      // rules={{ maxLength: 10 }}
                      render={({ field }) => (
                        <CustomInput
                          withValidation
                          ref={field.ref}
                          input={{
                            ...field,

                            padding: "12px 15px",
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            h: "38px",
                            marginTop: "1px",
                            borderTopRadius: "3px",
                            alignContent: "center",
                          }}
                          placeholder="Town / City"
                        />
                      )}
                    />
                    <HStack gap={0} alignItems={"baseline"}>
                      <Stack flex={1}>
                        <Controller
                          control={form.control}
                          name="postalState"
                          render={({ field, fieldState }) => (
                            <ReactSelect
                              {...field}
                              key={field.value}
                              options={postalProvinces}
                              ErrorMessage="Please Select State"
                              placeholder="Select..."
                              value={postalProvinces?.find(
                                (op: any) => op.name === field.value
                              )}
                              onChange={(newValue: any) => {
                                field.onChange(newValue?.name);
                              }}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.name}
                            />
                          )}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          name="postalPostcode"
                          control={form.control}
                          // rules={{ maxLength: 10 }}
                          render={({ field }) => (
                            <CustomInput
                              withValidation
                              ref={field.ref}
                              input={{
                                ...field,
                                h: "38px",
                                padding: "12px 15px",
                                fontSize: ".845rem",
                                variant: "outline",
                                type: "number",

                                borderRightRadius: "3px",
                                alignContent: "center",
                              }}
                              placeholder="Postal / Zip code"
                            />
                          )}
                        />
                      </Stack>
                    </HStack>
                    <CSSReset />
                    <Box
                      textAlign="left"
                      marginTop="10px"
                      borderTopRadius="3px"
                      placeholder="Country"
                    >
                      {/* <Countryselector onChange={handleCountryChange} /> */}
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Country is required",
                          },
                        }}
                        control={form.control}
                        name="postalCountry"
                        render={({ field, fieldState }) => (
                          <ReactSelect
                            {...field}
                            placeholder="Select Country"
                            // marginBottom="30px"
                            // width={"48em"}
                            isInvalid={fieldState.invalid}
                            ErrorMessage="Select Country"
                            withValidation
                            options={countryData}
                            value={countryData.find(
                              (op) => op.name === field.value
                            )}
                            onChange={(newValue: any) => {
                              field.onChange(newValue?.name);
                            }}
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option.name}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
            <Divider paddingTop="25px" orientation="horizontal" />{" "}
          </Container>

          <Container>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Contact Name is required",
                },
              }}
              control={form.control}
              name="contactName"
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  withValidation
                  ref={field.ref}
                  input={{
                    ...field,

                    padding: "12px 15px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",

                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=""
                  label="Contact Name"
                  labelProps={{
                    fontSize: ".845rem",
                    fontWeight: "600",
                    _light: {
                      color: "customColor.black7",
                    },
                    marginTop: "25px",
                  }}
                />
              )}
            />
            <FormLabel
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontWeight="700"
              fontSize=".8125rem"
              marginTop={"25px"}
            >
              LandLine Phone
            </FormLabel>

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "LandLine Phone number is required",
                },
              }}
              control={form.control}
              name="landlineNumber"
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    inputProps={field}
                    country={"au"}
                    placeholder="Phone"
                    inputStyle={{
                      width: "100%",
                      height: "40px",

                      borderColor: fieldState.error ? "red" : "#e5e7eb",

                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      borderColor: fieldState.error ? "red" : "#e5e7eb",
                    }}
                  />
                  {fieldState.error && (
                    <Text color="red.500" fontSize="sm">
                      {fieldState.error.message}
                    </Text>
                  )}
                </>
              )}
            />

            <Controller
              name="contactEmail"
              control={form.control}
              rules={{
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "email is not in a valid format",
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack flex={1} width={"100%"}>
                      <CustomInput
                        withValidation
                        ref={field.ref}
                        isInvalid={fieldState.error !== undefined}
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          alignContent: "center",
                        }}
                        placeholder=""
                        label="Email"
                        labelProps={{
                          fontWeight: "700",
                          fontSize: ".8125rem",
                          color: "rgba(0,10,30,.75)",
                          marginTop: "25px",
                        }}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
            />

            <Controller
              name="website"
              control={form.control}
              rules={{
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?\S+\.\S+/i,
                  message: "website is not in a valid format",
                },
                maxLength: 30,
              }}
              render={({ field }) => (
                <>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack flex={1} width={"100%"}>
                      <CustomInput
                        ref={field.ref}
                        withValidation
                        input={{
                          ...field,

                          padding: "12px 15px",
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          alignContent: "center",
                        }}
                        placeholder=""
                        label="Website"
                        labelProps={{
                          fontWeight: "700",
                          fontSize: ".8125rem",
                          color: "rgba(0,10,30,.75)",
                          marginTop: "25px",
                        }}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
            />

            <FormLabel
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontWeight="700"
              fontSize=".8125rem"
              paddingTop="25px"
            >
              Mobile
            </FormLabel>

            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Mobile number is required",
                },
              }}
              control={form.control}
              name="mobileNumber"
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    inputProps={field}
                    country={"au"}
                    placeholder="Phone"
                    inputStyle={{
                      width: "100%",
                      height: "40px",

                      borderColor: fieldState.error ? "red" : "#e5e7eb",

                      borderTopLeftRadius: "3px",
                      borderTopRightRadius: "3px",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      borderColor: fieldState.error ? "red" : "#e5e7eb",
                    }}
                  />
                  {fieldState.error && (
                    <Text color="red.500" fontSize="sm">
                      {fieldState.error.message}
                    </Text>
                  )}
                </>
              )}
            />

            <FormLabel
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontWeight="700"
              fontSize=".8125rem"
              paddingTop="25px"
            >
              Fax
            </FormLabel>

            <Controller
              name="faxNumber"
              control={form.control}
              render={({ field }) => (
                <CustomInput
                  ref={field.ref}
                  withValidation
                  input={{
                    ...field,

                    padding: "12px 15px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",

                    borderTopRadius: "3px",
                    alignContent: "center",
                  }}
                  placeholder=""
                />
              )}
            />

            <Controller
              name="facebookLink"
              control={form.control}
              rules={{ maxLength: 30 }}
              render={({ field }) => (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack flex={1} width={"100%"}>
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      input={{
                        ...field,
                        defaultValue: "facebook.com/",

                        padding: "12px 15px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        alignContent: "center",
                      }}
                      placeholder="facebook.com/"
                      label="Facebook"
                      labelProps={{
                        fontWeight: "700",
                        fontSize: ".8125rem",
                        color: "rgba(0,10,30,.75)",
                        marginTop: "25px",
                      }}
                    />
                  </Stack>
                  {/* <Stack
                    marginTop={"55px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {showCheckboxes && <IncludeCheckbox name="facebookLink" />}
                  </Stack> */}
                </Stack>
              )}
            />
            <Controller
              name="twitterLink"
              control={form.control}
              rules={{ maxLength: 30 }}
              render={({ field }) => (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack flex={1} width={"100%"}>
                    <CustomInput
                      withValidation
                      ref={field.ref}
                      input={{
                        ...field,
                        defaultValue: "twitter.com/",

                        padding: "12px 15px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        alignContent: "center",
                      }}
                      placeholder="twitter.com/"
                      label="Twitter"
                      labelProps={{
                        fontWeight: "700",
                        fontSize: ".8125rem",
                        color: "rgba(0,10,30,.75)",
                        marginTop: "25px",
                      }}
                    />{" "}
                  </Stack>
                  {/* <Stack
                    marginTop={"55px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {showCheckboxes && <IncludeCheckbox name="twitterLink" />}
                  </Stack> */}
                </Stack>
              )}
            />
            <Controller
              name="linkedinLink"
              control={form.control}
              rules={{ maxLength: 30 }}
              render={({ field }) => (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack flex={1} width={"100%"}>
                    <CustomInput
                      ref={field.ref}
                      withValidation
                      input={{
                        ...field,
                        defaultValue: "linkedin.com/",

                        padding: "12px 15px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",

                        borderTopRadius: "3px",
                        alignContent: "center",
                        marginBottom: "25px",
                      }}
                      placeholder="linkedin.com/"
                      label=" Linkedin"
                      labelProps={{
                        fontWeight: "700",
                        fontSize: ".8125rem",
                        color: "rgba(0,10,30,.75)",
                        marginTop: "25px",
                      }}
                    />
                  </Stack>
                  {/* <Stack
                    marginTop={"35px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"13.44px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    {showCheckboxes && <IncludeCheckbox name="linkedinLink" />}
                  </Stack> */}
                </Stack>
              )}
            />
          </Container>

          {/* </VStack> */}

          <Link
            marginBottom="50px"
            padding="30px"
            fontSize=".6875rem"
            alignSelf="center"
            color="#003c64"
            href="https://www.xero.com/legal/privacy/"
            isExternal
          >
            Privacy notice
            <ExternalLinkIcon mx="2px" />{" "}
          </Link>
          <Box
            className="fixed-box"
            bottom="0"
            left="0"
            right="0"
            maxWidth="100%"
            minHeight="60px"
            _light={{
              background: "#fff",
            }}
            _dark={{
              bg: "customColor.dark.50",
            }}
            boxShadow="rgba(0, 0, 0, 0.2)"
            padding="10px"
            textAlign="center"
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"flex-end"}
            >
              <FormControl marginLeft="10px" display="none" alignItems="center">
                <Switch
                  size="lg"
                  colorScheme="teal"
                  id="showCheckboxes"
                  name="showCheckboxes"
                  isChecked={showCheckboxes}
                  onChange={() => setShowCheckboxes(!showCheckboxes)}
                />
                <FormLabel
                  marginLeft="5px"
                  paddingTop="10px"
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Select additional details to display on your{" "}
                  <Link color="primary.950">
                    online invoices
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </FormLabel>
              </FormControl>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                {" "}
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  backgroundColor={"transparent"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    form.handleSubmit(
                      (data) => {
                        setIsLoading(true);
                        BusinessDetail.AddBusinessDetail(
                          data,
                          (success) => {
                            toast({
                              title: "Business Details Added",

                              status: "success",
                            });
                            setIsLoading(false);
                            setSelectedTab(selectedTab + 1);
                          },
                          (error) => {
                            toast({
                              title: "Business Detail Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      },
                      (error) => {
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  Save and Next
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    form.handleSubmit(
                      (data) => {
                        setIsLoading(true);
                        BusinessDetail.AddBusinessDetail(
                          data,
                          (success) => {
                            toast({
                              title: "Business Details Added",

                              status: "success",
                            });
                            setIsLoading(false);
                          },
                          (error) => {
                            toast({
                              title: "Business Detail Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      },
                      (error) => {
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
      {/* </Box> */}
    </FormProvider>
  );
}
