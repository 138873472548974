import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import * as XLSX from "xlsx";
import { TimeSheetNewDetail } from "../../../Api/TimeSheetDetail";
interface UploadBulkTimesheetProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timestamp: number) => void;
}
export default function UploadBulkTimesheet({
  isOpen,
  onClose,
  setLastUpdated,
}: UploadBulkTimesheetProps) {
  const [timesheets, setTimesheets] = useState<
    Array<TimeSheetNewDetail.BulkTimesheetData>
  >([]);
  const toast = useToast();
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        processTimesheetData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const processTimesheetData = (data: any[][]) => {
    const headers = data[0];
    const dateHeaders = headers.filter((header) =>
      moment(header, "DD-MM-YYYY", true).isValid()
    );
    const dates = dateHeaders.map((dateStr) => moment(dateStr, "DD-MM-YYYY"));

    const startDate = moment.min(dates).format("YYYY-MM-DD");
    const endDate = moment.max(dates).format("YYYY-MM-DD");
    const rows = data.slice(1);

    const newTimesheets: Array<TimeSheetNewDetail.BulkTimesheetData> = rows.map(
      (row) => {
        const empUniqueId = row[0] as number;
        // const payItemsEarningsId = row[
        //   headers.indexOf("Pay Item ID")
        // ] as string;

        const dayHours: Record<string, number> = {};
        let totalHours = 0;

        headers.forEach((header, index) => {
          if (header.includes("-")) {
            // Ensure headers match expected date format
            const hours = row[index] as number;
            if (hours >= 0) {
              const formattedDate = moment(header, "DD-MM-YYYY").format(
                "ddd DD MMM"
              );
              dayHours[formattedDate] = hours;
              totalHours += hours;
            }
          }
        });

        return {
          dateRange: {
            startDate: startDate,
            endDate: endDate,
          },
          totalHours,
          payRatesData: [
            {
              dayHours,
            },
          ],
          empUniqueId,
        };
      }
    );

    setTimesheets(newTimesheets);
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Timesheet File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="green"
            onClick={() => {
              setIsLoading(true);
              TimeSheetNewDetail.BulkUploadTimesheet(
                {
                  timesheets: timesheets,
                },
                () => {
                  setIsLoading(false);
                  setLastUpdated(Date.now());
                  onClose();
                },
                () => {
                  toast({
                    title: "Error",
                    description: "Failed to upload timesheet data",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });

                  setIsLoading(false);
                  onClose();
                }
              );
            }}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
