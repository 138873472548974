import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { TbMinusVertical } from "react-icons/tb";
import { Column } from "../Reports/Columns";

interface ColumnsProps {
  setSelectedColumns: Dispatch<SetStateAction<Column[]>>;
  selectedColumns: any;
  colBtns: {
    colItem: Array<Column>;
  }[];
}
export function ColumnsComponent({
  setSelectedColumns,
  selectedColumns,
  colBtns,
}: ColumnsProps) {
  const reportForm = useFormContext();
  return (
    <Stack margin={"10px 0px 0px"} padding={"0px 0px 0px 10px"}>
      <Text
        fontSize="13px"
        display={"block"}
        fontWeight="600"
        _light={{
          color: "customColor.black7",
        }}
        _dark={{
          color: "customColor.white",
        }}
      >
        Columns
      </Text>

      <Box>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                rightIcon={
                  <Flex>
                    <TbMinusVertical
                      fontSize={"22px"}
                      color={"hsl(0,0%,80%)"}
                    />
                    <ChevronDownIcon
                      fontSize={"22px"}
                      color={isOpen ? "hsl(0, 0%, 40%)" : "hsl(0,0%,80%)"}
                      _hover={{
                        color: isOpen ? "hsl(0, 0%, 20%)" : "hsl(0,0%,60%)",
                      }}
                    />
                  </Flex>
                } //5A5A5A //383c40
                width={"100%"}
                textAlign={"start"}
                h={"38px"}
                _light={{
                  bgColor: "customColor.white",
                  color: "customColor.black7",
                }}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                fontWeight={"400"}
                border={"1px solid #e5e7eb"}
                borderRadius={"4px"}
                _hover={{
                  color: "black",
                  bg: "#f2f3f4",
                  border: "1px solid hsl(0, 0%, 70%)",
                }}
                _active={{
                  color: "black",
                  bg: "#f2f3f4",
                  borderWidth: "1px",

                  boxShadow: "0 0 0 1px #2684FF",
                  _hover: {
                    borderWidth: "1px",
                    borderColor: "#2684FF",
                    boxShadow: "0 0 0 1px #2684FF",
                  },
                }}
              >
                {selectedColumns.length} Selected Columns
              </MenuButton>
              <MenuList
                w={"200px"}
                maxH="300px"
                overflowY="auto"
                zIndex={"overlay"}
              >
                {colBtns.map((items, k) => {
                  return (
                    <MenuGroup
                      fontSize={"13px"}
                      color={"#404756"}
                      fontWeight={"normal"}
                      whiteSpace={"nowrap"}
                      key={k}
                    >
                      {items.colItem.map((list, j) => {
                        return (
                          <MenuItem
                            key={j}
                            fontSize={"15px"}
                            padding={"9px 15px"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {/* <Controller
                          control={reportForm.control}
                          name={`columns.${list.column}` as any}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              isChecked={field.value}
                              paddingRight={"10px"}
                              size={"lg"}
                            />
                          )}
                        /> */}
                            <Checkbox
                              isChecked={
                                selectedColumns.findIndex(
                                  (v: any) => v.column === list.column
                                ) > -1
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedColumns([
                                    ...selectedColumns,
                                    list,
                                  ]);
                                } else {
                                  setSelectedColumns(
                                    selectedColumns.filter(
                                      (item: any) => item.column !== list.column
                                    )
                                  );
                                }
                              }}
                              paddingRight={"10px"}
                              size={"md"}
                            >
                              <Text
                                fontSize={".845rem"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                              >
                                {list.colLabel}
                              </Text>
                            </Checkbox>
                          </MenuItem>
                        );
                      })}

                      {k !== colBtns.length - 1 && <MenuDivider />}
                    </MenuGroup>
                  );
                })}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </Stack>
  );
}
