import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { CustomInput } from "../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../../../Api/EmployeeLeaveEntitlement";
import { PayItemLeaveApi } from "../../../../Api/PayItemLeave";

interface CashOutLeaveProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  FecthedLeaves: Array<PayItemLeaveApi.LeaveById>;
  employeeId: string;
}
export default function CashOutLeave({
  isOpen,
  onClose,
  setLastUpdated,
  FecthedLeaves,
  employeeId,
}: CashOutLeaveProps) {
  const form = useFormContext<EmployeeLeave.NewLeave>();
  const watchSelectedLeaveType = useWatch({
    control: form.control,
    name: "payrollPayItemLeaveId",
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [hours, setHours] =
    useState<EmployeeLeaveEntitlement.fetchEntitlementsHours>();
  const [selectedPayperiodsString, setSelectedPayperiodsStrings] = useState<
    Array<string>
  >([""]);
  useEffect(() => {
    if (watchSelectedLeaveType) {
      EmployeeLeaveEntitlement.FetchEntitlementsHours(
        employeeId,
        watchSelectedLeaveType,
        (success) => {
          setHours(success);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [watchSelectedLeaveType]);
  const { employeesList } = useBusinessContext();
  const watchPayoutDate = useWatch({
    control: form.control,
    name: "payoutDate",
  });
  useEffect(() => {
    if (employeeId && watchPayoutDate) {
      EmployeeLeave.LeavePayPeriods(
        {
          empId: employeeId,
          startDate: watchPayoutDate,
          endDate: watchPayoutDate,
        },
        (success) => {
          setSelectedPayperiodsStrings(
            success.map((period) => period?.string ?? "")
          );
          const payPeriods = success.map((period) => {
            const existingPayPeriod = form
              .getValues("payPeriods")
              ?.find(
                (p: any) =>
                  p.startDate === period.startDate &&
                  p.endDate === period.endDate
              );
            return {
              ...period,
              quantity: existingPayPeriod?.quantity ?? 0,
              id: existingPayPeriod?.id,
              string: existingPayPeriod?.string,
            };
          });
          form.setValue("payPeriods", payPeriods);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [employeeId, watchPayoutDate]);
  const LeavePayPeriodsQuantity = useFieldArray({
    control: form.control,
    name: "payPeriods",
  });
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          // _light={{
          //   color: "customColor.black2",
          // }}
          // _dark={{
          //   color: "customColor.white",
          // }}
          maxW="400px"
          maxH="550px"
        >
          <ModalHeader
            _dark={{
              color: "customColor.dark.150",
            }}
            padding={"12px 20px"}
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
            fontWeight={"700"}
            lineHeight={"1.75rem"}
            borderBottom={"1px solid #e8e8e8"}
          >
            Cash out leave
          </ModalHeader>
          <ModalCloseButton />
          <Box overflowY="auto">
            <ModalBody padding={"8px 20px"}>
              <Box
                backgroundColor={"#f2f3f4"}
                _dark={{
                  bgColor: "customColor.dark.150",
                }}
                fontSize={".8125rem"}
                padding={"12px 16px 12px 16px"}
                marginBottom={"20px"}
              >
                <HStack alignItems="flex-start">
                  <Icon as={AiFillInfoCircle} />
                  <Text
                    lineHeight={"1.5rem"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize={"0.8125rem"}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    marginTop={"-5px"}
                  >
                    There are specific rules around cashing out leave. Before
                    proceeding, check the rules on how they affect you and your
                    employee with the{" "}
                    <Link
                      fontSize={"0.8125rem"}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                    >
                      Fair Work Commission
                      <Icon as={BsBoxArrowUpRight} />
                    </Link>
                  </Text>
                </HStack>
              </Box>
              <Stack>
                <Text
                  fontSize=".8125rem"
                  fontWeight="600"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                >
                  Employee
                </Text>

                <HStack
                  border="1px solid #e5e7eb"
                  padding={"5px"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                    color: "customColor.black7",
                  }}
                  borderRadius={"3px"}
                >
                  <Avatar
                    name={
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName
                    }
                    h={"24px"}
                    w={"24px"}
                    size={"sm"}
                    marginLeft={"8px"}
                    bg={"#daa3e4"}
                    color={"#3f1946"}
                  />
                  <Text fontSize={"15px"}>
                    {employeesList?.find((emp) => emp.id === employeeId)
                      ?.firstName +
                      " " +
                      employeesList?.find((emp) => emp.id === employeeId)
                        ?.lastName}
                  </Text>
                </HStack>

                <Text
                  marginTop={"15px"}
                  fontSize=".8125rem"
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight="600"
                  _dark={{
                    color: "customColor.white",
                  }}
                  lineHeight={"1.3077"}
                  marginBottom="-7px"
                  padding={"1px 0px 4px"}
                >
                  Leave Type
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Select Employee Status is required",
                    },
                  }}
                  control={form.control}
                  name="payrollPayItemLeaveId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      options={FecthedLeaves?.map((leave) => ({
                        value: leave.id,
                        label: leave.name,
                      }))}
                      withValidation
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Please Select Status"
                      placeholder="Select..."
                      value={
                        FecthedLeaves?.find((leave) => leave.id === field.value)
                          ? {
                              value: field.value,
                              label: FecthedLeaves?.find(
                                (leave) => leave.id === field.value
                              )?.name,
                            }
                          : null
                      }
                      onChange={(selectedOption: any) =>
                        field.onChange(selectedOption.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                {watchSelectedLeaveType && (
                  <Stack>
                    <Text
                      fontSize=".8125rem"
                      marginTop={"15px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      marginBottom="-12px"
                      padding={"1px 0px 4px"}
                    >
                      Description (optional)
                    </Text>

                    <Controller
                      control={form.control}
                      name="description"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            fontSize: ".845rem",
                            variant: "outline",
                            type: "text",
                            h: "38px",
                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                        />
                      )}
                    />
                    <HStack marginTop={"20px"} borderBottom={"1px solid grey"}>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        Current Leave Balance
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {FecthedLeaves.find(
                          (op) => op.id === watchSelectedLeaveType
                        )?.unitType ?? "Days"}
                      </Heading>
                    </HStack>
                    <HStack marginTop={"10px"}>
                      <Heading
                        display={"flex"}
                        flex={1}
                        fontSize={".8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {
                          FecthedLeaves.find(
                            (op) => op.id === watchSelectedLeaveType
                          )?.name
                        }
                      </Heading>
                      <Heading
                        fontSize={".8125rem"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                      >
                        {hours?.balance}
                      </Heading>
                    </HStack>
                    <Text
                      fontSize=".8125rem"
                      marginTop={"20px"}
                      fontWeight="600"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1.3077"}
                      marginBottom="-12px"
                      padding={"1px 0px 4px"}
                    >
                      Date
                    </Text>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Payout Date is required",
                        },
                      }}
                      control={form.control}
                      name="payoutDate"
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          ref={field.ref}
                          validationMessage={error?.message}
                          withValidation
                          input={{
                            ...field,
                            isInvalid: error !== undefined,
                            w: "100%",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },

                            fontSize: ".845rem",
                            variant: "outline",
                            type: "date",
                            h: "38px",
                            borderTopRadius: "3px",
                          }}
                          placeholder=""
                        />
                      )}
                    />
                    <Text
                      fontSize=".6875rem"
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      lineHeight={"1rem"}
                      marginBottom="-12px"
                      padding={"1px 0px 4px"}
                    >
                      To pay this out over more than 1 pay period, you'll need
                      to create separate requests.
                    </Text>
                    {/* {watchPayoutDate &&
                      LeavePayPeriodsQuantity.fields.map((field, index) => ( */}
                    <Stack>
                      <Text
                        fontSize=".8125rem"
                        marginTop={"30px"}
                        fontWeight="600"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1.3077"}
                        marginBottom="-12px"
                        padding={"1px 0px 4px"}
                      >
                        Number of days to cash out
                      </Text>
                      <Controller
                        rules={{
                          required: {
                            value: true,
                            message: "Days is required",
                          },
                        }}
                        control={form.control}
                        name={`payPeriods.${0}.quantity`}
                        render={({ field, fieldState: { error } }) => (
                          <CustomInput
                            ref={field.ref}
                            validationMessage={error?.message}
                            withValidation
                            input={{
                              ...field,
                              isInvalid: error !== undefined,
                              w: "100%",
                              padding: "12px 15px",
                              _light: {
                                color: "customColor.black7",
                              },
                              border: "1px solid #e5e7eb",
                              fontSize: ".845rem",
                              variant: "outline",
                              type: "number",
                              h: "38px",
                              borderTopRadius: "3px",
                              onChange: (e) => {
                                field.onChange(Number(e.target.value));
                              },
                            }}
                            placeholder=""
                          />
                        )}
                      />
                      <Text
                        fontSize=".6875rem"
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        lineHeight={"1rem"}
                        marginBottom="-12px"
                        padding={"1px 0px 4px"}
                      >
                        To pay this out over more than 1 pay period, you'll need
                        to create separate requests.
                      </Text>

                      <Stack marginTop={"25px"} borderBottom={"1px solid grey"}>
                        <Heading
                          display={"flex"}
                          flex={1}
                          fontSize={".8125rem"}
                          fontWeight="600"
                          _light={{
                            color: "customColor.black2",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                          paddingBottom={"5px"}
                        >
                          Pay period leave will be cashed out
                        </Heading>
                      </Stack>
                      <Text
                        fontSize={"17px"}
                        fontWeight={"normal"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        paddingBottom={"5px"}
                        marginBottom={"20px"}
                      >
                        {selectedPayperiodsString[0]}
                      </Text>
                    </Stack>
                    {/* ))} */}
                  </Stack>
                )}
              </Stack>
            </ModalBody>
          </Box>
          <ModalFooter padding={"20px"} borderTop={"1px solid #e8e8e8"}>
            {/* {form.getValues("id") && (
              <ButtonGroup
                minH={"40px"}
                spacing="1px"
                colorScheme={"red"}
                flex={1}
              >
                <Button
                  borderLeftRadius="3px"
                  w="100px"
                  borderRight="0px"
                  minH={"40px"}
                  px="16px"
                  width="fit-content"
                  isLoading={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    EmployeeLeave.LeaveRejected(
                      form.getValues("id")!,
                      {},
                      () => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "leave Rejected",
                          status: "success",
                        });
                        setIsLoading(false);
                      },
                      (error) => {
                        toast({
                          title: "Error",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }}
                >
                  Reject
                </Button>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    borderRightRadius="3px"
                    aria-label="dropdown menu"
                    icon={<FiChevronDown />}
                    minH={"40px"}
                  />
                  <MenuList minW="100px">
                    <MenuItem
                      fontSize="13px"
                      // onClick={() => {
                      //   rejectModal.onOpen();
                      // }}
                    >
                      Reject with a note
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ButtonGroup>
            )} */}
            <Flex>
              <Button
                backgroundColor={"transparent"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                textColor={"#ea4d4d"}
                padding={"12px 16px"}
                marginRight={"5px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    const QuantitySum = data.payPeriods.reduce(
                      (acc, current) => acc + current.quantity,
                      0
                    );
                    setIsLoading(true);
                    if (form.getValues("id")) {
                      EmployeeLeave.UpdateLeave(
                        form.getValues("id")!,
                        employeeId!,
                        { ...data, quantity: QuantitySum },
                        (success) => {
                          toast({
                            title: "Leave is updated",

                            status: "success",
                          });
                          setIsLoading(false);

                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      EmployeeLeave.CreateLeave(
                        employeeId!,
                        { ...data, quantity: QuantitySum },
                        (success) => {
                          toast({
                            title: "Leave is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          setLastUpdated(Date.now());
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {form.getValues("id") ? "Update" : "Add"}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
