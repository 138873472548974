import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Switch,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { ChevronRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { BusinessDetail } from "../../Api/BusinessDetail";
import { IncludeCheckbox } from "./components";
interface TabProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function OrgDetails({ setSelectedTab, selectedTab }: TabProps) {
  const form = useForm<BusinessDetail.BusinessDetails>({
    defaultValues: {
      displayName: "",
      legalName: "",
      legalCode: "",
      industry: "",
      organizationType: "",
      taxNumber: "",
      branch: "",
      organizationDescription: "",
    },
  });
  const industries = [
    "Aged care industry",
    "Agricultural industry",
    "Airline operations",
    "Airport operations",
    "Aluminium industry",
    "Ambulance and patient transport",
    "Amusement, events and recreation industry",
    "Animal care and veterinary services",
    "Aquaculture",
    "Asphalt industry",
    "Australian Capital Territory",
    "Banking, finance and insurance industry",
    "Broadcasting and recorded entertainment industry",
    "Building services",
    "Building, metal and civil construction industries",
    "Business equipment industry",
    "Cement and concrete products",
    "Cemetery operations",
    "Children's services",
    "Christmas Island",
    "Cleaning services",
    "Clerical industry",
    "Clothing industry",
    "Coal export terminals",
    "Coal industry",
    "Cocos (Keeling) Islands",
    "Commercial sales",
    "Commonwealth employment",
    "Contract call centre industry",
    "Corrections and detentions",
    "Diving services",
    "Dredging industry",
    "Dry cleaning and laundry services",
    "Educational services",
    "Electrical contracting industry",
    "Electrical power industry",
    "Fast food industry",
    "Federal police operations",
    "Fire fighting services",
    "Food, beverages and tobacco manufacturing industry",
    "Funeral directing services",
    "Gardening services",
    "Grain handling industry",
    "Graphic arts",
    "Hair and beauty",
    "Health and welfare services",
    "Hospitality industry",
    "Indigenous organisations and services",
    "Journalism",
    "Licensed and registered clubs",
    "Live performance industry",
    "Local government administration",
    "Mannequins and modelling",
    "Manufacturing and associated industries",
    "Marine tourism and charter vessels",
    "Market and business consultancy services",
    "Meat industry",
    "Mining industry",
    "Miscellaneous",
    "Norfolk Island",
    "Northern Territory",
    "Nursery industry",
    "Passenger vehicle transport (non rail) industry",
    "Pet food manufacturing",
    "Pharmaceutical industry",
    "Pharmacy operations",
    "Plumbing industry",
    "Port authorities",
    "Postal services",
    "Poultry processing",
    "Publishing industry",
    "Quarrying industry",
    "Racing industry",
    "Rail industry",
    "Real estate industry",
    "Restaurants",
    "Retail industry",
    "Road transport industry",
    "Rubber, plastic and cable making industry",
    "Salt industry",
    "Scientific services",
    "Seafood processing",
    "Security services",
    "Social, community, home care and disability services",
    "Sporting organisations",
    "State and Territory government administration",
    "Stevedoring industry",
    "Storage services",
    "Sugar industry",
    "Tasmania",
    "Technical services",
    "Telecommunications services",
    "Textile industry",
    "Timber and paper products industry",
    "Tourism industry",
    "Uranium mining (incl. construction)",
    "Vehicle industry",
    "Waste management industry",
    "Water, sewerage and drainage services",

    "Wool storage, sampling and testing industry",
    "Aged Care Industry",
    "Agricultural Industry",
    "Airline Operations",
    "Automotive Industry",
    "Banking & Financial Services",
    "Biotechnology Industry",
    "Construction Industry",
    "Consumer Goods Industry",
    "Education Sector",
    "Energy Industry",
    "Entertainment & Media Industry",
    "Fashion & Apparel Industry",
    "Food & Beverage Industry",
    "Healthcare Industry",
    "Hospitality Industry",
    "Information Technology Industry",
    "Insurance Industry",
    "Manufacturing Industry",
    "Mining Industry",
    "Pharmaceutical Industry",
    "Professional Services",
    "Real Estate Industry",
    "Retail Industry",
    "Telecommunications Industry",
    "Transportation and Logistics",
    "Travel and Tourism Industry",
    "Utilities Industry",
    "Accounting Services",
    "Corporate Finance & Investment Management",
    "Financial Planning & Advisory Services",
    "Fintech Industry",
    "Insurance Services",
    "Investment Banking",
    "Private Equity & Venture Capital",
    "Taxation Services",
    "Other",
  ].map((industry) => ({ label: industry, value: industry }));

  const toast = useToast();
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    BusinessDetail.GetBusinessDetail(
      (success) => {
        form.reset(success);

        setIsLoading(false);
      },
      (error) => {
        toast({
          title: error,
          status: "error",
        });
        setIsLoading(false);
      }
    );
  }, []);

  const organizationData: Array<{
    label: string;
    value: keyof typeof BusinessDetail.BusinessOrganizationTypeEnums;
  }> = [
    { value: "CLUB_OR_SOCIETY", label: "Club or society" },
    { value: "COMPANY", label: "Company" },

    { value: "NOT_FOR_PROFIT", label: "Not for Profit" },
    { value: "PARTNERSHIP", label: "Partnership" },
    {
      value: "SELF_MANAGED_SUPER_FUND",
      label: "Self Managed Superannuation",
    },
    { value: "SOLE_TRADER", label: "Sole Trader" },
    { value: "TRUST", label: "Trust" },
    { value: "INDIVIDUAL", label: "Individual" },

    { value: "SUPERANNUATION_FUND", label: "Superannuation Fund" },
  ];

  return (
    <FormProvider {...form}>
      <Stack
        marginTop={"15px"}
        p="0px"
        // maxW="940px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        // border="1px solid #d6dade"
        // borderRadius="md"
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <HStack gap={0}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              as="h2"
              flex={1}
              _light={{
                color: "customColor.black7",
              }}
              size="md"
            >
              Basic Information
            </Heading>
            <Link
              color="primary.950"
              fontSize=" var(--x-font-size-small, .8125rem"
              paddingBottom="1px"
              href="/app/payroll/organisationSettings"
            >
              Organisation Settings <Icon as={ChevronRightIcon} />
            </Link>
          </HStack>

          <Divider orientation="horizontal" />

          {/* <form onSubmit={handleSubmit(onSubmit)}></form> */}
          <Container marginBottom={"25px"}>
            <Controller
              name="displayName"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Display name is required",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  label="Display name (required)"
                  labelProps={{
                    fontSize: ".845rem",
                    marginTop: "25px",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    padding: "12px 15px",
                    borderTopRadius: "3px",
                  }}
                  placeholder="HRIS 360"
                />
              )}
            />

            <Controller
              name="legalName"
              control={form.control}
              rules={{ required: "Legal Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <HStack align="flex-end">
                  <CustomInput
                    ref={field.ref}
                    label="Legal / Trading name (required)"
                    labelProps={{
                      fontSize: ".845rem",
                      marginTop: "25px",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,

                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      alignContent: "center",
                    }}
                    placeholder="HRIS 360"
                  />
                  {showCheckboxes && <IncludeCheckbox name="legalName" />}
                </HStack>
              )}
            />
            <Controller
              name="legalCode"
              control={form.control}
              rules={{ required: "Legal Code is required" }}
              render={({ field, fieldState: { error } }) => (
                <HStack align="flex-end">
                  <CustomInput
                    ref={field.ref}
                    label="Legal / Trading Code (required)"
                    labelProps={{
                      fontSize: ".845rem",
                      marginTop: "25px",
                      _light: {
                        color: "customColor.black7",
                      },
                    }}
                    validationMessage={error?.message}
                    withValidation
                    input={{
                      ...field,
                      isInvalid: error !== undefined,

                      fontSize: ".845rem",
                      variant: "outline",
                      type: "text",

                      borderTopRadius: "3px",
                      alignContent: "center",
                    }}
                    placeholder="P&G"
                  />
                  {showCheckboxes && <IncludeCheckbox name="legalCode" />}
                </HStack>
              )}
            />

            <FormControl paddingTop="20px">
              {/* <FormLabel
                alignItems="center"
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="600"
                fontSize=".845rem"
              >
                Logo <Icon as={InfoIcon} />
              </FormLabel>

              <Image
                boxSize="120px"
                src="https://edge.xero.com/cim/organisation-details/prod/28813d46bdf79772135e.jpg"
              />
              <Controller
                name="logo"
                control={form.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="file"
                    accept="image/*"
                    display="none"
                    id="logoInput"
                  />
                )}
              />

              <label htmlFor="logoInput">
                <Button
                  marginTop={"25px"}
                  padding={"7.52px 12px"}
                  borderRadius="3px"
                  as="span"
                  variant="outline"
                >
                  Upload Logo
                </Button>
              </label>

              <Text
                marginTop="1px"
                fontSize=".6875rem"
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
              >
                1MB total file size limit
              </Text> */}

              <FormLabel
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="600"
                fontSize=".845rem"
                paddingTop="25px"
              >
                Industry
              </FormLabel>
              <HStack align="center">
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Industry is required",
                    },
                  }}
                  control={form.control}
                  name="industry"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder=""
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Organization Type "
                      withValidation
                      options={industries}
                      value={industries.find(
                        (industries) => industries.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                {showCheckboxes && <IncludeCheckbox name="industry" />}
              </HStack>
            </FormControl>

            <FormLabel
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontWeight="600"
              fontSize=".845rem"
              paddingTop="25px"
            >
              Organisation type
            </FormLabel>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: "Organization Type is required",
                },
              }}
              control={form.control}
              name="organizationType"
              render={({ field, fieldState }) => (
                <ReactSelect
                  {...field}
                  placeholder=""
                  // marginBottom="30px"
                  // width={"48em"}
                  isInvalid={fieldState.invalid}
                  ErrorMessage="Select Organization Type "
                  withValidation
                  options={organizationData}
                  value={organizationData.find(
                    (organizationData) => organizationData.value === field.value
                  )}
                  onChange={(newValue: any) => field.onChange(newValue?.value)}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              )}
            />

            <Controller
              name="taxNumber"
              control={form.control}
              rules={{
                pattern: {
                  value: /[0-9]/,
                  message: "Tax Number contains invalid characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <HStack align="flex-end">
                  <>
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      label="Tax Number"
                      labelProps={{
                        fontSize: ".845rem",
                        marginTop: "25px",
                        _light: {
                          color: "customColor.black7",
                        },
                      }}
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        padding: "12px 15px",
                        borderTopRadius: "3px",
                      }}
                      placeholder=""
                    />
                  </>
                  {showCheckboxes && <IncludeCheckbox name="taxNumber" />}
                </HStack>
              )}
            />

            <Controller
              name="branch"
              control={form.control}
              rules={{
                maxLength: {
                  value: 3,
                  message: "Branch must be no longer than 3 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomInput
                  ref={field.ref}
                  validationMessage={error?.message}
                  withValidation
                  label="  Branch(if applicable)"
                  labelProps={{
                    fontSize: ".845rem",
                    marginTop: "25px",
                    _light: {
                      color: "customColor.black7",
                    },
                  }}
                  input={{
                    ...field,
                    isInvalid: error !== undefined,
                    w: "100%",
                    h: "38px",
                    fontSize: ".845rem",
                    variant: "outline",
                    type: "text",
                    padding: "12px 15px",
                    borderTopRadius: "3px",
                  }}
                  placeholder=""
                />
              )}
            />
            <Flex flexDirection="column" alignItems="start">
              <FormLabel
                _light={{
                  color: "customColor.black2",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="600"
                fontSize=".845rem"
                paddingTop="25px"
                marginBottom="5px" // Add margin bottom to create space between label and input
              >
                Organisation description
              </FormLabel>
              <Controller
                name="organizationDescription"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <HStack width={"100%"}>
                    <Textarea
                      {...field}
                      isInvalid={error !== undefined}
                      width={"100%"}
                      h="120px"
                      fontSize="13px"
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      variant="outline"
                      borderTopRadius="3px"
                      alignContent="start"
                    />
                    {showCheckboxes && (
                      <IncludeCheckbox name="organizationDescription" />
                    )}
                  </HStack>
                )}
              />
            </Flex>
          </Container>

          {/* </VStack> */}

          <Link
            marginBottom="50px"
            padding="30px"
            fontSize=".6875rem"
            alignSelf="center"
            color="#003c64"
            href="https://www.xero.com/legal/privacy/"
            isExternal
          >
            Privacy notice
            <ExternalLinkIcon mx="2px" />{" "}
          </Link>
          <Box
            className="fixed-box"
            bottom="0"
            left="0"
            right="0"
            maxWidth="100%"
            minHeight="60px"
            _light={{
              background: "#fff",
            }}
            _dark={{
              bg: "customColor.dark.50",
            }}
            boxShadow="rgba(0, 0, 0, 0.2)"
            padding="10px"
            textAlign="center"
          >
            <HStack justifyContent={"flex-end"}>
              <FormControl marginLeft="10px" display="none" alignItems="center">
                <Switch
                  size="lg"
                  colorScheme="teal"
                  id="showCheckboxes"
                  name="showCheckboxes"
                  isChecked={showCheckboxes}
                  onChange={() => setShowCheckboxes(!showCheckboxes)}
                />
                <FormLabel
                  marginLeft="5px"
                  paddingTop="10px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Select additional details to display on your{" "}
                  <Link color="primary.950">
                    online invoices
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </FormLabel>
              </FormControl>
              <ButtonGroup
                borderRadius="4px"
                fontSize="var(--x-font-size-small, .8125rem)"
              >
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    form.handleSubmit(
                      (data) => {
                        setIsLoading(true);
                        BusinessDetail.AddBusinessDetail(
                          data,
                          (success) => {
                            toast({
                              title: "Business Details Added",

                              status: "success",
                            });
                            setIsLoading(false);
                            setSelectedTab(selectedTab + 1);
                          },
                          (error) => {
                            toast({
                              title: "Business Detail Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      },
                      (error) => {
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  Save and Next
                </Button>
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={(e) => {
                    form.handleSubmit(
                      (data) => {
                        setIsLoading(true);
                        BusinessDetail.AddBusinessDetail(
                          data,
                          (success) => {
                            toast({
                              title: "Business Details Added",

                              status: "success",
                            });
                            setIsLoading(false);
                          },
                          (error) => {
                            toast({
                              title: "Business Detail Failed",
                              description: error,
                              status: "error",
                            });
                            setIsLoading(false);
                          }
                        );
                      },
                      (error) => {
                        setIsLoading(false);
                      }
                    )(e);
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Box>
      </Stack>
      {/* </Box> */}
    </FormProvider>
  );
}
