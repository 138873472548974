import {
  Avatar,
  Button,
  HStack,
  Icon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { useAuthHeader } from "react-auth-kit";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { EmployeeLeave } from "../../Api/EmployeeLeave";
import { EmployeeLeaveEntitlement } from "../../Api/EmployeeLeaveEntitlement";
import { Leaves } from "../../Api/Leaves";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";
import LeaveForEmployee from "../EmployeeDashboard/LeaveForEmployee";
import CashOutLeave from "../EmployeeDetails/Modal/NewRequest/CashOutLeave";
import Leave from "../EmployeeDetails/Modal/NewRequest/Leave";
import RejectLeave from "./RejectLeave";

interface LeaveTagProps {
  leave: Leaves.fetchBusinessLeaves;
  setlastUpdated: (lastUpdated: number) => void;
  FetchLeaves: Array<PayItemLeaveApi.LeaveById>;
  lastUpdated: number;
  FetchLeavesEligibility?: Array<EmployeeLeaveEntitlement.LeaveEntitlement>;
}
export default function LeaveTag({
  leave,
  setlastUpdated,
  FetchLeaves,
  lastUpdated,
  FetchLeavesEligibility,
}: LeaveTagProps) {
  const { allEmployeesList } = useBusinessContext();
  const rejectLeave = useDisclosure();
  const cashOutLeave = useDisclosure();
  const leaveModal = useDisclosure();
  const toast = useToast();
  const form = useForm<{
    status: string;
    note: string;
  }>();
  const LeaveForm = useForm<EmployeeLeave.Leave>();
  const auth = useAuthHeader();
  const { currentEmployeeId } = useBusinessContext();
  const decodedToken = jwtDecode<any>(auth());
  // useEffect(() => {
  //   if (decodedToken?.accessLevel === "EMPLOYEE") {
  //     EmployeeLeaveEntitlement.FetchLeaveEntitlements(
  //       currentEmployeeId!,
  //       (data) => {
  //         setFetchLeavesEligibility(data);
  //       },
  //       (error) => {
  //         toast({
  //           title: "Error",
  //           description: error,
  //           status: "error",
  //         });
  //       }
  //     );
  //   }
  // }, [decodedToken, currentEmployeeId]);

  return (
    <HStack
      border={"1px solid #ccced2"}
      padding={"12px 0px"}
      _hover={{
        bg: "#f2f3f4",
      }}
      h={"69px"}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
      borderRadius={"3px"}
      paddingRight={"20px"}
    >
      <HStack flex={1}>
        <Avatar
          name={
            allEmployeesList?.find((emp) => emp.id === leave.empId)?.firstName +
            " " +
            allEmployeesList?.find((emp) => emp.id === leave.empId)?.lastName
          }
          h={"32px"}
          w={"32px"}
          size={"sm"}
          fontWeight={"bold"}
          marginLeft={"8px"}
          bg={"#daa3e4"}
          _light={{
            color: "customColor.black7",
          }}
        />
        <Stack spacing="0px">
          <Text
            fontSize=".9975rem"
            fontWeight={"bold"}
            textAlign={"left"}
            _light={{
              color: "customColor.black7",
            }}
          >
            {allEmployeesList?.find((emp) => emp.id === leave.empId)
              ?.firstName +
              " " +
              allEmployeesList?.find((emp) => emp.id === leave.empId)?.lastName}
          </Text>
          <Stack direction={"row"} alignItems={"center"}>
            {leave.leaveCategory === "NORMAL_LEAVE" ? (
              <Text fontSize="13.44px">
                {moment(leave?.startDate).format("DD/MM/YYYY")}-{" "}
                {moment(leave?.endDate).format("DD/MM/YYYY")}
              </Text>
            ) : (
              <Text
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                {moment(leave?.payoutDate).format("DD/MM/YYYY")}
              </Text>
            )}

            <UnorderedList>
              <ListItem
                fontSize="13.44px"
                _light={{
                  color: "customColor.black7",
                }}
              >
                {leave?.description}
              </ListItem>
              {/* {leave?.note && (
                <ListItem    fontSize="13.44px"
                            _light={{
                              color: "customColor.black7",
                            }}>{leave?.note}</ListItem>
              )} */}
            </UnorderedList>

            <Text
              fontSize="13.44px"
              _light={{
                color: "customColor.black7",
              }}
            >{`(${leave.quantity} days)`}</Text>
          </Stack>
        </Stack>
        <HStack justifyContent={"flex-end"} flex={1}>
          <Text
            lineHeight={"1.3077"}
            fontSize=".9975rem"
            _light={{
              color: "customColor.black7",
            }}
            justifyContent={"flex-end"}
            fontWeight={"bold"}
            padding={"0px 12px 0px 12px"}
            display={"flex"}
            flex={1}
          >
            {
              FetchLeaves.find(
                (FetchLeaves) => FetchLeaves.id === leave.payrollPayItemLeaveId
              )?.name
            }
          </Text>
          {/* {leave.status === "PENDING" && ( */}
          {decodedToken.accessLevel !== "EMPLOYEE" && (
            <HStack>
              {(leave.status === "PENDING" || leave.status === "REJECTED") && (
                <Button
                  bgColor="#3454D1"
                  fontSize={"13px"}
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  borderColor={"#3454d1!important"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    Leaves.changeLeaveStatus(
                      leave.id,
                      {
                        status: "APPROVED",
                        note: "Leave Approved",
                      },
                      (success) => {
                        toast({
                          title: "Success",
                          description: success,
                          status: "success",
                        });
                        setlastUpdated(Date.now());
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          description: err,
                          status: "error",
                        });
                      }
                    );
                  }}
                >
                  Approve
                </Button>
              )}
              {(leave.status === "PENDING" || leave.status === "APPROVED") && (
                <Button
                  border={"1px solid #a6a9b0"}
                  colorScheme={"red"}
                  fontSize={"13px"}
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  onClick={() => {
                    rejectLeave.onOpen();
                  }}
                >
                  Reject
                </Button>
              )}
            </HStack>
          )}
        </HStack>
        {/* )} */}
      </HStack>
      <Menu>
        <MenuButton
          as={Button}
          padding={"6px 10px"}
          backgroundColor={"transparent"}
          h={"34.5px"}
          marginLeft={"8px"}
          color={"black"}
          display={{
            lg: "flex",
            md: "flex",
            sm: "flex",
            xs: "flex",
          }}
          _hover={{
            backgroundColor: "transparent",
          }}
          _active={{
            backgroundColor: "transparent",
          }}
          borderRadius={"full"}
          _focus={{
            backgroundColor: "transparent",
          }}
        >
          <Icon as={FiMoreVertical} />
        </MenuButton>
        <MenuList style={{ minWidth: "140px" }}>
          <MenuItem
            fontSize={"0.8125rem"}
            fontWeight={"600"}
            _light={{
              color: "customColor.black7",
            }}
            onClick={() => {
              LeaveForm.reset({
                empId: leave.empId,
                id: leave.id,
                description: leave.description,
                leaveCategory: leave.leaveCategory,
                endDate: leave.endDate,
                quantity: leave.quantity,
                note: leave.note,
                payoutDate: leave.payoutDate,
                payrollPayItemLeaveId: leave.payrollPayItemLeaveId,
                startDate: leave.startDate,
                status: leave.status,
                leaveEntitlementId: leave.leaveEntitlementId,
                payPeriods: leave.payPeriods,
              });

              leave.leaveCategory === "NORMAL_LEAVE"
                ? leaveModal.onOpen()
                : cashOutLeave.onOpen();
            }}
          >
            Edit
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              rejectLeave.onOpen();
            }}
          >
            Reject
          </MenuItem> */}
        </MenuList>
      </Menu>
      <FormProvider {...form}>
        <RejectLeave
          isOpen={rejectLeave.isOpen}
          onClose={rejectLeave.onClose}
          leaveId={leave.id}
          setlastUpdated={setlastUpdated}
        />
      </FormProvider>
      <FormProvider {...LeaveForm}>
        <CashOutLeave
          isOpen={cashOutLeave.isOpen}
          onClose={cashOutLeave.onClose}
          setLastUpdated={setlastUpdated}
          employeeId={leave.empId}
          FecthedLeaves={FetchLeaves}
        />
        {decodedToken.accessLevel !== "EMPLOYEE" && (
          <Leave
            isOpen={leaveModal.isOpen}
            onClose={leaveModal.onClose}
            setLastUpdated={setlastUpdated}
            employeeId={leave.empId}
            FecthedLeaves={FetchLeaves}
            lastUpdated={lastUpdated}
          />
        )}
        {decodedToken.accessLevel === "EMPLOYEE" && (
          <LeaveForEmployee
            isOpen={leaveModal.isOpen}
            onClose={leaveModal.onClose}
            setLastUpdated={setlastUpdated}
            employeeId={leave.empId}
            FecthedLeaves={FetchLeaves}
            lastUpdated={lastUpdated}
            FetchLeavesEligibility={FetchLeavesEligibility}
          />
        )}
      </FormProvider>
    </HStack>
  );
}
