import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { IoMdSunny } from "react-icons/io";
import { LuArrowUpDown } from "react-icons/lu";
import { MdNightsStay } from "react-icons/md";
import { TbChecklist } from "react-icons/tb";
import CalendarHeader from "./Common/CalendarHeader";
import NavigationButtons from "./Common/NavigationButtons";
import AddShiftModal from "./Modals/AddShiftModal";

// import List from './List/List';
import { ChevronDownIcon } from "@chakra-ui/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineCaretDown } from "react-icons/ai";
import { FaFilter, FaPlus } from "react-icons/fa6";
import { FiMoreVertical } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { Employees } from "../../../Payroll/Api/Employees";
import { Shifts } from "../../API/Shifts";
import Fortnight from "./EmployeeWise/Fortnight";
import Weekly from "./EmployeeWise/Weekly";
import { ScheduleContextProvider } from "./Hooks/ScheduleContext";
import CopyShiftModal from "./Modals/CopyShiftsModal";
import CreateBulkShiftModal from "./Modals/CreateBulkShifts";
import DeleteAllShiftsModal from "./Modals/DeleteAllShiftsModal";
import ShiftDetailModal from "./Modals/ShiftDetailModal";
import UpdateBulkShiftsModal from "./Modals/UpdateBulkShiftsModal";
import Month from "./Month/Month";
import Tabular from "./Tabular/Tabular";

enum TabsValues {
  MONTH = 0,
  EmployeeWeekly = 1,
  EmployeeFortnightly = 2,
  Daily = 1,
}
export default function Schedule() {
  const month = useBreakpointValue({
    md: "Month",
    xs: "M",
  });
  const employeeweekly = useBreakpointValue({
    md: "Employee/Week",
    xs: "E/W",
  });
  const employeefortnightly = useBreakpointValue({
    md: "Employee/Fortnight",
    xs: "E/F",
  });
  const daily = useBreakpointValue({
    md: "Daily",
    xs: "D",
  });
  const tabular = useBreakpointValue({
    md: "Tabular",
    xs: "T",
  });

  const tabs = [
    {
      label: month,
      index: 0,
    },
    {
      label: employeeweekly,
      index: 1,
    },
    {
      label: employeefortnightly,
      index: 2,
    },
    // {
    //   label: daily,
    //   index: 3,
    // },
    {
      label: tabular,
      index: 3,
    },
  ];
  const [selectedOptionTime, setSelectedOptionTime] = useState("AM");

  const handleOptionSelectTime = (option: any) => {
    setSelectedOptionTime(option);
  };
  const [selectedOptionOrder, setSelectedOptionOrder] = useState("A-Z");

  const handleOptionSelectOrder = (option: any) => {
    setSelectedOptionOrder(option);
  };
  const [selectedOptionType, setSelectedOptionType] = useState("All Type");

  const handleOptionSelectType = (option: any) => {
    setSelectedOptionType(option);
  };

  const [selectedLayout, setSelectedLayout] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [isLargerThanMD] = useMediaQuery("(min-width: 1000px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedTimestamp, setSelectedTimestamp] = useState<number>(
    moment()
      .set({
        hour: 9,
      })
      .unix()
  );

  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());

  function navigateDates(quantity: number) {
    if (selectedLayout === TabsValues.MONTH) {
      setCurrentDate(currentDate.clone().add(quantity, "month"));
    } else if (
      selectedLayout === TabsValues.EmployeeWeekly ||
      selectedLayout === TabsValues.EmployeeFortnightly
    ) {
      setCurrentDate(currentDate.clone().add(quantity, "week"));
    } else if (selectedLayout === TabsValues.Daily) {
      setCurrentDate(currentDate.clone().add(quantity, "day"));
    }
  }

  const { shiftId } = useParams();

  const [selectedShiftId, setSelectedShiftId] = useState<string | undefined>(
    shiftId
  );
  const [viewedShiftIds, setViewedShiftsIds] = useState<string[]>([]);
  const CopyModal = useDisclosure();
  const CreateBulkModal = useDisclosure();
  const DeleteModal = useDisclosure();
  const UpdateModal = useDisclosure();
  const menuList: Array<{
    title: string;
    onclick: () => void;
  }> = [
    {
      title: "Copy Schedule",
      onclick: () => {
        CopyModal.onOpen();
      },
    },
    {
      title: "Delete All Shifts",
      onclick: () => {
        DeleteModal.onOpen();
      },
    },
    {
      title: "Create Bulk Shifts",
      onclick: () => {
        CreateBulkModal.onOpen();
      },
    },
  ];
  const SELECTED_SHIFT_INIT: Shifts.Shift = {
    areas: [],
    break: 0,
    employeeId: "",
    locationId: "",
    notes: "",
    qrRequired: true,
    shiftLastNextDay: false,
    shiftEndDate: "",
    shiftEndTime: "",
    shiftStartDate: "",
    shiftStartTime: "",
    geoFencingEnabled: false,
  };
  const selectedShift = useForm<Shifts.Shift>({
    defaultValues: SELECTED_SHIFT_INIT,
  });
  const viewModal = useDisclosure({});
  const addModal = useDisclosure({
    onOpen: () => {
      viewModal.onClose();
    },
    onClose: () => {
      selectedShift.reset(SELECTED_SHIFT_INIT);

      setSelectedShiftId(undefined);
    },
  });
  const openAddModal = (timestamp: number) => {
    setSelectedTimestamp(timestamp);
    selectedShift.reset({
      shiftStartDate: moment.unix(timestamp).format("YYYY-MM-DD"),
      qrRequired: true,
    });
    setSelectedShiftId(undefined);
    addModal.onOpen();
  };
  const [allEmployeesList, setAllEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState<number>(25);
  const [pageCount, setPageCount] = useState<number>(0);
  const [employeesList, setEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const toast = useToast();
  useEffect(() => {
    Employees.FetchEmployees(
      {
        empStatus: "CURRENT",
        page: pageNumber,
        quantity: limit,
        search: searchText,
      },
      (data) => {
        setEmployeesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [pageNumber, searchText]);
  useEffect(() => {
    Employees.FetchEmployees(
      {
        empStatus: "ALL",
      },
      (data) => {
        setAllEmployeesList(data);
        setPageCount(Math.ceil(data.length / limit)); // Update pageCount
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [limit]);
  // const pageCount = Math.ceil(employeesList.length ?? 0 / limit);

  const typeOption = [
    { name: "All Type" },
    { name: "Personal Care" },
    { name: "Client Expense" },
    { name: "Domestic Assistance" },
    { name: "Night Shift" },
    { name: "Respite Care" },
    { name: "Sleepover" },
    { name: "Support coordination" },
    { name: "Transport" },
  ];
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <ScheduleContextProvider>
        <Box>
          <FormProvider {...selectedShift}>
            <AddShiftModal
              key={selectedTimestamp}
              isOpen={addModal.isOpen}
              onClose={() => {
                addModal.onClose();
              }}
              timestamp={selectedTimestamp}
              id={selectedShiftId}
            />
            <ShiftDetailModal
              isOpen={viewModal.isOpen}
              onClose={() => {
                viewModal.onClose();
              }}
              id={selectedShiftId}
              OpenUpdateModal={addModal.onOpen}
            />
            <CreateBulkShiftModal
              isOpen={CreateBulkModal.isOpen}
              onClose={CreateBulkModal.onClose}
            />
            <CopyShiftModal
              isOpen={CopyModal.isOpen}
              onClose={CopyModal.onClose}
              startDate={currentDate.clone().startOf("week")}
              selectedTab={selectedLayout}
            />
            <DeleteAllShiftsModal
              isOpen={DeleteModal.isOpen}
              onClose={DeleteModal.onClose}
              viewedshiftids={viewedShiftIds}
              setViewedShiftIds={setViewedShiftsIds}
            />
            <UpdateBulkShiftsModal
              isOpen={UpdateModal.isOpen}
              onClose={UpdateModal.onClose}
            />
          </FormProvider>

          <Stack
            height="90vh"
            overflowX={"auto"}
            border="1px"
            borderColor="borders.tableBorder"
            boxShadow="0 4px 12px 0 rgb(131 146 165 / 15%), 2px 2px 5px 0 rgb(60 70 83 / 4%)"
          >
            {/* {isLargerThanMD && ( // Conditionally render the CalendarSideBar based on screen size
                <Stack shadow="md" w={isSidebarOpen ? "20%" : "0%"}>
                  <CalendarSideBar
                    setIsSidebarOpen={setIsSidebarOpen}
                    isSidebarOpen={isSidebarOpen}
                    selectedTimestamp={openAddModal}
                  />
                </Stack>
              )} */}

            {/* <Stack width={isSidebarOpen ? "80%" : "100%"}> */}
            <Stack>
              <Tabs
                variant="unstyled"
                isLazy
                defaultIndex={0}
                index={selectedLayout}
                onChange={setSelectedLayout}
              >
                <HStack
                  px="15px"
                  position="sticky"
                  top="0px"
                  borderBottom="1px"
                  borderColor="borders.tableBorder"
                  h="55px"
                  backgroundColor="white"
                  zIndex={9}
                  justifyContent="space-between"
                >
                  <HStack spacing="10px">
                    {/* <IconButton
                        variant="outline"
                        aria-label="forward"
                        icon={<TbArrowLeft width="6px" height="13px" />}
                        w="34px"
                        color="#545f70"
                        borderColor="borders.tableBorder"
                        _hover={{
                          borderColor: "primary.500",
                          backgroundColor: "transparent",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          setIsSidebarOpen(!isSidebarOpen);
                        }}
                      /> */}

                    <NavigationButtons
                      next={() => {
                        if (selectedLayout === TabsValues.EmployeeFortnightly) {
                          navigateDates(2);
                        } else {
                          navigateDates(1);
                        }
                      }}
                      previous={() => {
                        if (selectedLayout === TabsValues.EmployeeFortnightly) {
                          navigateDates(-2);
                        } else {
                          navigateDates(-1);
                        }
                      }}
                    />

                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="outline"
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={400}
                        backgroundColor="backgrounds.header"
                        fontSize={"13px"}
                        border="1px"
                        borderColor="borders.tableBorder"
                        _hover={{
                          borderColor: "borders.hover",
                        }}
                        _active={{
                          borderColor: "borders.focus",
                        }}
                        rightIcon={<ChevronDownIcon />}
                      >
                        Advance
                      </MenuButton>
                      <MenuList>
                        {menuList.map((list, i) => {
                          return (
                            <MenuItem
                              key={i}
                              fontSize="14px"
                              fontWeight={400}
                              color="textcolor.primary"
                              onClick={list.onclick}
                            >
                              {list.title}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="outline"
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={400}
                        backgroundColor="backgrounds.header"
                        fontSize={"13px"}
                        border="1px"
                        borderColor="borders.tableBorder"
                        _hover={{
                          borderColor: "borders.hover",
                        }}
                        _active={{
                          borderColor: "borders.focus",
                        }}
                        px="15px"
                        rightIcon={<ChevronDownIcon />}
                        // eslint-disable-next-line react/jsx-no-undef
                        leftIcon={<FaFilter />}
                      >
                        Filter
                      </MenuButton>
                      <MenuList
                        padding={"30px 20px"}
                        margin={"12px 0px 0px"}
                        minW={"300px"}
                      >
                        <Stack paddingTop={"20px"}>
                          <Menu>
                            <MenuButton
                              as={Button}
                              h={"40px"}
                              variant="outline"
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={400}
                              backgroundColor="backgrounds.header"
                              fontSize={"13px"}
                              border="1px"
                              borderColor="borders.tableBorder"
                              _hover={{
                                borderColor: "borders.hover",
                              }}
                              _active={{
                                borderColor: "borders.focus",
                              }}
                              px="15px"
                              w={"100%"}
                              textAlign={"left"}
                              rightIcon={<ChevronDownIcon />}
                              // eslint-disable-next-line react/jsx-no-undef
                              leftIcon={<TbChecklist />}
                            >
                              {selectedOptionType}
                            </MenuButton>
                            <MenuList>
                              {typeOption.map((item, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    fontSize={"13.44px"}
                                    padding={"9px 15px"}
                                    _dark={{
                                      color: "customColor.dark.150",
                                    }}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    onClick={() =>
                                      handleOptionSelectType(item.name)
                                    }
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Stack>
                        <Stack paddingTop={"20px"}>
                          <Menu>
                            <MenuButton
                              as={Button}
                              h={"40px"}
                              variant="outline"
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={400}
                              backgroundColor="backgrounds.header"
                              fontSize={"13px"}
                              border="1px"
                              borderColor="borders.tableBorder"
                              _hover={{
                                borderColor: "borders.hover",
                              }}
                              _active={{
                                borderColor: "borders.focus",
                              }}
                              px="15px"
                              w={"100%"}
                              textAlign={"left"}
                              rightIcon={<ChevronDownIcon />}
                              // eslint-disable-next-line react/jsx-no-undef
                              leftIcon={<LuArrowUpDown />}
                            >
                              {selectedOptionOrder}
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                fontSize={"13.44px"}
                                padding={"9px 15px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                onClick={() => handleOptionSelectOrder("A-Z")}
                              >
                                A-Z
                              </MenuItem>
                              <MenuItem
                                fontSize={"13.44px"}
                                padding={"9px 15px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                onClick={() =>
                                  handleOptionSelectOrder("Shift Count")
                                }
                              >
                                Shift Count
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Stack>
                        <Stack paddingTop={"20px"}>
                          <Menu>
                            <MenuButton
                              as={Button}
                              h={"40px"}
                              variant="outline"
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight={400}
                              backgroundColor="backgrounds.header"
                              fontSize={"13px"}
                              border="1px"
                              borderColor="borders.tableBorder"
                              _hover={{
                                borderColor: "borders.hover",
                              }}
                              _active={{
                                borderColor: "borders.focus",
                              }}
                              px="15px"
                              w={"100%"}
                              textAlign={"left"}
                              rightIcon={<ChevronDownIcon />}
                              // eslint-disable-next-line react/jsx-no-undef
                              leftIcon={
                                selectedOptionTime === "AM" ? (
                                  <MdNightsStay />
                                ) : (
                                  <IoMdSunny />
                                )
                              }
                            >
                              {selectedOptionTime}
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                fontSize={"13.44px"}
                                padding={"9px 15px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                onClick={() => handleOptionSelectTime("AM")}
                              >
                                AM
                              </MenuItem>
                              <MenuItem
                                fontSize={"13.44px"}
                                padding={"9px 15px"}
                                _dark={{
                                  color: "customColor.dark.150",
                                }}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                onClick={() => handleOptionSelectTime("PM")}
                              >
                                PM
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Stack>
                      </MenuList>
                    </Menu>
                  </HStack>
                  <CalendarHeader>
                    {currentDate.format("MMMM YYYY")}
                  </CalendarHeader>

                  {/* {isLargerThanMD ? ( */}
                  {/* <Stack
                      spacing={0}
                      borderWidth="1px"
                      direction={"row"}
                      borderColor="gray.200"
                    >
                      {tabs.map((tab, i) => {
                        return (
                          <Tab
                            as={Button}
                            key={i}
                            variant="outline"
                            px="15px"
                            py="0px"
                            h="32px"
                            border="1px"
                            color="icons.primary"
                            borderColor="borders.tableBorder"
                            _hover={{
                              backgroundColor: "secondary.50",
                              color: "primary.800",
                            }}
                            _selected={{
                              zIndex: 1,
                              color: "primary.950",
                              bg: "primary.50",
                              borderColor: "primary.950",
                            }}
                            fontSize="13px"
                            fontWeight={{ xs: 500, md: 400 }}
                            onClick={() => setSelectedLayout(tab.index)}
                          >
                            {tab.label}
                          </Tab>
                        );
                      })}
                    </Stack> */}
                  {/* ) : ( */}
                  <Stack
                    marginTop={"5px"}
                    display="flex"
                    justifyContent="flex-end"
                    direction={"row"}
                  >
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<AiOutlineCaretDown />}
                        // h="40px"
                        // border="1px solid #ddd"
                        // borderRadius="3px 3px 0 0"
                        // marginBottom="15px"
                        // fontSize={"14px"}
                        // lineHeight={"1.2"}
                        // bg="#F8F8F8"
                        // color="#0078c8"
                        // cursor={"pointer"}
                        // _active={{
                        //   bg: "#FBFBFB",
                        //   color: "#333",
                        //   fontWeight: "bold",
                        //   border: "0",
                        // }}
                        // _hover={{
                        //   bg: "#f2f3f4",
                        //   color: "#000a1e",
                        // }}
                        h={"40px"}
                        variant="outline"
                        _light={{
                          color: "customColor.black7",
                        }}
                        fontWeight={400}
                        backgroundColor="backgrounds.header"
                        fontSize={"13px"}
                        border="1px"
                        borderColor="borders.tableBorder"
                        _hover={{
                          borderColor: "borders.hover",
                        }}
                        _active={{
                          borderColor: "borders.focus",
                        }}
                        px="15px"
                      >
                        {tabs.find((tab) => tab.index === selectedLayout)
                          ?.label ?? ""}
                      </MenuButton>
                      <MenuList zIndex={"overlay"}>
                        {tabs.map((tab, i) => (
                          <MenuItem
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontSize="14px"
                            key={i}
                            onClick={() => {
                              setSelectedLayout(i);
                            }}
                          >
                            {tab.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Button
                      fontSize={"13px"}
                      bgColor="#3454D1"
                      h={"40px"}
                      borderRadius={"3px"}
                      borderWidth={"1px"}
                      borderColor={"#3454d1!important"}
                      variant={"solid"}
                      padding={"12px 16px"}
                      fontWeight={700}
                      _hover={{
                        bgColor: "#3454D1",
                      }}
                      onClick={() => {
                        addModal.onOpen();
                      }}
                      leftIcon={<FaPlus />}
                    >
                      Shift
                    </Button>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        variant={"outline"}
                        backgroundColor="backgrounds.header"
                        fontSize={"13px"}
                        border="1px"
                        borderColor="borders.tableBorder"
                        _hover={{
                          borderColor: "borders.hover",
                        }}
                        _active={{
                          borderColor: "borders.focus",
                        }}
                        h={"40px"}
                        w={"40px"}
                        color={"black"}
                        display={{
                          lg: "flex",
                          md: "flex",
                          sm: "flex",
                          xs: "flex",
                        }}
                        icon={<FiMoreVertical fontSize={"20px"} />}
                      />
                      <MenuList style={{ minWidth: "140px" }}>
                        <MenuItem
                          _hover={{
                            backgroundColor: "rgba(0,10,30,.1)",
                          }}
                          minH={"36px"}
                          padding={"0px 20px"}
                          fontSize={"14px"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Export
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Stack>

                  {/* )} */}
                </HStack>

                <TabPanels tabIndex={selectedLayout}>
                  <TabPanel p={0}>
                    <Month
                      currentMonth={currentDate.clone()}
                      selectedTimestamp={openAddModal}
                      selectedShiftId={(shiftId) => {
                        setSelectedShiftId(shiftId);
                        viewModal.onOpen();
                      }}
                      setViewShiftsIds={(ids: string[]) => {
                        setViewedShiftsIds(ids);
                      }}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <Weekly
                      employeesList={employeesList}
                      startDate={currentDate.clone().startOf("isoWeek")}
                      selectedTimestamp={openAddModal}
                      selectedShiftId={(shiftId) => {
                        setSelectedShiftId(shiftId);
                        viewModal.onOpen();
                      }}
                      setViewShiftsIds={(ids: string[]) => {
                        setViewedShiftsIds(ids);
                      }}
                      pageCount={pageCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      setSearchText={setSearchText}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <Fortnight
                      employeesList={employeesList}
                      startDate={currentDate.clone().startOf("isoWeek")}
                      selectedTimestamp={openAddModal}
                      selectedShiftId={(shiftId) => {
                        setSelectedShiftId(shiftId);
                        viewModal.onOpen();
                      }}
                      setViewShiftsIds={(ids: string[]) => {
                        setViewedShiftsIds(ids);
                      }}
                      pageCount={pageCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      setSearchText={setSearchText}
                    />
                  </TabPanel>
                  {/* <TabPanel p={0}>
                    <Daily
                      employeesList={employeesList}
                      startDate={currentDate.clone().startOf("isoWeek")}
                      selectedTimestamp={openAddModal}
                      selectedShiftId={(shiftId) => {
                        setSelectedShiftId(shiftId);
                        viewModal.onOpen();
                      }}
                      setViewShiftsIds={(ids: string[]) => {
                        setViewedShiftsIds(ids);
                      }}
                      pageCount={pageCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      setSearchText={setSearchText}
                    />
                  </TabPanel> */}
                  <TabPanel p={0}>
                    <Tabular />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Stack>
        </Box>
      </ScheduleContextProvider>
    </Stack>
  );
}
