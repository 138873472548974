import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { EmployeeReferences } from "../../../../../Api/EmployeeReferences";

interface ReferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  empId?: string;
  setLastUpdated: (time: number) => void;
  referenceType?: Array<{ label: string; value: string }>;
}
export default function ReferencesModal({
  isOpen,
  onClose,
  setLastUpdated,
  empId,
  referenceType,
}: ReferencesModalProps) {
  const form = useFormContext<EmployeeReferences.AddReferences>();
  const toast = useToast();
  const watchRadioValue = useWatch({
    control: form.control,
    name: "referenceType",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Reference
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody borderTop={"1px solid #ddd"} borderBottom={"1px solid #ddd"}>
          <Box>
            <Flex justifyContent="space-between" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Reference Type
              </Text>
              <Box w="380px">
                {/* <Controller
                  control={form.control}
                  name="referenceType"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      key={field.value}
                      placeholder="Select Qualification"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Qualification"
                      withValidation
                      options={referenceType}
                      value={referenceType?.find(
                        (ref) => ref.value === field.value
                      )}
                      onChange={(newValue: any) => {
                        field.onChange(newValue?.value);
                      }}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                /> */}
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Employee Type is required",
                    },
                  }}
                  name="referenceType"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <RadioGroup {...field}>
                      <HStack>
                        <Radio
                          isInvalid={fieldState.invalid}
                          size="lg"
                          value="PERSONAL"
                          colorScheme="blue"
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {" "}
                            Personal
                          </Text>
                        </Radio>

                        <Radio
                          isInvalid={fieldState.invalid}
                          size="lg"
                          defaultChecked
                          value="PROFESSIONAL"
                          colorScheme="blue"
                          lineHeight={"1.5rem"}
                        >
                          <Text
                            fontSize={"0.8125rem"}
                            fontWeight={"600"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Professional
                          </Text>
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Name
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                    pattern: {
                      value: /^[A-Za-z ]+$/,
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "text",

                        borderRadius: "3px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            {watchRadioValue === "PROFESSIONAL" && (
              <Flex
                justifyContent="space-between"
                mt="16px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Position
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="position"
                    rules={{
                      required: {
                        value: true,
                        message: "Position is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}
            {watchRadioValue === "PERSONAL" && (
              <Flex
                justifyContent="space-between"
                mt="16px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Relation
                </Text>
                <Box w="380px">
                  <Controller
                    control={form.control}
                    name="position"
                    rules={{
                      required: {
                        value: true,
                        message: "Relation is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",
                          border: "1px solid",
                          borderColor: "gray.200",
                          borderRadius: "4px",
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
            )}

            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Email
              </Text>
              <Box w="380px">
                <Controller
                  control={form.control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",

                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        backgroundColor: "#fff",
                        variant: "outline",
                        // color: "#ccc",

                        lineHeight: "1.2",
                        h: "36px",

                        type: "email",

                        borderRadius: "4px",
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt="8px" alignItems={"center"}>
              <Text
                w="115px"
                fontSize={"0.8125rem"}
                fontWeight={"600"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Contact Number
              </Text>
              <Box w="380px">
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Contact number is required",
                    },
                    minLength: {
                      value: 11,
                      message: "Contact Number must be 11 characters",
                    },
                    maxLength: {
                      value: 14,
                      message: "Contact Number must be 14 characters",
                    },
                  }}
                  control={form.control}
                  name="contact"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        padding: "12px 15px",
                        _light: {
                          color: "customColor.black7",
                        },
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "number",

                        borderTopRadius: "3px",
                        minLength: 11,
                        maxLength: 14,
                      }}
                      placeholder=""
                    />
                  )}
                />
              </Box>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor={"transparent"}
            borderRadius={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            textColor={"#ea4d4d"}
            padding={"12px 16px"}
            marginRight={"5px"}
            fontWeight={600}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            isLoading={isLoading}
            onClick={(e) => {
              form.handleSubmit((data) => {
                setIsLoading(true);
                if (form.getValues("id")) {
                  EmployeeReferences.UpdateReferences(
                    form.getValues("id")!,
                    data,
                    () => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Reference Updated",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (error) => {
                      toast({
                        title: "Error Updating Reference",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                } else {
                  EmployeeReferences.PostReferences(
                    empId!,
                    data,
                    (data) => {
                      setLastUpdated(Date.now());
                      onClose();
                      toast({
                        title: "Reference Added",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    },
                    (err) => {
                      toast({
                        title: "Error Adding Reference",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      setIsLoading(false);
                    }
                  );
                }
              })(e);
            }}
          >
            {isLoading ? "Saving" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
