import {
  Box,
  Button,
  Checkbox,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { ChartOfAccounts } from "../../../../../Api/ChartOfAccounts";
import { PayItemDeductionApi } from "../../../../../Api/PayItemDeduction";

interface WorkplaceGivingModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedDeductionId: string | undefined;
  allAccounts: Array<ChartOfAccounts.FetchChartOfAccounts>;
}
export default function WorkplaceGivingModal({
  isOpen,
  onClose,
  selectedDeductionId,
  setLastUpdated,
  allAccounts,
}: WorkplaceGivingModalProps) {
  const toast = useToast();
  const form = useFormContext<PayItemDeductionApi.AddNewDeduction>();
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <FormProvider {...form}>
      <Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            maxW="360px"
            maxH={"650px"}
          >
            <ModalHeader
              fontSize={"15.21px"}
              margin={"15.21px 0pxs"}
            ></ModalHeader>
            <ModalCloseButton />
            <Box overflowY="auto">
              <ModalBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={
                    "https://edge.xero.com/people/payroll/assets/images/settings/pay-items/deductions.svg"
                  }
                />
                <Heading
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"15px 0px"}
                  textAlign={"center"}
                >
                  Workplace Givings
                </Heading>

                <Text
                  textAlign={"center"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize={"0.8125rem"}
                  lineHeight={"1.5"}
                >
                  Workplace giving is a simple way to regularly donate to
                  charities or organisations that are entitled to receive tax
                  deductible donations.
                  <Link
                    _light={{
                      color: "primary.950",
                    }}
                  >
                    {" "}
                    Learn more about workplace giving{" "}
                  </Link>
                </Text>
                {/* <Link textAlign={'center'}>Learn more about deductions</Link> */}
              </ModalBody>

              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  mb={"5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Deduction Name
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Deduction Name is required",
                    },
                  }}
                  control={form.control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: ".845rem",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"600"}
                >
                  Account
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Account type is required",
                    },
                  }}
                  control={form.control}
                  name="chartAccountId"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select"
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select Account type"
                      withValidation
                      options={allAccounts}
                      value={{
                        id: field.value,
                        code:
                          allAccounts.find(
                            (account) => account.id === field.value
                          )?.code ?? "",
                        name:
                          allAccounts.find(
                            (account) => account.id === field.value
                          )?.name ?? "",
                      }}
                      onChange={(newValue: any) => field.onChange(newValue?.id)}
                      getOptionLabel={(option: any) =>
                        `${option.code}:${option.name}`
                      }
                      getOptionValue={(option: any) => option.id}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody marginTop={"10px"}>
                <Stack>
                  <Stack>
                    <Controller
                      control={form.control}
                      name="isTaxable"
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          {...field}
                          isChecked={value}
                          isInvalid={error !== undefined}
                          colorScheme="rgb(50,70,90,.35)"
                          margin={"1px 7px 0px 0px"}
                          iconColor={"#000a1e"}
                          // isChecked={value}
                        >
                          <Text
                            fontSize={"13px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Apply Tax
                          </Text>
                        </Checkbox>
                      )}
                    />
                    <Controller
                      control={form.control}
                      name="showOnPayslip"
                      render={({
                        field: { value, ...field },
                        fieldState: { error },
                      }) => (
                        <Checkbox
                          {...field}
                          isChecked={value}
                          isInvalid={error !== undefined}
                          colorScheme="rgb(50,70,90,.35)"
                          margin={"1px 7px 0px 0px"}
                          iconColor={"#000a1e"}
                          // isChecked={value}
                        >
                          <Text
                            fontSize={"13px"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            Show on Payslip
                          </Text>
                        </Checkbox>
                      )}
                    />
                  </Stack>
                </Stack>
              </ModalBody>
            </Box>

            <ModalFooter>
              <Button
                onClick={onClose}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                marginLeft={"8px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedDeductionId) {
                      PayItemDeductionApi.UpdateDeduction(
                        selectedDeductionId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Deduction is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Deduction Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayItemDeductionApi.NewDeductionAdded(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Deduction is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Deduction Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedDeductionId ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </FormProvider>
  );
}
