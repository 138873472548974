import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactSelectForPayRollSetting } from "../../../../Common/ReactSelectForPayRollSetting";

import { PayItemDeductionApi } from "../../../Api/PayItemDeduction";
import { PayTemp } from "../../../Api/PayTemp";

interface DeductionLineModalProps {
  isOpen: boolean;
  onClose: () => void;
  resetData: {
    payItemDeductionId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  };
  onPressOK: (data: {
    payItemDeductionId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    category: PayTemp.PayTemplateItemTypeEnum;
    minimumWage?: number;
    percentage?: number;
    index?: number;
  }) => void;
  account: PayItemDeductionApi.DeductionById[];
}
export function DeductionLineModal({
  isOpen,
  onClose,
  onPressOK,
  account,
  resetData,
}: DeductionLineModalProps) {
  const [payItemId, setPayItemId] = useState("");
  const [index, setIndex] = useState<number | undefined>(resetData?.index);
  const [minimumWage, setMinimumWage] = useState<number | undefined>(undefined);
  const [percentage, setPercentage] = useState<number | undefined>(undefined);
  const handlePayItemChange = (newValue: any) => {
    const calculationType =
      newValue?.category === PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI
        ? PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI
        : newValue?.category ===
          PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_PROVIDENT_FUND
        ? PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_PROVIDENT_FUND
        : "";
    setPayItemId(newValue?.id);
    setCalculationType(calculationType);
    setMinimumWage(newValue?.minimumWage);
    setPercentage(newValue?.percentage);
  };

  const [calculationType, setCalculationType] = useState<
    PayTemp.PayTemplateCalcTypeEnum | string
  >("");

  const handleCalculationTypeChange = (value: any) => {
    setCalculationType(value);
  };
  useEffect(() => {
    setPayItemId(resetData.payItemDeductionId);
    setCalculationType(resetData.calculationType);
    setIndex(resetData.index);
  }, [resetData]);
  return (
    <Modal size="sm" isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"420px"}>
        <ModalHeader
          _light={{
            color: "customColor.black7",
          }}
          fontSize=".9975rem"
        >
          Add an Deductions Line
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          border="1px solid #ddd"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "gray.50",
          }}
        >
          <Stack maxW={"300px"} gap={0}>
            <Text
              fontSize={"12px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.dark.250",
              }}
              fontWeight={"bold"}
            >
              Deduction Rate <Icon color="teal.500" as={QuestionIcon} />
            </Text>

            <ReactSelectForPayRollSetting
              placeholder="Select"
              ErrorMessage="Select Account type"
              withValidation
              options={account}
              value={{
                id: payItemId,
                name:
                  account.find((account) => account.id === payItemId)?.name ??
                  "",
              }}
              onChange={handlePayItemChange}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </Stack>
          <RadioGroup
            value={calculationType}
            onChange={handleCalculationTypeChange}
            isDisabled={
              calculationType ===
                PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_EOBI ||
              calculationType ===
                PayTemp.PayTemplateCalcTypeEnum.EMPLOYEE_PROVIDENT_FUND
                ? true
                : false
            }
          >
            <HStack spacing={0}>
              <Radio size="md" value="FIXED_AMOUNT" colorScheme="blue">
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Fixed Amount
                </Text>
              </Radio>
              <Radio size="md" value="PRE-TAX" colorScheme="blue">
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Pre-Tax %
                </Text>
              </Radio>
              <Radio size="md" value="POST-TAX" colorScheme="blue">
                <Text
                  fontSize={"12px"}
                  padding={"1px 0px 0px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                  paddingRight={"5px"}
                >
                  Post-Tax %
                </Text>
              </Radio>
            </HStack>
          </RadioGroup>
          <Stack gap={0} maxW={"300px"}>
            <FormControl>
              <FormLabel
                mb={0}
                fontWeight="bold"
                marginTop="20px"
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.dark.250",
                }}
              >
                Control Account
              </FormLabel>

              <Text
                textAlign={"left"}
                fontSize="12px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                marginTop={"5px"}
                marginBottom={"5px"}
                borderTop={"1px solid #999"}
                borderBottom={"1px solid #ccc"}
              >
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.code
                }
                {":"}
                {
                  account.find((account) => account.id === payItemId)
                    ?.chartOfAccount?.name
                }
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter h="50px">
          <Button
            fontSize={"13px"}
            bgColor="#3454D1"
            h={"40px"}
            borderRadius={"3px"}
            borderWidth={"1px"}
            borderColor={"#3454d1!important"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              bgColor: "#3454D1",
            }}
            mr={2}
            type="submit"
            onClick={() => {
              onPressOK({
                payItemDeductionId: payItemId,
                calculationType,
                category: PayTemp.PayTemplateItemTypeEnum.DEDUCTION,
                minimumWage,
                percentage,
                index: index,
              });
              onClose();
            }}
          >
            Add
          </Button>
          <Button
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            color={"#ea4d4d"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"40px"}
            variant={"solid"}
            padding={"12px 16px"}
            fontWeight={700}
            _hover={{
              backgroundColor: "#fff",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
