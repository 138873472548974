import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillFilePdf, AiOutlineCaretDown } from "react-icons/ai";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { FaPiggyBank } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { GrCurrency } from "react-icons/gr";
import {
  MdCurrencyExchange,
  MdHideImage,
  MdManageAccounts,
} from "react-icons/md";
import { PiCurrencyCircleDollarFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { BusinessTaxRate } from "../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
import Accmodal from "../AddAccount/Accmodal";
import { Header } from "./Components/Header";
import { HeaderForAchive } from "./Components/HeaderForAchive";
import { AccountsTable } from "./Tabs/AccountsTable";

export function ChartOfAccount() {
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [lastUpdated, setLastUpdated] = useState<number>();

  const form = useForm({
    defaultValues: {
      accountCategory: "",
      accountType: "",
      code: 0,
      name: "",
      description: "",
      businesTaxRateId: "",
      showDashboardWatchlist: false,
      showExpenseClaims: false,
      enablePayments: false,
    },
  });
  const [count, setCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const toast = useToast();
  const [taxRate, setTaxRate] = useState<BusinessTaxRate.FetchTaxRate[]>([]);
  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRate(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [lastUpdated]);
  const [isLargerThanMD] = useMediaQuery("(min-width: 48em)");
  const tabslist: Array<{
    label: string;
    index: number;
    icon?: any;
    value?: any;
  }> = [
    {
      label: "All Accounts",
      index: 0,
      icon: <MdManageAccounts />,
    },
    {
      label: "Assets",
      index: 1,
      icon: <GrCurrency />,
      value: ChartOfAccounts.AccountCategory.ASSETS,
    },
    {
      label: "Liabilities",
      index: 2,
      icon: <MdCurrencyExchange />,
      value: ChartOfAccounts.AccountCategory.LIABILITY,
    },
    {
      label: "Equity",
      index: 3,
      icon: <PiCurrencyCircleDollarFill />,
      value: ChartOfAccounts.AccountCategory.EQUITY,
    },
    {
      label: "Expense",
      index: 4,
      icon: <GiPayMoney />,
      value: ChartOfAccounts.AccountCategory.EXPENSES,
    },
    {
      label: "Revenue",
      index: 5,
      icon: <FaPiggyBank />,
      value: ChartOfAccounts.AccountCategory.REVENUE,
    },
    {
      label: "Archive",
      index: 6,
      icon: <MdHideImage />,
      value: ChartOfAccounts.AccountCategory.ARCHIVE,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  const [data, setData] = useState<Array<ChartOfAccounts.FetchChartOfAccounts>>(
    []
  );
  const [allYTD, setAllYTD] =
    useState<ChartOfAccounts.FetchChartOfAccountsYTD>();
  const [isLoading, setIsLoading] = useState(true);
  // const [typeOfAccounts, setTypeOfAccounts] = useState<string>();
  const [typeOfAccounts, setTypeOfAccounts] = useState<
    ChartOfAccounts.AccountCategory | undefined
  >(undefined);
  useEffect(() => {
    // if (typeOfAccounts === ChartOfAccounts.AccountCategory.ARCHIVE) {
    //   ChartOfAccounts.FetchAllArchivedAccounts(
    //     (res) => {
    //       setData(res?.data);

    //       setIsLoading(false);
    //     },
    //     (err) => {
    //       toast({
    //         title: "Error",
    //         status: "error",
    //       });
    //       setIsLoading(false);
    //     }
    //   );
    // } else {
    ChartOfAccounts.GetAllAccounts(
      {
        category:
          typeOfAccounts === ChartOfAccounts.AccountCategory.ARCHIVE
            ? undefined
            : typeOfAccounts,
        archive:
          typeOfAccounts === ChartOfAccounts.AccountCategory.ARCHIVE
            ? true
            : false,
        page: pageNumber,
        limit: pageLimit,
      },
      (res) => {
        setData(res.data);
        setCount(res?.count);
        ChartOfAccounts.FetchChartofAccountsYTD(
          (data) => {
            setAllYTD(data);
            setIsLoading(false);
          },
          (err) => {
            toast({
              title: "Error",
              status: "error",
            });
            setIsLoading(false);
          }
        );
      },
      (err) => {
        toast({
          title: "Error",
          status: "error",
        });
        setIsLoading(false);
      }
    );
    // }
  }, [lastUpdated, typeOfAccounts, pageLimit, pageNumber]);
  const onAccountUpdate = (account: ChartOfAccounts.FetchChartOfAccounts) => {
    form.reset(account);
    onOpen();
  };
  const downloadExcel = () => {
    const headerMapping = {
      code: "Code",
      name: "Name",

      accountType: "Account Type",
      accountCategory: "Account Category",
      businesTaxRateId: "Business Tax Rate",
      description: "Description",

      showDashboardWatchlist: "Want to Show Dashboard",
      showExpenseClaims: "Want to show Expense Claims",
      enablePayments: "Enable Payments",
      archive: "Archive",
    };

    const filteredData = data.map((item) => ({
      Code: item.code,
      Name: item.name,

      "Account Type":
        item.accountType === "CURRENT_ASSET"
          ? "Current Asset"
          : item.accountType === "FIXED_ASSET"
          ? "Fixed Assets"
          : item.accountType === "INVENTORY"
          ? "Inventory"
          : item.accountType === "NON_CURRENT_ASSET"
          ? "Non Current Asset"
          : item.accountType === "PREPAYMENT"
          ? "Prepayment"
          : item.accountType === "EQUITY"
          ? "Equity"
          : item.accountType === "DEPRECIATION"
          ? "Depreciation"
          : item.accountType === "DIRECT_COSTS"
          ? "Direct Costs"
          : item.accountType === "EXPENSE"
          ? "Expense"
          : item.accountType === "OVERHEAD"
          ? "Overhead"
          : item.accountType === "CURRENT_LIABILITY"
          ? "Current Liability"
          : item.accountType === "LIABILITY"
          ? "Liability"
          : item.accountType === "NON_CURRENT_LIABILITY"
          ? "Non Current Liability"
          : item.accountType === "OTHER_INCOME"
          ? "Other Income"
          : item.accountType === "REVENUE"
          ? "Revenue"
          : item.accountType === "SALES"
          ? "Sales"
          : "",
      "Account Category": item.accountCategory,
      "Business Tax Rate":
        taxRate.find((taxRate: any) => taxRate.id === item.businesTaxRateId)
          ?.name || "",
      Description: item.description,

      "Want to Show Dashboard": item.showDashboardWatchlist ? "Yes" : "No",
      "Want to show Expense Claims": item.showExpenseClaims ? "Yes" : "No",
      "Enable Payments": item.enablePayments ? "Yes" : "No",
      Archive:
        typeOfAccounts === ChartOfAccounts.AccountCategory.ARCHIVE
          ? "Yes"
          : "No",
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.values(headerMapping),
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, "accounts.xlsx");
  };
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const filterData = useCallback(() => {
    return data?.filter((account) => {
      const name = account.name.toLowerCase();
      // You can add additional fields to search here
      return name.includes(searchQuery.toLowerCase());
    });
  }, [data, searchQuery]);
  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Stack width={"100%"}>
          <Container
            maxW={"100%"}
            padding={"0px 20px"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
          >
            <Stack h={"50px"} paddingTop={"10px"} marginBottom={"10px"}>
              <Link
                color={"primary.950"}
                fontWeight={"normal "}
                fontSize={"11px"}
                href="advancedSettings"
              >
                Advanced accounting
              </Link>
              <Heading
                marginTop={"-10px"}
                fontSize="24px"
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                fontWeight="700"
              >
                Chart of accounts
              </Heading>
            </Stack>
            <HStack borderTop={"1px solid #cfd2d4"} padding={"30px 0px 30px"}>
              <Image
                borderRadius="0"
                w="190px"
                h="120px"
                src="https://edge.xero.com/people/payroll/assets/images/video-thumbs/settings-video.png"
                alt="Payroll Settings"
              />
              <VStack
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{ color: "customColor.white" }}
                marginLeft={"5px"}
                fontSize="18px"
                align="left"
              >
                <Text
                  borderBottom={"1px solid #e2e2e2"}
                  paddingBottom={"10px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                >
                  Categorise every transaction in HRIS 360 with our full
                  <Link color={"primary.950"}> Chart of accounts</Link>
                </Text>

                <Text
                  borderBottom={"1px solid #e2e2e2"}
                  paddingBottom={"10px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                >
                  Financial reports draw on each account to show how your
                  business is performing
                </Text>

                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{ color: "customColor.white" }}
                >
                  <Link href="#" color={"primary.950"}>
                    Learn more{" "}
                  </Link>
                  about customizing the chart of accounts
                </Text>
              </VStack>
            </HStack>
          </Container>
        </Stack>

        <Container
          maxW={"100%"}
          padding={"0px 20px 0px 1px"}
          paddingTop={"21.23px"}
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.gray.400",
          }}
        >
          <Stack
            direction={"row"}
            padding={0}
            marginBottom={"15px"}
            paddingLeft={"20px"}
          >
            <SimpleGrid columns={{ xs: 3, md: 5 }} spacing={2}>
              <Button
                leftIcon={<BsFillPlusSquareFill size={"15px"} />}
                margin={"0px 10px 15px 0px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  form.reset({
                    accountCategory: "",
                    accountType: "",
                    code: 0,
                    name: "",
                    description: "",
                    businesTaxRateId: "",
                    showDashboardWatchlist: false,
                    showExpenseClaims: false,
                    enablePayments: false,
                  });

                  setSelectedId([]);

                  onOpen();
                }}
              >
                Add Account
              </Button>

              <Button
                leftIcon={<BsFillPlusSquareFill size={"15px"} />}
                margin={"0px 10px 15px 0px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => navigate("/app/payroll/AddBankAccount")}
              >
                Add Bank Account
              </Button>
              <Button
                leftIcon={<AiFillFilePdf size={"18px"} />}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
              >
                Print PDF
              </Button>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
                onClick={() => navigate("/app/payroll/Import")}
              >
                Import{" "}
              </Button>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "#3454D1",
                }}
                onClick={downloadExcel}
              >
                Export
              </Button>

              <Accmodal
                isOpen={isOpen}
                onClose={onClose}
                setLastUpdated={setLastUpdated}
                taxRateData={taxRate}
              />
            </SimpleGrid>
          </Stack>
          <Container maxW={"100%"} mt="10px" padding={0}>
            <Box w={"100%"}>
              <Tabs
                index={selectedTab}
                orientation={isLargerThanMD ? "vertical" : "horizontal"}
                minH="calc(60vh - 100px)"
                onChange={(index) => {
                  setSelectedId([]);
                  setSelectedTab(index);
                }}
                isLazy
              >
                {isLargerThanMD ? (
                  <Stack
                    w="23%"
                    spacing={0}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                    marginBottom={"1.25rem"}
                    boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                    borderWidth="1px"
                    borderStyle={"solid"}
                    borderColor="#f6f6f6"
                    padding={"20px"}
                  >
                    <TabList fontSize="14px" border={0}>
                      {tabslist.map((tab, i) => {
                        return (
                          <Tab
                            marginLeft="0px"
                            justifyContent="flex-start"
                            w="100%"
                            p={"5px 15px"}
                            key={i}
                            marginBottom={"5px"}
                            fontSize="13px"
                            borderRadius={"3px"}
                            cursor="pointer"
                            color="#74788D"
                            _light={{
                              color: "customColor.black7",
                            }}
                            _selected={{
                              bgColor: "#EAEBEF",
                              color: "customColor.black8",
                              borderRadius: "5px",
                              transition: "all .3s ease",
                            }}
                            _hover={{
                              bgColor: "#EAEBEF",
                              color: "customColor.black8",
                              borderRadius: "5px",
                              transition: "all .3s ease",
                            }}
                            onClick={() => {
                              setSelectedTab(tab.index);
                              setTypeOfAccounts(tab.value);
                            }}
                          >
                            <Stack
                              direction={"row"}
                              gap={0}
                              alignItems={"center"}
                              _hover={{
                                color: "customColor.black8",
                              }}
                            >
                              <Button
                                as={IconButton}
                                color={
                                  selectedTab === tab.index
                                    ? "#001327"
                                    : "#283C50"
                                }
                                icon={tab.icon}
                                variant={"ghost"}
                                padding={"0px"}
                                _hover={{
                                  bg: "transparent",
                                }}
                              />
                              {/* <Icon as={tab.iconName} /> */}
                              <Text
                                fontSize={"13px"}
                                fontWeight={"600"}
                                _hover={{
                                  color: "customColor.black8",
                                }}
                              >
                                {tab.label}
                              </Text>
                            </Stack>
                          </Tab>
                        );
                      })}
                    </TabList>
                  </Stack>
                ) : (
                  <Stack alignItems={"flex-end"}>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<AiOutlineCaretDown />}
                        backgroundColor={"#fff"}
                        borderRadius={"3px"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        h={"40px"}
                        variant={"solid"}
                        textColor={"customColor.black7"}
                        padding={"12px 16px"}
                        fontWeight={600}
                        _hover={{
                          color: "primary.950",
                          backgroundColor: "#fff",
                        }}
                        cursor={"pointer"}
                        _active={{
                          backgroundColor: "#fff",
                          color: "primary.950",
                        }}
                      >
                        {tabslist.find((tab) => tab.index === selectedTab)
                          ?.label ?? ""}
                      </MenuButton>
                      <MenuList>
                        {tabslist.map((tab, i) => (
                          <MenuItem
                            key={i}
                            fontSize={"13.44px"}
                            onClick={() => {
                              setSelectedTab(i);
                            }}
                          >
                            {tab.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Stack>
                )}
                <Stack padding={"10px"}></Stack>
                <Box
                  w={{ sm: "100%", lg: "75%" }}
                  gap={0}
                  marginBottom={"1.25rem"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  borderWidth="1px"
                  borderStyle={"solid"}
                  borderColor="#f6f6f6"
                >
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    p="0px"
                    // maxW="940px"
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "customColor.white",
                    }}
                  >
                    <Heading
                      textAlign="left"
                      fontSize=".9975rem"
                      fontWeight="bold"
                      padding="20px"
                      as="h2"
                      _light={{
                        color: "customColor.black7",
                      }}
                      size="md"
                    >
                      {tabslist.find((tab) => tab.index === selectedTab)
                        ?.label ?? ""}
                    </Heading>
                    <Divider orientation="horizontal" />
                  </Box>
                  {selectedTab === 6 ? (
                    <HeaderForAchive
                      numberOfCheckedRows={0}
                      handleSearch={handleSearch}
                      selectedIds={selectedId}
                      searchQuery={searchQuery}
                      setLastUpdated={setLastUpdated}
                    />
                  ) : (
                    <Header
                      selectedIds={selectedId}
                      setLastUpdated={setLastUpdated}
                      searchQuery={searchQuery}
                      handleSearch={handleSearch}
                      setPageNumber={setPageNumber}
                      setPageLimit={setPageLimit}
                      pageLimit={pageLimit}
                    />
                  )}

                  <TabPanels overflowY="auto" w="100%">
                    <TabPanel p={0}>
                      <AccountsTable
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        downloadExcel={downloadExcel}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        typeOfAccounts={ChartOfAccounts.AccountCategory.ASSETS}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        downloadExcel={downloadExcel}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        typeOfAccounts={
                          ChartOfAccounts.AccountCategory.LIABILITY
                        }
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        downloadExcel={downloadExcel}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        typeOfAccounts={ChartOfAccounts.AccountCategory.EQUITY}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        setLastUpdated={setLastUpdated}
                        downloadExcel={downloadExcel}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        typeOfAccounts={
                          ChartOfAccounts.AccountCategory.EXPENSES
                        }
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        downloadExcel={downloadExcel}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        typeOfAccounts={ChartOfAccounts.AccountCategory.REVENUE}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        downloadExcel={downloadExcel}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                    <TabPanel p={0}>
                      <AccountsTable
                        // typeOfAccounts={ChartOfAccounts.AccountCategory.ARCHIVE}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                        downloadExcel={downloadExcel}
                        setLastUpdated={setLastUpdated}
                        onAccountUpdate={onAccountUpdate}
                        taxRateData={taxRate}
                        lastUpdated={lastUpdated}
                        data={data}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        filterData={filterData}
                        allYTD={allYTD}
                        count={count}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        pageLimit={pageLimit}
                      />
                    </TabPanel>
                  </TabPanels>
                </Box>
              </Tabs>
            </Box>
          </Container>
        </Container>
      </Stack>
    </FormProvider>
  );
}
