import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelect } from "../../../Common/ReactSelect";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import Header from "./Header";

export function SuperannuationPaymentDetails() {
  const { employeesList } = useBusinessContext();
  const employees = employeesList ?? [];
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState("");
  const [selectedSuper, setSelectedSuper] = useState("");
  const [selectedFund, setSelectedFund] = useState("");
  const employeeOptions = [{ value: "all", label: "all" }];
  const superOptions = [
    { value: "all", label: "all" },
    { value: "AustralianSuper", label: "AustralianSuper" },
    {
      value: "Rest Super(Retail Employees Superannuation Trust)",
      label: "Rest Super(Retail Employees Superannuation Trust)",
    },
    { value: "Spirit Super", label: "Spirit Super" },
  ];
  const fundOptions = [
    { value: "PROVIDENT_FUNDS", label: "Provident Fund" },
    { value: "GRATUITY", label: "Gratuity" },
    {
      value: "EOBI",
      label: "EOBI",
    },
    { value: "SOCIAL_SECURITY", label: "Social Security" },
  ];
  return (
    <Stack
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      gap={0}
    >
      <Container
        h="64px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        maxW="100%"
        justifyContent={"center"}
        borderBottom={"1px solid #cfd2d4"}
        padding={"0px 20px"}
      >
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          paddingTop={"5px"}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/app/payroll/PayEmployee"
              fontSize={"11px"}
              color={"#0590C3"}
            >
              Statuary Benefits
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href=""
              fontSize={"11px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              padding={"0px 20px 0px 0px"}
            >
              New Payment
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"24px"}
          margin={"0px 4px 0px 0px"}
        >
          Statuary Benefits Payment Details
        </Heading>
        {/* <Divider padding="10px" orientation="horizontal" /> */}
      </Container>
      <Stack
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
        padding={"20px"}
        borderBottom={"1px solid #dadddf"}
      >
        <Container
          justifyContent="center"
          alignItems="center"
          p="0px"
          maxW={"container.xl"}
          borderRadius="md"
        >
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "customColor.white",
            }}
            borderRadius={"4px"}
            paddingTop={"10px"}
            paddingLeft={"10px"}
            margin={"0px 1px 20px"}
            border="1px solid #f6f6f6"
            padding={"7.52px 12px"}
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
            gap={0}
            direction={"row"}
            alignItems={"center"}
          >
            <SimpleGrid
              columns={{
                xs: 3,
                md: 4,
                lg: 6,
              }}
              spacing={3}
            >
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Employee/s
                </Text>

                <ReactSelect
                  placeholder=""
                  options={employees.map((employee: any) => ({
                    value: employee.id,
                    label: `${employee.firstName} ${employee.lastName}`,
                  }))}
                  value={
                    selectedEmployee
                      ? {
                          value: selectedEmployee,
                          label: `${
                            employees.find(
                              (employee) => employee.id === selectedEmployee
                            )?.firstName
                          } ${
                            employees.find(
                              (employee) => employee.id === selectedEmployee
                            )?.lastName
                          }`,
                        }
                      : ""
                  }
                  onChange={(newValue: any) => {
                    setSelectedEmployee(newValue?.value);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Super Fund
                </Text>
                <ReactSelect
                  placeholder=""
                  options={superOptions}
                  value={selectedSuper}
                  onChange={(newValue: any) => {
                    setSelectedSuper(newValue);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Type
                </Text>
                <ReactSelect
                  placeholder=""
                  options={fundOptions}
                  value={selectedFund}
                  onChange={(newValue: any) => {
                    setSelectedFund(newValue);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={3} gap={0}>
                <Text
                  fontSize={"12px"}
                  margin={"0px 0px 5px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.dark.250",
                  }}
                  fontWeight={"bold"}
                >
                  Employee Group
                </Text>
                <ReactSelect
                  placeholder=""
                  options={employeeOptions}
                  value={selectedEmployeeGroup}
                  onChange={(newValue: any) => {
                    setSelectedEmployeeGroup(newValue);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                />
              </Stack>
              <Stack flex={2} gap={0}>
                <CustomInput
                  withValidation
                  input={{
                    isInvalid: false,
                    w: "100%",
                    fontSize: "12px",
                    // value: selectedFilterStartDate,
                    variant: "outline",
                    type: "date",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",
                    height: "38px",
                    borderRadius: "4px",
                    // onChange: (e: any) => {
                    //   setSelectedFilterStartDate(e.target.value);
                    // },
                  }}
                  placeholder="dd/mm/yyyy"
                  label="Due Date Start"
                  labelProps={{
                    fontSize: "12px",
                    fontWeight: "700",
                    margin: "0px 0px 5px",
                    color: "#444",
                  }}
                />
              </Stack>
              <Stack flex={2} gap={0}>
                <CustomInput
                  withValidation
                  input={{
                    isInvalid: false,
                    w: "100%",
                    fontSize: "12px",
                    variant: "outline",
                    // value: selectedFilterEndDate,
                    type: "date",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc3",

                    height: "38px",
                    borderRadius: "4px",
                    // onChange: (e: any) => {
                    //   setSelectedFilterEndDate(e.target.value);
                    // },
                  }}
                  placeholder="dd/mm/yyyy"
                  label="Due Date Ending"
                  labelProps={{
                    fontSize: "12px",
                    fontWeight: "700",
                    margin: "0px 0px 5px",
                    color: "#444",
                  }}
                />
              </Stack>
            </SimpleGrid>

            <Stack alignItems={"flex-end"} padding={"10px"} flex={1}>
              <Stack direction={"row"}>
                <Button
                  marginTop={"10px"}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  //   onClick={() => {
                  //     setLastUpdated(Date.now());
                  //   }}
                >
                  Apply Filter
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Header />

      <Stack paddingLeft={"20px"} paddingRight={"20px"} marginBottom={"20px"}>
        <Container maxW={"container.xl"} p={0}>
          <Text
            fontSize={"16px"}
            _light={{
              color: "customColor.black7",
            }}
            fontWeight={"700"}
            marginBottom={"16px"}
          >
            0 payment Included
          </Text>
          <Stack
            _light={{
              color: "customColor.black7",
              bgColor: "customColor.white",
            }}
            fontSize={"16px"}
            _dark={{
              bgColor: "customColor.dark.50",
              color: "customColor.dark.250",
            }}
            gap={0}
            border="1px solid #f6f6f6"
            borderRadius="4px"
            boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          >
            <Box overflowX="auto" p="0px">
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    borderTopWidth={"1px"}
                    borderTopColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    <Th
                      padding="8px 26px 8px 30px"
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                    >
                      <Checkbox
                        size="md"
                        borderColor="#adb5bd"
                        bgColor="white"
                      />
                    </Th>
                    {[
                      {
                        name: "Period",
                        value: "20%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Due Date",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Employee",
                        value: "20%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Type",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Fund",
                        value: "30%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Amount",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {[
                    {
                      period: "Fortnight ending 31 Dec 2023",
                      dueDate: "28 April 2024",
                      employee: "Danish Khowaja",
                      type: "Provident Fund",
                      fund: "AustralianSuper",
                      amount: "154.00",
                    },
                    {
                      period: "Fortnight ending 14 Jan 2023",
                      dueDate: "28 April 2024",
                      employee: "Danish Khowaja",
                      type: "Gratuity",
                      fund: "AustralianSuper",
                      amount: "154.00",
                    },
                    {
                      period: "Fortnight ending 28 Jan 2023",
                      dueDate: "28 April 2024",
                      employee: "Danish Khowaja",
                      type: "EOBI",
                      fund: "AustralianSuper",
                      amount: "154.00",
                    },
                    {
                      period: "Fortnight ending 11 Feb 2023",
                      dueDate: "28 April 2024",
                      employee: "Danish Khowaja",
                      type: "Social Security",
                      fund: "AustralianSuper",
                      amount: "154.00",
                    },
                  ].map((item, i) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            size="md"
                            borderColor="#adb5bd"
                            bgColor="white"
                          />
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.period}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.dueDate}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.employee}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.type}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.fund}</Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Text>{item.amount}</Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
