import {
  Button,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillCaretDown } from "react-icons/ai";
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";
import { PayItemEarningApi } from "../../../../Api/PayItemEarning";
import { PayItemRetirement } from "../../../../Api/PayItemRetirement";
import { DeleteModal } from "./DeleteModal";
import AddRetirementPayItem from "./Modals/AddRetirementPayItem";

export default function StaturyBenefits() {
  const toast = useToast();
  const [earningAdded, setEarningAdded] = useState<
    Array<PayItemEarningApi.EarningById>
  >([]);
  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<ChartOfAccounts.FetchChartOfAccounts>
  >([]);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [staturyBenefits, setStaturyBenefits] = useState<
    PayItemRetirement.FetchRetirementFund[]
  >([]);

  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (data) => {
        setEarningAdded(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (data) => {
        setChartOfAccounts(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      },
      "LIABILITY"
    );
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(staturyBenefits);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };
  useEffect(() => {
    PayItemRetirement.FetchRetirementFund(
      (data) => {
        setStaturyBenefits(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, [lastUpdate]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteModal = useDisclosure();

  const [selectedId, setSelectedId] = useState<string>();
  const form = useForm<PayItemRetirement.AddRetirementFund>({
    defaultValues: {
      category: "",
      chartOfAccountId: "",
      earningPayItems: [],
      minimumWage: undefined,
      name: "",
      percentage: undefined,
      province: "",
      showOnPayslip: false,
    },
  });
  // const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  // useEffect(() => {
  //   const initialCheckboxes = staturyBenefits.reduce((acc: any, item) => {
  //     acc[item.id] = false;
  //     return acc;
  //   }, {});
  //   setCheckboxes(initialCheckboxes);
  // }, [staturyBenefits]);

  // const handleCheckboxChange = (id: string) => {
  //   setCheckboxes({
  //     ...checkboxes,
  //     [id]: !checkboxes[id],
  //   });
  // };

  // const handleCheckAll = () => {
  //   const updatedCheckboxes = Object.keys(checkboxes).reduce(
  //     (acc, key) => ({
  //       ...acc,
  //       [key]: !checkboxes[key],
  //     }),
  //     {}
  //   );
  //   setCheckboxes(updatedCheckboxes);
  // };
  return (
    <FormProvider {...form}>
      <Skeleton height={"100%"} isLoaded={!isLoading}>
        <Stack color={"#32456A"}>
          <HStack justifyContent="space-between" px={"12px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              py="20px"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Statuary Benefits
            </Heading>
            <Menu>
              <Tooltip
                label="Statutory Benefits allows users to input or record legally mandated employee benefits, such as social security contributions or workers' compensation premiums"
                fontSize="sm"
                padding={"10px"}
                bg={"#fff"}
                border={"1px solid #ddd"}
                _light={{
                  color: "customColor.black7",
                }}
                maxW={"300px"}
                placement="auto"
              >
                <MenuButton
                  as={Button}
                  rightIcon={<RiArrowDownSFill fontSize={"20px"} />}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  _active={{
                    bgColor: "#3454D1",
                  }}
                >
                  Add
                </MenuButton>
              </Tooltip>
              <MenuList width={"140px"}>
                {[
                  {
                    name: "Provident Fund",
                    onClick: () => {
                      form.reset({
                        category: "EMPLOYER_PROVIDENT_FUND",
                        chartOfAccountId: "",
                        earningPayItems: [],
                        minimumWage: undefined,
                        name: "",
                        percentage: undefined,
                        province: "",
                        showOnPayslip: false,
                      });
                      onOpen();
                    },
                  },
                  {
                    name: "Gratuity",
                    onClick: () => {
                      form.reset({
                        category: "GRATUITY",
                        chartOfAccountId: "",
                        earningPayItems: [],

                        minimumWage: undefined,
                        name: "",
                        percentage: undefined,
                        province: "",
                        showOnPayslip: false,
                      });
                      onOpen();
                    },
                  },
                  {
                    name: "EOBI",
                    onClick: () => {
                      form.reset({
                        category: "EMPLOYER_EOBI",
                        chartOfAccountId: "",
                        earningPayItems: [],

                        minimumWage: undefined,
                        name: "",
                        percentage: undefined,
                        province: "",
                        showOnPayslip: false,
                      });
                      onOpen();
                    },
                  },
                  {
                    name: "Social Security",
                    onClick: () => {
                      form.reset({
                        category: "SOCIAL_SECURITY",
                        chartOfAccountId: "",
                        earningPayItems: [],

                        minimumWage: undefined,
                        name: "",
                        percentage: undefined,
                        province: "",
                        showOnPayslip: false,
                      });
                      onOpen();
                    },
                  },
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"1px 0px 1px 20px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      onClick={item.onClick}
                    >
                      <Button
                        variant={"ghost"}
                        fontWeight={"normal"}
                        fontSize={"13px"}
                        padding={"0px 20px 0px 0px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        onClick={item.onClick}
                        _hover={{
                          bg: "none",
                        }}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <AddRetirementPayItem
              isOpen={isOpen}
              onClose={onClose}
              setLastUpdate={setLastUpdate}
              earningAdded={earningAdded}
              chartOfAccounts={chartOfAccounts}
            />
          </HStack>
        </Stack>

        <Stack overflowX="auto" p="0px">
          <Table variant="simple" w="100%" overflowX="auto">
            <Thead h={"36.8px"}>
              <Tr
                color={"customColor.black7"}
                _dark={{
                  color: "customColor.dark.150",
                }}
              >
                {/* <Th
                    w="20px"
                    borderBottomColor="#f5f6f8"
                    px="12px"
                    minW="50px"
                  >
                    <Checkbox
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={Object.values(checkboxes).every(
                        (value) => value
                      )}
                      onChange={handleCheckAll}
                    />
                  </Th> */}
                {[
                  {
                    name: "Name",
                    value: "20%",
                    paddingValue: "8px 26px 8px 30px",
                    icon: <AiFillCaretDown />,
                  },
                  {
                    name: "Minimum Wage",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Percentage",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Province",
                    value: "20%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "Chart Of Account",
                    value: "20%",
                    paddingValue: "8px 26px 8px 15px",
                  },

                  {
                    name: "Action Required",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                  {
                    name: "View Details",
                    value: "10%",
                    paddingValue: "8px 26px 8px 15px",
                  },
                ].map((item, i) => {
                  return (
                    <Th
                      key={i}
                      padding={item.paddingValue}
                      borderBottomColor="borders.tableBorder"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      fontWeight={700}
                      whiteSpace="nowrap"
                      fontSize="12px"
                      textTransform="none"
                      width={item.value}
                    >
                      {item.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody padding={0}>
              {paginatedData &&
                paginatedData.map((item: any, i: any) => {
                  return (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "#f2f3f8",
                        cursor: "pointer",
                      }}
                    >
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        whiteSpace="nowrap"
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {item.name !== null ? `${item.name}` : ""}
                      </Td>

                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        whiteSpace="nowrap"
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text h={"13.5px"}>
                          {item.minimumWage !== null
                            ? `${item.minimumWage} `
                            : "0"}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        whiteSpace="nowrap"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text h={"13.5px"}>
                          {item.percentage !== null
                            ? `${item.percentage} %`
                            : "0"}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        whiteSpace="nowrap"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text h={"13.5px"}>
                          {item.province !== null ? `${item.province} ` : ""}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        whiteSpace="nowrap"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text h={"13.5px"}>
                          {
                            chartOfAccounts.find(
                              (chartOfAccount) =>
                                chartOfAccount.id === item.chartOfAccountId
                            )?.name
                          }
                        </Text>
                      </Td>
                      <Td
                        padding="10px"
                        fontSize="13.44px"
                        whiteSpace="nowrap"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <HStack alignItems="center" justifyContent="center">
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdDelete}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={() => {
                              setSelectedId(item.id);
                              deleteModal.onOpen();
                            }}
                          />
                        </HStack>
                      </Td>
                      <Td
                        padding="10px"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <HStack alignItems="center" justifyContent="center">
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdOutlineRemoveRedEye}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={() => {
                              form.reset({
                                category: item.category,
                                id: item.id,
                                chartOfAccountId: item.chartOfAccountId,
                                earningPayItems: item.earningPayItems,
                                minimumWage:
                                  item.minimumWage === null
                                    ? undefined
                                    : item.minimumWage,
                                name: item.name,
                                percentage:
                                  item.percentage === null
                                    ? undefined
                                    : item.percentage,
                                province: item.province,
                                showOnPayslip: item.showOnPayslip,
                              });
                              onOpen();
                            }}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Stack>
        <DeleteModal
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          selectedId={selectedId}
          setLastUpdate={setLastUpdate}
        />
      </Skeleton>
    </FormProvider>
  );
}
