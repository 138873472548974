import { axiosInstance } from "./axiosInstance-hris";
import { EmployeeBankAccountsModule } from "./EmployeeBankAccounts";
import { EmployeeEducation } from "./EmployeeEducation";
import { EmployeeReferences } from "./EmployeeReferences";

declare module Employees {
  type BulkUpload = {
    title: string;
    firstName: string;
    lastName: string;
    cnic: string;
    dateOfBirth: string;

    gender: string;
    email: string;
    mobileNumber: string;
    address: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;

    businessId: string;
    employeeBankDetails: {
      bankName: string;
      iban: string;
      accountName: string;
      accountNumber: string;
      branchCode: string;
    };
    employeeEmployementDetails: {
      startDate: string;
      department: string;
      jobTitle: string;
    };
  };
  type AddBulkEmployee = {
    employees: Array<BulkUpload>;
  };
  type EmployeeAllDetail = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    cnic: string;
    mobileNumber: string;
    gender: string;
    address: string;
    phoneNumber: string;
    city: string;
    country: string;
    dateOfBirth: string;
    postalCode: string;
    payTemplate: {
      grossSalary: number;
    };
    completed?: number;
    employeeEmployementDetails: {
      id: string;
      startDate: string;
      jobTitle: string;
      department: string;
      payItemsEarnings: {
        name: string;
      };
      payrollCalendars: {
        name: string;
      };
    };
    emergencyContacts: [
      {
        name: string;
        email: string;
        contact: string;
        relationship: string;
      }
    ];
    employeeBankDetails: {
      id: string;
      createdAt: string;
      empId: string;
      accountName: string;
      accountNumber: string;
      bankName: string;
      branchCode: string;
      iban: string;
    };
    education: [
      {
        courseName: string;
        instituteName: string;
        dateFrom: string;
        dateTo: string;
      }
    ];
    experiences: [
      {
        startDate: string;
        endDate: string;
        companyName: string;
        position: string;
      }
    ];
    references: [
      {
        id: string;
        createdAt: string;
        name: string;
        position: string;
        email: string;
        contact: string;
        referenceType: string;
        empId: string;
      }
    ];
    dependents: [
      {
        id: string;
        createdAt: string;
        name: string;
        dateOfBirth: string;
        gender: string;
        cnic: string;
        relationship: string;
        martialStatus: string;
        empId: string;
      }
    ];
    nextOfKins: [
      {
        id: string;
        createdAt: string;
        empId: string;
        name: string;
        cnic: string;
        address: string;
        relationship: string;
        contact: string;
        shareInPercent: number;
      }
    ];
    employeeDocuments: [
      {
        id: string;
        createdAt: string;
        fileName: string;
        fileUrl: string;
        empId: string;
        documentType: string;
      }
    ];
  };
  type AddNewEmployee = {
    title: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    mobileNumber: string;
    photo: string;
    dateOfBirth: string;
    gender: string;
    address: string;
    parentOrPartner: string;
    relationType: string;
    cnic: string;
    phoneNumber: string;
    sendInvitation: boolean;
    cnicExpiry: string;
    cnicIssuance: string;
    religion: string;
    maritalStatus: string;
    nationality: string;
    bloodGroup: string;
    passportNumber?: string;
    passportExpiry?: string;
    passportIssuance?: string;
    driverLicenseNumber?: string;
    driverLicenseExpiry?: string;
    driverLicenseIssuance?: string;
    province: string;
    city: string;
    country: string;
    postalCode: string;
    uniqueId: string;
  };

  type timeSheetEmployees = {
    id: string;
    firstName: string;
    lastName: string;
    employeeEmployementDetails: {
      payrollPayCalenderId: string;
    };
  };

  type EmployeeReport = {
    id: string;
    title: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    mobileNumber: string;
    userId: string | null;
    businessId: string;
    photo: string;
    dateOfBirth: string;
    gender: string;
    address: string;
    parentOrPartner: string;
    relationType: string;
    cnic: string;
    phoneNumber: string;
    cnicExpiry: string;
    cnicIssuance: string;
    religion: string;
    maritalStatus: string;
    nationality: string;
    bloodGroup: string;
    passportNumber: string | null;
    passportExpiry: string | null;
    passportIssuance: string | null;
    driverLicenseNumber: string | null;
    driverLicenseExpiry: string | null;
    driverLicenseIssuance: string | null;
    province: string;
    city: string;
    country: string;
    postalCode: string;
    education: Array<EmployeeEducation.FetchEducation>;
    references: Array<EmployeeReferences.FetchReferences>;
    employeeEmployementDetails: {
      id: string;
      empType: string;
      empIncomeType: string;
      empBasis: string;
      empId: string;
      payrollPayCalenderId: string;
      employeeGroupId: string | null;
      holidayGroupId: string | null;
      ratesId: string;
      startDate: string;
      endDate: string | null;
      terminationReason: string;
      department: string;
      jobTitle: string;
      includeHolidaysPayslips: boolean;
      approveLeaveAuthorization: boolean;
      approveLeaveTimesheets: boolean;
      workPercentageOnPartTime: number;
    };
    employeeBankDetails: Array<EmployeeBankAccountsModule.FetchBankAccounts>;
  };
  type FetchEmployees = {
    id: string;
    employeeEmployementDetails: {
      startDate: string;
      department: string;
      jobTitle: string;
    };
    uniqueId: string;
    title: string;

    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    mobileNumber: string;
    photo: string;
    dateOfBirth: string;
    gender: string;
    address: string;
    parentOrPartner: string;
    relationType: string;
    cnic: string;
    phoneNumber: string;
    sendInvitation: boolean;
    cnicExpiry: string;
    cnicIssuance: string;
    religion: string;
    maritalStatus: string;
    nationality: string;
    bloodGroup: string;
    passportNumber: string;
    passportExpiry: string;
    passportIssuance: string;
    driverLicenseNumber: string;
    driverLicenseExpiry: string;
    driverLicenseIssuance: string;
    province: string;
    city: string;
    country: string;
    postalCode: string;
  };
  type EmployeesPayrollEarning = {
    annualSalary: number;
    ytdSalary: number;
    firstName: string;
    lastName: string;
    nextPayPeriod: Array<nextPayPeriod>;
    payFrequencyAnnually: number;
  };
  type nextPayPeriod = {
    startDate: string;
    endDate: string;
    string: string;
  };
  type calndersbyid = {
    id: string;
    name: string;
  };
}
module Employees {
  const API_URL = "/employees";
  export function downloadTimesheetTemplate(
    calenderId: string,
    data: {
      startDate: "2021-01-01";
      endDate: "2021-01-01";
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/timesheet-template/${calenderId}`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function AddEmployee(
    data: AddNewEmployee,
    success: (res: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function BulkUploadEmployee(
    data: AddBulkEmployee,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post("/employees/upload-employees", data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateEmployee(
    id: string,
    data: Partial<AddNewEmployee>,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchEmployees(
    params: {
      empStatus: "CURRENT" | "ALL" | "PAST";
      page?: number;
      quantity?: number;
      search?: string;
    },
    success: (data: Array<FetchEmployees>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, { params })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function EmployeeAllDetail(
    success: (data: EmployeeAllDetail) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/profile`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchTimeSheetEmployees(
    success: (data: Array<timeSheetEmployees>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/timesheet-employees`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchEmployeesById(
    id: string,
    success: (data: FetchEmployees) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  // export function DeleteEmployee(
  //   id: string,
  //   success: (message: string) => void,
  //   error: (message: string) => void
  // ) {
  //   axiosInstance
  //     .delete(`${URL}/${id}`)
  //     .then((response) => {
  //       success(response.data.message);
  //     })
  //     .catch((err) => {
  //       error(err.response.data.message);
  //     });
  // }
  export function FetchEmployeesReportByID(
    id: string,
    success: (data: EmployeeReport) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/full-detail/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchEmployeesPayrollEarning(
    id: string | undefined,
    success: (data: EmployeesPayrollEarning) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/payroll-earnings/${id}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function CalendersByEarningId(
    payItemsEarningId: string,
    success: (data: Array<calndersbyid>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/calendarsByEarningId/${payItemsEarningId}`)
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Employees };
