import {
  Button,
  Container,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomInput } from "../../../../../Common/CustomInput";
import { Restore } from "../Modal/Restore";

interface HeaderForAchiveProps {
  numberOfCheckedRows: number;
  selectedIds: string[];
  setLastUpdated: (timestamp: number) => void;
  searchQuery: string;
  handleSearch: (e: any) => void;
}
export function HeaderForAchive({
  numberOfCheckedRows,
  selectedIds,
  setLastUpdated,
  searchQuery,
  handleSearch,
}: HeaderForAchiveProps) {
  const buttonSize = useBreakpointValue({ base: "sm", sm: "md" }); // Adjust button size based on screen size
  const restoreModal = useDisclosure();
  return (
    <Container
      justifyContent="center"
      alignItems="center"
      maxW={"100%"}
      p={0}
      margin={0}
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
    >
      <Stack direction={"row"} gap={0}>
        <Stack
          direction={["column", "row"]}
          alignItems="center"
          padding={["2px", "2px 10px 2px"]}
          gap={0}
          flex={1}
        >
          <Button
            color={"#999"}
            backgroundColor={"#fff"}
            borderRadius={"3px"}
            marginRight={"3px"}
            border={"1px solid #dcdee4"}
            fontSize={"13px"}
            h={"25px"}
            variant={"solid"}
            textColor={"customColor.black7"}
            padding={"12px 16px"}
            fontWeight={600}
            cursor={"pointer"}
            size={buttonSize}
            _hover={{
              backgroundColor: "#fff",
              color: "#3454D1",
            }}
            onClick={() => restoreModal.onOpen()}
          >
            Restore
          </Button>
          <Restore
            isOpen={restoreModal.isOpen}
            onClose={restoreModal.onClose}
            setLastUpdated={setLastUpdated}
            status={false}
            selectedIds={selectedIds}
          />
          <Text color={"#868686"} fontSize={"11px"}>
            {selectedIds.length > 0 ? selectedIds.length : "0"} account selected
          </Text>
        </Stack>

        <Stack
          direction={["row"]}
          alignItems="flex-end"
          gap={0}
          padding={["2px", "2px 0px 2px"]}
        >
          <CustomInput
            input={{
              placeholder: "",
              type: "text",
              h: "25px",
              border: "1px solid #e6e6e6",
              borderRadius: "3px",
            }}
          />
          <Button
            h={"25px"}
            color={"#009ACF"}
            bg={"#fff!important"}
            border={"1px solid #e6e6e6"}
            borderRadius={"3px"}
            fontSize={"11px"}
            marginRight={"3px"}
            fontWeight={"700"}
            _hover={{
              color: "#005e7b",
            }}
            size={buttonSize}
          >
            Search
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
