import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Header } from "./Header";
import { Details } from "./Tabs/Details";

import { useForm } from "react-hook-form";
import { AiOutlineCaretDown } from "react-icons/ai";
import { CgTemplate } from "react-icons/cg";
import { FaHandshakeAltSlash } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { GrUserWorker } from "react-icons/gr";
import { IoIosArrowBack, IoMdDocument } from "react-icons/io";
import {
  IoInformationCircleOutline,
  IoReceiptSharp,
  IoSchoolSharp,
} from "react-icons/io5";
import {
  MdAccountBalanceWallet,
  MdBoy,
  MdOutlineContactEmergency,
  MdOutlineHolidayVillage,
  MdOutlineLocalHospital,
  MdOutlineMedicalInformation,
  MdOutlinePayments,
  MdOutlineRoomPreferences,
  MdSpeakerNotes,
  MdWork,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Employees } from "../../Api/Employees";
import { UploadAttachments } from "../../Api/UploadAttachments";
import Notes from "../Employees/notes";
import Payslips from "../Employees/payslips";
import Paytemp from "../Employees/payTem";
import { BankAccount } from "./Tabs/Bank Account";

import Dependents from "./Tabs/Dependents/Dependents";
import DocumentUpload from "./Tabs/DocumentsUpload/DocumentUpload";
import Education from "./Tabs/Education/Education";
import EmergencyContact from "./Tabs/EmergencyContact/EmergencyContact";
import { EmployeeSeparation } from "./Tabs/EmployeeSeparation";
import { Employment } from "./Tabs/Employment";
import Experience from "./Tabs/Experience/Experience";
import { LeaveAdded } from "./Tabs/Leaves/LeaveAdded";
import { MedicalBenefits } from "./Tabs/MedicalBenefits";
import NextOfKin from "./Tabs/NextOfKin/NextOfKin";
import References from "./Tabs/References/references";
import { StatutoryBenefits } from "./Tabs/StatutoryBenefits";
import { Taxes } from "./Tabs/Taxes";

export function EmployeeDetail() {
  const [isLargerThanMD] = useMediaQuery("(min-width: 1000px)");
  const tabCategories: Array<{
    categoryName: string;
    tabs: Array<{ label: string; index: number; iconName?: any }>;
  }> = [
    {
      categoryName: "Personal Information",
      tabs: [
        {
          label: "Personal Info",
          iconName: <IoInformationCircleOutline />,
          index: 0,
        },

        { label: "Education", iconName: <IoSchoolSharp />, index: 1 },
        { label: "Experience", iconName: <MdWork />, index: 2 },
        {
          label: "References",
          iconName: <MdOutlineRoomPreferences />,
          index: 3,
        },

        { label: "Dependents", iconName: <MdOutlineLocalHospital />, index: 4 },
        { label: "Next of Kin", iconName: <MdBoy />, index: 5 },
        {
          label: "Emergency Contact",
          iconName: <MdOutlineContactEmergency />,
          index: 6,
        },
        { label: "Statutory Benefits", iconName: <GoLaw />, index: 7 },
        {
          label: "Medical Benefits",
          iconName: <MdOutlineMedicalInformation />,
          index: 8,
        },
        { label: "Leave", iconName: <MdOutlineHolidayVillage />, index: 9 },

        // { label: "Opening Balances", iconName: <AiOutlineBank />, index: 8 },

        { label: "Documents", iconName: <IoMdDocument />, index: 10 },
        { label: "Notes", iconName: <MdSpeakerNotes />, index: 11 },
      ],
    },
    {
      categoryName: "Employement Information",
      tabs: [
        { label: "Employment", iconName: <GrUserWorker />, index: 12 },
        { label: "Pay Template", iconName: <CgTemplate />, index: 13 },
        {
          label: "Bank Account",
          iconName: <MdAccountBalanceWallet />,
          index: 14,
        },
        { label: "Tax Detail", iconName: <IoReceiptSharp />, index: 15 },

        { label: "Salary Slip", iconName: <MdOutlinePayments />, index: 16 },
        {
          label: "Employee Separation",
          iconName: <FaHandshakeAltSlash />,
          index: 17,
        },
      ],
    },
  ];
  const toast = useToast();
  const [uploadedPhoto, setUploadPhoto] = useState<number>(Date.now());
  const [photoLoading, setPhotoLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const form = useForm<Employees.AddNewEmployee>({
    defaultValues: {
      title: "",
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      mobileNumber: "",
      photo: "",
      dateOfBirth: "",
      gender: "",
      address: "",
      parentOrPartner: "",
      relationType: "",
      cnic: "",
      phoneNumber: "",
      sendInvitation: false,
      cnicExpiry: "",
      cnicIssuance: "",
      religion: "",
      maritalStatus: "",
      nationality: "",
      bloodGroup: "",
      passportNumber: undefined,
      passportExpiry: undefined,
      passportIssuance: undefined,
      driverLicenseNumber: undefined,
      driverLicenseExpiry: undefined,
      driverLicenseIssuance: undefined,
      province: "",
      city: "",
      country: "",
      postalCode: "",
    },
  });
  const { id } = useParams();
  const [selectedEmployee, setSelectedEmployee] =
    useState<Employees.EmployeesPayrollEarning>();
  const param = useParams();
  const employeeId = param.id;
  useEffect(() => {
    Employees.FetchEmployeesPayrollEarning(
      employeeId,
      (data) => {
        setSelectedEmployee(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
        });
      }
    );

    if (id) {
      Employees.FetchEmployeesById(
        id,
        (data) => {
          form.reset(data);
          setLoading(false);
        },
        (error) => {
          toast({
            title: "Error fetching employee",
            status: "error",
          });
          setLoading(false);
        }
      );
    } else {
      toast({
        title: "Employee not found",
        status: "error",
      });
    }
  }, []);
  // const formRefs = useRef<Array<React.RefObject<any>>>([]);
  // formRefs.current = tabCategories.flatMap((category) =>
  //   category.tabs.map(() => useRef(null))
  // );

  // // Focus on the input field when the selected tab changes
  // useEffect(() => {
  //   const currentRef = formRefs.current[selectedTab];
  //   if (currentRef && currentRef.current) {
  //     currentRef.current.focus();
  //   }
  // }, [selectedTab]);
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [selectedTab]);
  const navigate = useNavigate();
  return (
    <Stack
      ref={scrollRef}
      h="calc(100vh - 70px)"
      overflowY="auto"
      gap={0}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Box>
        <Header
          selectedEmployee={selectedEmployee}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
        {/* <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button> */}
        <Container maxW="100%" mt="10px" padding={0}>
          <Box
            paddingLeft={"1px"}
            paddingRight={"20px"}
            borderRight={"1px solid #e5e7eb"}
          >
            <Tabs
              index={selectedTab}
              orientation={isLargerThanMD ? "vertical" : "horizontal"}
              onChange={setSelectedTab}
              isLazy
              align={isLargerThanMD ? "start" : "center"}
            >
              {isLargerThanMD ? (
                <Stack
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "customColor.white",
                  }}
                  w="30%"
                  // borderWidth="1px"
                  // borderColor="gray.200"
                  marginBottom={"1.25rem"}
                  boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
                  borderWidth="1px"
                  borderStyle={"solid"}
                  borderColor="#f6f6f6"
                  padding={"15px"}
                  gap={0}
                >
                  <Icon
                    as={IoIosArrowBack}
                    borderRadius={"full"}
                    width={"30px"}
                    height={"30px"}
                    alignItems="center"
                    justifyContent="center"
                    borderStyle={"solid"}
                    borderWidth={"1px"}
                    borderColor={"borders.tableBorder"}
                    bg={"#fff"}
                    px="5px"
                    py="5px"
                    fontSize="22px"
                    color="#6B7280"
                    _hover={{
                      color: "#3454d1",
                    }}
                    _active={{
                      color: "#3454d1",
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <Center marginTop={"20px"}>
                    <Stack>
                      <Center>
                        <Stack position="relative">
                          <Avatar
                            key={uploadedPhoto}
                            size={"xl"}
                            h={"95px"}
                            w={"95px"}
                            objectFit="fill"
                            name={
                              form.watch("firstName") +
                              " " +
                              form.watch("lastName")
                            }
                            src={`${form.watch(
                              "photo"
                            )}?updated=${uploadedPhoto}`}
                            // Other props
                            borderWidth={"2px"}
                            borderColor={"gray.200"}
                          />
                          {(photoLoading || loading) && (
                            <Center
                              position="absolute"
                              top="0"
                              right="0"
                              bottom="0"
                              left="0"
                              backgroundColor="rgba(255, 255, 255, 0.8)" // Optional: semi-transparent overlay
                            >
                              <Spinner size="lg" />
                            </Center>
                          )}
                          {/* Other components */}
                        </Stack>
                      </Center>

                      <Button
                        backgroundColor={"transparent"}
                        borderRadius={"3px"}
                        border={"1px solid #dcdee4"}
                        fontSize={"13px"}
                        variant={"solid"}
                        textColor={"customColor.black7"}
                        padding={"12px 16px"}
                        fontWeight={600}
                        _hover={{
                          backgroundColor: "#fff",
                          color: "#3454D1",
                        }}
                        onClick={() => {
                          if (inputRef.current) {
                            inputRef.current.click();
                          }
                        }}
                      >
                        Upload photo
                      </Button>
                      <input
                        type="file"
                        ref={inputRef}
                        onChange={(e) => {
                          if (e.target.files) {
                            const file = e.target.files[0];
                            const formData = new FormData();
                            formData.append("docType", "PROFILE_PICTURE");
                            formData.append("file", file);
                            formData.append("empId", id!);
                            setPhotoLoading(true);
                            UploadAttachments.UploadAttachment(
                              formData,
                              (success) => {
                                form.setValue("photo", success.url);
                                setUploadPhoto(Date.now());
                                setPhotoLoading(false);
                              },
                              (err) => {
                                toast({
                                  title: "Error uploading photo",
                                  status: "error",
                                });
                                setPhotoLoading(false);
                              }
                            );
                          }
                          e.target.value = "";
                        }}
                        style={{ display: "none" }}
                      />
                    </Stack>
                  </Center>
                  <Divider
                    borderColor="gray.200"
                    marginTop={"24px"}
                    size={"md"}
                  />
                  {/* <Box
                  position="sticky"
                  top="10px"
                  // zIndex="sticky"
                  _dark={{
        bgColor: "customColor.dark.50",
      }}
      _light={{
        bgColor: "customColor.white",
      }}
                > */}
                  {tabCategories.map((category, i) => (
                    <Box key={i}>
                      <Text
                        fontSize={"14.4px"}
                        fontWeight={"bold"}
                        _light={{
                          // color: "customColor.black7",
                          color: "#E97132",
                        }}
                        margin={"24px 0px 8px"}
                      >
                        {category.categoryName}
                      </Text>
                      {category.tabs.map((tab, j) => (
                        <Tab
                          marginLeft="0px"
                          justifyContent="flex-start"
                          w="100%"
                          p={"5px 15px"}
                          key={j}
                          marginBottom={"5px"}
                          fontSize="13px"
                          borderRadius={"3px"}
                          cursor="pointer"
                          color="#74788D"
                          _light={{
                            color: "customColor.black7",
                          }}
                          _selected={{
                            bgColor: "#EAEBEF",
                            color: "customColor.black8",
                            borderRadius: "5px",
                            transition: "all .3s ease",
                          }}
                          _hover={{
                            bgColor: "#EAEBEF",
                            color: "customColor.black8",
                            borderRadius: "5px",
                            transition: "all .3s ease",
                          }}
                          onClick={() => setSelectedTab(tab.index)}
                        >
                          <Stack
                            direction={"row"}
                            gap={0}
                            alignItems={"center"}
                            _hover={{
                              color: "customColor.black8",
                            }}
                          >
                            <Button
                              as={IconButton}
                              color={
                                selectedTab === tab.index
                                  ? "#001327"
                                  : "#283C50"
                              }
                              _hover={{
                                bg: "transparent",
                              }}
                              icon={tab.iconName}
                              variant={"ghost"}
                              padding={"0px"}
                            />
                            {/* <Icon as={tab.iconName} /> */}
                            <Text
                              fontSize={"13px"}
                              fontWeight={"600"}
                              _hover={{
                                color: "customColor.black8",
                              }}
                            >
                              {tab.label}
                            </Text>
                          </Stack>
                        </Tab>
                      ))}
                      <Divider />
                    </Box>
                  ))}
                  {/* </Box> */}
                </Stack>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<AiOutlineCaretDown />}
                      backgroundColor={"#fff"}
                      borderRadius={"3px"}
                      border={"1px solid #dcdee4"}
                      fontSize={"13px"}
                      h={"40px"}
                      variant={"solid"}
                      textColor={"customColor.black7"}
                      padding={"12px 16px"}
                      fontWeight={600}
                      _hover={{
                        color: "primary.950",
                        backgroundColor: "#fff",
                      }}
                      cursor={"pointer"}
                      _active={{
                        backgroundColor: "#fff",
                        color: "primary.950",
                      }}
                    >
                      {tabCategories
                        .map((tabCategory) =>
                          tabCategory.tabs.find(
                            (tab) => tab.index === selectedTab
                          )
                        )
                        .filter(Boolean)
                        .map((tab: any) => tab.label)}
                    </MenuButton>
                    <MenuList
                      zIndex={"overlay"}
                      maxH={"300px"}
                      overflowX={"scroll"}
                    >
                      {tabCategories.map((tabCategory, i) => (
                        <MenuGroup
                          key={i}
                          title={tabCategory.categoryName}
                          padding={"6px 12px"}
                          margin={0}
                        >
                          {tabCategory.tabs.map((tab, j) => (
                            <MenuItem
                              key={j}
                              fontSize={"13.44px"}
                              onClick={() => {
                                setSelectedTab(tab.index);
                              }}
                            >
                              {tab.label}
                            </MenuItem>
                          ))}
                          <Divider />
                        </MenuGroup>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              )}
              <Stack padding={"10px"}></Stack>
              <TabPanels overflowX={"auto"}>
                <TabPanel p={0}>
                  <Details
                    navigate={navigate}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[0]}
                  />
                </TabPanel>

                <TabPanel p={0}>
                  <Education
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[1]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Experience
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[2]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <References
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[3]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Dependents
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[4]}
                  />
                </TabPanel>

                <TabPanel p={0}>
                  <NextOfKin
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[5]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <EmergencyContact
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[6]}
                  />
                </TabPanel>

                <TabPanel p={0}>
                  <StatutoryBenefits
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[7]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  {" "}
                  <MedicalBenefits
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[8]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <LeaveAdded
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[9]}
                    payFrequencyAnnually={
                      selectedEmployee?.payFrequencyAnnually
                    }
                  />
                </TabPanel>
                {/* <TabPanel p={0} >
                <OpeningBal />
              </TabPanel> */}

                <TabPanel p={0}>
                  <DocumentUpload
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[10]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Notes
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Employment
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[11]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Paytemp
                    navigate={navigate}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[14]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <BankAccount
                    navigate={navigate}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[12]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Taxes
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[13]}
                  />
                </TabPanel>

                <TabPanel p={0}>
                  <Payslips
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    // ref={formRefs.current[15]}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <EmployeeSeparation navigate={navigate} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Container>
      </Box>
    </Stack>
  );
}
