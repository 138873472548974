import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomInput } from "../../../../Common/CustomInput";
import { PayRun } from "../../../Api/PayRun";
import { PayslipsModule } from "../../../Api/PaySlips";
import { HeaderPostedPayslip } from "./HeaderPostedPayslip";

export default function PostedPayslip() {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [paySlipData, setPaySlipData] = useState<PayRun.PayrunByEmpId>();
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useToast();
  const [queryParams, setQueryParams] = useSearchParams();
  let payslipId = queryParams.get("payslipId");
  useEffect(() => {
    if (payslipId) {
      PayRun.getPayItemByEmpId(
        payslipId,
        (success) => {
          setPaySlipData(success);
          setIsLoading(false);
        },
        (err) => {
          toast({
            title: "Error",
            status: "error",
            description: "Pay Item Fetch Failed",
          });
          setIsLoading(false);
        }
      );
    }
  }, []);
  const navigate = useNavigate();
  const totalNetPay =
    (paySlipData?.totalEarnings ?? 0) -
    (paySlipData?.totalDeductions ?? 0) +
    (paySlipData?.totalReimbursements ?? 0) -
    (paySlipData?.totalTax ?? 0);
  return (
    <Box
      paddingBottom={"50px"}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Container
        h="64px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        maxW={"100%"}
        padding={"0px 20px"}
        justifyContent={"center"}
      >
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          paddingTop={"5px"}
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/app/payroll/PayEmployee"
              fontSize={"11px"}
              color={"#3454d1"}
            >
              Pay Employee
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              href=""
              fontSize={"11px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              padding={"0px 20px 0px 0px"}
            >
              {`${paySlipData?.payRun?.payrollCalendar?.name} ending ${moment(
                paySlipData?.payRun?.endDate
              ).format("D MMM YYYY")}`}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading
          fontWeight={"bold"}
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.white",
          }}
          fontSize={"24px"}
          margin={"0px 4px 0px 0px"}
        >
          {`${paySlipData?.payRun?.payrollCalendar?.name} ending ${moment(
            paySlipData?.payRun?.endDate
          ).format("D MMM YYYY")}`}
        </Heading>
      </Container>
      <HeaderPostedPayslip
        totalEarnings={paySlipData?.totalEarnings ?? 0}
        totalTaxs={paySlipData?.totalTax ?? 0}
        totalDeductions={paySlipData?.totalDeductions ?? 0}
        totalReimbursement={paySlipData?.totalReimbursements ?? 0}
        totalNetPay={totalNetPay}
      />

      <Stack padding="20px 20px 0px 20px">
        <Container maxW={"100%"} padding={"0px"}>
          <Stack
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#FBFBFB",
            }}
            padding={"0px 10px"}
            margin={"0px 0px 30px"}
            border={"1px solid #e5e5e5"}
            direction={"row"}
            gap={0}
          >
            <Stack
              padding={"15px 0px"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              gap={0}
              flex={2}
            >
              <Stack w={"80px"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={"link"}
                    textAlign={"left"}
                    fontSize={"12px"}
                    padding={"0px 10px 0px 0px"}
                    rightIcon={<IoMdArrowDropdown />}
                    color={"primary.950"}
                    _hover={{
                      color: "#003c64",
                      textDecoration: "underline",
                    }}
                    _active={{
                      color: "#003c64",
                      textDecoration: "underline",
                    }}
                  >
                    Employees
                  </MenuButton>
                  <MenuList padding={0} maxW={"120px"}></MenuList>
                </Menu>
              </Stack>

              <Text
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.pearlWhite",
                }}
                fontSize={"24px"}
                fontWeight={"600"}
              >
                {paySlipData?.employee?.firstName}{" "}
                {paySlipData?.employee?.lastName}
              </Text>
            </Stack>
            <Stack
              gap={0}
              padding={"15px 0px 15px 15px"}
              textAlign={"left"}
              flex={1}
              borderLeft={"1px solid #E5E5E5"}
            >
              <Text
                color={"#999999"}
                margin={"0px 0px 4px"}
                padding={"1px 0px 0px"}
                fontSize={"12px"}
                fontWeight={"bold"}
              >
                Employee's Net Pay
              </Text>
              <HStack gap={0} alignItems={"baseline"}>
                <Text
                  fontSize={"24px"}
                  textAlign={"right"}
                  _light={{ color: "customColor.black4" }}
                  _dark={{ color: "customColor.white" }}
                  fontWeight={"bold"}
                >
                  {totalNetPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </HStack>
            </Stack>
          </Stack>
          <Stack spacing="20px">
            <Container
              maxW={"100%"}
              _dark={{
                bgColor: "customColor.dark.100",
              }}
              _light={{
                bgColor: "customColor.gray.400",
              }}
              padding={"0px"}
            >
              <Stack spacing="20px">
                {/* Earnings */}
                {paySlipData?.earnings && paySlipData?.earnings?.length > 0 && (
                  <Container
                    maxW={"100%"}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "#FBFBFB",
                    }}
                    border={"1px solid #e5e5e5"}
                    padding={"15px 10px 20px"}
                  >
                    {paySlipData?.earnings?.map(
                      (earning: PayRun.PayrunItem) => (
                        <Stack>
                          {earning?.payItemEarning?.rateType ===
                          "GROSS_SALARY" ? (
                            <Stack>
                              <HStack w={"100%"}>
                                <Box w={"100%"}>
                                  <Text
                                    flex={1}
                                    color={"#999999"}
                                    _dark={{
                                      color: "customColor.pearlWhite",
                                    }}
                                    fontSize={"12px"}
                                    fontWeight={"bold"}
                                    marginBottom={"4px"}
                                  >
                                    Earnings Rate
                                  </Text>

                                  <Text
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.pearlWhite",
                                    }}
                                    fontSize={"12px"}
                                    fontWeight={"bold"}
                                  >
                                    Base Salary
                                  </Text>
                                </Box>
                                {earning?.hours && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "number",
                                        value: earning.hours,
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "12px 15px",
                                        boxShadow: "none",
                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label="Unit"
                                      labelProps={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}

                                {earning?.rate && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "number",
                                        value: Number(
                                          (
                                            ((earning.rate ?? 0) / 100) *
                                            (earning?.payItemEarning
                                              ?.baseSalaryPercentage ?? 0)
                                          ).toFixed(2)
                                        ),
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "0px 0px 0px 3px",
                                        boxShadow: "none",
                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label="Rate"
                                      labelProps={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}
                                {earning?.amount && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "text",
                                        value: earning.amount
                                          ? (
                                              ((earning.amount ?? 0) / 100) *
                                              (earning?.payItemEarning
                                                ?.baseSalaryPercentage ?? 0)
                                            )
                                              .toFixed(2)
                                              .toLocaleString()
                                          : "",

                                        textAlign: "right",
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "0px 0px 0px 3px",
                                        boxShadow: "none",

                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label={
                                        earning?.hours && earning?.rate
                                          ? "Total"
                                          : "Fixed Amount"
                                      }
                                      labelProps={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}
                              </HStack>
                              <HStack w={"100%"}>
                                <Box w={"100%"}>
                                  <Text
                                    flex={1}
                                    color={"#999999"}
                                    _dark={{
                                      color: "customColor.pearlWhite",
                                    }}
                                    fontSize={"12px"}
                                    fontWeight={"bold"}
                                    marginBottom={"4px"}
                                  >
                                    Earnings Rate
                                  </Text>

                                  <Text
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.pearlWhite",
                                    }}
                                    fontSize={"12px"}
                                    fontWeight={"bold"}
                                  >
                                    Allowance
                                  </Text>
                                </Box>
                                {earning?.hours && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "number",
                                        value: earning.hours,
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "12px 15px",
                                        boxShadow: "none",
                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label="Unit"
                                      labelProps={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}

                                {earning?.rate && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "number",
                                        value: Number(
                                          (
                                            ((earning.rate ?? 0) / 100) *
                                            (earning?.payItemEarning
                                              ?.allowancesPercentage ?? 0)
                                          ).toFixed(2)
                                        ),
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "0px 0px 0px 3px",
                                        boxShadow: "none",
                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label="Rate"
                                      labelProps={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}
                                {earning?.amount && (
                                  <Flex minW="92px" maxW="92px">
                                    <CustomInput
                                      withValidation
                                      input={{
                                        fontSize: "12px",
                                        variant: "outline",
                                        type: "text",
                                        value: earning.amount
                                          ? (
                                              ((earning.amount ?? 0) / 100) *
                                              (earning?.payItemEarning
                                                ?.allowancesPercentage ?? 0)
                                            )
                                              .toFixed(2)
                                              .toLocaleString()
                                          : "",

                                        textAlign: "right",
                                        borderTop: "1px solid #ccc !important",
                                        borderBottom:
                                          "1px solid #ccc !important",
                                        borderWidth: "none",
                                        borderStyle: "none",
                                        borderColor: "none",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        height: "26.78px",
                                        isReadOnly: true,
                                        padding: "0px 0px 0px 3px",
                                        boxShadow: "none",

                                        placeholder: "",
                                        _hover: {
                                          borderColor:
                                            "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                        },
                                      }}
                                      label={
                                        earning?.hours && earning?.rate
                                          ? "Total"
                                          : "Fixed Amount"
                                      }
                                      labelProps={{
                                        textAlign: "right",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        _light: {
                                          color: "customColor.black7",
                                        },
                                        margin: "0px 0px 4px",
                                      }}
                                    />
                                  </Flex>
                                )}
                              </HStack>
                            </Stack>
                          ) : (
                            <HStack w={"100%"}>
                              <Box w={"100%"}>
                                <Text
                                  flex={1}
                                  color={"#999999"}
                                  _dark={{
                                    color: "customColor.pearlWhite",
                                  }}
                                  fontSize={"12px"}
                                  fontWeight={"bold"}
                                  marginBottom={"4px"}
                                >
                                  {!!earning?.payItemEarningId
                                    ? "Earnings Rate"
                                    : "Leaves Type"}
                                </Text>

                                <Text
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.pearlWhite",
                                  }}
                                  fontSize={"12px"}
                                  fontWeight={"bold"}
                                >
                                  {!!earning?.payItemEarningId
                                    ? earning?.payItemEarning?.name
                                    : earning?.payItemLeave?.name}
                                </Text>
                              </Box>
                              {earning?.hours && (
                                <Flex minW="92px" maxW="92px">
                                  <CustomInput
                                    withValidation
                                    input={{
                                      fontSize: "12px",
                                      variant: "outline",
                                      type: "number",
                                      value: earning.hours,
                                      borderTop: "1px solid #ccc !important",
                                      borderBottom: "1px solid #ccc !important",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      borderWidth: "none",
                                      borderStyle: "none",
                                      borderColor: "none",
                                      height: "26.78px",
                                      isReadOnly: true,
                                      padding: "12px 15px",
                                      boxShadow: "none",
                                      placeholder: "",
                                      _hover: {
                                        borderColor:
                                          "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                      },
                                    }}
                                    label="Unit"
                                    labelProps={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      margin: "0px 0px 4px",
                                    }}
                                  />
                                </Flex>
                              )}

                              {earning?.rate && (
                                <Flex minW="92px" maxW="92px">
                                  <CustomInput
                                    withValidation
                                    input={{
                                      textAlign: "right",
                                      fontSize: "12px",
                                      variant: "outline",
                                      type: "number",
                                      value: earning.rate,
                                      borderTop: "1px solid #ccc !important",
                                      borderBottom: "1px solid #ccc !important",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      borderWidth: "none",
                                      borderStyle: "none",
                                      borderColor: "none",
                                      height: "26.78px",
                                      isReadOnly: true,
                                      padding: "0px 0px 0px 3px",
                                      boxShadow: "none",
                                      placeholder: "",
                                      _hover: {
                                        borderColor:
                                          "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                      },
                                    }}
                                    label="Rate"
                                    labelProps={{
                                      textAlign: "right",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      margin: "0px 0px 4px",
                                    }}
                                  />
                                </Flex>
                              )}
                              {earning?.amount && (
                                <Flex minW="92px" maxW="92px">
                                  <CustomInput
                                    withValidation
                                    input={{
                                      fontSize: "12px",
                                      variant: "outline",
                                      type: "text",
                                      value: earning.amount.toLocaleString()
                                        ? earning.amount.toLocaleString()
                                        : "",
                                      // ? earning.amount.toLocaleString()
                                      textAlign: "right",
                                      borderTop: "1px solid #ccc !important",
                                      borderBottom: "1px solid #ccc !important",
                                      borderWidth: "none",
                                      borderStyle: "none",
                                      borderColor: "none",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      height: "26.78px",
                                      isReadOnly: true,
                                      padding: "0px 0px 0px 3px",
                                      boxShadow: "none",

                                      placeholder: "",
                                      _hover: {
                                        borderColor:
                                          "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                      },
                                    }}
                                    label={
                                      earning?.hours && earning?.rate
                                        ? "Total"
                                        : "Fixed Amount"
                                    }
                                    labelProps={{
                                      textAlign: "right",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      _light: {
                                        color: "customColor.black7",
                                      },
                                      margin: "0px 0px 4px",
                                    }}
                                  />
                                </Flex>
                              )}
                            </HStack>
                          )}
                        </Stack>
                      )
                    )}
                    <HStack
                      w={"100%"}
                      display="flex"
                      justifyContent="flex-end"
                      marginTop="10px"
                    >
                      <Box
                        w="250px"
                        borderTop="1px solid #ccc"
                        borderBottom={"1px solid #ccc"}
                        padding="4px 0px 3px 80px"
                      >
                        <Text
                          fontSize="20px"
                          fontWeight="bold"
                          textAlign={"right"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          {paySlipData?.totalEarnings
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </Box>
                    </HStack>
                  </Container>
                )}

                {/* Deductions */}
                {paySlipData?.deductions &&
                  paySlipData?.deductions?.length > 0 && (
                    <Container
                      maxW={"100%"}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "#FBFBFB",
                      }}
                      border={"1px solid #e5e5e5"}
                      padding={"15px 10px 20px"}
                    >
                      {paySlipData?.deductions?.map(
                        (deduction: PayRun.PayrunItem) => (
                          <HStack w={"100%"}>
                            <Box w={"100%"}>
                              <Text
                                flex={1}
                                color={"#999999"}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"12px"}
                                fontWeight={"bold"}
                                marginBottom={"4px"}
                              >
                                Deduction Type
                              </Text>
                              <Text>
                                <Text
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.pearlWhite",
                                  }}
                                  fontSize={"12px"}
                                  fontWeight={"bold"}
                                >
                                  {deduction?.payItemDeduction?.name}
                                </Text>
                              </Text>
                            </Box>
                            {deduction?.percentage && (
                              <Flex minW="92px" maxW="92px">
                                <CustomInput
                                  withValidation
                                  input={{
                                    fontSize: "12px",
                                    textAlign: "right",
                                    variant: "outline",
                                    type: "number",
                                    value: `${deduction.percentage}`,
                                    borderTop: "1px solid #ccc !important",

                                    borderBottom: "1px solid #ccc !important",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    borderWidth: "none",
                                    borderStyle: "none",
                                    borderColor: "none",
                                    height: "26.78px",
                                    isReadOnly: true,
                                    padding: "0px 0px 0px 3px",
                                    boxShadow: "none",
                                    placeholder: "",
                                    _hover: {
                                      borderColor:
                                        "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                    },
                                  }}
                                  label="Percentage"
                                  labelProps={{
                                    fontSize: "12px",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    margin: "0px 0px 4px",
                                  }}
                                />
                              </Flex>
                            )}

                            {deduction?.amount && (
                              <Flex minW="92px" maxW="92px">
                                <CustomInput
                                  withValidation
                                  input={{
                                    fontSize: "12px",
                                    textAlign: "right",
                                    variant: "outline",
                                    type: "text",
                                    value: deduction.amount.toLocaleString(),
                                    borderTop: "1px solid #ccc !important",
                                    borderBottom: "1px solid #ccc !important",
                                    _light: {
                                      color: "customColor.black7",
                                    },

                                    borderWidth: "none",
                                    borderStyle: "none",
                                    borderColor: "none",
                                    height: "26.78px",
                                    isReadOnly: true,
                                    padding: "0px 0px 0px 3px",
                                    boxShadow: "none",
                                    placeholder: "",
                                    _hover: {
                                      borderColor:
                                        "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                    },
                                  }}
                                  label="Amount"
                                  labelProps={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    margin: "0px 0px 4px",
                                  }}
                                />
                              </Flex>
                            )}
                          </HStack>
                        )
                      )}
                      <HStack
                        w={"100%"}
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="10px"
                      >
                        <Box
                          w="250px"
                          borderTop="1px solid #ccc"
                          borderBottom={"1px solid #ccc"}
                          padding="4px 0px 3px 80px"
                        >
                          <Text
                            fontSize="20px"
                            fontWeight="bold"
                            textAlign={"right"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {paySlipData?.totalDeductions
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Text>
                        </Box>
                      </HStack>
                    </Container>
                  )}

                {/* Taxes */}
                {paySlipData?.taxes && paySlipData?.taxes?.length > 0 && (
                  <Container
                    maxW={"100%"}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "#FBFBFB",
                    }}
                    border={"1px solid #e5e5e5"}
                    padding={"15px 10px 20px"}
                  >
                    {paySlipData?.taxes?.map((tax: PayRun.PayrunItem) => (
                      <HStack w={"100%"}>
                        <Box w={"100%"}>
                          <Text
                            flex={1}
                            color={"#999999"}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                            fontSize={"12px"}
                            fontWeight={"bold"}
                            marginBottom={"4px"}
                          >
                            Tax Type
                          </Text>
                          <Text>
                            <Text
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.pearlWhite",
                              }}
                              fontSize={"12px"}
                              fontWeight={"bold"}
                            >
                              Tax
                            </Text>
                          </Text>
                        </Box>

                        <Flex minW="92px" maxW="92px">
                          <CustomInput
                            withValidation
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "text",
                              value: tax?.amount?.toLocaleString() ?? "0",
                              borderTop: "1px solid #ccc !important",
                              borderBottom: "1px solid #ccc !important",
                              _light: {
                                color: "customColor.black7",
                              },
                              textAlign: "right",
                              borderWidth: "none",
                              borderStyle: "none",
                              borderColor: "none",
                              height: "26.78px",
                              isReadOnly: true,
                              padding: "0px 0px 0px 3px",
                              boxShadow: "none",
                              placeholder: "",
                              _hover: {
                                borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                              },
                            }}
                            label="Amount"
                            labelProps={{
                              fontSize: "12px",
                              textAlign: "right",
                              fontWeight: "bold",
                              _light: {
                                color: "customColor.black7",
                              },
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Flex>
                      </HStack>
                    ))}
                    <HStack
                      w={"100%"}
                      display="flex"
                      justifyContent="flex-end"
                      marginTop="10px"
                    >
                      <Box
                        w="250px"
                        borderTop="1px solid #ccc"
                        borderBottom={"1px solid #ccc"}
                        padding="4px 0px 3px 80px"
                      >
                        <Text
                          fontSize="20px"
                          fontWeight="bold"
                          textAlign={"right"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          {paySlipData?.totalTax
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </Box>
                    </HStack>
                  </Container>
                )}

                {/* Reimbursement */}
                {paySlipData?.reimbursments &&
                  paySlipData?.reimbursments?.length > 0 && (
                    <Container
                      maxW={"100%"}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "#FBFBFB",
                      }}
                      border={"1px solid #e5e5e5"}
                      padding={"15px 10px 20px"}
                    >
                      {paySlipData?.reimbursments?.map(
                        (reimbursement: PayRun.PayrunItem) => (
                          <HStack w={"100%"}>
                            <Box w={"100%"}>
                              <Text
                                flex={1}
                                color={"#999999"}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"12px"}
                                fontWeight={"bold"}
                                marginBottom={"4px"}
                              >
                                Reimbursement Type
                              </Text>
                              <Text>
                                <Text
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.pearlWhite",
                                  }}
                                  fontSize={"12px"}
                                  fontWeight={"bold"}
                                >
                                  {reimbursement?.payItemReimbursement?.name}
                                </Text>
                              </Text>
                            </Box>
                            {reimbursement?.amount && (
                              <Flex minW="92px" maxW="92px">
                                <CustomInput
                                  withValidation
                                  input={{
                                    fontSize: "12px",
                                    variant: "outline",
                                    type: "text",
                                    value:
                                      reimbursement?.amount.toLocaleString(),
                                    borderTop: "1px solid #ccc !important",
                                    borderBottom: "1px solid #ccc !important",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    textAlign: "right",
                                    borderWidth: "none",
                                    borderStyle: "none",
                                    borderColor: "none",
                                    height: "26.78px",
                                    isReadOnly: true,
                                    padding: "0px 0px 0px 3px",
                                    boxShadow: "none",
                                    placeholder: "",
                                    _hover: {
                                      borderColor:
                                        "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                    },
                                  }}
                                  label="Amount"
                                  labelProps={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    margin: "0px 0px 4px",
                                  }}
                                />
                              </Flex>
                            )}
                          </HStack>
                        )
                      )}
                      <HStack
                        w={"100%"}
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="10px"
                      >
                        <Box
                          w="250px"
                          borderTop="1px solid #ccc"
                          borderBottom={"1px solid #ccc"}
                          padding="4px 0px 3px 80px"
                        >
                          <Text
                            fontSize="20px"
                            fontWeight="bold"
                            textAlign={"right"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {paySlipData?.totalReimbursements
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Text>
                        </Box>
                      </HStack>
                    </Container>
                  )}

                {/* Statutory Benefits */}
                {paySlipData?.retirements &&
                  paySlipData?.retirements?.length > 0 && (
                    <Container
                      maxW={"100%"}
                      _dark={{
                        bgColor: "customColor.dark.50",
                      }}
                      _light={{
                        bgColor: "#FBFBFB",
                      }}
                      border={"1px solid #e5e5e5"}
                      padding={"15px 10px 20px"}
                    >
                      {paySlipData?.retirements?.map(
                        (retirements: PayRun.PayrunItem) => (
                          <HStack w={"100%"}>
                            <Box w={"100%"}>
                              <Text
                                flex={1}
                                color={"#999999"}
                                _dark={{
                                  color: "customColor.pearlWhite",
                                }}
                                fontSize={"12px"}
                                fontWeight={"bold"}
                                marginBottom={"4px"}
                              >
                                Statutory Benefit
                              </Text>
                              <Text>
                                <Text
                                  _light={{
                                    color: "customColor.black7",
                                  }}
                                  _dark={{
                                    color: "customColor.pearlWhite",
                                  }}
                                  fontSize={"12px"}
                                  fontWeight={"bold"}
                                >
                                  {retirements?.payItemRetirement?.name}
                                </Text>
                              </Text>
                            </Box>
                            {retirements?.percentage && (
                              <Flex minW="92px" maxW="92px">
                                <CustomInput
                                  withValidation
                                  input={{
                                    fontSize: "12px",
                                    variant: "outline",
                                    type: "number",
                                    value: `${retirements.percentage}`,
                                    borderTop: "1px solid #ccc !important",
                                    borderBottom: "1px solid #ccc !important",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    borderWidth: "none",
                                    textAlign: "right",
                                    borderStyle: "none",
                                    borderColor: "none",
                                    height: "26.78px",
                                    isReadOnly: true,
                                    padding: "0px 0px 0px 3px",
                                    boxShadow: "none",
                                    placeholder: "",
                                    _hover: {
                                      borderColor:
                                        "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                    },
                                  }}
                                  label="Percentage"
                                  labelProps={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    margin: "0px 0px 4px",
                                  }}
                                />
                              </Flex>
                            )}

                            {retirements?.amount && (
                              <Flex minW="92px" maxW="92px">
                                <CustomInput
                                  withValidation
                                  input={{
                                    fontSize: "12px",
                                    variant: "outline",
                                    type: "text",
                                    value: retirements.amount.toLocaleString(),
                                    borderTop: "1px solid #ccc !important",
                                    borderBottom: "1px solid #ccc !important",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    textAlign: "right",
                                    borderWidth: "none",
                                    borderStyle: "none",
                                    borderColor: "none",
                                    height: "26.78px",
                                    isReadOnly: true,
                                    padding: "0px 0px 0px 3px",
                                    boxShadow: "none",
                                    placeholder: "",
                                    _hover: {
                                      borderColor:
                                        "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                                    },
                                  }}
                                  label="Amount"
                                  labelProps={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    _light: {
                                      color: "customColor.black7",
                                    },
                                    margin: "0px 0px 4px",
                                  }}
                                />
                              </Flex>
                            )}
                          </HStack>
                        )
                      )}
                      <HStack
                        w={"100%"}
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="10px"
                      >
                        <Box
                          w="250px"
                          borderTop="1px solid #ccc"
                          borderBottom={"1px solid #ccc"}
                          padding="4px 0px 3px 80px"
                        >
                          <Text
                            fontSize="20px"
                            fontWeight="bold"
                            textAlign={"right"}
                            _light={{
                              color: "customColor.black7",
                            }}
                          >
                            {paySlipData?.totalRetirements
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Text>
                        </Box>
                      </HStack>
                    </Container>
                  )}

                {/* Account */}
                <Container
                  maxW={"100%"}
                  _dark={{
                    bgColor: "customColor.dark.50",
                  }}
                  _light={{
                    bgColor: "#FBFBFB",
                  }}
                  border={"1px solid #e5e5e5"}
                  padding={"15px 10px 20px"}
                >
                  <HStack w={"100%"}>
                    <Box w={"100%"}>
                      <Text
                        flex={1}
                        color={"#999999"}
                        _dark={{
                          color: "customColor.pearlWhite",
                        }}
                        fontSize={"12px"}
                        fontWeight={"bold"}
                        marginBottom={"4px"}
                      >
                        Total Payment for Employee
                      </Text>

                      {paySlipData?.bankDetails?.accountName &&
                      paySlipData?.bankDetails?.iban ? (
                        <Text
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.pearlWhite",
                          }}
                          fontSize={"12px"}
                          fontWeight={"bold"}
                        >
                          {`${paySlipData?.bankDetails?.accountName} (${paySlipData?.bankDetails?.iban})`}
                        </Text>
                      ) : null}
                    </Box>

                    <Flex w="250px">
                      <CustomInput
                        withValidation
                        input={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          variant: "outline",
                          textAlign: "right",
                          type: "text",
                          value: totalNetPay.toLocaleString(),
                          //                            .toLocaleString()

                          // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          borderTop: "1px solid #ccc !important",
                          borderBottom: "1px solid #ccc !important",
                          _light: {
                            color: "customColor.black7",
                          },
                          borderWidth: "none",
                          borderStyle: "none",
                          borderColor: "none",
                          w: "250px",
                          isReadOnly: true,
                          padding: "4px 0px 3px 80px",
                          boxShadow: "none",
                          placeholder: "",
                          _hover: {
                            borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                          },
                        }}
                        label="Total Net pay"
                        labelProps={{
                          textAlign: "right",
                          fontSize: "20px",
                          fontWeight: "bold",
                          _light: {
                            color: "customColor.black7",
                          },
                          margin: "0px 0px 4px",
                        }}
                      />
                    </Flex>
                  </HStack>
                </Container>
                {/* Leaves */}
                {paySlipData?.leaves && paySlipData?.leaves?.length > 0 && (
                  <Container
                    maxW={"100%"}
                    _dark={{
                      bgColor: "customColor.dark.50",
                    }}
                    _light={{
                      bgColor: "#FBFBFB",
                    }}
                    border={"1px solid #e5e5e5"}
                    padding={"15px 10px 20px"}
                  >
                    {paySlipData?.leaves?.map((leave: PayRun.PayrunItem) => (
                      <HStack w={"100%"}>
                        <Box w={"100%"}>
                          <Text
                            flex={1}
                            color={"#999999"}
                            _dark={{
                              color: "customColor.pearlWhite",
                            }}
                            fontSize={"12px"}
                            fontWeight={"bold"}
                            marginBottom={"4px"}
                          >
                            Leave Type
                          </Text>
                          <Text>
                            <Text
                              _light={{
                                color: "customColor.black7",
                              }}
                              _dark={{
                                color: "customColor.pearlWhite",
                              }}
                              fontSize={"12px"}
                              fontWeight={"bold"}
                            >
                              {leave?.payItemLeave?.name}
                            </Text>
                          </Text>
                        </Box>

                        <Flex minW="92px" maxW="92px">
                          <CustomInput
                            withValidation
                            input={{
                              fontSize: "12px",
                              variant: "outline",
                              type: "number",
                              value: leave?.hours,
                              borderTop: "1px solid #ccc !important",
                              borderBottom: "1px solid #ccc !important",
                              _light: {
                                color: "customColor.black7",
                              },
                              borderWidth: "none",
                              textAlign: "right",
                              borderStyle: "none",
                              borderColor: "none",
                              height: "26.78px",
                              isReadOnly: true,
                              padding: "0px 0px 0px 3px",
                              boxShadow: "none",
                              placeholder: "",
                              _hover: {
                                borderColor: "#b5b8bb #e2e4e7 #e8eaec #bdbfc30",
                              },
                            }}
                            label="Hours"
                            labelProps={{
                              textAlign: "right",
                              fontSize: "12px",
                              fontWeight: "bold",
                              _light: {
                                color: "customColor.black7",
                              },
                              margin: "0px 0px 4px",
                            }}
                          />
                        </Flex>
                      </HStack>
                    ))}
                  </Container>
                )}
              </Stack>
              <HStack
                gap={0}
                marginTop={"30px"}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Button
                  isLoading={isDownloading}
                  margin={"0px 10px 15px 0px"}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    setIsDownloading(true);
                    PayslipsModule.DownloadPayslip(
                      payslipId!,
                      (response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response], { type: "application/pdf" })
                        );
                        window.open(url, "_blank", "noopener,noreferrer,popup");
                        setIsDownloading(false);
                      },
                      (message) => {
                        toast({
                          title: "Error",
                          status: "error",
                          description: message,
                        });
                        setIsDownloading(false);
                      }
                    );
                  }}
                >
                  Print
                </Button>
                <Button
                  margin={"0px 10px 15px 0px"}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Close
                </Button>
              </HStack>
            </Container>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}
