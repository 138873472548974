import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { CustomInput } from "../../../../../../Common/CustomInput";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { PayItemLeaveApi } from "../../../../../Api/PayItemLeave";
interface PaidLeaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedLeaveId: string | undefined;
  deductionPayItemArray: any;
}
export default function PaidLeaveModal({
  isOpen,
  onClose,
  setLastUpdated,
  selectedLeaveId,
  deductionPayItemArray,
}: PaidLeaveModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const leave = [
    { value: "ANNUAL_LEAVE", label: "Annual Leave" },
    { value: "COMMUNITY_SERVICE_LEAVE", label: "Community Service Leave" },
    {
      value: "COMPASSIONATE_BEREAVEMENT_LEAVE",
      label: "Compassionate and Bereavement Leave",
    },
    { value: "DEFENCE_RESERVE_LEAVE", label: "Defense Reserve Leave" },
    {
      value: "FAMILY_AND_DOMESTIC_VIOLENCE_LEAVE",
      label: "Family and Domestic Leave",
    },
    { value: "JURY_DUTY", label: "Jury Duty Leave" },
    { value: "LONG_SERVICE_LEAVE", label: "Long Service Leave" },
    { value: "PERSONAL_CARERS_LEAVE", label: "Personal (Sick/Carer's) Leave" },
    { value: "ROSTERED_DAY_OFF", label: "Rostered Day off" },
    { value: "SPECIAL_PAID_LEAVE", label: "Special Paid Leave" },
    { value: "STUDY_LEAVE", label: "Study Leave" },
    { value: "TIME_OFF_IN_LIEU", label: "Time Off in Lieu" },
  ];
  const form = useFormContext<PayItemLeaveApi.AddNewLeave>();

  return (
    <FormProvider {...form}>
      <Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            maxW="360px"
            maxH={"650px"}
          >
            <ModalHeader
              fontSize={"15.21px"}
              margin={"15.21px 0pxs"}
            ></ModalHeader>
            <ModalCloseButton />
            <Box overflowY="auto">
              <ModalBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={
                    "https://edge.xero.com/people/payroll/assets/images/settings/pay-items/timeoff.svg"
                  }
                />
                <Heading
                  fontSize={"15px"}
                  _light={{
                    color: "customColor.black2",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  margin={"15px 0px"}
                  textAlign={"center"}
                >
                  Paid Leave
                </Heading>
                <Stack
                  border={"1px solid #CCCED2"}
                  borderRadius={"3px"}
                  borderLeft={"4px solid #59606D"}
                  marginTop={"16px"}
                  padding={"14px 16px"}
                  marginBottom={"30px"}
                >
                  <HStack
                    margin={"5px 0px 0px"}
                    fontSize={"13px"}
                    lineHeight={"20px"}
                    alignItems="flex-start"
                  >
                    <Icon as={AiFillInfoCircle} />

                    <Text
                      _light={{
                        color: "customColor.black7",
                      }}
                    >
                      To comply with STP2, assign a leave category to this leave
                      item. The corresponding ATO reporting category will be
                      automatically applied.{" "}
                      <Link
                        _light={{
                          color: "primary.950",
                        }}
                      >
                        Learn more about paid leave categories
                        <Icon as={BsBoxArrowUpRight} />
                      </Link>
                    </Text>
                  </HStack>
                </Stack>
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontWeight={"600"}
                >
                  Leave type
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Leave type is required",
                    },
                  }}
                  control={form.control}
                  name="leaveType"
                  render={({ field, fieldState }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select leave type"
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={fieldState.invalid}
                      ErrorMessage="Select leave type"
                      withValidation
                      options={leave}
                      value={leave.find((leave) => leave.value === field.value)}
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  mb={"5px"}
                >
                  Leave Name
                </Text>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Leave Name is required",
                    },
                  }}
                  control={form.control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  mb={"5px"}
                >
                  Type of Units (e.g days)
                </Text>

                {/* <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Type of Units is required",
                    },
                  }}
                  control={form.control}
                  name="unitType"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                /> */}
                <Controller
                  control={form.control}
                  name="unitType"
                  rules={{
                    required: {
                      value: true,
                      message: "Type of Unit is required",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <ReactSelect
                      {...field}
                      placeholder="Select unit type"
                      // marginBottom="30px"
                      // width={"48em"}
                      isInvalid={error !== undefined}
                      ErrorMessage="Select unit type"
                      withValidation
                      options={[
                        { value: "DAYS", label: "Days" },
                        { value: "HOURS", label: "Hours" },
                      ]}
                      value={
                        field.value && {
                          value: field.value,
                          label: field.value,
                        }
                      }
                      onChange={(newValue: any) =>
                        field.onChange(newValue?.value)
                      }
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                    />
                  )}
                />
                <Text
                  fontSize={"11px"}
                  margin={"5px 0px 0px"}
                  lineHeight={"16px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  The type of units by which leave entitlements are normally
                  tracked. These are typically the same as the type of units
                  used for the employee's ordinary earnings rate.
                </Text>
              </ModalBody>
              <ModalBody>
                <Text
                  fontSize={"13px"}
                  padding={"1px 0px 4px"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Normal Entitlement (optional)
                </Text>
                <Controller
                  control={form.control}
                  name="normalEntitlement"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        fontSize: "15px",
                        variant: "outline",
                        type: "number",
                        onChange: (e) => {
                          field.onChange(Number(e.target.value));
                        },
                        // w:"",
                        mb: "-2px",

                        borderRadius: "5px",
                        padding: "12px 15px",
                        boxShadow: "none",
                        placeholder: "",
                      }}
                    />
                  )}
                />
                <Text
                  fontSize={"11px"}
                  margin={"5px 0px 0px"}
                  lineHeight={"16px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  The number of units the employee is entitled to each year.
                </Text>
                <Box>
                  <Text
                    fontSize={"13px"}
                    padding={"1px 0px 4px"}
                    fontWeight={"600"}
                    _light={{
                      color: "customColor.black7",
                    }}
                  >
                    Maximum Threshold
                  </Text>
                  <Controller
                    control={form.control}
                    name="maxThreshold"
                    rules={{
                      required: {
                        value: true,
                        message: "Max Threshold is required",
                      },
                      validate: (value) => {
                        if (value && value < 0) {
                          return "Max Threshold must be greater than 0";
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          fontSize: "15px",
                          variant: "outline",
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                          // w:"",
                          mb: "-2px",

                          borderRadius: "5px",
                          padding: "12px 15px",
                          boxShadow: "none",
                          placeholder: "",
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mt="10px">
                  <Text
                    fontWeight="600"
                    fontSize=".845rem"
                    margin={"0px 12px 8px 0px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Deduction Pay Item
                  </Text>
                  <Controller
                    control={form.control}
                    name="deductionPayItemId"
                    rules={{
                      required: {
                        value: true,
                        message: "Deduction Pay Item is required",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        placeholder="Select deduction type"
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Select deduction type"
                        withValidation
                        options={deductionPayItemArray}
                        value={deductionPayItemArray.find(
                          (item: any) => item.id === field.value
                        )}
                        onChange={(newValue: any) =>
                          field.onChange(newValue?.id)
                        }
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                      />
                    )}
                  />
                </Box>
              </ModalBody>
              <ModalBody marginTop={"10px"}>
                <Stack>
                  <Controller
                    control={form.control}
                    name="showBalancePayslip"
                    render={({
                      field: { value, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        isChecked={value}
                        isInvalid={error !== undefined}
                        colorScheme="rgb(50,70,90,.35)"
                        margin={"1px 7px 0px 0px"}
                        iconColor={"#000a1e"}
                        // isChecked={value}
                      >
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                        >
                          Show Balance on Payslip
                        </Text>
                      </Checkbox>
                    )}
                  />
                  <Text
                    lineHeight={"16px"}
                    fontSize={"11px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    padding={"0px 0px 15px 15px"}
                  >
                    Check this box if you want a balance for this leave type to
                    be shown on your employee's payslips.
                  </Text>
                </Stack>
              </ModalBody>
            </Box>

            <ModalFooter>
              <Button
                onClick={onClose}
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                h={"40px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
              >
                Cancel
              </Button>
              <Button
                marginLeft={"8px"}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                isLoading={isLoading}
                onClick={(e) => {
                  form.handleSubmit((data) => {
                    setIsLoading(true);
                    if (selectedLeaveId) {
                      PayItemLeaveApi.UpdateLeave(
                        selectedLeaveId,
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is updated",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Update Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    } else {
                      PayItemLeaveApi.NewLeaveAdded(
                        data,
                        (success) => {
                          setLastUpdated(Date.now());
                          toast({
                            title: "Leave is added",

                            status: "success",
                          });
                          setIsLoading(false);
                          onClose();
                        },
                        (error) => {
                          toast({
                            title: "Add Leave Failed",
                            description: error,
                            status: "error",
                          });
                          setIsLoading(false);
                        }
                      );
                    }
                  })(e);
                }}
              >
                {selectedLeaveId ? "Edit" : "Add"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </FormProvider>
  );
}
