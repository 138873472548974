import {
  Button,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Country } from "country-state-city";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import { useBusinessContext } from "../../../../../Hooks/BusinessContext";
import { ChartOfAccounts } from "../../../../Api/ChartOfAccounts";
import { PayItemEarningApi } from "../../../../Api/PayItemEarning";
import AllowanceModal from "./AddMenuModals/AllowanceModal";
import BonusesModal from "./AddMenuModals/BonusesModal";
import { DeleteEarning } from "./AddMenuModals/DeleteEarning";
import DirectorsFeeModal from "./AddMenuModals/DirectorsFeeModal";
import EmploymentTerminationModal from "./AddMenuModals/EmploymentTerminationModal";
import LumpSumEModal from "./AddMenuModals/LumpSumEModal";
import LumpSumWModal from "./AddMenuModals/LumpSumWModal";
import OrdinaryTimeEarningModal from "./AddMenuModals/OrdinaryTimeEarningModal";
import OverTimeEarningModal from "./AddMenuModals/OverTimeEarningModal";
import PaidParentalModal from "./AddMenuModals/PaidParentalLeaveModal";
import WorkersModal from "./AddMenuModals/WorkersModal";
interface EarningProps {
  showInactive: boolean | undefined;
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Earning({
  showInactive,
  setSelectedTab,
  selectedTab,
}: EarningProps) {
  const ordinaryTimeEarningModal = useDisclosure();
  const overTimeEarningModal = useDisclosure();
  const allowanceModal = useDisclosure();
  const employmentTerminationModal = useDisclosure();
  const lumpSumEModal = useDisclosure();
  const bonusesModal = useDisclosure();
  const wLumpSum = useDisclosure();
  const directorsFeeModal = useDisclosure();
  const paidParentalLeaveModal = useDisclosure();
  const workersModal = useDisclosure();
  const deleteModal = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setAccount(res);

        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      },
      "EXPENSES"
    );
  }, []);

  const [lastUpdated, setLastUpdated] = useState<number>();
  const [earningAdded, setEarningAdded] = useState<Array<any>>([]);
  const toast = useToast();
  const [selectedEarningId, setSelectedEarningId] = useState<string>();
  const form = useForm<PayItemEarningApi.AddNewEarning>({
    defaultValues: {
      id: "",
      category: "",
      name: "",
      displayName: "",
      rateType: "",
      fixedAmount: 0,
      chartAccountId: "",
      isTaxable: false,
      active: false,
      retirementFundExcemption: false,
      reportableActivityStatement: false,
      multiple: 0,
      rateUnitType: "",
      unitRate: undefined,
      allowanceType: "",
    },
  });
  const filteredData = showInactive
    ? earningAdded.filter((item) => !item.active)
    : earningAdded.filter((item) => item.active);
  useEffect(() => {
    PayItemEarningApi.FetchEarning(
      (data) => {
        setEarningAdded(data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [lastUpdated]);
  const ratetype = [
    { value: "FIXED_AMOUNT", label: "1: Fixed Amount" },
    {
      value: "MULTIPLE_OF_ORDINARY_EARNINGS",
      label: "2: Multiple of employee's ordinary earnings rate",
    },
    { value: "RATE_PER_UNIT", label: "3: Rate per Unit" },
    { value: "GROSS_SALARY", label: "4: Gross Salary" },
  ];
  let countryData = Country.getAllCountries();
  const { businessesList, currentBusinessId } = useBusinessContext();
  const countryName = businessesList.find(
    (business) => business.id === currentBusinessId
  )?.country;
  const currencyData = countryData.find(
    (country) => country.name === countryName
  )?.currency;
  return (
    <FormProvider {...form}>
      <Skeleton height={"100%"} isLoaded={!isLoading}>
        <Stack color={"#32456A"}>
          <HStack justifyContent="space-between" px={"12px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              _dark={{
                color: "customColor.white",
              }}
              _light={{
                color: "customColor.black7",
              }}
              fontWeight="bold"
              py="20px"
              as="h2"
              size="md"
            >
              Earnings
            </Heading>
            <Menu>
              <Tooltip
                label="Earnings enables users to input or record additional income sources or bonuses for an individual or entity."
                fontSize="sm"
                padding={"10px"}
                bg={"#fff"}
                border={"1px solid #ddd"}
                _light={{
                  color: "customColor.black7",
                }}
                maxW={"300px"}
                placement="auto"
              >
                <MenuButton
                  as={Button}
                  rightIcon={<RiArrowDownSFill fontSize={"20px"} />}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  _active={{
                    bgColor: "#3454D1",
                  }}
                >
                  Add
                </MenuButton>
              </Tooltip>
              <MenuList width={"250px"} maxH={"200px"} overflowX={"auto"}>
                {[
                  {
                    name: "Ordinary Time Earning",
                    onClick: () => {
                      form.reset(FormResetData("ORDINARY_TIME_EARNINGS"));
                      setSelectedEarningId(undefined);
                      ordinaryTimeEarningModal.onOpen();
                    },
                  },
                  {
                    name: "OverTime Earning",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "OVERTIME_EARNINGS",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      overTimeEarningModal.onOpen();
                    },
                  },
                  {
                    name: "Allowance",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "ALLOWANCES",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                        allowanceType: "",
                      });
                      setSelectedEarningId(undefined);
                      allowanceModal.onOpen();
                    },
                  },
                  {
                    name: "Employment Terminations Payment",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "EMPLOYMENT_TERMINATION_PAYMENTS",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,

                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      employmentTerminationModal.onOpen();
                    },
                  },
                  {
                    name: "Lump Sum E",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "LUMP_SUM_E",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      lumpSumEModal.onOpen();
                    },
                  },
                  {
                    name: "Bonuses and Commissions",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "BONUSES_AND_COMMISSIONS",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      bonusesModal.onOpen();
                    },
                  },
                  {
                    name: "Lump Sum W",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "LUMP_SUM_W",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      wLumpSum.onOpen();
                    },
                  },
                  {
                    name: "Directors Fees",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "DIRECTORS_FEES",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      directorsFeeModal.onOpen();
                    },
                  },
                  {
                    name: "Paid Parental Leave",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "PAID_PARENTAL_LEAVE",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      paidParentalLeaveModal.onOpen();
                    },
                  },
                  {
                    name: "Workers Compensation",
                    onClick: () => {
                      form.reset({
                        id: "",
                        category: "WORKERS_COMPENSATION",
                        name: "",
                        displayName: "",
                        rateType: "",
                        fixedAmount: 0,
                        chartAccountId: "",
                        isTaxable: false,
                        retirementFundExcemption: false,
                        reportableActivityStatement: false,
                        multiple: 0,
                        rateUnitType: "",
                        unitRate: undefined,
                      });
                      setSelectedEarningId(undefined);
                      workersModal.onOpen();
                    },
                  },
                ].map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      padding={"1px 0px 1px 20px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.white",
                      }}
                      onClick={item.onClick}
                    >
                      <Button
                        variant={"ghost"}
                        fontWeight={"normal"}
                        fontSize={"13px"}
                        padding={"0px 20px 0px 0px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.white",
                        }}
                        _hover={{
                          bg: "none",
                        }}
                      >
                        {item.name}
                      </Button>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </HStack>

          <Stack overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Earnings Name",
                      value: "10%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Earnings Category",
                      value: "20%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Rate",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Account",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Reportable as W1",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Status",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "View Details",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <>
                <Tbody padding={0}>
                  {filteredData &&
                    filteredData.map((item: any, i: any) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            whiteSpace={"nowrap"}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <Text
                              textDecoration={
                                !item?.active ? "line-through" : "none"
                              }
                            >
                              {item.name}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            whiteSpace={"nowrap"}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <Text
                              textDecoration={
                                !item?.active ? "line-through" : "none"
                              }
                            >
                              {item.category === "ORDINARY_TIME_EARNINGS"
                                ? "Ordinary Time Earning"
                                : item.category === "OVERTIME_EARNINGS"
                                ? " Overtime Earning"
                                : item.category === "ALLOWANCES"
                                ? " Allowance "
                                : item.category ===
                                  "EMPLOYMENT_TERMINATION_PAYMENTS"
                                ? " Employment Termination Payment "
                                : item.category === "LUMP_SUM_E"
                                ? " Lump Sum E "
                                : item.category === "BONUSES_AND_COMMISSIONS"
                                ? " Bonuses and Commissions "
                                : item.category === "LUMP_SUM_W"
                                ? " Lump Sum W "
                                : item.category === "DIRECTORS_FEES"
                                ? " Directors Fees "
                                : item.category === "PAID_PARENTAL_LEAVE"
                                ? " Paid Parental Leave "
                                : item.category === "WORKERS_COMPENSATION"
                                ? " Workers Compensation "
                                : ""}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            whiteSpace={"nowrap"}
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <Text
                              textDecoration={
                                !item?.active ? "line-through" : "none"
                              }
                            >
                              {item.rateType === "FIXED_AMOUNT"
                                ? currencyData + " " + (item.fixedAmount ?? "")
                                : item.rateType ===
                                  "MULTIPLE_OF_ORDINARY_EARNINGS"
                                ? item.multiple + "X"
                                : item.rateType === "RATE_PER_UNIT"
                                ? currencyData + " " + item.unitRate
                                : ""}{" "}
                              {item.rateType === "FIXED_AMOUNT"
                                ? "Fixed Amount"
                                : item.rateType ===
                                  "MULTIPLE_OF_ORDINARY_EARNINGS"
                                ? " of ordinary earnings"
                                : item.rateType === "RATE_PER_UNIT"
                                ? " rate per unit "
                                : ""}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            whiteSpace={"nowrap"}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <Text
                              textDecoration={
                                !item?.active ? "line-through" : "none"
                              }
                            >
                              {item.chartOfAccount.code}:
                              {item.chartOfAccount.name}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            whiteSpace={"nowrap"}
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <Text
                              textDecoration={
                                !item?.active ? "line-through" : "none"
                              }
                            >
                              {item.reportableActivityStatement ? "Yes" : "No"}
                            </Text>
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            whiteSpace={"nowrap"}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Tag
                              padding="5px 6px"
                              fontWeight={600}
                              fontSize="13.44px"
                              lineHeight={1.5}
                              borderRadius={"3px"}
                              color={item?.active ? "#3454D1" : "#EA4D4D"}
                              backgroundColor={
                                item?.active ? "#EBEEFA" : "#FDEDED"
                              }
                              _dark={{
                                color: item?.active
                                  ? "#3454D1"
                                  : "customColor.dark.150",
                                backgroundColor: item?.active
                                  ? "#EBEEFA"
                                  : "customColor.dark.bg",
                              }}
                            >
                              {item?.active ? "Active" : "Inactive"}
                            </Tag>
                            {/* {item.active ? "Active" : "Inactive"} */}
                          </Td>
                          <Td
                            padding="10px"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                            style={{
                              opacity: item?.active ? 1 : 0.6,
                              backgroundColor: item?.active
                                ? "none"
                                : "gray.200",
                            }}
                          >
                            <HStack alignItems="center" justifyContent="center">
                              {" "}
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdOutlineRemoveRedEye}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={() => {
                                  if (item.active) {
                                    setSelectedEarningId(item.id);

                                    item.category === "ORDINARY_TIME_EARNINGS"
                                      ? ordinaryTimeEarningModal.onOpen()
                                      : item.category === "OVERTIME_EARNINGS"
                                      ? overTimeEarningModal.onOpen()
                                      : item.category === "ALLOWANCES"
                                      ? allowanceModal.onOpen()
                                      : item.category ===
                                        "EMPLOYMENT_TERMINATION_PAYMENTS"
                                      ? employmentTerminationModal.onOpen()
                                      : item.category === "LUMP_SUM_E"
                                      ? lumpSumEModal.onOpen()
                                      : item.category ===
                                        "BONUSES_AND_COMMISSIONS"
                                      ? bonusesModal.onOpen()
                                      : item.category === "LUMP_SUM_W"
                                      ? wLumpSum.onOpen()
                                      : item.category === "DIRECTORS_FEES"
                                      ? directorsFeeModal.onOpen()
                                      : item.category === "PAID_PARENTAL_LEAVE"
                                      ? paidParentalLeaveModal.onOpen()
                                      : workersModal.onOpen();
                                  }
                                }}
                              />
                            </HStack>
                          </Td>
                          <Td
                            padding="15px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Menu>
                              <MenuButton
                                as={Button}
                                backgroundColor={"transparent"}
                                h={"34.5px"}
                                marginLeft={"8px"}
                                _light={{
                                  color: "customColor.black7",
                                }}
                                display={{
                                  lg: "flex",
                                  md: "flex",
                                  sm: "flex",
                                  xs: "flex",
                                }}
                                borderRadius={"full"}
                                _hover={{
                                  backgroundColor: "primary.50",
                                }}
                                _active={{
                                  backgroundColor: "primary.100",
                                }}
                              >
                                <Icon as={FiMoreVertical} />
                              </MenuButton>
                              <MenuList style={{ minWidth: "140px" }}>
                                {[
                                  {
                                    name: "Edit",
                                    onClick: () => {
                                      setSelectedEarningId(item.id);

                                      item.category === "ORDINARY_TIME_EARNINGS"
                                        ? ordinaryTimeEarningModal.onOpen()
                                        : item.category === "OVERTIME_EARNINGS"
                                        ? overTimeEarningModal.onOpen()
                                        : item.category === "ALLOWANCES"
                                        ? allowanceModal.onOpen()
                                        : item.category ===
                                          "EMPLOYMENT_TERMINATION_PAYMENTS"
                                        ? employmentTerminationModal.onOpen()
                                        : item.category === "LUMP_SUM_E"
                                        ? lumpSumEModal.onOpen()
                                        : item.category ===
                                          "BONUSES_AND_COMMISSIONS"
                                        ? bonusesModal.onOpen()
                                        : item.category === "LUMP_SUM_W"
                                        ? wLumpSum.onOpen()
                                        : item.category === "DIRECTORS_FEES"
                                        ? directorsFeeModal.onOpen()
                                        : item.category ===
                                          "PAID_PARENTAL_LEAVE"
                                        ? paidParentalLeaveModal.onOpen()
                                        : workersModal.onOpen();
                                    },
                                  },
                                  {
                                    name: "Delete",
                                    onClick: () => {
                                      setSelectedEarningId(item.id);
                                      deleteModal.onOpen();
                                    },
                                  },
                                  {
                                    name: item.active
                                      ? "Mark as Inactive"
                                      : "Mark as Active",
                                    onClick: () => {
                                      PayItemEarningApi.IsActive(
                                        item.id,
                                        !item.active,
                                        (res) => {
                                          setLastUpdated(Date.now());
                                          toast({
                                            title: item.active
                                              ? "Earning is inactive"
                                              : "Earning is active",
                                            status: "success",
                                          });
                                        },
                                        (error) => {
                                          toast({
                                            title: item.active
                                              ? "Failed to inactive earning"
                                              : "Failed to activate earning",
                                            status: "success",
                                          });
                                        }
                                      );
                                    },
                                  },
                                ].map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      padding={"1px 0px 1px 20px"}
                                      _light={{
                                        color: "customColor.black7",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                      onClick={item.onClick}
                                      _hover={{}}
                                    >
                                      <Button
                                        variant={"ghost"}
                                        fontWeight={"normal"}
                                        fontSize={"13px"}
                                        padding={"0px 20px 0px 0px"}
                                        _light={{
                                          color: "customColor.black7",
                                        }}
                                        _dark={{
                                          color: "customColor.white",
                                        }}
                                        _hover={{
                                          bg: "none",
                                        }}
                                      >
                                        {item.name}
                                      </Button>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
                <OrdinaryTimeEarningModal
                  isOpen={ordinaryTimeEarningModal.isOpen}
                  onClose={ordinaryTimeEarningModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <OverTimeEarningModal
                  isOpen={overTimeEarningModal.isOpen}
                  onClose={overTimeEarningModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <AllowanceModal
                  isOpen={allowanceModal.isOpen}
                  onClose={allowanceModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <EmploymentTerminationModal
                  isOpen={employmentTerminationModal.isOpen}
                  onClose={employmentTerminationModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                />
                <LumpSumEModal
                  isOpen={lumpSumEModal.isOpen}
                  onClose={lumpSumEModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                />
                <BonusesModal
                  isOpen={bonusesModal.isOpen}
                  onClose={bonusesModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <DirectorsFeeModal
                  isOpen={directorsFeeModal.isOpen}
                  onClose={directorsFeeModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <PaidParentalModal
                  isOpen={paidParentalLeaveModal.isOpen}
                  onClose={paidParentalLeaveModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <WorkersModal
                  isOpen={workersModal.isOpen}
                  onClose={workersModal.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                  ratetype={ratetype}
                />
                <LumpSumWModal
                  isOpen={wLumpSum.isOpen}
                  onClose={wLumpSum.onClose}
                  allAccount={account}
                  selectedEarningId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                />
                <DeleteEarning
                  isOpen={deleteModal.isOpen}
                  onClose={deleteModal.onClose}
                  selectedId={selectedEarningId}
                  setLastUpdated={setLastUpdated}
                />
              </>
            </Table>
          </Stack>
          <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
            <Button
              colorScheme={"green"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.white"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </Skeleton>
    </FormProvider>
  );
}
function FormResetData(category: string) {
  return {
    category: category,
    name: "",
    displayName: "",
    rateType: "",
    chartAccountId: "",
    isTaxable: false,
    retirementFundExcemption: false,
    reportableActivityStatement: false,
    rateUnitType: "",
    unitRate: undefined,
  };
}
