import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useDashboardContext } from "../../Hooks/DashboardContext";
import StaffChart from "../StaffChart";

export default function TotalStaff() {
  const { editDashboard, dashboardAnalytics } = useDashboardContext();
  return (
    <Box
      // h={"100%"}
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      w={"100%"}
      p="25px"
      color={"customColor.black"}
      _dark={{
        bgColor: "customColor.dark.50",
        color: "customColor.dark.150",
      }}
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text
            my="4px"
            as={"h4"}
            _light={{
              color: "customColor.black7",
            }}
            fontSize="24px"
            fontWeight={500}
            lineHeight="1.2"
            word-wrap="break-word"
          >
            {dashboardAnalytics?.totalEmpCount}
          </Text>
          <Text
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.dark.250",
            }}
            word-wrap="break-word"
            fontSize="14.4px"
            fontWeight="500"
            lineHeight={1.5}
          >
            Total Staff
          </Text>
        </Box>
        <StaffChart
          femaleGenderEmpCount={dashboardAnalytics?.femaleGenderEmpCount}
          maleGenderEmpCount={dashboardAnalytics?.maleGenderEmpCount}
        />
      </Flex>
      <HStack
        mt="16px"
        divider={
          <Text
            mx="4px"
            _dark={{
              color: "customColor.dark.250",
            }}
            _light={{
              color: "customColor.gray.64748B",
            }}
          >
            |
          </Text>
        }
      >
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.maleGenderEmpCount}
          </Text>{" "}
          Male
        </Text>
        <Text
          _light={{
            color: "customColor.gray.64748B",
          }}
          _dark={{
            color: "customColor.dark.250",
          }}
          fontSize="14.4px"
          fontWeight="400px"
          lineHeight={1.5}
        >
          <Text as="span" color="#34C38F" fontSize="14.4px">
            {dashboardAnalytics?.femaleGenderEmpCount}
          </Text>{" "}
          Female
        </Text>
      </HStack>
    </Box>
    // </Card>
  );
}
