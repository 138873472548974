import {
  Button,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiMoreVertical } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { PayItemReimbursementApi } from "../../../../Api/PayItemReinbursement";
import AddModal from "./AddModal/AddModal";
import { DeleteReimbursement } from "./AddModal/DeleteReimbursement";
interface ReimbursementProps {
  showInactive: boolean | undefined;
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}
export default function Reimbursement({
  showInactive,
  setSelectedTab,
  selectedTab,
}: ReimbursementProps) {
  const addModal = useDisclosure();
  const deleteModal = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState<number>();
  const [reimbursementAdded, setReimbursementAdded] = useState<Array<any>>([]);
  const toast = useToast();
  const [selectedReimbursementId, setSelectedReimbursementId] =
    useState<string>();
  const form = useForm<PayItemReimbursementApi.AddNewReimbursement>({
    defaultValues: {
      id: "",
      name: "",
      chartAccountId: "",
    },
  });
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = showInactive
    ? reimbursementAdded.filter((item) => !item.active)
    : reimbursementAdded.filter((item) => item.active);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(reimbursementAdded);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };
  useEffect(() => {
    PayItemReimbursementApi.FetchReimbursement(
      (data) => {
        setReimbursementAdded(data);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  }, [lastUpdated]);
  // const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({});
  // useEffect(() => {
  //   const initialCheckboxes = reimbursementAdded.reduce((acc: any, item) => {
  //     acc[item.id] = false;
  //     return acc;
  //   }, {});
  //   setCheckboxes(initialCheckboxes);
  // }, [reimbursementAdded]);

  // const handleCheckboxChange = (id: string) => {
  //   setCheckboxes({
  //     ...checkboxes,
  //     [id]: !checkboxes[id],
  //   });
  // };

  // const handleCheckAll = () => {
  //   const updatedCheckboxes = Object.keys(checkboxes).reduce(
  //     (acc, key) => ({
  //       ...acc,
  //       [key]: !checkboxes[key],
  //     }),
  //     {}
  //   );
  //   setCheckboxes(updatedCheckboxes);
  // };
  return (
    <FormProvider {...form}>
      <Skeleton height={"100%"} isLoaded={!isLoading}>
        <Stack color={"#32456A"}>
          <HStack justifyContent="space-between" px={"12px"}>
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              py="20px"
              as="h2"
              size="md"
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
            >
              Reimbursement
            </Heading>
            <Tooltip
              label="Reimbursements permits users to input or record expenses that are eligible for repayment by another party or organization."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="auto"
            >
              <HStack
                style={{ marginTop: "auto" }}
                display={{ xs: "none", md: "flex" }}
                padding={"8px 16px"}
                alignItems={"center"}
              >
                <Button
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  _active={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    form.reset({
                      id: "",
                      name: "",
                      chartAccountId: "",
                    });
                    setSelectedReimbursementId(undefined);

                    addModal.onOpen();
                  }}
                >
                  Add
                </Button>
              </HStack>
            </Tooltip>
          </HStack>
          <Stack overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Reimbursement Name",
                      value: "40%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Account",
                      value: "30%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Status",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "View Details",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody padding={0}>
                {filteredData &&
                  filteredData.map((item: any, i: any) => {
                    return (
                      <Tr
                        key={i}
                        _hover={{
                          bgColor: "#f2f3f8",
                          cursor: "pointer",
                        }}
                      >
                        {/* <Td
                        px="12px"
                        fontSize="14.4px"
                        fontWeight={400}
                       _dark={{
                  color: "customColor.dark.150",
                }}
                _light={{
                  color: "customColor.black",
                }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor="#f6f6f6"
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={checkboxes[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </Td> */}
                        <Td
                          padding="15px 15px 15px 30px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                          onClick={() => {
                            setSelectedReimbursementId(item.id);
                            form.reset({
                              id: item.id,
                              name: item.name,
                              chartAccountId: item.chartOfAccount.id,
                            });
                            addModal.onOpen();
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.name}
                          </Text>
                        </Td>
                        <Td
                          padding="15px "
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          whiteSpace="nowrap"
                          lineHeight={1.5}
                          fontWeight={500}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <Text
                            textDecoration={
                              !item?.active ? "line-through" : "none"
                            }
                          >
                            {item.chartOfAccount.code}:
                            {item.chartOfAccount.name}
                          </Text>
                        </Td>
                        <Td
                          padding="15px"
                          fontWeight={500}
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Tag
                            padding="5px 6px"
                            fontWeight={600}
                            fontSize="13.44px"
                            lineHeight={1.5}
                            borderRadius={"3px"}
                            color={item?.active ? "#3454D1" : "#EA4D4D"}
                            backgroundColor={
                              item?.active ? "#EBEEFA" : "#FDEDED"
                            }
                            _dark={{
                              color: item?.active
                                ? "#3454D1"
                                : "customColor.dark.150",
                              backgroundColor: item?.active
                                ? "#EBEEFA"
                                : "customColor.dark.bg",
                            }}
                          >
                            {item?.active ? "Active" : "Inactive"}
                          </Tag>
                        </Td>
                        <Td
                          padding="10px"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                          style={{
                            opacity: item?.active ? 1 : 0.6,
                            backgroundColor: item?.active ? "none" : "gray.200",
                          }}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={MdOutlineRemoveRedEye}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                              }}
                              onClick={() => {
                                setSelectedReimbursementId(item.id);
                                form.reset({
                                  id: item.id,
                                  name: item.name,
                                  chartAccountId: item.chartOfAccount.id,
                                });
                                addModal.onOpen();
                              }}
                            />
                          </HStack>
                        </Td>
                        <Td
                          padding="15px"
                          fontSize="13.44px"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          lineHeight={1.5}
                          borderTopWidth="1px"
                          borderBottomWidth="0px"
                          borderTopColor={"borders.tableBorder"}
                        >
                          <Menu>
                            <MenuButton
                              as={Button}
                              padding={0}
                              backgroundColor="transparent"
                              h="34.5px"
                              marginLeft="auto"
                              color="black"
                              display={{
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              }}
                              _hover={{
                                backgroundColor: "primary.50",
                              }}
                              _active={{
                                backgroundColor: "primary.100",
                              }}
                              borderRadius="full"
                            >
                              <Icon as={FiMoreVertical} />
                            </MenuButton>
                            <MenuList style={{ minWidth: "140px" }}>
                              {[
                                {
                                  name: "Edit",
                                  onClick: () => {
                                    setSelectedReimbursementId(item.id);
                                    form.reset({
                                      id: item.id,
                                      name: item.name,
                                      chartAccountId: item.chartOfAccount.id,
                                    });

                                    addModal.onOpen();
                                  },
                                },
                                {
                                  name: "Delete",
                                  onClick: () => {
                                    setSelectedReimbursementId(item.id);
                                    deleteModal.onOpen();
                                  },
                                },
                                {
                                  name: item.active
                                    ? "Mark as Inactive"
                                    : "Mark as Active",
                                  onClick: () => {
                                    PayItemReimbursementApi.IsActive(
                                      item.id,
                                      !item.active,
                                      (res) => {
                                        setLastUpdated(Date.now());
                                        toast({
                                          title: "Reimbursement is inactive",
                                          status: "success",
                                        });
                                      },
                                      (error) => {
                                        toast({
                                          title:
                                            "Failed to inactive Reimbursement",
                                          status: "success",
                                        });
                                      }
                                    );
                                  },
                                },
                              ].map((item, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    padding={"1px 0px 1px 20px"}
                                    _light={{
                                      color: "customColor.black7",
                                    }}
                                    _dark={{
                                      color: "customColor.white",
                                    }}
                                    onClick={item.onClick}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      fontWeight={"normal"}
                                      fontSize={"13px"}
                                      padding={"0px 20px 0px 0px"}
                                      _light={{
                                        color: "customColor.black2",
                                      }}
                                      _dark={{
                                        color: "customColor.white",
                                      }}
                                    >
                                      {item.name}
                                    </Button>
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
              <AddModal
                isOpen={addModal.isOpen}
                onClose={addModal.onClose}
                selectedReimbursementId={selectedReimbursementId}
                setLastUpdated={setLastUpdated}
              />
              <DeleteReimbursement
                isOpen={deleteModal.isOpen}
                onClose={deleteModal.onClose}
                selectedId={selectedReimbursementId}
                setLastUpdated={setLastUpdated}
              />
            </Table>
          </Stack>
          <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
            <Button
              colorScheme={"green"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"customColor.white"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              onClick={() => setSelectedTab(selectedTab + 1)}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </Skeleton>
    </FormProvider>
  );
}
