import {
  Avatar,
  Box,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Analytics } from "../../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../../Hooks/DashboardContext";

export default function EmployeesTable() {
  const form = useFormContext<Analytics.DashBoardData>();

  const toast = useToast();
  const { editDashboard } = useDashboardContext();
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
  }, 1000);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState<number>(25);
  const { employeesList } = useBusinessContext();
  const employees = employeesList ?? [];
  const pageCount = Math.ceil(employees.length / limit); // State for pageCount

  // useEffect(() => {
  //   Employees.FetchEmployees(
  //     {
  //       empStatus: "CURRENT",
  //       page: pageNumber,
  //       quantity: limit,
  //       search: searchText,
  //     },
  //     (data) => {
  //       setEmployeesList(data);
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //       });
  //     }
  //   );
  // }, [pageNumber, searchText, limit]);

  // useEffect(() => {
  //   Employees.FetchEmployees(
  //     {
  //       empStatus: "ALL",
  //     },
  //     (data) => {
  //       setAllEmployeesList(data);
  //       setPageCount(Math.ceil(data.length / limit)); // Update pageCount
  //     },
  //     (error) => {
  //       toast({
  //         title: "Error",
  //         description: error,
  //       });
  //     }
  //   );
  // }, [limit]);

  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            borderRadius={"full"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  return (
    <Box
      word-wrap="break-word"
      w={"100%"}
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
    >
      <Stack
        p={"25px"}
        borderBottom={"1px solid #e5e7eb"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text
          _light={{
            color: "customColor.black7",
          }}
          _dark={{
            color: "customColor.dark.150",
          }}
          lineHeight="1.2"
          fontSize="16px"
          fontWeight={600}
        >
          Employee
        </Text>
        <Stack gap={0} direction={"row"} alignItems={"center"}>
          <Text
            fontSize={"13.44px"}
            _light={{
              color: "customColor.gray.6b7885",
            }}
          >
            Search:
          </Text>
          <InputGroup ml={[0, "7px"]}>
            <Input
              type="text"
              placeholder="Search"
              borderRadius={"3px"}
              h={["auto", "40px"]}
              _focusVisible={{
                border: "1px solid grey",
              }}
              onChange={(e) => {
                debounced(e.target.value);
              }}
            />
          </InputGroup>
        </Stack>
      </Stack>
      <Box overflowX="auto" p="0px">
        <Table variant="simple" w="100%" overflowX="auto">
          <Thead h={"36.8px"}>
            <Tr
              color={"customColor.black7"}
              _dark={{
                color: "customColor.dark.150",
              }}
            >
              {[
                {
                  name: "Employee ID",
                  value: "10%",
                  paddingValue: "8px 26px 8px 30px",
                },
                {
                  name: "Full Name",
                  value: "25%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Department",
                  value: "15%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Designation",
                  value: "15%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Location",
                  value: "15%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "Date Of Joining",
                  value: "10%",
                  paddingValue: "8px 26px 8px 15px",
                },
                {
                  name: "View Details",
                  value: "10%",
                  paddingValue: "8px 26px 8px 15px",
                },
              ].map((item, i) => {
                return (
                  <Th
                    key={i}
                    padding={item.paddingValue}
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                    width={item.value}
                  >
                    {item.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {employeesList &&
              employeesList.map((emp: any, i: any) => {
                return (
                  <Tr
                    key={i}
                    _hover={{
                      bgColor: "#f2f3f8",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/app/payroll/EmployeeDetail/${emp.id}`);
                    }}
                  >
                    <Td
                      padding="15px 15px 15px 30px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      fontWeight={500}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.uniqueId}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <Avatar
                          size="sm"
                          objectFit="fill"
                          name={emp?.firstName + " " + emp?.lastName}
                          src={emp.photo}
                          borderWidth={"2px"}
                          borderColor={"gray.200"}
                        />
                        <Text
                          _hover={{
                            color: "#3454d1",
                          }}
                        >
                          {emp?.firstName + " " + emp?.lastName}
                        </Text>
                      </Stack>
                    </Td>
                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      fontWeight={500}
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.employeeEmployementDetails?.department}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.employeeEmployementDetails?.jobTitle}
                    </Td>
                    <Td
                      padding="15px"
                      fontWeight={500}
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.city}
                    </Td>
                    <Td
                      padding="15px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      {emp?.employeeEmployementDetails?.startDate}
                    </Td>
                    <Td
                      padding="10px"
                      fontSize="13.44px"
                      color={"customColor.black7"}
                      _dark={{
                        color: "customColor.dark.150",
                      }}
                      lineHeight={1.5}
                      borderTopWidth="1px"
                      borderBottomWidth="0px"
                      borderTopColor={"borders.tableBorder"}
                    >
                      <HStack alignItems="center" justifyContent="center">
                        <Icon
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="50%"
                          borderStyle={"solid"}
                          borderWidth={"1px"}
                          borderColor={"borders.tableBorder"}
                          bg={"#fff"}
                          w="25px"
                          h="25px"
                          px="5px"
                          py="5px"
                          as={MdOutlineRemoveRedEye}
                          fontSize="22px"
                          color="#6B7280"
                          _hover={{
                            color: "#3454d1",
                          }}
                          onClick={() => {
                            navigate(`/app/payroll/EmployeeDetail/${emp.id}`);
                          }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
        <Stack
          p={"25px"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
            <IconButton
              aria-label="back"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              backgroundColor="transparent"
              _hover={{
                backgroundColor: "#3454D1",
                color: "white",
              }}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              icon={
                <FaArrowLeft
                  size={15}
                  color={
                    isHovered2
                      ? "#FFFFFF"
                      : pageNumber <= 1 || pageNumber > pageCount
                      ? "#828ea0"
                      : "#283c50"
                  }
                />
              }
              isDisabled={pageNumber <= 1 || pageNumber > pageCount}
              onClick={() => {
                if (pageNumber > 1 && pageNumber <= pageCount) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber - 1));
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <HStack spacing="13px">{renderPageNumbers()}</HStack>
            <IconButton
              aria-label="right"
              backgroundColor="transparent"
              border={"1px solid #e8eaf2"}
              borderRadius={"full"}
              _hover={{
                backgroundColor: "#3454D1",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              icon={
                <FaArrowRight
                  size={15}
                  color={
                    isHovered
                      ? "#FFFFFF"
                      : pageNumber >= pageCount || pageNumber < 1
                      ? "#828ea0"
                      : "#283c50"
                  }
                  style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                />
              }
              isDisabled={pageNumber >= pageCount || pageNumber < 1}
              onClick={() => {
                if (pageNumber < pageCount && pageNumber >= 1) {
                  pageNumberInputRef.current?.value &&
                    (pageNumberInputRef.current.value = String(pageNumber + 1));
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
}
