import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Heading,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export function Payroll({ showDescription }: any) {
  const navigate = useNavigate();
  const data1: Array<{
    title: string;
    desc: string;
    reportName?: any;
    menu?: Array<{
      menuTitle?: string;
      leftIcon?: any;
      rightIcon?: any;
      menuItem: Array<{
        menuLabel: string;
      }>;
    }>;
    onClick?: () => void;
    isClickable?: boolean;
  }> = [
    {
      title: "Employee Contact Details",
      desc: "See employee address and email information",
      reportName: "/app/payroll/EmployeeContactDetailReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: true,
    },
    {
      title: "Employee Remuneration",
      desc: "See the pay rates for employees' including their employment start and termination dates",
      reportName: "/app/payroll/EmployeeRemunerationReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: true,
    },
    {
      title: "Leave Balances",
      desc: "See all leave balances as at a certain date from posted pay runs",
      reportName: "/app/payroll/LeaveBalanceReport",
      isClickable: true,
    },
    {
      title: "Leave Requests",
      desc: "See the details for leave requested, approved and taken, for any or all employees",
      reportName: "/app/payroll/LeaveRequestReport",
      isClickable: true,
    },
    {
      title: "Leave Transactions",
      desc: "See all leave accruals and leave taken for your employees",
      reportName: "/app/payroll/LeaveTransactionsReport",
      isClickable: false,
    },
    {
      title: "Payment Summary Details",
      desc: "See amounts that are included on your employees' payment summaries, or to verify any Lump Sum or RFB amounts",
      reportName: "/app/payroll/PaymentSummaryDetails",
      isClickable: false,
    },
    {
      title: "Payroll Activity Details",
      desc: "View a breakdown of payroll activity within a chosen date range",
      reportName: "/app/payroll/PayrollActivityDetails",
      isClickable: true,
    },
    {
      title: "Payroll Activity Summary",
      desc: "Get a summary of employee payroll activity within a date range",
      reportName: "/app/payroll/PayrollActivitySummary",
      isClickable: true,
    },
    // {
    //   title: "Attendance Report",
    //   desc: "See the attendance records of your employees",
    //   reportName: "/app/payroll/attendance-report",
    //   isClickable: true,
    // },
  ];
  const data2: Array<{
    title: string;
    desc: string;
    reportName?: any;
    menu?: Array<{
      menuTitle?: string;
      leftIcon?: any;
      rightIcon?: any;
      menuItem: Array<{
        menuLabel: string;
      }>;
    }>;
    onClick?: () => void;
    isClickable?: boolean;
  }> = [
    {
      title: "Payroll Employee Summary",
      desc: "Get a summary of employees earnings, deductions, taxes, and super for a date range",
      reportName: "/app/payroll/PayrollEmployeeSummaryReport",
      isClickable: true,
    },
    {
      title: "Statutory  Accruals",
      desc: "See all superannuation accruals by pay period, within a specified date range.",
      reportName: "/app/payroll/SuperannuationAccrualsReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: false,
    },
    {
      title: "Statutory Payments",
      desc: "See super payments for each super fund by expected payment date, as set within pay runs and/or pay templates",
      reportName: "/app/payroll/SuperannuationPaymentsReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: false,
    },
    {
      title: "Timesheet Details",
      desc: "See earnings rate transactions processed through timesheets, for a given time period and tracking category",
      reportName: "/app/payroll/TimesheetDetailsReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: false,
    },
    {
      title: "Timesheet Summary",
      desc: "See a summary of your employees' timesheet entries within a chosen date range",
      reportName: "/app/payroll/TimesheetSummaryReport",
      menu: [
        {
          menuTitle: "Custom Reports",
          rightIcon: BsBoxArrowUpRight,
          menuItem: [
            {
              menuLabel: "Learn how to create a custom report",
            },
          ],
        },
      ],
      isClickable: false,
    },
    {
      title: "Transaction Listing Details",
      desc: "See a summary of pay items by pay item type",
      reportName: "/app/payroll/TransactionLisitingDetailReport",
      isClickable: true,
    },
    {
      title: "Transaction Listing Summary",
      desc: "Get a detailed breakdown of payroll transactions for each employee",
      reportName: "/app/payroll/TransactionLisitingSummaryReport",
      isClickable: true,
    },
    {
      title: "Employee Details",
      desc: "See employee Full Details",
      reportName: "/app/payroll/EmployeeDetailsReport",
      isClickable: true,
    },
  ];
  const [clickedItems, setClickedItems] = useState<Array<number>>([]);
  const [clickedItems2, setClickedItems2] = useState<Array<number>>([]);

  const handleClick = (index: number) => {
    if (clickedItems.includes(index)) {
      setClickedItems((prev) => prev.filter((item) => item !== index));
    } else {
      setClickedItems((prev) => [...prev, index]);
    }
  };
  const handleClick2 = (index: number) => {
    if (clickedItems2.includes(index)) {
      setClickedItems2((prev) => prev.filter((item) => item !== index));
    } else {
      setClickedItems2((prev) => [...prev, index]);
    }
  };
  return (
    <Accordion
      allowMultiple
      allowToggle
      defaultIndex={[0]}
      _dark={{
        bgColor: "customColor.dark.100",
      }}
    >
      <AccordionItem border={"none"}>
        <Stack direction={"row"} alignItems={"center"}>
          <AccordionButton
            w={"40px"}
            padding={0}
            h={"40px"}
            borderRadius={"full"}
            justifyContent={"center"}
            _focus={{
              boxShadow: "0 0 0 1px #fff, 0 0 0 4px rgba(0,10,30,.5)",
              transition: "box-shadow .1s",
              color: "#000a1e",
            }}
          >
            <AccordionIcon />
          </AccordionButton>
          <Stack>
            <Text
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black7",
              }}
              fontSize={"17px"}
              fontWeight={"bold"}
            >
              Payroll
            </Text>
          </Stack>
        </Stack>
        {showDescription && (
          <Text
            paddingLeft={"50px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Get valuable information on payroll activity, transactions, leave
            balances, timesheets, remuneration, and superannuation
          </Text>
        )}
        <AccordionPanel pb={9} paddingLeft={"50px"}>
          <Stack
            spacing={{ xs: 2, lg: 6 }}
            direction={{ xs: "column", lg: "row" }}
          >
            <Stack gap={0}>
              <Stack
                gap={0}
                border={"1px solid #ccced2"}
                borderTopRadius={"7px"}
                borderBottomRadius={"7px"}
              >
                {data1.map((item, i) => {
                  const isClicked = clickedItems.includes(i);
                  return (
                    <Tooltip label={item.isClickable ? "" : "Featured Report."}>
                      <Stack
                        cursor={item.isClickable ? "pointer" : "not-allowed"}
                        direction={"row"}
                        alignItems={"center"}
                        key={i}
                        _dark={{
                          bgColor: "customColor.dark.100",
                        }}
                        _light={{
                          bgColor: "customColor.white",
                          _hover: { bgColor: "#e6e7e9" },
                        }}
                        h={"64px"}
                        // h={"44px"}
                        minW={{ xs: "100%", md: "560px" }}
                        borderBottom={"1px solid #ccced2"}
                        p={4}
                      >
                        <Icon
                          as={AiFillStar}
                          w={5}
                          h={5}
                          color={isClicked ? "#0078C8" : "#ccced2"}
                          _hover={{ color: "#0078C8" }}
                          onClick={() => {
                            if (item.isClickable) {
                              handleClick(i);
                              if (item.onClick) item.onClick();
                            }
                          }}
                        />
                        <Stack
                          flexGrow={1}
                          paddingLeft={"10px"}
                          onClick={() => {
                            if (item.isClickable) navigate(item.reportName);
                          }}
                        >
                          <Heading
                            fontSize={{ base: "sm", md: "15px" }}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight={"500"}
                          >
                            {item.title}
                          </Heading>
                          {showDescription && (
                            <Heading
                              fontSize={{ base: "xs", md: "13px" }}
                              _light={{
                                color: "customColor.black7",
                              }}
                              fontWeight="400"
                            >
                              {item.desc}
                            </Heading>
                          )}
                        </Stack>
                        <Stack></Stack>
                        {item.menu && (
                          <Menu>
                            <MenuButton
                              as={Button}
                              padding={0}
                              backgroundColor={"transparent"}
                              h={"34.5px"}
                              marginLeft={"8px"}
                              color={"black"}
                              display={{
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              }}
                              borderRadius={"full"}
                              _hover={{
                                backgroundColor: "rgba(0,10,30,.05)",
                                color: "#000a1e",
                              }}
                              _active={{
                                backgroundColor: "rgba(0,10,30,.05)",
                                color: "#000a1e",
                              }}
                            >
                              <Icon as={FiMoreVertical} />
                            </MenuButton>
                            <MenuList padding={"12px 0px"} w={"399px"}>
                              {item.menu.map((menuItem, index) => {
                                return (
                                  <Stack key={index}>
                                    <Stack padding={"0px 12px"}>
                                      <Text
                                        padding={"0px 8px"}
                                        fontSize={"11px"}
                                        _light={{
                                          color: "customColor.black7",
                                        }}
                                      >
                                        {menuItem.menuTitle}
                                      </Text>
                                    </Stack>

                                    {menuItem.menuItem.map(
                                      (subMenuItem, subIndex) => (
                                        <Stack
                                          key={subIndex}
                                          padding={"0px 12px"}
                                          _hover={{
                                            bg: "rgba(0,10,30,.05)",
                                          }}
                                        >
                                          <Link
                                            padding={"8px 4px 8px 8px"}
                                            _dark={{
                                              color: "customColor.dark.150",
                                            }}
                                            _light={{
                                              color: "customColor.black7",
                                            }}
                                            fontSize={"15px"}
                                          >
                                            {menuItem.leftIcon && (
                                              <Icon
                                                as={menuItem.leftIcon}
                                                marginRight={"8px"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                _light={{
                                                  color: "customColor.black7",
                                                }}
                                              />
                                            )}
                                            {subMenuItem.menuLabel}
                                            {menuItem.rightIcon && (
                                              <Icon
                                                as={menuItem.rightIcon}
                                                marginLeft={"8px"}
                                                _dark={{
                                                  color: "customColor.dark.150",
                                                }}
                                                _light={{
                                                  color: "customColor.black7",
                                                }}
                                              />
                                            )}
                                          </Link>
                                        </Stack>
                                      )
                                    )}
                                  </Stack>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        )}
                      </Stack>
                    </Tooltip>
                  );
                })}
              </Stack>
            </Stack>

            <Stack
              gap={0}
              border={"1px solid #ccced2"}
              h={"fit-content"}
              borderTopRadius={"7px"}
              borderBottomRadius={"7px"}
            >
              {data2.map((item, i) => {
                const isClicked = clickedItems2.includes(i);
                return (
                  <Tooltip label={item.isClickable ? "" : "Featured Report."}>
                    <Stack
                      cursor={item.isClickable ? "pointer" : "not-allowed"}
                      direction={"row"}
                      alignItems={"center"}
                      key={i}
                      _dark={{
                        bgColor: "customColor.dark.100",
                      }}
                      _light={{
                        bgColor: "customColor.white",
                        _hover: { bgColor: "#e6e7e9" },
                      }}
                      h={"64px"}
                      minW={{ xs: "100%", md: "560px" }}
                      borderBottom={"1px solid #ccced2"}
                      p={4}
                    >
                      <Icon
                        as={AiFillStar}
                        w={5}
                        h={5}
                        color={isClicked ? "#0078C8" : "#ccced2"}
                        _hover={{ color: "#0078C8" }}
                        onClick={() => {
                          if (item.isClickable) {
                            handleClick2(i);
                            if (item.onClick) item.onClick();
                          }
                        }}
                      />
                      <Stack
                        flexGrow={1}
                        paddingLeft={"10px"}
                        onClick={() => {
                          if (item.isClickable) navigate(item.reportName);
                        }}
                      >
                        <Heading
                          fontSize={{ base: "sm", md: "15px" }}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          _light={{
                            color: "customColor.black7",
                          }}
                          fontWeight={"500"}
                        >
                          {item.title}
                        </Heading>
                        {showDescription && (
                          <Heading
                            fontSize={{ base: "xs", md: "13px" }}
                            _light={{
                              color: "customColor.black7",
                            }}
                            fontWeight="400"
                          >
                            {item.desc}
                          </Heading>
                        )}
                      </Stack>

                      {item.menu && (
                        <Menu>
                          <MenuButton
                            as={Button}
                            padding={0}
                            backgroundColor={"transparent"}
                            h={"34.5px"}
                            marginLeft={"8px"}
                            color={"black"}
                            display={{
                              lg: "flex",
                              md: "flex",
                              sm: "flex",
                              xs: "flex",
                            }}
                            borderRadius={"full"}
                            _hover={{
                              backgroundColor: "rgba(0,10,30,.05)",
                              color: "#000a1e",
                            }}
                            _active={{
                              backgroundColor: "rgba(0,10,30,.05)",
                              color: "#000a1e",
                            }}
                          >
                            <Icon as={FiMoreVertical} />
                          </MenuButton>
                          <MenuList padding={"12px 0px"} w={"399px"}>
                            {item.menu.map((menuItem, index) => {
                              return (
                                <Stack key={index}>
                                  <Stack padding={"0px 12px"}>
                                    <Text
                                      padding={"0px 8px"}
                                      fontSize={"11px"}
                                      _light={{
                                        color: "customColor.black7",
                                      }}
                                    >
                                      {menuItem.menuTitle}
                                    </Text>
                                  </Stack>

                                  {menuItem.menuItem.map(
                                    (subMenuItem, subIndex) => (
                                      <Stack
                                        key={subIndex}
                                        padding={"0px 12px"}
                                        _hover={{
                                          bg: "rgba(0,10,30,.05)",
                                        }}
                                      >
                                        <Link
                                          padding={"8px 4px 8px 8px"}
                                          _dark={{
                                            color: "customColor.dark.150",
                                          }}
                                          _light={{
                                            color: "customColor.black7",
                                          }}
                                          fontSize={"15px"}
                                        >
                                          {menuItem.leftIcon && (
                                            <Icon
                                              as={menuItem.leftIcon}
                                              marginRight={"8px"}
                                              _dark={{
                                                color: "customColor.dark.150",
                                              }}
                                              _light={{
                                                color: "customColor.black",
                                              }}
                                            />
                                          )}
                                          {subMenuItem.menuLabel}
                                          {menuItem.rightIcon && (
                                            <Icon
                                              as={menuItem.rightIcon}
                                              marginLeft={"8px"}
                                              _dark={{
                                                color: "customColor.dark.150",
                                              }}
                                              _light={{
                                                color: "customColor.black",
                                              }}
                                            />
                                          )}
                                        </Link>
                                      </Stack>
                                    )
                                  )}
                                </Stack>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      )}
                    </Stack>
                  </Tooltip>
                );
              })}
            </Stack>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
