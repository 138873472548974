import {
  Box,
  Button,
  Container,
  Heading,
  Link,
  Stack,
  Table,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function Superannuation() {
  const navigate = useNavigate();
  return (
    <Stack
      gap={0}
      h="calc(100vh - 70px)"
      overflowY="auto"
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack borderBottom={"1px solid #dee0e2"}>
        <Container
          justifyContent="center"
          alignItems="center"
          padding={"10px 20px 40px"}
          maxW={"100%"}
          borderRadius="md"
          _dark={{
            bgColor: "customColor.dark.100",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          paddingBottom={"40px"}
        >
          <Heading
            padding="0px 0px 10px"
            margin={"0px 0px 25px"}
            borderBottom={"1px solid #e2e2e2"}
            fontSize="24px"
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontWeight="bold"
          >
            Auto Statuary Benefits Payments
          </Heading>

          <VStack
            _light={{
              color: "customColor.black7",
            }}
            _dark={{ color: "customColor.white" }}
            fontSize="18px"
            align="left"
            gap={0}
          >
            <Text
              paddingBottom={"10px"}
              margin={"10px 0px 0px 5px"}
              borderBottom={"1px solid #e2e2e2"}
              fontWeight="bold"
              fontSize="16px"
              _light={{
                color: "customColor.black7",
              }}
            >
              The thought of another member contribution form make you want to
              cry?
            </Text>

            <Text
              paddingBottom={"10px"}
              margin={"10px 0px 0px 5px"}
              borderBottom={"1px solid #e2e2e2"}
              fontWeight="normal"
              fontSize="16px"
              _light={{
                color: "customColor.black7",
              }}
            >
              Activate Automatic Statuary Benefits Payments today, and make the
              pain go away.
            </Text>
            <Text
              paddingBottom={"10px"}
              margin={"10px 0px 0px 5px"}
              fontWeight="normal"
              fontSize="16px"
              _light={{
                color: "customColor.black7",
              }}
            >
              Need more information? Check out out
              <Link href="#" color="primary.950">
                easy help guide.
              </Link>
            </Text>
          </VStack>
        </Container>
      </Stack>
      <Container
        justifyContent="center"
        alignItems="center"
        p="20px"
        maxW={"container.xl"}
        borderRadius="md"
      >
        <Button
          fontSize={"13px"}
          bgColor="#3454D1"
          borderRadius={"3px"}
          leftIcon={<FaPlus />}
          borderWidth={"1px"}
          borderColor={"#3454d1!important"}
          variant={"solid"}
          padding={"12px 16px"}
          fontWeight={700}
          _hover={{
            bgColor: "#3454D1",
          }}
          margin={"0px 10px 15px 0px"}
          onClick={() => {
            navigate("/app/payroll/SuperannuationPaymentDetails");
          }}
        >
          {" "}
          Add Super Payment
        </Button>
        <Stack
          borderRadius="4px"
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          border="1px"
          borderColor="#f6f6f6"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <Box overflowX="auto" p="0px">
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  {[
                    {
                      name: "Created",
                      value: "10%",
                      paddingValue: "8px 26px 8px 30px",
                    },
                    {
                      name: "Status",
                      value: "30%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Amount",
                      value: "40%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Approved",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Processed",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
            </Table>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
}
