import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { PayrollCalendar } from "../../../Api/PayRollCalendar";

interface AddCalendarProps {
  isOpen: boolean;
  onClose: () => void;
  setLastUpdated: (timeStamp: number) => void;
  selectedCalendarId: string | undefined;
}
export function AddCalendar({
  isOpen,
  onClose,
  setLastUpdated,
  selectedCalendarId,
}: AddCalendarProps) {
  const form = useFormContext<PayrollCalendar.AddNewCalendar>();
  const payPeriodOptions = [
    { value: "WEEKLY", label: "Weekly" },
    { value: "FORTNIGHTLY", label: "Fortnightly" },
    { value: "MONTHLY", label: "Monthly" },
    { value: "TWICE_MONTHLY", label: "Twice a month" },
    { value: "QUARTERLY", label: "Quarterly" },
    { value: "FOUR_WEEKLY", label: "Four Weekly" },
  ];

  const watchPayPeriod = useWatch({
    control: form.control,
    name: "payPeriod",
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <FormProvider {...form}>
      <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="var(--x-font-size-large, 1.0625rem)"
            _light={{
              color: "customColor.black7",
            }}
            fontWeight="700"
          >
            {selectedCalendarId ? "Edit Pay Calendar" : "Add Pay Calendar"}
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl marginTop="25px">
              <FormLabel
                mb={0}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize="14px"
                fontWeight="700"
              >
                Pay Period
              </FormLabel>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Pay Period type is required",
                  },
                }}
                control={form.control}
                name="payPeriod"
                render={({ field, fieldState }) => (
                  <ReactSelect
                    {...field}
                    key={field.value}
                    placeholder="Select Pay Period Type"
                    isInvalid={fieldState.invalid}
                    ErrorMessage="Select Pay Period Type"
                    withValidation
                    options={payPeriodOptions}
                    value={payPeriodOptions.find(
                      (op) => op.value === field.value
                    )}
                    onChange={(newValue: any) => {
                      field.onChange(newValue?.value);
                    }}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                  />
                )}
              />
            </FormControl>
            {(watchPayPeriod === "WEEKLY" ||
              watchPayPeriod === "FORTNIGHTLY" ||
              watchPayPeriod === "MONTHLY" ||
              watchPayPeriod === "TWICE_MONTHLY" ||
              watchPayPeriod === "QUARTERLY" ||
              watchPayPeriod === "FOUR_WEEKLY") && (
              <FormControl>
                <FormLabel
                  mb={0}
                  mt="20px"
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="14px"
                  fontWeight="700"
                >
                  Name
                </FormLabel>

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  }}
                  control={form.control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        fontSize: "15px",
                        variant: "outline",
                        type: "text",
                        padding: "12px 15x",
                        borderTopRadius: "3px",
                        // value: watchPayPeriod,
                      }}
                      placeholder="Enter Name"
                    />
                  )}
                />

                <FormLabel
                  mt="20px"
                  mb={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="14px"
                  fontWeight="700"
                >
                  Start Date
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Start date is required",
                    },
                  }}
                  control={form.control}
                  name="startDate"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        fontSize: "15px",
                        variant: "outline",
                        type: "date",
                        padding: "12px 15px",
                        borderTopRadius: "3px",
                      }}
                      placeholder="dd/mm/yyyy"
                    />
                  )}
                />

                <FormLabel
                  mt="20px"
                  mb={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="14px"
                  fontWeight="700"
                >
                  First Payment Date
                </FormLabel>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "First Payment date is required",
                    },
                  }}
                  control={form.control}
                  name="nextPaymentDate"
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      ref={field.ref}
                      validationMessage={error?.message}
                      withValidation
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        w: "100%",
                        h: "38px",
                        fontSize: "15px",
                        variant: "outline",
                        type: "date",
                        padding: "12px 15px",
                        borderTopRadius: "3px",
                      }}
                      placeholder="dd/mm/yyyy"
                    />
                  )}
                />
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"transparent"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel{" "}
            </Button>
            <Button
              ml="8px"
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              isLoading={isLoading}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  setIsLoading(true);
                  if (selectedCalendarId) {
                    PayrollCalendar.UpdateCalendar(
                      selectedCalendarId,
                      data,
                      (success) => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "Calendar is updated",
                          status: "success",
                        });
                        setIsLoading(false);
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Update Calendar Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  } else {
                    PayrollCalendar.CreateCalendar(
                      data,
                      (success) => {
                        setLastUpdated(Date.now());
                        toast({
                          title: "Calendar is added",

                          status: "success",
                        });
                        setIsLoading(false);
                        onClose();
                      },
                      (error) => {
                        toast({
                          title: "Add Calendar Failed",
                          description: error,
                          status: "error",
                        });
                        setIsLoading(false);
                      }
                    );
                  }
                })(e);
              }}
            >
              {" "}
              {selectedCalendarId ? "Edit" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
