import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../Common/CustomInput";
import { ReactSelect } from "../../../../Common/ReactSelect";
import { useBusinessContext } from "../../../../Hooks/BusinessContext";
import { AreasModule } from "../../../API/Areas";
import { LocationApi } from "../../../API/LocationAPi";

import { Shifts } from "../../../API/Shifts";
import TextHeading from "../Common/Heading";
import { useScheduleContext } from "../Hooks/ScheduleContext";

interface CreateBulkShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRefresh?: () => void;
}
export default function CreateBulkShiftModal({
  isOpen,
  onClose,
  onRefresh,
}: CreateBulkShiftModalProps) {
  const form = useForm<Shifts.CreateBulkShifts>({
    defaultValues: {
      employeeIds: [],
      locationId: "",
      areas: [],
      shiftStartDate: "",
      shiftEndDate: "",
      shiftStartTime: "",
      shiftEndTime: "",
      notes: "",
      qrRequired: false,
      break: 0,
      multiDates: true,
      geoFencingEnabled: false,
      days: [0, 1, 2, 3, 4, 5, 6],
    },
  });
  useEffect(() => {
    form.reset();
  }, []);
  const watchedStartDate = useWatch({
    control: form.control,
    name: "shiftStartDate",
  });

  const watchedLocationId = useWatch({
    control: form.control,
    name: "locationId",
  });
  const [areasList, setAreasList] = useState<Array<LocationApi.area>>([]);
  useEffect(() => {
    if (watchedLocationId) {
      AreasModule.FetchAreasByLocationId(
        watchedLocationId,
        (data) => {
          setAreasList(data);
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, [watchedLocationId]);
  const { employeesList, locationList } = useBusinessContext();

  const toast = useToast();
  const [isLoading, setIsLoading] = useBoolean();
  const [selectAllAreas, setSelectAllAreas] = useState(false);
  useEffect(() => {
    const selectedAreas = form.getValues("areas");
    if (areasList?.length !== selectedAreas?.length) {
      setSelectAllAreas(false);
    }
  }, [areasList, form.watch("areas")]);
  const { setLastUpdated } = useScheduleContext();
  function isShiftFinishingNextDay(startTime: number, endTime: number) {
    const startTimeMoment = moment.unix(startTime);
    const endTimeMoment = moment.unix(endTime);
    const isBefore = endTimeMoment.isBefore(startTimeMoment);
    if (isBefore) {
      return {
        isNextDay: isBefore,
        endTime: endTimeMoment.clone().add(1, "day").unix(),
      };
    } else {
      return {
        isNextDay: isBefore,
        endTime,
      };
    }
  }
  const watchMultiDates = useWatch({
    control: form.control,
    name: "multiDates",
  });

  const daysOfWeek = [
    { value: 0, name: "S" },
    { value: 1, name: "M" },
    { value: 2, name: "T" },
    { value: 3, name: "W" },
    { value: 4, name: "T" },
    { value: 5, name: "F" },
    { value: 6, name: "S" },
  ];
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderTopRadius="6px">
            <HStack w="100%">
              <TextHeading
                flex={1}
                _light={{
                  color: "customColor.black7",
                }}
                fontSize=".9975rem"
              >
                Create Bulk Shifts
              </TextHeading>
            </HStack>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody
            borderTop={"1px solid #E5E5E5"}
            borderBottom={"1px solid #E5E5E5"}
            _dark={{
              bgColor: "customColor.dark.50",
            }}
            _light={{
              bgColor: "#FBFBFB",
            }}
          >
            <Stack spacing="16px">
              <HStack w="100%" spacing="16px" justifyContent="space-between">
                <Stack spacing="8px" w="100%">
                  <Text
                    mt="16px"
                    mb={0}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize="13.44px"
                    fontWeight={600}
                  >
                    Employees (Multiple)
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Employee is required",
                      },
                    }}
                    control={form.control}
                    name="employeeIds"
                    render={({ field, fieldState }) => {
                      return (
                        <ReactSelect
                          {...field}
                          withValidation
                          isMulti
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Please Select Employee"
                          placeholder="Select Employee"
                          options={employeesList}
                          value={
                            employeesList?.filter((emp: any) =>
                              field.value?.includes(emp.id)
                            ) ?? []
                          }
                          onChange={(val: any) => {
                            field.onChange(val.map((emp: any) => emp.id));
                          }}
                          getOptionLabel={(option: any) => {
                            return `${option?.firstName} (${option?.lastName})`;
                          }}
                          getOptionValue={(option: any) => option.id}
                          closeMenuOnSelect={false}
                        />
                      );
                    }}
                  />
                </Stack>

                <Stack spacing="8px" w="100%">
                  <Text
                    mt="16px"
                    mb={0}
                    _light={{
                      color: "customColor.black7",
                    }}
                    fontSize="13.44px"
                    fontWeight={600}
                  >
                    Location
                  </Text>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Location is required",
                      },
                    }}
                    control={form.control}
                    name="locationId"
                    render={({ field, fieldState }) => {
                      return (
                        <ReactSelect
                          {...field}
                          withValidation
                          isInvalid={fieldState.invalid}
                          ErrorMessage="Please Select Location"
                          placeholder="Select Location"
                          options={locationList}
                          value={
                            field.value
                              ? {
                                  id: field.value,
                                  name:
                                    locationList?.find(
                                      (loc: any) => loc.id === field.value
                                    )?.name ?? "",
                                }
                              : undefined
                          }
                          onChange={(val: any) => {
                            field.onChange(val?.id);
                            form.setValue("areas", []);
                            setSelectAllAreas(false);
                          }}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                        />
                      );
                    }}
                  />
                </Stack>
              </HStack>

              <Stack spacing="8px">
                <Text
                  mb={0}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={600}
                >
                  Areas
                </Text>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Area is required",
                    },
                  }}
                  control={form.control}
                  name="areas"
                  render={({ field, fieldState }) => {
                    const selectedAreas = areasList
                      .filter((area) => field.value?.includes(area.id!))
                      .map((area) => ({
                        label: area.name,
                        value: area.id,
                      }));
                    return (
                      <ReactSelect
                        {...field}
                        isMulti
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select Area"
                        placeholder="Select Area"
                        options={areasList.map((area) => ({
                          label: area.name,
                          value: area.id,
                        }))}
                        value={selectedAreas}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        onChange={(val: any) => {
                          field.onChange(
                            val.map(
                              (selectedOption: any) => selectedOption.value
                            )
                          );
                        }}
                      />
                    );
                  }}
                />
              </Stack>
              <HStack>
                <Checkbox
                  isChecked={selectAllAreas}
                  onChange={(e: any) => {
                    setSelectAllAreas(e.target.checked);
                    if (e.target.checked) {
                      form.setValue(
                        "areas",
                        areasList.map((area: any) => area.id)
                      );
                    } else {
                      form.setValue("areas", []);
                    }
                  }}
                />
                <Text
                  lineHeight={1}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={400}
                >
                  Do you want to add all areas?
                </Text>
              </HStack>
              <HStack>
                <Controller
                  control={form.control}
                  name="qrRequired"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        isChecked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    );
                  }}
                />

                <Text
                  lineHeight={1}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={400}
                >
                  Make QRCode Check-in Mandatory
                </Text>
              </HStack>
              <HStack>
                <Controller
                  control={form.control}
                  defaultValue={false}
                  name="geoFencingEnabled"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        isChecked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    );
                  }}
                />

                <Text
                  lineHeight={1}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={400}
                >
                  Enable geo-fencing
                </Text>
              </HStack>
              <HStack>
                <Controller
                  control={form.control}
                  name="multiDates"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if (!e.target.checked) {
                            form.setValue(
                              "shiftEndDate",
                              form.getValues("shiftStartDate")
                            );
                          }
                        }}
                      />
                    );
                  }}
                />

                <Text
                  lineHeight={1}
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={400}
                >
                  Multiple Dates
                </Text>
              </HStack>
              <Stack>
                <HStack spacing="16px">
                  <Stack flex={1}>
                    <Controller
                      name="shiftStartDate"
                      rules={{
                        required: {
                          value: true,
                          message: "Shift Date is required",
                        },
                      }}
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <CustomInput
                          input={{
                            ...field,
                            variant: "outline",
                            fontSize: ".845rem",
                            bg: "#fff",
                            padding: "12px 15px",
                            _light: {
                              color: "customColor.black7",
                            },
                            isInvalid:
                              (fieldState.error?.message?.length ?? 0) > 0,
                            value: field.value,
                            type: "date",
                            onChange: (e) => {
                              field.onChange(e.target.value);
                              !watchMultiDates &&
                                form.setValue(
                                  "shiftEndDate",
                                  form.getValues("shiftStartDate")
                                );
                            },
                          }}
                          label="Shift Date"
                          withValidation
                          validationMessage={fieldState.error?.message}
                          labelProps={{
                            fontSize: ".845rem",
                            fontWeight: "600",
                            _light: {
                              color: "customColor.black7",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  {watchMultiDates && (
                    <Stack flex={1}>
                      <Controller
                        name="shiftEndDate"
                        rules={{
                          required: {
                            value: true,
                            message: "End Date is required",
                          },
                        }}
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <CustomInput
                            input={{
                              ...field,
                              variant: "outline",
                              value: field.value,
                              type: "date",
                            }}
                            label="End Date"
                            withValidation
                            validationMessage={fieldState.error?.message}
                            labelProps={{
                              fontSize: ".845rem",
                              fontWeight: "600",
                              _light: {
                                color: "customColor.black7",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  )}
                </HStack>
              </Stack>
              {watchMultiDates && (
                <Controller
                  control={form.control}
                  name="days"
                  render={({ field, fieldState }) => (
                    <Stack>
                      <HStack align="start" spacing="16px">
                        {daysOfWeek.map((day, index) => {
                          // const isCheckedDefault = (
                          //   (field.value ?? []) as Array<number>
                          // ).findIndex((value) => value === day.value);
                          const isChecked = field?.value?.includes(day?.value);
                          return (
                            <Checkbox
                              key={index}
                              // isChecked={isCheckedDefault > -1}
                              isChecked={isChecked}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // allDays.append(day.value);
                                  field.onChange([
                                    ...(field?.value as any),
                                    day.value,
                                  ]);
                                } else {
                                  // allDays.remove(isCheckedDefault);
                                  field.onChange(
                                    field?.value?.filter(
                                      (value) => value !== day?.value
                                    )
                                  );
                                }
                              }}
                            >
                              {day.name}
                            </Checkbox>
                          );
                        })}
                      </HStack>
                      {fieldState.error && (
                        <Text color="red.500" fontSize="xs">
                          {fieldState.error.message}
                        </Text>
                      )}
                    </Stack>
                  )}
                />
              )}

              <HStack spacing="16px">
                <Stack flex={1}>
                  <Controller
                    name="shiftStartTime"
                    rules={{
                      required: {
                        value: true,
                        message: "Start time is required",
                      },
                    }}
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          fontSize: ".845rem",
                          bg: "#fff",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          value: field.value,
                          type: "time",
                        }}
                        label="Start"
                        withValidation
                        validationMessage={fieldState.error?.message}
                        labelProps={{
                          fontSize: ".845rem",
                          fontWeight: "600",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack flex={1}>
                  <Controller
                    name="shiftEndTime"
                    rules={{
                      required: {
                        value: true,
                        message: "End time is required",
                      },
                    }}
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          fontSize: ".845rem",
                          bg: "#fff",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          variant: "outline",
                          value: field.value,
                          type: "time",
                          onChange: (e) => {
                            const newEndTime = e.target.value;
                            const startTime = form.getValues("shiftStartTime");
                            // Assuming startTime and newEndTime are in 'HH:mm' format
                            const startDate = moment(watchedStartDate); // watchedStartDate needs to be in a format compatible with moment
                            const startDateTime = startDate
                              .clone()
                              .hour(parseInt(startTime.split(":")[0], 10))
                              .minute(parseInt(startTime.split(":")[1], 10));
                            const endDateTime = startDate
                              .clone()
                              .hour(parseInt(newEndTime.split(":")[0], 10))
                              .minute(parseInt(newEndTime.split(":")[1], 10));

                            // if (endDateTime.isBefore(startDateTime)) {
                            //   form.setValue(
                            //     "shiftEndDate",
                            //     startDate.add(1, "days").format("YYYY-MM-DD")
                            //   );
                            // } else {
                            //   form.setValue(
                            //     "shiftEndDate",
                            //     startDate.format("YYYY-MM-DD")
                            //   );
                            // }

                            field.onChange(newEndTime); // Update the end time with the new value
                          },
                        }}
                        label="End"
                        withValidation
                        validationMessage={fieldState.error?.message}
                        labelProps={{
                          fontSize: ".845rem",
                          fontWeight: "600",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </HStack>
              <HStack spacing="16px">
                <Stack flex={1}>
                  <Controller
                    name="break"
                    rules={{
                      required: {
                        value: true,
                        message: "Meal Break is required",
                      },
                    }}
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <CustomInput
                        input={{
                          ...field,
                          fontSize: ".845rem",
                          bg: "#fff",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          variant: "outline",
                          isInvalid:
                            (fieldState.error?.message?.length ?? 0) > 0,
                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.value));
                          },
                        }}
                        label="Break (Minutes)"
                        withValidation
                        validationMessage={fieldState.error?.message}
                        labelProps={{
                          fontSize: ".845rem",
                          fontWeight: "600",
                          _light: {
                            color: "customColor.black7",
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </HStack>
              <Stack>
                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  fontSize="13.44px"
                  fontWeight={600}
                >
                  Notes
                </Text>
                <Controller
                  name="notes"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Textarea
                      {...field}
                      placeholder="Add Notes"
                      // border="1px solid"
                      bg={"#fff"}
                      // borderColor="borders.tableBorder"
                      fontSize=".845rem"
                      fontWeight={400}
                      _light={{
                        color: "customColor.black7",
                      }}
                      padding="12px 15px"
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
                {form.formState.errors.notes && (
                  <Text color="red.500" fontSize="sm">
                    {form.formState.errors.notes.message}
                  </Text>
                )}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={isLoading}
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={form.handleSubmit((data) => {
                setIsLoading.on();
                if (!data.multiDates) {
                  delete data.days;
                }
                Shifts.AddBulksShifts(
                  data,
                  () => {
                    toast({
                      title: " Shifts Created",
                      status: "success",
                    });
                    setIsLoading.off();
                    onClose();
                    setLastUpdated(Date.now());
                    onRefresh?.();
                  },
                  (error) => {
                    toast({
                      title: "Error",
                      description: error,
                      status: "error",
                    });
                    setIsLoading.off();
                  }
                );
              })}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
}
