import {
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  UnorderedList,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import * as XLSX from "xlsx";
import { useBusinessContext } from "../../../../../../Hooks/BusinessContext";
import { BusinessTaxRate } from "../../../../../Api/BusinessTaxRate";
import { ChartOfAccounts } from "../../../../../Api/ChartOfAccounts";

const excelFile = require("../../../../../../assets/template/accounts.xlsx");
export function Import() {
  const [taxRate, setTaxRate] = useState<BusinessTaxRate.FetchTaxRate[]>([]);
  useEffect(() => {
    BusinessTaxRate.GetTaxRates(
      (data) => {
        setTaxRate(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  const [importData, setImportData] = useState<
    Array<ChartOfAccounts.BulkUploadData>
  >([]);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const data = [
    "MYOB Accounting or AccountRight Standard",
    "MYOB Accounting Plus or AccountRight Plus",

    "MYOB Premier or AccountRight Premier",
    "MYOB Premier Enterprise or AccountRight Enterprise",
    " MYOB AccountEdge",
    "Tips for importing",
  ];
  const { setLastUpdated, currentBusinessId } = useBusinessContext();
  const [isUploading, setIsUploading] = useBoolean(false);
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const uploadedData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setImportData(transformData(uploadedData));
    };

    reader.readAsBinaryString(file);
  };
  const transformData = (dataArray: any) => {
    return dataArray.slice(1).map((row: any) => ({
      code: row[0],
      name: row[1],
      accountType: row[2],
      accountCategory: row[3],
      businesTaxRateId: row[4],
      description: row[5],
      showDashboardWatchlist: row[6],

      showExpenseClaims: row[7],
      enablePayments: row[8],
      // businessId: row[9],
    }));
  };
  const consoleData = () => {
    const formattedData = {
      data: importData.map((item) => ({
        code: item.code,
        name: item.name,
        accountType: item.accountType,
        accountCategory: item.accountCategory,

        businesTaxRateId: taxRate.find(
          (taxRate) => taxRate.name === item.businesTaxRateId
        )?.id,

        description: item.description,
        showDashboardWatchlist: Boolean(item.showDashboardWatchlist),

        showExpenseClaims: Boolean(item.showExpenseClaims),
        enablePayments: Boolean(item.enablePayments),

        businessId: currentBusinessId,
      })),
    };
    setIsUploading.on();
    ChartOfAccounts.PostBulkUploadChartOfAccount(
      formattedData,
      (res) => {
        toast({
          title: "Chart of Account added successfully",
          status: "success",
        });

        setLastUpdated(Date.now());

        setIsUploading.off();
        handleDelete();
      },
      (err) => {
        setIsUploading.off();
        toast({
          title: "Something went wrong",
          description: err,
          status: "error",
        });
      }
    );
  };
  const toast = useToast();
  const handleDelete = () => {
    setImportData([]);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <Stack h="calc(100vh - 70px)" overflowY="auto" gap={0}>
      <Stack
        padding={"10px 21.2px 5.28px"}
        _light={{
          bg: "customColor.gray.450",
        }}
        _dark={{
          color: "customColor.dark.100",
        }}
      >
        <Container maxW={"980px"} padding={"0"}>
          <Stack h={"62px"}>
            <Text
              fontWeight={"bold"}
              _dark={{
                color: "customColor.dark.150",
              }}
              _light={{
                color: "customColor.black7",
              }}
              paddingLeft={"20px"}
              fontSize={"24px"}
              marginTop={"25px"}
            >
              Import your chart of account
            </Text>
          </Stack>
        </Container>
      </Stack>
      <Container maxW={"980px"} padding={"21.12px"}>
        <Stack direction={"row"}>
          <Box
            bg="#E8F1FF"
            flex={7}
            marginRight={"40px"}
            gap={0}
            border={"1px solid #c1c1c1"}
            h={"fit-content"}
          >
            <Stack padding={"10px 0px"} gap={0}>
              <Stack marginLeft={"10px"} gap={0}>
                <Stack padding={"0px 0px 25px"} gap={0}>
                  <Text
                    fontWeight={"700"}
                    fontSize={"13px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    What system are you importing from?
                  </Text>
                  <RadioGroup>
                    <Stack gap={0}>
                      <Radio value="1" margin={"5px 0px"}>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Hris360
                        </Text>
                      </Radio>
                      <Radio value="2" margin={"5px 0px"}>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          MYCO
                        </Text>
                      </Radio>
                      <Radio value="3" margin={"5px 0px"}>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          BankLink
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
                <Stack padding={"0px 0px 25px"}>
                  <Text
                    fontWeight={"700"}
                    fontSize={"13px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    Does the file you are importing contain account balances?
                  </Text>
                  <RadioGroup>
                    <Stack gap={0}>
                      <Radio value="1" margin={"5px 0px"}>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          Yes
                        </Text>
                      </Radio>
                      <Radio value="2" margin={"5px 0px"}>
                        <Text
                          fontSize={"13px"}
                          _light={{
                            color: "customColor.black7",
                          }}
                          _dark={{
                            color: "customColor.white",
                          }}
                        >
                          No / Don't know
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
                <Text
                  marginBottom={"5px"}
                  fontWeight={"700"}
                  fontSize={"13px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                >
                  Select the file to import
                </Text>
                {/* <HStack>
                  <Button
                    bg="#fff!important"
                    color={"primary.500"}
                    border={"1px solid #7f9db9"}
                    h={"25px"}
                    borderRadius={"4px"}
                    fontWeight={"bold"}
                  >
                    Browse
                  </Button>
                  <Text
                    fontSize={"12px"}
                   _light={{
                color: "customColor.black7",
              }}
                    _dark={{
                      color: "customColor.white",
                    }}
                  >
                    No Selected File
                  </Text>
                </HStack> */}
                <Input
                  ref={fileInputRef}
                  padding={0}
                  display={fileName ? "none" : "block"}
                  border="none"
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                  w="250px"
                />
                {fileName && (
                  <HStack spacing="2px" mt="16px">
                    <PiMicrosoftExcelLogoFill size={18} color="#21A366" />
                    <Text>{fileName}</Text>
                    <IconButton
                      aria-label={""}
                      icon={<MdDelete size={18} />}
                      bg="none"
                      color="gray.500"
                      _hover={{ bg: "none" }}
                      onClick={handleDelete}
                    />
                  </HStack>
                )}
              </Stack>
            </Stack>
            <Stack
              bg={"#D7E7FF"}
              padding={"8px 0px 10px"}
              alignItems={"flex-end"}
            >
              <Stack direction={"row"} padding={"0px 10px 0px 10px"}>
                <Button
                  isLoading={isUploading}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  h={"40px"}
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  onClick={() => {
                    importData.length > 0
                      ? consoleData()
                      : toast({
                          title: "Please upload a file or add data in file",
                          status: "error",
                        });
                  }}
                >
                  Import
                </Button>
                <Button
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box
            h={"fit-content"}
            bg="#FFFAEA"
            padding={"10px 15px"}
            flex={5}
            border={"1px solid #fbdbc2"}
            gap={0}
          >
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"13px"}
              fontWeight={"700"}
              padding={"0px 0px 5px"}
            >
              Example files
            </Text>
            <List marginBottom={"10px"}>
              <UnorderedList marginLeft={"17px"} marginBottom={"5px"}>
                <ListItem>
                  <Link
                    color="primary.950"
                    href={excelFile}
                    fontSize={"12px"}
                    download="accounts.xlsx"
                  >
                    Chart of Accounts CSV file for a GST registered company
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color="primary.950"
                    href={excelFile}
                    fontSize={"12px"}
                    download="accounts.xlsx"
                  >
                    Chart of Accounts CSV file for a Non-GST registered company
                  </Link>
                </ListItem>
              </UnorderedList>
            </List>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"13px"}
              padding={"0px 0px 5px"}
            >
              You can import your chart of accounts from:
            </Text>
            <List marginBottom={"10px"}>
              {data.map((item, index) => (
                <UnorderedList
                  marginLeft={"17px"}
                  marginBottom={"5px"}
                  key={index}
                >
                  <ListItem
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontSize={"13px"}
                  >
                    {item}
                  </ListItem>
                </UnorderedList>
              ))}
            </List>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"13px"}
              fontWeight={"700"}
              padding={"0px 0px 5px"}
            >
              Tips for importing
            </Text>
            <Text
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"13px"}
              padding={"0px 0px 5px"}
            >
              Whether you have created your own Chart of Accounts, exported one
              from your previous accounting system or you are using a Hris360
              Chart of Accounts, you should make sure you follow a few simple
              guidelines:
            </Text>
            <List marginBottom={"10px"}>
              <UnorderedList>
                <ListItem
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"13px"}
                  marginLeft={"17px"}
                  marginBottom={"5px"}
                >
                  The file you import must be a CSV (Comma Separated Values)
                  file. The name of your file should end with either .CSV or
                  .TXT.
                </ListItem>
                <ListItem
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"13px"}
                  marginLeft={"17px"}
                  marginBottom={"5px"}
                >
                  Be sure to include all of the active accounts you used in your
                  previous accounting system.
                </ListItem>
                <ListItem
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"13px"}
                  marginLeft={"17px"}
                  marginBottom={"5px"}
                >
                  If you're importing from another accounting system we
                  recommend that you first review your accounts in that system,
                  bringing your accounts up-to-date and removing any accounts
                  that are no longer active, before you import it into Hris360.
                </ListItem>
                <ListItem>
                  <Link
                    color="primary.950"
                    marginLeft={"17px"}
                    marginBottom={"5px"}
                    fontSize={"12px"}
                    download="ChartOfAccount.xlsx"
                  >
                    More help for importing Chart of Accounts
                  </Link>
                </ListItem>
              </UnorderedList>
            </List>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
}
