import {
  Avatar,
  Box,
  Checkbox,
  Container,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { Employees } from "../../Api/Employees";
import { PaginationControl } from "./PaginationControl/PaginationControl";

interface PastProps {
  paddingValue?: string;
  lastUpdatedEmployees: number;
  tabKey: string;
}
export default function Past({
  paddingValue,
  lastUpdatedEmployees,
  tabKey,
}: // tabKey,
PastProps) {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const [allPastEmployeesList, setAllPastEmployeesList] = useState<
    Array<Employees.FetchEmployees>
  >([]);
  const query = useQuery();
  const toast = useToast();
  const deafultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [pageNumber, setPageNumber] = useState<number>(
    deafultPage ? +deafultPage : 1
  );
  // useEffect(() => {
  //   setPageNumber(1); // Reset page number to 1 on tab switch
  // }, [tabKey]);
  const pageNumberInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState<number>(defaultLimit ? +defaultLimit : 25);
  useEffect(() => {
    Employees.FetchEmployees(
      {
        empStatus: "PAST",
        page: pageNumber,
        quantity: limit,
        search: searchText,
      },
      (data) => {
        setAllPastEmployeesList(data);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
      }
    );
  }, [pageNumber, searchText, lastUpdatedEmployees]);
  useEffect(() => {
    navigate(`?page=${pageNumber}&limit=${limit}`);
  }, [pageNumber, limit]);
  const pageCount = Math.ceil(allPastEmployeesList.length ?? 0 / limit);
  const debounced = useDebouncedCallback((value) => {
    setSearchText(value);
    setPageNumber(1);
  }, 1000);
  const navigate = useNavigate();
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedList, setCheckedList] = useState<Record<string, boolean>>({});

  const handleHeaderCheckboxChange = () => {
    setIsCheckedAll(!isCheckedAll);
    const newCheckedList: Record<string, boolean> = {};
    allPastEmployeesList?.forEach((employee: any) => {
      newCheckedList[employee.id] = !isCheckedAll;
    });
    setCheckedList(newCheckedList);
  };

  const handleCheckboxChange = (id: string) => {
    const newCheckedList = { ...checkedList, [id]: !checkedList[id] };
    setCheckedList(newCheckedList);
    const allChecked = Object.values(newCheckedList).every(Boolean);
    setIsCheckedAll(allChecked);
  };

  // const paginatedData = paginateData(pastEmployeesList);
  // const getPageNumbers = (totalPages: any) => {
  //   return Array.from({ length: totalPages }, (_, index) => index + 1);
  // };
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const renderPageNumbers = () => {
    const pageButtons = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            aria-label={`Page ${i}`}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }
    } else {
      pageButtons.push(
        <IconButton
          key={1}
          aria-label={`Page 1`}
          border={"1px solid #e8eaf2"}
          borderRadius={"full"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          backgroundColor={pageNumber === 1 ? "#3454D1" : "#fff"}
          color={pageNumber === 1 ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === 1 ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>1</Text>}
          onClick={() => handlePageChange(1)}
        />
      );

      if (pageNumber > 3) {
        pageButtons.push(<Text key="left-dots">...</Text>);
      }

      const startPage = Math.max(2, pageNumber - 1);
      const endPage = Math.min(pageCount - 1, pageNumber + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <IconButton
            key={i}
            border={"1px solid #e8eaf2"}
            style={{ transition: "color 0.2s backgroundColor 0.2s" }}
            borderRadius={"full"}
            aria-label={`Page ${i}`}
            backgroundColor={pageNumber === i ? "#3454D1" : "#fff"}
            color={pageNumber === i ? "white" : "black"}
            _hover={{
              backgroundColor: pageNumber === i ? "#3454D1" : "#3454D1",
              color: "white",
            }}
            icon={<Text>{i}</Text>}
            onClick={() => handlePageChange(i)}
          />
        );
      }

      if (pageNumber < pageCount - 2) {
        pageButtons.push(<Text key="right-dots">...</Text>);
      }

      pageButtons.push(
        <IconButton
          key={pageCount}
          border={"1px solid #e8eaf2"}
          style={{ transition: "color 0.2s backgroundColor 0.2s" }}
          borderRadius={"full"}
          aria-label={`Page ${pageCount}`}
          backgroundColor={pageNumber === pageCount ? "#3454D1" : "#fff"}
          color={pageNumber === pageCount ? "white" : "black"}
          _hover={{
            backgroundColor: pageNumber === pageCount ? "#3454D1" : "#3454D1",
            color: "white",
          }}
          icon={<Text>{pageCount}</Text>}
          onClick={() => handlePageChange(pageCount)}
        />
      );
    }

    return pageButtons;
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const { pastEmployeesList } = useBusinessContext();
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
      h={{
        xs: "calc(100vh - 200px)",
        md: "calc(100vh - 130px)",
      }}
      overflowY="auto"
      padding={{
        xs: "20px",
        sm: "20px",
        md: "20px",
        lg: paddingValue,
        xl: paddingValue,
      }}
    >
      <Container maxW={"container.xl"} padding={0}>
        {/* <Text
          fontWeight="600"
          lineHeight="1.2"
          color={"customColor.black7"}
          _dark={{
            color: "customColor.dark.150",
          }}
          fontSize="18px"
          paddingBottom={"10px"}
        >
          {" "}
          {pastEmployeesList?.length} Employee
        </Text> */}
        <Stack
          borderRadius="4px"
          gap={0}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
          borderWidth="1px"
          borderStyle={"solid"}
          borderColor="#f6f6f6"
        >
          <Stack
            p={"25px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box w={"100%"}>
              <PaginationControl
                limit={limit}
                setLimit={setLimit}
                setPageNumber={setPageNumber}
              />
            </Box>
            <Stack gap={0} direction={"row"} alignItems={"center"}>
              <Text
                fontSize={"13.44px"}
                _light={{
                  color: "customColor.gray.6b7885",
                }}
              >
                Search:
              </Text>
              <InputGroup ml={[0, "0px"]}>
                {/* <InputLeftElement>
                  <Icon as={FiSearch} />
                </InputLeftElement> */}
                <Input
                  type="text"
                  placeholder="Search"
                  borderRadius={"3px"}
                  h={["auto", "40px"]}
                  _focusVisible={{
                    border: "1px solid grey",
                  }}
                  onChange={(e) => {
                    debounced(e.target.value);
                  }}
                />
              </InputGroup>
            </Stack>
          </Stack>
          {/* <HStack height={"60px"}>
            <InputGroup ml={[0, "0px"]}>
              <InputLeftElement>
                <Icon as={FiSearch} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                borderRadius={"3px"}
                h={["auto", "40px"]}
                _focusVisible={{
                  border: "1px solid grey",
                }}
                onChange={(e) => {
                  debounced(e.target.value);
                }}
              />
            </InputGroup>
            <Button
              mr={["0", "7px"]}
              cursor={"not-allowed"}
              color={"#80858f"}
              padding={"7.52px 12px"}
              boxShadow={"none"}
              size={"lg"}
              fontSize={{ xs: "12px", sm: "14px" }}
              h={["auto", "38px"]}
              borderRadius={"3px"}
              border={"1px solid #a6a9b0"}
              backgroundColor={"#fff"}
              _hover={{
                backgroundColor: "#fff",
              }}
            >
              Invite to HRIS360 Me
            </Button>
          </HStack> */}
          <Box overflowX="auto" p="0px" maxWidth={"1164px"}>
            <Text
              textAlign="end"
              fontSize="12px"
              fontWeight={600}
              color="gray.400"
              marginRight={"25px"}
            >
              Total Employees {pastEmployeesList?.length}
            </Text>
            <Table variant="simple" w="100%" overflowX="auto">
              <Thead h={"36.8px"}>
                <Tr
                  color={"customColor.black7"}
                  _dark={{
                    color: "customColor.dark.150",
                  }}
                >
                  <Th
                    padding="8px 26px 8px 30px"
                    borderBottomColor="borders.tableBorder"
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    fontSize="12px"
                    textTransform="none"
                  >
                    <Checkbox
                      size="md"
                      borderColor="#adb5bd"
                      bgColor="white"
                      isChecked={isCheckedAll}
                      onChange={handleHeaderCheckboxChange}
                    />
                  </Th>
                  {[
                    {
                      name: "Employee ID",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Full Name",
                      value: "25%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Department",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Designation",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Location",
                      value: "15%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Date Of Joining",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                    {
                      name: "Action",
                      value: "10%",
                      paddingValue: "8px 26px 8px 15px",
                    },
                  ].map((item, i) => {
                    return (
                      <Th
                        key={i}
                        padding={item.paddingValue}
                        borderBottomColor="borders.tableBorder"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        fontWeight={700}
                        whiteSpace="nowrap"
                        fontSize="12px"
                        textTransform="none"
                        width={item.value}
                      >
                        {item.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody padding={0}>
                {/* {(filterEmployee() ?? paginatedData)?.map( */}
                {allPastEmployeesList?.map((emp: any, i: any) => {
                  return (
                    <Tr
                      key={i}
                      _hover={{
                        bgColor: "#f2f3f8",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/app/payroll/EmployeeDetail/${emp.id}`);
                      }}
                    >
                      <Td
                        padding="15px 15px 15px 30px"
                        fontSize="13.44px"
                        whiteSpace="nowrap"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        fontWeight={500}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Checkbox
                          size="md"
                          borderColor="#adb5bd"
                          bgColor="white"
                          isChecked={checkedList[emp.id] || false}
                          onChange={() => handleCheckboxChange(emp.id)}
                        />
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        {emp?.uniqueId}
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Stack direction={"row"} alignItems={"center"}>
                          <Avatar
                            size="sm"
                            objectFit="fill"
                            // objectFit="cover"
                            // padding={"20px"}
                            name={emp?.firstName + " " + emp?.lastName}
                            src={emp.photo}
                            // src={item?.photo}
                            // Other props
                            borderWidth={"2px"}
                            borderColor={"gray.200"}
                          />
                          <Text
                            _hover={{
                              color: "#3454d1",
                            }}
                          >
                            {" "}
                            {emp?.firstName + " " + emp?.lastName}
                          </Text>
                        </Stack>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text>
                          {" "}
                          {emp?.employeeEmployementDetails?.department}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text>
                          {" "}
                          {emp?.employeeEmployementDetails?.jobTitle}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text>{emp?.city}</Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontWeight={500}
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <Text>
                          {emp?.employeeEmployementDetails?.startDate}
                        </Text>
                      </Td>
                      <Td
                        padding="15px"
                        fontSize="13.44px"
                        color={"customColor.black7"}
                        _dark={{
                          color: "customColor.dark.150",
                        }}
                        lineHeight={1.5}
                        borderTopWidth="1px"
                        borderBottomWidth="0px"
                        borderTopColor={"borders.tableBorder"}
                      >
                        <HStack alignItems={"center"}>
                          <Icon
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            borderStyle={"solid"}
                            borderWidth={"1px"}
                            borderColor={"borders.tableBorder"}
                            bg={"#fff"}
                            w="25px"
                            h="25px"
                            px="5px"
                            py="5px"
                            as={MdOutlineRemoveRedEye}
                            fontSize="22px"
                            color="#6B7280"
                            _hover={{
                              color: "#3454d1",
                            }}
                            onClick={() => {
                              navigate(`/app/payroll/EmployeeDetail/${emp.id}`);
                            }}
                          />
                          <Tooltip label="Employee Report" fontSize="md">
                            <Icon
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="50%"
                              borderStyle={"solid"}
                              borderWidth={"1px"}
                              borderColor={"borders.tableBorder"}
                              bg={"#fff"}
                              w="25px"
                              h="25px"
                              px="5px"
                              py="5px"
                              as={IoDocumentText}
                              fontSize="22px"
                              color="#6B7280"
                              _hover={{
                                color: "#3454d1",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/app/payroll/full-details/${emp.id}`);
                              }}
                            />
                          </Tooltip>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Stack borderTop={"1px solid #dcdee4 "}>
            <HStack
              marginTop={"9.35px"}
              padding={"25px"}
              fontSize={"13px"}
              _light={{
                color: "customColor.black2",
              }}
              _dark={{
                color: "customColor.white",
              }}
              justifyContent={"space-between"}
            >
              <Text
                fontWeight={"normal"}
                color={"customColor.6b7885"}
                _dark={{
                  color: "customColor.dark.200",
                }}
                fontSize={"11px"}
              >
                Showing {limit * (pageNumber - 1) + 1} to{" "}
                {limit + limit * (pageNumber - 1)} of{" "}
                {allPastEmployeesList?.length} entries
              </Text>
              <HStack spacing="13px" justify={{ xs: "center", md: "end" }}>
                <IconButton
                  aria-label="back"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: "#3454D1",
                    color: "white",
                  }}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  icon={
                    <FaArrowLeft
                      size={15}
                      color={
                        isHovered2
                          ? "#FFFFFF"
                          : pageNumber <= 1 || pageNumber > pageCount
                          ? "#828ea0"
                          : "#283c50"
                      }
                    />
                  }
                  isDisabled={pageNumber <= 1 || pageNumber > pageCount}
                  onClick={() => {
                    if (pageNumber > 1 && pageNumber <= pageCount) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber - 1
                        ));
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                />
                <HStack spacing="13px">{renderPageNumbers()}</HStack>
                <IconButton
                  aria-label="right"
                  backgroundColor="transparent"
                  border={"1px solid #e8eaf2"}
                  borderRadius={"full"}
                  _hover={{
                    backgroundColor: "#3454D1",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  icon={
                    <FaArrowRight
                      size={15}
                      color={
                        isHovered
                          ? "#FFFFFF"
                          : pageNumber >= pageCount || pageNumber < 1
                          ? "#828ea0"
                          : "#283c50"
                      }
                      style={{ transition: "color 0.2s" }} // Add smooth transition for color change
                    />
                  }
                  isDisabled={pageNumber >= pageCount || pageNumber < 1}
                  onClick={() => {
                    if (pageNumber < pageCount && pageNumber >= 1) {
                      pageNumberInputRef.current?.value &&
                        (pageNumberInputRef.current.value = String(
                          pageNumber + 1
                        ));
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                />
              </HStack>
            </HStack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
