import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { IoMdCloseCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomInput } from "../../../Common/CustomInput";
import { ReactSelectForPayRollSetting } from "../../../Common/ReactSelectForPayRollSetting";
import { useBusinessContext } from "../../../Hooks/BusinessContext";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { TimeSheetNewDetail } from "../../Api/TimeSheetDetail";
import { Header } from "./Header";
import { AddProject } from "./Modal/AddProjectTime";
import { DeleteTimeSheet } from "./Modal/DeleteTimeSheet";
import { SaveTemplate } from "./Modal/SaveTemplate";
import { splitArrayIntoChunks } from "./utils";

interface InputValues {
  [lineId: string]: TimeSheetNewDetail.DayHours;
}
export function TimeSheetEnterDetail() {
  const form = useForm<TimeSheetNewDetail.AddNewDetail>({
    defaultValues: {
      employeeId: "",
      period: "",
      status: "",
      totalHours: 0,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      payRatesData: [
        {
          payItemsEarningsId: "",
          dayHours: {},
        },
      ],
    },
  });
  const { employeesList } = useBusinessContext();
  const { fields, append, remove } =
    useFieldArray<TimeSheetNewDetail.AddNewDetail>({
      control: form.control,
      name: "payRatesData",
    });
  let location = useLocation();

  const saveTemplate = useDisclosure();
  const [accountEarning, setAccountEarning] = useState<
    PayItemEarningApi.EarningById[]
  >([]);

  const deleteTimeSheet = useDisclosure();
  const addProjectTime = useDisclosure();

  useEffect(() => {
    PayItemEarningApi.GetTimeSheetPayItems(
      (res) => {
        setAccountEarning(res);
        setIsLoading(false);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
        setIsLoading(false);
      }
    );
    if (location.state?.timesheetId) {
      TimeSheetNewDetail.FetchTimeSheetById(
        location.state?.timesheetId,
        (res) => {
          form.reset(res);
          setIsLoading(false);
        },
        (err) => {
          toast({
            title: err,
            status: "error",
          });
          setIsLoading(false);
        }
      );
    }
  }, []);
  const toast = useToast();
  const startDate = location.state?.dateRange?.startDate;
  const endDate = location.state?.dateRange?.endDate;
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = useState<Array<Moment>>([]);
  const [splitedDays, setSplitedDays] = useState<Array<Array<Moment>>>([]);
  const lastDateOfWeek =
    splitedDays[weeklyIndex]?.[splitedDays[weeklyIndex]?.length - 1]?.format(
      "DD MMM YYYY"
    );

  useEffect(() => {
    let startDateMoment = moment(startDate, "YYYY-MM-DD");
    let endDateMoment = moment(endDate, "YYYY-MM-DD");
    let numberOfDays = endDateMoment.diff(startDateMoment, "days");
    let days = Array.from({ length: numberOfDays + 1 }).map((_, i) => {
      return moment(startDateMoment).add(i, "days");
    });
    setDays(days);
    const defaultdayHours = days.reduce((acc, day) => {
      return {
        ...acc,
        [day.format("ddd DD MMM")]: 0,
      };
    }, {});

    form.setValue("payRatesData.0.dayHours", defaultdayHours);
    setSplitedDays(splitArrayIntoChunks(days));
  }, []);

  const watchedPayRateData = useWatch({
    control: form.control,
    name: "payRatesData",
  });

  const [sums, setSums] = useState({});

  const calculateSums = (): Record<string, number> => {
    const newSums: Record<string, number> = {};
    const visibleDays =
      splitedDays[weeklyIndex]?.map((day) => day.format("ddd DD MMM")) || [];

    watchedPayRateData.forEach((payRateData) => {
      visibleDays.forEach((day) => {
        if (!newSums[day]) {
          newSums[day] = 0;
        }
        newSums[day] += parseFloat(String(payRateData.dayHours[day])) || 0;
      });
    });
    const newTotalHours = Object.values(newSums).reduce(
      (total, hours) => total + hours,
      0
    );

    return newSums;
  };
  useEffect(() => {
    setSums(calculateSums());
  }, [watchedPayRateData, weeklyIndex]);

  const [inputValues, setInputValues] = useState<InputValues>({});
  const resetDayHoursForPage = (pageIndex: number) => {
    const updatedPayRateData = watchedPayRateData.map((payRateData, index) => {
      const lineId = fields[index].id;
      const lineInputValues = inputValues[lineId] || {};
      const updatedDayHours: TimeSheetNewDetail.DayHours = {
        ...payRateData.dayHours,
      };
      splitedDays[pageIndex]?.forEach((day) => {
        const formattedDate = day.format("ddd DD MMM");
        if (lineInputValues[formattedDate] !== undefined) {
          updatedDayHours[formattedDate] = lineInputValues[formattedDate];
        }
      });

      return { ...payRateData, dayHours: updatedDayHours };
    });

    form.reset({ payRatesData: updatedPayRateData });
  };
  const [weeklySums, setWeeklySums] = useState<Array<number>>([]);
  const calculateWeeklySums = () => {
    const newWeeklySums: Array<number> = [];

    for (let i = 0; i < splitedDays.length; i++) {
      const weeklySum = watchedPayRateData.reduce((total, payRateData) => {
        return (
          total +
          splitedDays[i]?.reduce((weekTotal: number, day: any) => {
            const formattedDate = day.format("ddd DD MMM");
            const dayHours = payRateData?.dayHours[formattedDate] ?? 0;
            return weekTotal + Number(dayHours);
          }, 0)
        );
      }, 0);

      newWeeklySums.push(weeklySum);
    }

    return newWeeklySums;
  };
  const navigate = useNavigate();
  useEffect(() => {
    setWeeklySums(calculateWeeklySums());
  }, [watchedPayRateData, weeklyIndex]);
  const totalHours = weeklySums.reduce((total, sum) => total + sum, 0);
  const onSubmit = (data: TimeSheetNewDetail.AddNewDetail, Status: string) => {
    const PostPayload: TimeSheetNewDetail.AddNewDetail = {
      employeeId: location.state?.employeeId,
      period: location.state?.period,
      payrollCalendarId: location.state?.PayrollCalendarId,
      status: Status,
      totalHours: totalHours,
      dateRange: {
        startDate: location.state?.dateRange?.startDate,
        endDate: location.state?.dateRange?.endDate,
      },
      payRatesData: data.payRatesData.map((payRate) => ({
        payItemsEarningsId: payRate.payItemsEarningsId,
        dayHours: payRate.dayHours,
      })),
    };
    TimeSheetNewDetail.AddTimesheet(
      PostPayload,
      (success) => {
        toast({
          title: "Success",
          description: "Timesheet created successfully",
          status: "success",
        });
        navigate("/app/payroll/Timesheets");
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  };

  const onUpdate = (data: TimeSheetNewDetail.AddNewDetail, Status: string) => {
    const UpdatePayload: TimeSheetNewDetail.AddNewDetail = {
      employeeId: location.state?.employeeId,
      period: location.state?.period,
      status: Status,
      payrollCalendarId: location.state?.PayrollCalendarId,
      totalHours: totalHours,
      dateRange: {
        startDate: location.state?.dateRange?.startDate,
        endDate: location.state?.dateRange?.endDate,
      },
      payRatesData: data.payRatesData.map((payRate) => ({
        timesheetId: payRate.timesheetId,
        id: payRate.id,
        payItemsEarningsId: payRate.payItemsEarningsId,
        dayHours: payRate.dayHours,
      })),
    };
    TimeSheetNewDetail.PatchTimeSheet(
      location.state?.timesheetId,
      UpdatePayload,
      (success) => {
        toast({
          title: "Success",
          description: success,
          status: "success",
        });
        navigate("/app/payroll/Timesheets");
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
        });
      }
    );
  };

  return (
    <FormProvider {...form}>
      <Stack
        gap={0}
        minW={"980px"}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Box
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          borderBottom={"1px solid #cfd2d4"}
        >
          <Container
            h="50px"
            maxW={"100%"}
            padding={"0px 20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="Timesheets"
                  fontSize={"11px"}
                  color={"#3454d1"}
                >
                  Timesheet
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href=""
                  fontSize={"11px"}
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  padding={"0px 20px 0px 0px"}
                >
                  {employeesList?.find(
                    (employee) => employee.id === location.state?.employeeId
                  )?.firstName +
                    " " +
                    employeesList?.find(
                      (employee) => employee.id === location.state?.employeeId
                    )?.lastName}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Heading
              fontWeight={"bold"}
              _light={{
                color: "customColor.black7",
              }}
              _dark={{
                color: "customColor.white",
              }}
              fontSize={"18px"}
              margin={"0px 4px 0px 0px"}
            >
              {`Timesheets for the ${location.state?.period}`}
            </Heading>
          </Container>
        </Box>

        <Container maxW={"container.lg"} padding={"20px 20px"}>
          <Header
            Status={location.state?.status}
            employeeId={location.state?.employeeId}
            weeklyIndex={weeklyIndex}
            setWeeklyIndex={setWeeklyIndex}
            splitedDays={splitedDays}
            resetDayHoursForPage={resetDayHoursForPage}
            lastDateOfWeek={lastDateOfWeek}
            totalWeeksSum={totalHours}
          />

          <Stack paddingTop={"20px"} paddingBottom={"20px"} gap={0}>
            <Skeleton height={"100%"} isLoaded={!isLoading}>
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                margin={"0px 0px 30px"}
                padding={"15px 0px 20px"}
                border="1px solid #f6f6f6"
                borderRadius="4px"
                boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
              >
                <Stack direction={"row"}>
                  <Stack padding={"0px 0px 0px 20px"} w={"363.25px"}>
                    <Text
                      padding={"10px 0px 11px 0px"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                      fontSize={"12px"}
                      fontWeight={"bold"}
                    >
                      Earnings Rate
                    </Text>
                  </Stack>
                  {splitedDays?.[weeklyIndex]?.map((day, index) => (
                    <Stack
                      key={index}
                      padding={"10px 0px 0px"}
                      w={"49.04px"}
                      gap={0}
                    >
                      <Text
                        fontSize={"12px"}
                        fontWeight={"normal"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                      >
                        {day.format("ddd")}
                      </Text>
                      <Text
                        fontSize={"12px"}
                        fontWeight={"bold"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                      >
                        {day.format("DD MMM")}
                      </Text>
                    </Stack>
                  ))}

                  <Stack padding={"10px 0px 0px"} w={"98.08px"}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"bold"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                    >
                      Unit
                    </Text>
                  </Stack>
                  <Stack padding={"10px 0px 0px"} w={"49.04px"}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"bold"}
                      _light={{
                        color: "customColor.black7",
                      }}
                      _dark={{
                        color: "customColor.dark.250",
                      }}
                    ></Text>
                  </Stack>
                </Stack>

                {fields.map((field, index) => {
                  return (
                    <Stack key={field.id}>
                      <Stack direction={"row"} marginBottom={"10px"}>
                        <Stack padding={"0px 0px 0px 20px"}>
                          <Box width={"343.25px"} h={"30px"}>
                            <Controller
                              name={`payRatesData.${index}.payItemsEarningsId`}
                              control={form.control}
                              render={({ field }) => (
                                <ReactSelectForPayRollSetting
                                  {...field}
                                  placeholder="Select"
                                  ErrorMessage="Select Account type"
                                  withValidation
                                  options={accountEarning}
                                  value={accountEarning.find(
                                    (option) => option.id === field.value
                                  )}
                                  onChange={(newValue: any) => {
                                    field.onChange(newValue?.id);
                                  }}
                                  getOptionLabel={(option: any) => option.name}
                                  getOptionValue={(option: any) => option.id}
                                />
                              )}
                            />
                          </Box>
                        </Stack>

                        {splitedDays?.[weeklyIndex]?.map((day, i) => (
                          <Stack key={i} w={"49.04px"}>
                            <Box>
                              <Controller
                                key={`0-${i}`}
                                control={form.control}
                                name={`payRatesData.${index}.dayHours.${day.format(
                                  "ddd DD MMM"
                                )}`}
                                render={({ field }) => (
                                  <CustomInput
                                    withValidation
                                    input={{
                                      ...field,
                                      isInvalid: false,
                                      defaultValue: 0,
                                      w: "100%",
                                      h: "38px",
                                      fontSize: "12px",
                                      padding: "0px",
                                      textAlign: "center",
                                      variant: "outline",
                                      type: "number",
                                      borderWidth: "1px",
                                      bg: "#fff",
                                      borderStyle: "solid",
                                      border: "1px solid",
                                      borderColor: "gray.200",
                                      min: 0,
                                      max: 24,
                                      borderRadius: 0,
                                      // onChange: (
                                      //   e: React.ChangeEvent<HTMLInputElement>
                                      // ) => {
                                      //   field.onChange(
                                      //     parseFloat(e.target.value) || 0
                                      //   );
                                      //   const lineId = fields[index].id;
                                      //   setInputValues((prevValues) => ({
                                      //     ...prevValues,
                                      //     [lineId]: {
                                      //       ...prevValues[lineId],
                                      //       [day.format("ddd DD MMM")]:
                                      //         parseFloat(e.target.value) || 0,
                                      //     },
                                      //   }));
                                      // },
                                      onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        const value =
                                          parseFloat(e.target.value) || 0;
                                        // Clamping the value between 0 and 24
                                        const clampedValue = Math.min(
                                          24,
                                          Math.max(0, value)
                                        );
                                        field.onChange(clampedValue);
                                        const lineId = fields[index].id;
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          [lineId]: {
                                            ...prevValues[lineId],
                                            [day.format("ddd DD MMM")]:
                                              clampedValue,
                                          },
                                        }));
                                      },
                                    }}
                                    placeholder=""
                                  />
                                )}
                              />
                            </Box>
                          </Stack>
                        ))}
                        <Stack
                          w={"98.08px"}
                          borderTop={"1px solid #e8e8e8"}
                          borderBottom={"1px solid #e8e8e8"}
                          h={"38px"}
                        >
                          <Text
                            paddingTop={"6px"}
                            color={"customColor.black7"}
                            fontWeight={500}
                            fontSize="13.44px"
                          >
                            {splitedDays[weeklyIndex]?.reduce((total, day) => {
                              const formattedDate = day.format("ddd DD MMM");
                              const dayHours =
                                watchedPayRateData[index]?.dayHours[
                                  formattedDate
                                ] ?? 0;
                              return total + Number(dayHours);
                            }, 0)}
                          </Text>
                        </Stack>
                        <Stack w={"49.04px"}>
                          <Button
                            onClick={() => remove(index)}
                            variant={"ghost"}
                            fontSize={"13px"}
                            _light={{
                              color: "customColor.black",
                            }}
                            _dark={{
                              color: "customColor.white",
                            }}
                            _hover={{
                              color: "#c00",
                              bg: "transparent",
                            }}
                            padding={"5px"}
                            w={"25px"}
                          >
                            <Icon
                              as={IoMdCloseCircle}
                              color={"#555"}
                              height={"17px"}
                              w={"17px"}
                            />
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })}
                <Stack direction={"row"} paddingLeft={"20px"}>
                  <Stack w={"343.25px"}></Stack>

                  {Object.entries(sums).map(([day, sum]) => (
                    <Stack
                      w={"49.04px"}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      <Text
                        key={day}
                        color={"customColor.black7"}
                        fontWeight={500}
                        fontSize="13.44px"
                      >{`${sum}`}</Text>
                    </Stack>
                  ))}
                  <Stack w={"98.08px"}></Stack>
                  <Stack w={"49.04px"}></Stack>
                </Stack>

                <Stack direction={"row"} paddingLeft={"20px"}>
                  <Button
                    fontSize={"13px"}
                    bgColor="#3454D1"
                    borderRadius={"3px"}
                    borderWidth={"1px"}
                    borderColor={"#3454d1!important"}
                    variant={"solid"}
                    padding={"12px 16px"}
                    fontWeight={700}
                    _hover={{
                      bgColor: "#3454D1",
                    }}
                    onClick={() => {
                      const newDayHours: TimeSheetNewDetail.DayHours = {};
                      days?.forEach((day) => {
                        newDayHours[day.format("ddd DD MMM")] = 0;
                      });

                      append({
                        payItemsEarningsId: "",
                        dayHours: newDayHours,
                      });
                    }}
                  >
                    + Add another line
                  </Button>
                  <AddProject
                    isOpen={addProjectTime.isOpen}
                    onClose={addProjectTime.onClose}
                  />
                </Stack>
              </Stack>
            </Skeleton>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {location.state?.timesheetId && (
              <Button
                variant="solid"
                fontSize={"13px"}
                margin={"0px 0px 0px 10px"}
                padding={"10px 15px"}
                borderRadius={"3px"}
                border={"1px solid #FDEDED"}
                bg={"#FDEDED"}
                color={"#EA4D4D"}
                minW={"75px"}
                _hover={{
                  border: "1px solid #EA4D4D",
                  bg: "#EA4D4D",
                  color: "#fff",
                }}
                onClick={deleteTimeSheet.onOpen}
              >
                Delete Timesheet
              </Button>
            )}

            <Stack direction={"row"}>
              <Button
                type="submit"
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                textColor={"customColor.black7"}
                padding={"12px 16px"}
                fontWeight={600}
                _hover={{
                  backgroundColor: "#fff",
                  color: "primary.950",
                }}
                // onClick={form.handleSubmit(onSubmit)}
                onClick={form.handleSubmit((data) => {
                  if (location.state?.timesheetId) {
                    onUpdate(data, "DRAFT");
                  } else {
                    onSubmit(data, "DRAFT");
                  }
                })}
              >
                Save Draft
              </Button>
              <Button
                fontSize={"13px"}
                bgColor="#3454D1"
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={form.handleSubmit((data) => {
                  if (location.state?.timesheetId) {
                    onUpdate(data, "APPROVED");
                  } else {
                    onSubmit(data, "APPROVED");
                  }
                })}
              >
                Approve
              </Button>
              <Button
                backgroundColor={"#fff"}
                borderRadius={"3px"}
                color={"#ea4d4d"}
                border={"1px solid #dcdee4"}
                fontSize={"13px"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  navigate("/app/payroll/Timesheets");
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
          <SaveTemplate
            isOpen={saveTemplate.isOpen}
            onClose={saveTemplate.onClose}
          />
          <DeleteTimeSheet
            isOpen={deleteTimeSheet.isOpen}
            onClose={deleteTimeSheet.onClose}
            timesheetId={location.state?.timesheetId}
          />
        </Container>
      </Stack>
    </FormProvider>
  );
}
