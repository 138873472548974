import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export default function Header() {
  return (
    <Stack
      _dark={{
        bgColor: "customColor.dark.100",
      }}
      _light={{
        bgColor: "customColor.gray.400",
      }}
    >
      <Stack padding="20px 20px 30px 20px">
        <Container
          justifyContent="center"
          alignItems="center"
          maxW={"container.xl"}
          padding="0px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          border="1px solid #d6dade"
          borderRadius="md"
        >
          <SimpleGrid columns={[3, 3]}>
            {[
              {
                name: "Total",
                width: 1,
                number: "0.00",
                nameColor: "#999999",
                numberColor: "#283C50",
              },
              {
                name: "Liability",
                number: "3,832.40",
                width: 1,
                nameColor: "#999999",
                numberColor: "#283C50",
              },

              {
                name: "Status",
                width: 3,
                number: "Draft",
                nameColor: "#999999",
                numberColor: "#AAAAAA",
              },
            ].map((item) => (
              <Stack
                flex={item.width}
                key={item.name}
                borderRight="1px solid #e8ebee"
                padding="20px 10px 20px 10px"
                gap="0rem"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                <Heading
                  fontSize="12px"
                  fontWeight="bold"
                  _light={{
                    color: item.nameColor,
                  }}
                  _dark={{
                    color: "customColor.cream",
                  }}
                >
                  {item.name}
                </Heading>
                <Stack
                  padding={0}
                  gap="0.2rem"
                  direction={{ xs: "column", lg: "row" }}
                >
                  <Tooltip label={item.number} fontSize="md">
                    <Text
                      _light={{
                        color: item.numberColor,
                      }}
                      _dark={{
                        color: "customColor.pearlWhite",
                      }}
                      fontWeight="bold"
                      fontSize="24px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {item.number}
                    </Text>
                  </Tooltip>
                  {/* <Text
                    color="#AAAAAA"
                    fontWeight="400"
                    fontSize="13px"
                    whiteSpace="nowrap"
                    padding={0}
                  >
                    {item.decimal}
                  </Text> */}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
    </Stack>
  );
}
