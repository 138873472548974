import { axiosInstance } from "./axiosInstance-hris";

declare module MappingModuleApi {
  type EmployeeDetailFilters = {
    empType?: string;
    empIncomeType?: string;
    empBasis?: string;
    payrollPayCalenderId?: string;
    employeeGroupId?: string;
    holidayGroupId?: string;
    ratesId?: string;
    startDate?: string;
    department?: string;
    jobTitle?: string;
    includeHolidaysPayslips?: boolean;
    approveLeaveAuthorization?: boolean;
    approveLeaveTimesheets?: boolean;
    isManagementStaff?: boolean;
    manager?: string;
  };
  type PayTemplateFilters = {
    earning?: string;
    deduction?: string;
    retirement?: string;
    reimbursement?: string;
  };
  type LeaveEntitlementFilters = {
    payrollPayItemLeaveId?: string;
    calculationMethod?: string;
    quantity?: number;
    balance?: number;
    terminationUnusedBalance?: string;
    etpLeaveType?: boolean;
    includeRetirementContribution?: boolean;
    accruedQuantity?: number;
    openingBalance?: number;
  };
  type FetchEmployeesForMapping = {
    leaveEntitlement?: LeaveEntitlementFilters;
    payTemplate?: PayTemplateFilters;
    employmentDetails?: EmployeeDetailFilters;
  };
  type Leave = {
    payrollPayItemLeaveId?: string;
    calculationMethod?: string;
    quantity?: number;
    terminationUnusedBalance?: string;
    etpLeaveType?: boolean;
    includeRetirementContribution?: boolean;
    accruedQuantity?: number;
    ids: Array<string>;
  };
  type EmploymentDetailData = {
    empType?: string;
    empIncomeType?: string;
    empBasis?: string;
    payrollPayCalenderId?: string;
    employeeGroupId?: string;

    ratesId?: string;
    startDate?: string;

    department?: string;
    jobTitle?: string;

    isManagementStaff?: boolean;
    manager?: string;
    ids: any[];
  };
  type AddNewPayTemp = {
    empIds: Array<string>;
    earnings: Array<EarningRate>;
    deductions: Array<DeductionRate>;
    reimbursments: Array<ReimbursementRate>;
    retirements: Array<RetirementRate>;
  };
  type EarningRate = {
    id?: string;
    payItemEarningId: string;
    calculationType?: string;
    category: string;
    hours?: number | null;
    annualSalary?: number | null;
    earningFixedAmount?: number;
    rate?: number;
    rateType: string;
  };
  type DeductionRate = {
    id?: string;
    payItemDeductionId: string;
    calculationType?: string;
    category: string;
    amount?: number;
    percentage?: number;
    minimumWage?: number;
    rateType?: string;
  };
  type RetirementRate = {
    id?: string;
    payItemRetirementId: string;
    amount?: number;
    description?: string;
    rateType?: string;
  };
  type ReimbursementRate = {
    id?: string;
    payItemReimbursementId: string;
    amount?: number;
    description?: string;
    rateType?: string;
  };
}
module MappingModuleApi {
  const EMPLOYEE_MAPPING_API_URL = "/employee-employement-details";
  const PAY_TEMPLATE_MAPPING_API_URL = "/employees";
  const LEAVE_ENTITLEMENT_API_URL = "/employee-leave-entitlements";
  export function fetchEmployeeForMapping(
    data: FetchEmployeesForMapping,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${PAY_TEMPLATE_MAPPING_API_URL}/find-by-criteria`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.message);
      });
  }
  export function PayTemplateBulkUpdate(
    data: AddNewPayTemp,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${PAY_TEMPLATE_MAPPING_API_URL}/pay-template-bulk-update`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.message);
      });
  }
  export function UpdateBulkEmployeeDetails(
    data: EmploymentDetailData,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${EMPLOYEE_MAPPING_API_URL}/update-bulk`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.message);
      });
  }
  export function UpdateLeaveEntitlement(
    data: Leave,
    success: (data: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${LEAVE_ENTITLEMENT_API_URL}/bulk-update`, data)
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.message);
      });
  }
}
export { MappingModuleApi };
