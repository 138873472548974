import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { PayItemDeductionApi } from "../../Api/PayItemDeduction";
import { PayItemEarningApi } from "../../Api/PayItemEarning";
import { PayItemReimbursementApi } from "../../Api/PayItemReinbursement";

import { ChevronRightIcon } from "@chakra-ui/icons";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PayItemLeaveApi } from "../../Api/PayItemLeave";
import { PayItemRetirement } from "../../Api/PayItemRetirement";
import { PayRun } from "../../Api/PayRun";
import { PayTemp } from "../../Api/PayTemp";
import { TaxFinancialYear } from "../../Api/TaxFinancialYear";
import { HeaderPaySlip } from "./Common/HeaderPaySlip";
import { FinalPayModal } from "./FinalPayModal";
import AddDeduction from "./PayRunComponents/AddDeduction";
import AddEarning from "./PayRunComponents/AddEarning";
import AddLeaves from "./PayRunComponents/AddLeaves";
import AddReimbursementRate from "./PayRunComponents/AddReimbursementRate";
import AddTaxes from "./PayRunComponents/AddTaxes";
import { Retirement } from "./PayRunComponents/retirement";
import AddDeductionLine from "./PayRunModals/AddDeductionLine";
import AddEarningLine from "./PayRunModals/AddEarningLine";
import AddLeavesModal from "./PayRunModals/AddLeavesModal";
import AddReimbursementRateLine from "./PayRunModals/AddReimbursementRateLine";
import { StaturyBenefitsModal } from "./PayRunModals/StaturyBenefitsModal";
import { TaxLineModal } from "./PayRunModals/TaxLine";
import { generatePayRunItems } from "./Utils/generatePayRunItems";

export function PayRunPaySlip() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let location = useLocation();
  const navigate = useNavigate();
  const form = useForm<PayRun.PayrunByEmpId>({
    defaultValues: {
      earnings: [],
      deductions: [],
      reimbursments: [],
      retirements: [],
      taxes: [],
      leaves: [],
      paymentDate: "",
      bankDetails: {
        id: "",
        empId: "",
        accountName: "",
        accountNumber: "",
        bankName: "",
        branchCode: "",
        iban: "",
      },
      note: "",
    },
  });
  const taxLine = useDisclosure();
  const earningLine = useDisclosure();
  const [accountEarning, setAccountEarning] = useState<
    PayItemEarningApi.EarningById[]
  >([]);
  const deductionLine = useDisclosure();
  const [accountDeduction, setAccountDeduction] = useState<
    PayItemDeductionApi.DeductionById[]
  >([]);
  const reimbursementLine = useDisclosure();
  const [accountReimbursement, setAccountReimbursement] = useState<
    PayItemReimbursementApi.ReimbursementById[]
  >([]);
  const earningFieldArray = useFieldArray({
    control: form.control,
    name: "earnings",
    keyName: "uniqueId",
  });
  const deductionFieldArray = useFieldArray({
    control: form.control,
    name: "deductions",
    keyName: "uniqueId",
  });
  const reimbursementFieldArray = useFieldArray({
    control: form.control,
    name: "reimbursments",
    keyName: "uniqueId",
  });
  const taxesFieldArray = useFieldArray({
    control: form.control,
    name: "taxes",
    keyName: "uniqueId",
  });
  const retirementFieldArray = useFieldArray({
    control: form.control,
    name: "retirements",
    keyName: "uniqueId",
  });
  const leavesFieldArray = useFieldArray({
    control: form.control,
    name: "leaves",
    keyName: "uniqueId",
  });
  const leavesModal = useDisclosure();
  const retirementModal = useDisclosure();
  const [queryParams, setQueryParams] = useSearchParams();
  const [paySlipData, setPaySlipData] = useState<PayRun.PayrunByEmpId>();
  const finalPay = useDisclosure();
  const [lastUpdated, setLastUpdated] = useState<number>();
  useEffect(() => {
    let payslipId = queryParams.get("payslipId");
    if (payslipId) {
      PayRun.getPayItemByEmpId(
        payslipId,
        (success) => {
          form.reset(success);
          setPaySlipData(success);
          setIsLoading(false);
        },
        (err) => {
          toast({
            title: "Error",
            status: "error",
            description: "Pay Item Fetch Failed",
          });
          setIsLoading(false);
        }
      );
    }
  }, []);
  const [activeTaxFinancialYear, setActiveTaxFinancialYear] =
    useState<TaxFinancialYear.FetchTax>();
  useEffect(() => {
    TaxFinancialYear.GetActiveTaxFinancialYear(
      (data) => {
        setActiveTaxFinancialYear(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          status: "error",
          description: error,
        });
        setIsLoading(false);
      }
    );
  }, []);
  const watchedEarning = useWatch({
    control: form.control,
    name: "earnings",
  });
  const totalEarningsAmount = useCallback(() => {
    const earningArray = watchedEarning || [];
    return Math.round(
      earningArray?.reduce(
        (acc: any, item: any) => acc + Number(item.amount || 0),
        0
      )
    );
  }, [watchedEarning]);

  const watchedDeduction = useWatch({
    control: form.control,
    name: "deductions",
  });

  const totalDeductionsAmount = useCallback(() => {
    const deductionArray = watchedDeduction || [];
    return Math.round(
      deductionArray.reduce(
        (acc: any, item: any) => acc + Number(item.amount || 0),
        0
      )
    );
  }, [watchedDeduction]);

  const watchedReimbursement = useWatch({
    control: form.control,
    name: "reimbursments",
  });

  const totalReimbursementAmount = useCallback(() => {
    const reimbursementArray = watchedReimbursement || [];
    return Math.round(
      reimbursementArray.reduce(
        (acc: any, item: any) => acc + Number(item.amount || 0),
        0
      )
    );
  }, [watchedReimbursement]);

  const watchedTaxes = useWatch({
    control: form.control,
    name: "taxes",
  });

  const totalTaxesAmount = useCallback(() => {
    const taxesArray = watchedTaxes || [];
    return Math.round(
      taxesArray.reduce((acc, item) => acc + Number(item.amount || 0), 0)
    );
  }, [watchedTaxes]);

  const watchedRetirement = useWatch({
    control: form.control,
    name: "retirements",
  });
  const totalRetirementAmount = useCallback(() => {
    const retirementArray = watchedRetirement || [];
    return Math.round(
      retirementArray.reduce(
        (acc: any, item: any) => acc + Number(item.amount || 0),
        0
      )
    );
  }, [watchedRetirement]);
  const TotalNetPay =
    Number(totalEarningsAmount()) -
    Number(totalTaxesAmount()) -
    Number(totalDeductionsAmount()) +
    Number(totalReimbursementAmount());
  const payFrequency =
    paySlipData?.payRun?.payrollCalendar?.payPeriod === "WEEKLY"
      ? 52
      : paySlipData?.payRun?.payrollCalendar?.payPeriod === "FORTNIGHTLY"
      ? 26
      : paySlipData?.payRun?.payrollCalendar?.payPeriod === "MONTHLY"
      ? 12
      : paySlipData?.payRun?.payrollCalendar?.payPeriod === "QUARTERLY"
      ? 4
      : paySlipData?.payRun?.payrollCalendar?.payPeriod === "FOUR_WEEKLY"
      ? 13
      : 0;

  function recalculatePayslip() {
    const formData = form.getValues();

    const empId = queryParams.get("employeeId");
    const payRunId = queryParams.get("payRunId");
    const payslipId = queryParams.get("payslipId");

    if (empId && payRunId && activeTaxFinancialYear && payslipId) {
      const recalculatedIems = generatePayRunItems(
        empId,
        payRunId,
        [
          ...formData.deductions,
          ...formData.earnings,
          ...formData.reimbursments,
          ...formData.taxes,
          ...formData.retirements,
          ...(formData?.leaves ?? []),
        ],
        payFrequency,
        activeTaxFinancialYear,
        payslipId
      );
      const bankDetails = form.getValues("bankDetails");
      const note = form.getValues("note");
      const paymentDate = form.getValues("paymentDate");
      form.reset({
        bankDetails,
        deductions: recalculatedIems.deductions,
        earnings: recalculatedIems.earnings,
        reimbursments: recalculatedIems.reimbursments,
        retirements: recalculatedIems.retirements,
        taxes: recalculatedIems.taxes,
        leaves: recalculatedIems?.leaves,
        paymentDate,
        note,
      });
    }
  }
  const [earningResetData, setEarningResetData] = useState<{
    payItemEarningId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum;
    index?: number;
  }>({
    payItemEarningId: "",
    calculationType: PayTemp.PayTemplateCalcTypeEnum.MANUAL_EARNING,
    index: undefined,
  });
  const [deductionResetData, setDeductionResetData] = useState<{
    payItemDeductionId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemDeductionId: "",
    calculationType: "",
    index: undefined,
  });
  const [reimbursementResetData, setReimbursementResetData] = useState<{
    payItemReimbursementId: string;
    description: string;
    index?: number;
  }>({
    payItemReimbursementId: "",
    description: "",
    index: undefined,
  });
  const [accountRetirement, setAccountRetirement] = useState<
    Array<PayItemRetirement.FetchRetirementFund>
  >([]);
  const [retirementResetData, setRetirementResetData] = useState<{
    payItemRetirementId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemRetirementId: "",
    calculationType: "",
    index: undefined,
  });
  const [accountLeaves, setAccountLeaves] = useState<
    Array<PayItemLeaveApi.LeaveById>
  >([]);
  const [leavesResetData, setLeavesResetData] = useState<{
    payItemLeaveId: string;
    calculationType: PayTemp.PayTemplateCalcTypeEnum | string;
    index?: number;
  }>({
    payItemLeaveId: "",
    calculationType: "",
    index: undefined,
  });
  return (
    <FormProvider {...form}>
      <Box
        paddingBottom={"50px"}
        h="calc(100vh - 70px)"
        overflowY="auto"
        _dark={{
          bgColor: "customColor.dark.100",
        }}
        _light={{
          bgColor: "customColor.gray.400",
        }}
      >
        <Container
          h="64px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          maxW={"100%"}
          padding={"0px 20px"}
          justifyContent={"center"}
        >
          <Breadcrumb
            separator={<ChevronRightIcon color="gray.500" />}
            paddingTop={"5px"}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                href="/app/payroll/PayEmployee"
                fontSize={"11px"}
                color={"#3454d1"}
              >
                Pay Employee
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href=""
                fontSize={"11px"}
                _light={{
                  color: "customColor.black7",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                padding={"0px 20px 0px 0px"}
              >
                {`${paySlipData?.payRun?.payrollCalendar?.name} ending ${moment(
                  paySlipData?.payRun?.endDate
                ).format("D MMM YYYY")}`}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Heading
            fontWeight={"bold"}
            _light={{
              color: "customColor.black7",
            }}
            _dark={{
              color: "customColor.white",
            }}
            fontSize={"24px"}
            margin={"0px 4px 0px 0px"}
          >
            {`${paySlipData?.payRun?.payrollCalendar?.name} ending ${moment(
              paySlipData?.payRun?.endDate
            ).format("D MMM YYYY")}`}
          </Heading>
        </Container>
        <HeaderPaySlip
          payslipId={queryParams.get("payslipId")!}
          totalEarnings={totalEarningsAmount()}
          totalTaxs={totalTaxesAmount()}
          totalProvidentFunds={totalRetirementAmount()}
          totalNetPay={TotalNetPay}
          isPosted={false}
          // nextPaymentYear={nextPaymentYear}
          nextPayment={moment(form.getValues("paymentDate")).format(
            "D MMM YYYY"
          )}
          itemWidth={"100%"}
        />

        <Stack padding="20px 20px 0px 20px">
          <Container maxW={"100%"} padding={"0px"}>
            <Stack
              _dark={{
                bgColor: "customColor.dark.50",
              }}
              _light={{
                bgColor: "#FBFBFB",
              }}
              padding={"0px 10px"}
              margin={"0px 0px 30px"}
              border={"1px solid #e5e5e5"}
              direction={"row"}
              gap={0}
            >
              <Stack
                padding={"15px 0px"}
                _light={{
                  color: "customColor.black6",
                }}
                _dark={{
                  color: "customColor.white",
                }}
                gap={0}
                flex={2}
              >
                <Stack w={"80px"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant={"link"}
                      textAlign={"left"}
                      fontSize={"12px"}
                      padding={"0px 10px 0px 0px"}
                      rightIcon={<IoMdArrowDropdown />}
                      color={"primary.950"}
                      _hover={{
                        color: "#003c64",
                        textDecoration: "underline",
                      }}
                      _active={{
                        color: "#003c64",
                        textDecoration: "underline",
                      }}
                    >
                      Employees
                    </MenuButton>
                    <MenuList padding={0} maxW={"120px"}>
                      <MenuItem
                        // key={index}
                        fontSize={"12px"}
                        color={"primary.950"}
                        padding={"13px 30px 12px 10px"}
                        h={"41px"}
                        _hover={{
                          bg: "#e6f5fa",
                        }}
                      >
                        {paySlipData?.employee?.firstName}{" "}
                        {paySlipData?.employee?.lastName}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>

                <Text
                  _light={{
                    color: "customColor.black7",
                  }}
                  _dark={{
                    color: "customColor.white",
                  }}
                  fontSize={"24px"}
                  fontWeight={"600"}
                >
                  {paySlipData?.employee?.firstName}{" "}
                  {paySlipData?.employee?.lastName}
                </Text>
              </Stack>
              <Stack
                gap={0}
                padding={"15px 0px 15px 15px"}
                textAlign={"left"}
                flex={1}
                borderLeft={"1px solid #E5E5E5"}
              >
                <Text
                  color={"#999999"}
                  margin={"0px 0px 4px"}
                  padding={"1px 0px 0px"}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                >
                  Employee's Net Pay
                </Text>
                <HStack gap={0} alignItems={"baseline"}>
                  <Text
                    fontSize={"24px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{ color: "customColor.white" }}
                    fontWeight={"bold"}
                  >
                    {TotalNetPay.toLocaleString()}
                  </Text>
                  {/* <Text fontSize={"16px"}   _light={{
            color: "customColor.black7",
          }}
                _dark={{ color: "customColor.white" }} fontWeight={"bold"}>
                  .25
                </Text> */}
                </HStack>
              </Stack>
            </Stack>
            <Stack spacing="20px">
              <Container
                maxW={"100%"}
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <AddEarning
                  form={form}
                  earningFieldArray={earningFieldArray}
                  earningLine={earningLine}
                  accountEarning={accountEarning}
                  setAccountEarning={setAccountEarning}
                  resetPaySlip={recalculatePayslip}
                  setResetData={setEarningResetData}
                />
                <HStack justifyContent="space-between">
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"141px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      setEarningResetData({
                        payItemEarningId: "",
                        calculationType:
                          PayTemp.PayTemplateCalcTypeEnum.MANUAL_EARNING,
                        index: undefined,
                      });
                      earningLine.onOpen();
                    }}
                  >
                    + Add Earning Line
                  </Button>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    {` Total Earnings: ${totalEarningsAmount().toLocaleString()}`}
                  </Text>
                </HStack>
                <AddEarningLine
                  isOpen={earningLine.isOpen}
                  onClose={earningLine.onClose}
                  resetData={earningResetData}
                  onPressOK={(data) => {
                    data?.index
                      ? earningFieldArray.update(data.index, {
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          payItemEarningId: data.payItemEarningId!,
                          calculationType: data.calcType,
                          isTaxable: data.isTaxable,
                          payslipId: queryParams.get("payslipId")!,
                        })
                      : earningFieldArray.append({
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          payItemEarningId: data.payItemEarningId!,
                          calculationType: data.calcType,
                          isTaxable: data.isTaxable,
                          payslipId: queryParams.get("payslipId")!,
                        });
                  }}
                  account={accountEarning}
                />
              </Container>
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <AddDeduction
                  form={form}
                  deductionFieldArray={deductionFieldArray}
                  deductionLine={deductionLine}
                  accountDeduction={accountDeduction}
                  setAccountDeduction={setAccountDeduction}
                  totalEarnings={totalEarningsAmount()}
                  resetPaySlip={recalculatePayslip}
                  setResetData={setDeductionResetData}
                />
                <HStack justifyContent="space-between">
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"141px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      setDeductionResetData({
                        payItemDeductionId: "",
                        calculationType: "",
                        index: undefined,
                      });
                      deductionLine.onOpen();
                    }}
                  >
                    + Add Deduction Line
                  </Button>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    {` Total Deductions: ${totalDeductionsAmount().toLocaleString()}`}
                  </Text>
                </HStack>
                <AddDeductionLine
                  resetData={deductionResetData}
                  isOpen={deductionLine.isOpen}
                  resetPaySlip={recalculatePayslip}
                  onClose={deductionLine.onClose}
                  onPressOK={(data) => {
                    data?.index
                      ? deductionFieldArray.update(data.index, {
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,

                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: data?.percentage,
                          minimumWage: data?.minimumWage,
                          payItemDeductionId: data.payItemDeductionId!,
                          isTaxable: data?.isTaxable,
                          payItemDeduction: {
                            earningPayItems: data?.earningPayItems,
                          },
                          payslipId: queryParams.get("payslipId")!,
                        })
                      : deductionFieldArray.append({
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,

                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: data?.percentage,
                          minimumWage: data?.minimumWage,
                          payItemDeductionId: data.payItemDeductionId!,
                          isTaxable: data?.isTaxable,
                          payItemDeduction: {
                            earningPayItems: data?.earningPayItems,
                          },
                          payslipId: queryParams.get("payslipId")!,
                        });
                  }}
                  account={accountDeduction}
                />
              </Stack>
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <AddTaxes form={form} taxesFieldArray={taxesFieldArray} />
                <HStack width="100%" justifyContent={"space-between"}>
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"141px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      taxLine.onOpen();
                    }}
                  >
                    + Add Tax Line
                  </Button>
                  <Text
                    fontSize={"12px"}
                    _light={{
                      color: "customColor.black7",
                    }}
                    _dark={{
                      color: "customColor.white",
                    }}
                    fontWeight={"bold"}
                  >
                    {` Total Taxes: ${totalTaxesAmount()}`}
                  </Text>
                </HStack>

                <TaxLineModal
                  isOpen={taxLine.isOpen}
                  onClose={taxLine.onClose}
                />
              </Stack>
              {/* <Stack
              _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "#FBFBFB",
          }}
              border={"1px solid #e5e5e5"}
              padding={"15px 10px 20px"}
            >
              <Retirement
                form={form}
                retirementsFieldArray={retirementsFieldArray}
              />
              <Button
                variant="outline"
                fontSize="12px"
                bg="primary.50"
                color="primary.950"
                _hover={{ color: "#333" }}
                borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                w={"181px"}
                bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                onClick={() => providentFundLine.onOpen()}
              >
                + Add Provident Fund Line
              </Button>
              <ProvidentFundLine
                isOpen={providentFundLine.isOpen}
                onClose={providentFundLine.onClose}
              />
            </Stack> */}
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <AddReimbursementRate
                  form={form}
                  reimbursementFieldArray={reimbursementFieldArray}
                  reimbursementLine={reimbursementLine}
                  accountReimbursement={accountReimbursement}
                  setAccountReimbursement={setAccountReimbursement}
                  setResetData={setReimbursementResetData}
                  resetPaySlip={recalculatePayslip}
                />
                <HStack justifyContent="space-between">
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"181px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      setReimbursementResetData({
                        payItemReimbursementId: "",
                        description: "",
                        index: undefined,
                      });
                      reimbursementLine.onOpen();
                    }}
                  >
                    + Add Reimbursement Line
                  </Button>
                  <Text
                    fontSize={"12px"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"bold"}
                  >
                    {` Total Reimbursements: ${totalReimbursementAmount().toLocaleString()}`}
                  </Text>
                </HStack>

                <AddReimbursementRateLine
                  isOpen={reimbursementLine.isOpen}
                  onClose={reimbursementLine.onClose}
                  resetData={reimbursementResetData}
                  onPressOK={(data) => {
                    data?.index
                      ? reimbursementFieldArray.update(data.index, {
                          calculationType: data.calculationType!,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          payItemReimbursementId: data.payItemId!,
                          payslipId: queryParams.get("payslipId")!,
                        })
                      : reimbursementFieldArray.append({
                          calculationType: data.calculationType!,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          payItemReimbursementId: data.payItemId!,
                          payslipId: queryParams.get("payslipId")!,
                        });
                  }}
                  account={accountReimbursement}
                />
              </Stack>

              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <Stack>
                  <Stack direction={"row"}>
                    <Stack flex={3}>
                      <Text
                        fontSize={"12px"}
                        margin={"0px 0px 4px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontWeight={"bold"}
                      >
                        Total Payment for Employee
                      </Text>
                      <Text
                        fontSize={"12px"}
                        margin={"0px 0px 4px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                      >
                        {paySlipData?.bankDetails?.accountName} (
                        {form.getValues("bankDetails.iban")})
                      </Text>
                    </Stack>
                    <Stack flex={1}>
                      <Text
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontWeight={"bold"}
                        fontSize={"12px"}
                        textAlign={"right"}
                      >
                        Total Net Pay
                      </Text>
                      <Text
                        borderTop={"1px solid #ccc !important "}
                        borderBottom={"1px solid #E8EAEC !important"}
                        padding={"6px 3px 0px 0px"}
                        _light={{
                          color: "customColor.black7",
                        }}
                        _dark={{
                          color: "customColor.dark.250",
                        }}
                        fontSize={"16px"}
                        textAlign={"right"}
                        fontWeight={"bold"}
                      >
                        {TotalNetPay.toFixed(2).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <AddLeaves
                  leavesFieldArray={leavesFieldArray}
                  LeavesLine={leavesModal}
                  accountLeaves={accountLeaves}
                  setAccountLeaves={setAccountLeaves}
                  setResetData={setLeavesResetData}
                  resetPaySlip={recalculatePayslip}
                />
                <HStack justifyContent="space-between">
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"181px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      setLeavesResetData({
                        payItemLeaveId: "",
                        calculationType: "",
                        index: undefined,
                      });
                      leavesModal.onOpen();
                    }}
                  >
                    + Add Leave Line
                  </Button>
                </HStack>

                <AddLeavesModal
                  isOpen={leavesModal.isOpen}
                  onClose={leavesModal.onClose}
                  resetData={leavesResetData}
                  onPressOK={(data) => {
                    data?.index
                      ? leavesFieldArray.update(data.index, {
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          minimumWage: undefined,
                          payItemLeaveId: data.payItemId!,
                          payslipId: queryParams.get("payslipId")!,
                        })
                      : leavesFieldArray.append({
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: undefined,
                          minimumWage: undefined,
                          payItemLeaveId: data.payItemId!,
                          payslipId: queryParams.get("payslipId")!,
                        });
                  }}
                  accountLeaves={accountLeaves}
                />
              </Stack>
              <Stack
                _dark={{
                  bgColor: "customColor.dark.50",
                }}
                _light={{
                  bgColor: "#FBFBFB",
                }}
                border={"1px solid #e5e5e5"}
                padding={"15px 10px 20px"}
              >
                <Retirement
                  retirementFieldArray={retirementFieldArray}
                  retirementLine={retirementModal}
                  accountRetirement={accountRetirement}
                  setAccountRetirement={setAccountRetirement}
                  setResetData={setRetirementResetData}
                  form={form}
                  resetPaySlip={recalculatePayslip}
                />
                <HStack justifyContent="space-between">
                  <Button
                    variant="outline"
                    fontSize="12px"
                    bg="primary.50"
                    color="primary.950"
                    _hover={{ color: "#333" }}
                    borderColor={"#cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4"}
                    w={"181px"}
                    bgImage="linear-gradient(to bottom, #fcfdfd 0%, #f5f7f8 100%)"
                    onClick={() => {
                      setRetirementResetData({
                        payItemRetirementId: "",
                        calculationType: "",
                        index: undefined,
                      });
                      retirementModal.onOpen();
                    }}
                  >
                    + Add Statutory Benefits
                  </Button>
                  <Text
                    fontSize={"12px"}
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.pearlWhite",
                    }}
                    fontWeight={"bold"}
                  >
                    {` Total Retirenment Funds: ${totalRetirementAmount().toLocaleString()}`}
                  </Text>
                </HStack>

                <StaturyBenefitsModal
                  isOpen={retirementModal.isOpen}
                  onClose={retirementModal.onClose}
                  resetData={retirementResetData}
                  resetPaySlip={recalculatePayslip}
                  onPressOK={(data) => {
                    data?.index
                      ? retirementFieldArray.update(data.index, {
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: data?.percentage,
                          minimumWage: data?.minimumWage,
                          payItemRetirementId: data.payItemRetirementId!,
                          payItemRetirement: {
                            earningPayItems: data.earningPayItems,
                          },
                          payslipId: queryParams.get("payslipId")!,
                        })
                      : retirementFieldArray.append({
                          calculationType:
                            data.calculationType! as PayTemp.PayTemplateCalcTypeEnum,
                          amount: undefined,
                          category: data.category!,
                          employeeId: queryParams.get("employeeId")!,
                          hours: undefined,
                          payRunId: queryParams.get("payRunId")!,
                          rate: undefined,
                          percentage: data?.percentage,
                          minimumWage: data?.minimumWage,
                          payItemRetirementId: data.payItemRetirementId!,
                          payItemRetirement: {
                            earningPayItems: data.earningPayItems,
                          },
                          payslipId: queryParams.get("payslipId")!,
                        });
                  }}
                  account={accountRetirement}
                />
              </Stack>
            </Stack>
          </Container>
          <Container maxW={"100%"} padding={"0px"}>
            <Stack padding={"20px 0px 20px 0px"}>
              <Stack
                border={"1px solid #e5e5e5"}
                padding={"15px 10px"}
                bg={"#fff"}
              >
                <Button
                  variant={"link"}
                  color={"#3454d1"}
                  alignSelf={"flex-start"}
                  leftIcon={<AiOutlinePlus />}
                  fontWeight={"400"}
                  fontSize={"12px"}
                  padding={0}
                >
                  Add an individual message of{" "}
                  {paySlipData?.employee?.firstName}
                </Button>
                <Controller
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      border="1px solid #e5e7eb"
                      _hover={{
                        border: "1px solid hsl(0, 0%, 70%)",
                      }}
                      _focus={{
                        borderWidth: "1px",

                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      _focusVisible={{
                        borderWidth: "1px",
                        boxShadow: "0 0 0 1px #2684FF",
                        _hover: {
                          borderWidth: "1px",
                          borderColor: "#2684FF",
                          boxShadow: "0 0 0 1px #2684FF",
                        },
                      }}
                      placeholder="Add a note"
                      fontSize=".845rem"
                      fontWeight={400}
                      _light={{
                        color: "customColor.black7",
                      }}
                      padding="12px 15px"
                    />
                  )}
                />
              </Stack>
            </Stack>
            <SimpleGrid columns={{ xs: 1, md: 2 }} spacing={0}>
              <HStack justifyContent="flex-start">
                <Button
                  margin={"0px 10px 15px 0"}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  isLoading={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    PayRun.ResetPaySlip(
                      {
                        employeeId: queryParams.get("employeeId")!,
                        payRunId: queryParams.get("payRunId")!,
                        payslipId: queryParams.get("payslipId")!,
                      },
                      (success) => {
                        toast({
                          title: "Success",
                          status: "success",
                          description: "Pay Slip Reset Successfully",
                        });
                        setIsLoading(false);
                        form.reset({
                          earnings: success?.earnings,
                          deductions: success?.deductions,
                          reimbursments: success?.reimbursments,
                          retirements: success?.retirements,
                          taxes: success?.taxes,
                          bankDetails: form.getValues("bankDetails"),
                        });
                        setIsLoading(false);
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          status: "error",
                          description: "Pay Slip Reset Failed",
                        });
                        setIsLoading(false);
                      }
                    );
                  }}
                >
                  Reset PaySlip
                </Button>
                <Button
                  margin={"0px 10px 15px 0"}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    finalPay.onOpen();
                  }}
                >
                  Set as Final Pay
                </Button>
                <FinalPayModal
                  isOpen={finalPay.isOpen}
                  onClose={finalPay.onClose}
                  setLastUpdated={setLastUpdated}
                />
              </HStack>
              <HStack justifyContent={{ xs: "flex-Start", md: "flex-end" }}>
                <Button
                  margin={"0px 10px 15px 0px"}
                  fontSize={"13px"}
                  bgColor="#3454D1"
                  borderRadius={"3px"}
                  borderWidth={"1px"}
                  borderColor={"#3454d1!important"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    bgColor: "#3454D1",
                  }}
                  isLoading={isLoading}
                  onClick={form.handleSubmit((data) => {
                    setIsLoading(true);
                    PayRun.UpdatePayItemByEmpId(
                      queryParams.get("payslipId")!,
                      queryParams.get("payRunId")!,
                      data,
                      (success) => {
                        toast({
                          title: "Success",
                          status: "success",
                          description: "Pay Item Updated Successfully",
                        });
                        setIsLoading(false);
                        form.reset({
                          earnings: success?.earnings,
                          deductions: success?.deductions,
                          reimbursments: success?.reimbursments,
                          retirements: success?.retirements,
                          taxes: success?.taxes,
                          leaves: success?.leaves,
                          bankDetails: form.getValues("bankDetails"),
                          note: form.getValues("note"),
                        });
                        setIsLoading(false);
                      },
                      (err) => {
                        toast({
                          title: "Error",
                          status: "error",
                          description: "Pay Item Updated Failed",
                        });
                        setIsLoading(false);
                      }
                    );
                  })}
                >
                  Save
                </Button>
                <Button
                  borderRadius={"3px"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  variant={"solid"}
                  textColor={"customColor.black7"}
                  padding={"12px 16px"}
                  backgroundColor={"#fff"}
                  fontWeight={600}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  margin={"0px 10px 15px 0px"}
                  onClick={form.handleSubmit((data) => {})}
                >
                  Save & Next
                </Button>
                <Button
                  margin={"0px 0px 15px 0"}
                  backgroundColor={"#fff"}
                  borderRadius={"3px"}
                  color={"#ea4d4d"}
                  border={"1px solid #dcdee4"}
                  fontSize={"13px"}
                  h={"40px"}
                  variant={"solid"}
                  padding={"12px 16px"}
                  fontWeight={700}
                  _hover={{
                    backgroundColor: "#fff",
                  }}
                  onClick={(e) => {
                    navigate(
                      `/app/payroll/PayRunDetail/${queryParams.get("payRunId")}`
                    );
                  }}
                >
                  Close
                </Button>
              </HStack>
            </SimpleGrid>
          </Container>
        </Stack>
      </Box>
    </FormProvider>
  );
}
