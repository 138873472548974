import { axiosInstance } from "./axiosInstance-hris";

declare module TimeSheetNewDetail {
  type DayHours = {
    [key: string]: number;
  };

  type PayRateData = {
    timesheetId?: string;
    id?: string;
    payItemsEarningsId: string;
    dayHours: DayHours;
  };
  type BulkTimesheetData = {
    dateRange: {
      startDate: string;
      endDate: string;
    };
    totalHours: number;
    payRatesData: Array<{
      dayHours: Record<string, number>;
    }>;
    empUniqueId: number;
  };
  type BulkTimesheetUpload = {
    timesheets: Array<BulkTimesheetData>;
  };
  type AddNewDetail = {
    employeeId: string;
    payrollCalendarId: string;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    period: string;
    status: string;
    totalHours: number;
    payRatesData: PayRateData[];
  };
  type UpdateTimesheetToApprove = {
    status: string;
    ids: string[];
  };
  // type UpdateTimeSheetPayHours = {
  //   id: string;
  //   payItemsEarningsId: string;
  //   dayHours: DayHours;
  // };

  // type UpdateTimeSheet = {
  //   employeeId: string;
  //   dateRange: {
  //     startDate: string;
  //     endDate: string;
  //   };
  //   period: string;
  //   status: string;
  //   totalHours: number;
  //   payRatesData: Array<UpdateTimeSheetPayHours>;
  // };

  type FetchTimeSheetData = {
    id: string;
    employeeId: string;
    dateRange: {
      endDate: string;
      startDate: string;
    };
    period: string;
    status: string;
    totalHours: number;
    employee: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
    };
    payRatesData: [
      {
        dayHours: DayHours;
        payItemsEarningsId: string;
        totalHours: number;
        id: string;
        timesheetId: string;
        payItemsEarnings: {
          id: string;
          category: string;
          name: string;
          displayName: string;
          rateType: string;
          fixedAmount: number;
          chartAccountId: string;
          isTaxable: boolean;
          retirementFundExcemption: boolean;
          reportableActivityStatement: boolean;
          multiple: number;
          rateUnitType: string;
          unitRate: string;
          active: boolean;
          allowanceType: string;
          businessId: string;
        };
      }
    ];
    updatedAt: string;
  };
  type AllTimesheetsData = {
    data: Array<FetchTimeSheetData>;
    count: number;
  };
}
module TimeSheetNewDetail {
  const API_URL = "/timesheet";
  export function AddTimesheet(
    data: AddNewDetail,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function BulkUploadTimesheet(
    data: BulkTimesheetUpload,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/upload-bulk-timesheet`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function UpdateTimesheetToApproved(
    data: UpdateTimesheetToApprove,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/status/update`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function FetchTimeSheets(
    params: {
      employeeId?: string;
      startDate?: string;
      endDate?: string;
      status: string;

      page?: number;
      limit?: number;
    },
    success: (response: AllTimesheetsData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}`, {
        params,
      })
      .then(({ data }) => {
        success(data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function FetchTimeSheetById(
    id: string,
    success: (data: FetchTimeSheetData) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data.data);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function PatchTimeSheet(
    id: string,
    data: AddNewDetail,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .patch(`${API_URL}/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function DeleteTimeSheet(
    id: string,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`${API_URL}/${id}`)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { TimeSheetNewDetail };
