import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../../Common/CustomInput";

import { useEffect, useState } from "react";
import { ReactSelect } from "../../../../../../Common/ReactSelect";
import { NextOfKinApi } from "../../../../../Api/NextOfKin";

interface AddNextOfKinProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId: string | undefined;
  setLastUpdated: (time: number) => void;
}
export default function AddNextOfKin({
  isOpen,
  onClose,
  selectedId,
  setLastUpdated,
}: AddNextOfKinProps) {
  const form = useFormContext<NextOfKinApi.AddNextOfKin>();
  const addressRegex = /^(?!\d+$)[A-Za-z0-9\s#,\-]+$/;
  const toast = useToast();
  const [displayValue, setDisplayValue] = useState("");
  useEffect(() => {
    setDisplayValue(formatDisplayValue(form.watch("cnic")));
  }, [form.watch("cnic")]);
  const formatDisplayValue = (value: any) => {
    const numericValue = value?.replace(/\D/g, "");
    if (numericValue?.length <= 5) {
      return numericValue;
    } else if (numericValue?.length <= 12) {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(5, 12)}`;
    } else {
      return `${numericValue?.slice(0, 5)}-${numericValue?.slice(
        5,
        12
      )}-${numericValue?.slice(12, 13)}`;
    }
  };
  const relationType: Array<{ label: string; value: string }> = [
    {
      label: "Mother",
      value: "Mother",
    },
    {
      label: "Father",
      value: "Father",
    },
    {
      label: "Spouse",
      value: "Spouse",
    },
    {
      label: "Children",
      value: "Children",
    },
  ];
  return (
    <FormProvider {...form}>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            _light={{
              color: "customColor.black7",
            }}
            fontSize=".9975rem"
          >
            Next Of Kin
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            borderTop={"1px solid #ddd"}
            borderBottom={"1px solid #ddd"}
          >
            <Box>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Name:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabetic characters are allowed",
                      },
                    }}
                    control={form.control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder="Name"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Cnic:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "CNIC is required",
                      },
                      minLength: {
                        value: 13,
                        message: "CNIC must be 13 characters",
                      },
                      maxLength: {
                        value: 13,
                        message: "CNIC must be 13 characters",
                      },
                    }}
                    control={form.control}
                    name="cnic"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "text",

                          borderTopRadius: "3px",
                          value: displayValue,
                          minLength: 15,
                          maxLength: 15,
                          onChange: (e) => {
                            const formattedValue = formatDisplayValue(
                              e.target.value
                            );
                            setDisplayValue(formattedValue);
                            field.onChange(e.target.value.replace(/\D/g, ""));
                          },
                        }}
                        placeholder="361**-833****-*"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Contact Number:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Contact number is required",
                      },
                      minLength: {
                        value: 11,
                        message: "Contact Number must be 11 characters",
                      },
                      maxLength: {
                        value: 14,
                        message: "Contact Number must be 14 characters",
                      },
                    }}
                    control={form.control}
                    name="contact"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          w: "100%",
                          h: "36px",
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          variant: "outline",
                          type: "number",

                          borderTopRadius: "3px",
                          minLength: 11,
                          maxLength: 14,
                        }}
                        placeholder=""
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Address:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Address is required",
                      },
                      validate: {
                        notOnlyNumbers: (value) =>
                          addressRegex.test(value) ||
                          "Address shouldn't only contain numbers",
                      },
                    }}
                    control={form.control}
                    name="address"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        ref={field.ref}
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",
                          // color: "#ccc",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "text",

                          borderRadius: "4px",
                        }}
                        placeholder=" Address"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Relationship:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Relationship type is required",
                      },
                    }}
                    control={form.control}
                    name="relationship"
                    render={({ field, fieldState }) => (
                      <ReactSelect
                        {...field}
                        key={field.value}
                        {...field}
                        options={relationType}
                        withValidation
                        isInvalid={fieldState.invalid}
                        ErrorMessage="Please Select relationship"
                        placeholder="Select..."
                        value={relationType.find(
                          (op) => op.value === field.value
                        )}
                        onChange={(newValue: any) => {
                          field.onChange(newValue?.value);
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex
                justifyContent="space-between"
                mt="8px"
                alignItems={"center"}
              >
                <Text
                  w="115px"
                  fontSize={"0.8125rem"}
                  fontWeight={"600"}
                  _light={{
                    color: "customColor.black7",
                  }}
                >
                  Share in %:
                </Text>
                <Box w={"380px"}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Share is required",
                      },
                      validate: (value) => {
                        if (isNaN(value)) {
                          return "Share must be a number";
                        }
                        if (value > 100) {
                          return "Share cannot be more than 100";
                        }
                        return true;
                      },
                    }}
                    control={form.control}
                    name="shareInPercent"
                    render={({ field, fieldState: { error } }) => (
                      <CustomInput
                        validationMessage={error?.message}
                        withValidation
                        input={{
                          ...field,
                          isInvalid: error !== undefined,
                          padding: "12px 15px",
                          _light: {
                            color: "customColor.black7",
                          },
                          fontSize: ".845rem",
                          backgroundColor: "#fff",
                          variant: "outline",

                          lineHeight: "1.2",
                          h: "36px",

                          type: "number",
                          onChange: (e) => {
                            field.onChange(Number(e.target.valueAsNumber));
                          },

                          borderRadius: "4px",
                        }}
                        placeholder="Share in %"
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"#fff"}
              borderRadius={"3px"}
              border={"1px solid #dcdee4"}
              fontSize={"13px"}
              h={"40px"}
              variant={"solid"}
              textColor={"#ea4d4d"}
              padding={"12px 16px"}
              marginRight={"5px"}
              fontWeight={600}
              _hover={{
                backgroundColor: "#fff",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              onClick={(e) => {
                form.handleSubmit((data) => {
                  if (form.getValues("id")) {
                    NextOfKinApi.UpdateNextOfKin(
                      form.getValues("id")!,
                      data,
                      (success) => {
                        toast({
                          title: "Next Of Kin Updated",
                          status: "success",
                        });

                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  } else {
                    NextOfKinApi.PostNextOfKin(
                      data,
                      (success) => {
                        toast({
                          title: "Next Of Kin Added",
                          status: "success",
                        });

                        setLastUpdated(Date.now());
                        onClose();
                      },
                      (err) => {
                        toast({
                          title: err,
                          status: "error",
                        });
                      }
                    );
                  }
                })(e);
              }}
            >
              {selectedId ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
