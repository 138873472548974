import { Box } from "@chakra-ui/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDashboardContext } from "../../Hooks/DashboardContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Define the possible types for the attribute prop
type AttributeType = "gender" | "department" | "province" | "employeementType";
type CalculationType = "sum" | "average" | "median" | "min" | "max";

interface DynamicBarChartProps {
  attribute: AttributeType;
  calculation?: CalculationType;
  colors?: string[];
  fontStyle?: "normal" | "italic" | "oblique";
  fontWeight?: "normal" | "bold";
  fontSize?: number;
}
const calculateValues = (
  values: number[],
  calculation: CalculationType
): number[] => {
  switch (calculation) {
    case "min":
      const minValue = Math.min(...values);
      return values.map((value) => (value === minValue ? value : 0));
    case "max":
      const maxValue = Math.max(...values);
      return values.map((value) => (value === maxValue ? value : 0));
    case "average":
      const avgValue = values.reduce((a, b) => a + b, 0) / values.length;
      return new Array(values.length).fill(avgValue);
    case "median":
      values.sort((a, b) => a - b);
      const mid = Math.floor(values.length / 2);
      const medianValue =
        values.length % 2 !== 0
          ? values[mid]
          : (values[mid - 1] + values[mid]) / 2;
      return new Array(values.length).fill(medianValue);
    case "sum":
    default:
      return values;
  }
};

export default function DynamicBarChart({
  attribute,
  calculation = "sum",
  colors = ["#54a0ff", "#ff6b6b", "#1dd1a1", "#feca57", "#5f27cd"],
  fontStyle = "normal",
  fontWeight = "normal",
  fontSize = 12,
}: DynamicBarChartProps) {
  const { dashboardAnalytics, editDashboard } = useDashboardContext();

  let labels: string[] = [];
  let dataValues: number[] = [];

  // Prepare data based on the attribute prop
  if (attribute === "gender") {
    labels = ["Male", "Female"];
    dataValues = [
      dashboardAnalytics?.maleGenderEmpCount || 0,
      dashboardAnalytics?.femaleGenderEmpCount || 0,
    ];
  } else if (attribute === "department") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInDepartments || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInDepartments || {}
    );
  } else if (attribute === "province") {
    labels = Object.keys(dashboardAnalytics?.employeeCountInProvinces || {});
    dataValues = Object.values(
      dashboardAnalytics?.employeeCountInProvinces || {}
    );
  } else if (attribute === "employeementType") {
    labels = ["Full Time", "Part Time", "Daily Wagers"];
    dataValues = [
      dashboardAnalytics?.fullTimeEmployees || 0,
      dashboardAnalytics?.partTimeEmployees || 0,
      dashboardAnalytics?.casualEmployees || 0,
    ];
  }

  const calculatedDataValues = calculateValues(dataValues, calculation);
  function lightenColor(color: any, percent: any) {
    let num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? R : 255) * 0x10000 +
        (B < 255 ? B : 255) * 0x100 +
        (G < 255 ? G : 255)
      )
        .toString(16)
        .slice(1)
    );
  }
  const data = {
    labels,
    datasets: [
      {
        label: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} ${
          calculation.charAt(0).toUpperCase() + calculation.slice(1)
        } Distribution`,
        data: calculatedDataValues,
        // backgroundColor: labels.map(
        //   (_, i) =>
        //     `rgba(${(i * 40) % 255}, ${(i * 80) % 255}, ${
        //       (i * 120) % 255
        //     }, 0.6)`
        // ),
        // borderColor: labels.map(
        //   (_, i) =>
        //     `rgba(${(i * 40) % 255}, ${(i * 80) % 255}, ${(i * 120) % 255}, 1)`
        // ),

        backgroundColor: colors
          .slice(0, labels.length)
          .map((color) => lightenColor(color, 20)), // Lightens each color by 20%
        borderColor: colors.slice(0, labels.length),
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          font: {
            size: fontSize,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: fontSize + 2,
            weight: fontWeight,
            style: fontStyle,
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: fontSize,
          weight: fontWeight,
          style: fontStyle,
        },
      },
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      word-wrap="break-word"
      _dark={{
        bgColor: "customColor.dark.50",
      }}
      minW={"100%"}
      p="20px"
      overflowY="auto"
      h={editDashboard ? "calc(100% - 30px)" : "100%"}
    >
      <Bar
        data={data}
        options={options}
        style={{ width: "100%", height: "100%", alignSelf: "center" }}
      />
    </Box>
  );
}
