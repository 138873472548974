import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillCaretDown } from "react-icons/ai";
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ChartOfAccounts } from "../../../Api/ChartOfAccounts";
import { TaxFinancialYear } from "../../../Api/TaxFinancialYear";
import HistoryAccordion from "../../Superannuation/History/HistoryAccordion";
import { AddTaxSlabs } from "./AddTaxSlabs";
import DeleteTaxFinancialYear from "./DeleteModal";

export function TaxSlabs() {
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>();
  const [taxFinancialYear, setTaxFinancialYear] = useState<
    TaxFinancialYear.FetchTax[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartOfAccountEarning, setChartOfAccountEarning] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const paginateData = (data: any) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = paginateData(taxFinancialYear);
  const getPageNumbers = (totalPages: any) => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };
  const form = useForm<TaxFinancialYear.AddNewTax>({
    defaultValues: {
      name: "",
      startDate: "",
      endDate: "",
      isActivated: false,
      chartOfAccountId: "",
      taxBrackets: [
        {
          minimum: undefined,
          maximum: undefined,
          percentage: undefined,
          isHighestSlab: false,
        },
      ],
    },
  });
  const [chartOfAccounts, setChartOfAccounts] = useState<
    ChartOfAccounts.FetchChartOfAccounts[]
  >([]);
  const toast = useToast();
  useEffect(() => {
    TaxFinancialYear.GetTaxFinancialYear(
      (data) => {
        setTaxFinancialYear(data);
        setIsLoading(false);
      },
      (error) => {
        toast({
          title: "Error",
          description: error,
        });
        setIsLoading(false);
      }
    );
    ChartOfAccounts.GetAllAccounts(
      {
        category: undefined,
        archive: false,
        page: 1,
        limit: 1000,
      },
      (res) => {
        setChartOfAccounts(res.data);
      },
      (err) => {
        toast({
          title: "Error",
          description: err,
        });
      }
    );
  }, [lastUpdated]);
  useEffect(() => {
    if (form.getValues("id")) {
      TaxFinancialYear.FetchTaxById(
        form.getValues("id")!,
        (res) => {
          form.reset(res);
        },
        (err) => {
          toast({
            title: "Error",
            description: err,
          });
        }
      );
    }
  }, [form.getValues("id")]);
  useEffect(() => {
    ChartOfAccounts.GetChartofAccountThroughCategory(
      (res) => {
        setChartOfAccountEarning(res);
      },
      (err) => {
        toast({
          title: err,
          status: "error",
        });
      },
      "EXPENSES"
    );
  }, []);
  const addTaxModal = useDisclosure();
  const deleteModal = useDisclosure();
  const navigate = useNavigate();
  return (
    <FormProvider {...form}>
      <Stack
        marginTop={"15px"}
        marginBottom={"1.25rem"}
        border={"1px solid #f6f6f6"}
        borderRadius="4px"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        boxShadow={"0 2px 4px rgba(15,34,58,.12)"}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          p="0px"
          // maxW="940px"
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
        >
          <HStack justifyContent="space-between">
            <Heading
              textAlign="left"
              fontSize=".9975rem"
              fontWeight="bold"
              padding="20px"
              _light={{
                color: "customColor.black7",
              }}
              as="h2"
              size="md"
            >
              Tax Slabs
            </Heading>
            <Tooltip
              label="Add Tax Slabs enables users to input or define the income brackets and corresponding tax rates used for calculating taxes owed by individuals or entities."
              fontSize="sm"
              padding={"10px"}
              bg={"#fff"}
              border={"1px solid #ddd"}
              _light={{
                color: "customColor.black7",
              }}
              maxW={"300px"}
              placement="auto"
            >
              <Button
                mr={["0", "7px"]}
                fontSize={"13px"}
                bgColor="#3454D1"
                h={"40px"}
                borderRadius={"3px"}
                borderWidth={"1px"}
                borderColor={"#3454d1!important"}
                variant={"solid"}
                padding={"12px 16px"}
                fontWeight={700}
                _hover={{
                  bgColor: "#3454D1",
                }}
                onClick={() => {
                  form.reset({
                    name: "",
                    startDate: "",
                    endDate: "",
                    chartOfAccountId: "",
                    isActivated: false,
                    taxBrackets: [
                      {
                        minimum: 0,
                        maximum: 0,
                        percentage: 0,
                      },
                    ],
                  });
                  setSelectedId(undefined);
                  addTaxModal.onOpen();
                }}
              >
                Add Tax Slabs
              </Button>
            </Tooltip>
          </HStack>
          <Divider orientation="horizontal" />
          <Skeleton height="100%" isLoaded={!isLoading}>
            <Stack overflowX="auto" p="0px">
              <Table variant="simple" w="100%" overflowX="auto">
                <Thead h={"36.8px"}>
                  <Tr
                    color={"customColor.black7"}
                    _dark={{
                      color: "customColor.dark.150",
                    }}
                  >
                    {[
                      {
                        name: "Name",
                        value: "30%",
                        paddingValue: "8px 26px 8px 30px",
                        icon: <AiFillCaretDown />,
                      },

                      {
                        name: "Status",
                        value: "20%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Chart Of Account",
                        value: "30%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "Action Required",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                      {
                        name: "View Details",
                        value: "10%",
                        paddingValue: "8px 26px 8px 15px",
                      },
                    ].map((item, i) => {
                      return (
                        <Th
                          key={i}
                          padding={item.paddingValue}
                          borderBottomColor="borders.tableBorder"
                          color={"customColor.black7"}
                          _dark={{
                            color: "customColor.dark.150",
                          }}
                          fontWeight={700}
                          whiteSpace="nowrap"
                          fontSize="12px"
                          textTransform="none"
                          width={item.value}
                        >
                          {item.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody padding={0}>
                  {paginatedData &&
                    paginatedData.map((item: any, i: any) => {
                      return (
                        <Tr
                          key={i}
                          _hover={{
                            bgColor: "#f2f3f8",
                            cursor: "pointer",
                          }}
                        >
                          <Td
                            padding="15px 15px 15px 30px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            fontWeight={500}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            {item.name}
                          </Td>
                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Tag
                              padding="5px 6px"
                              fontWeight={500}
                              fontSize="13.44px"
                              lineHeight={1.5}
                              borderRadius={"3px"}
                              color={item?.isActivated ? "#3454D1" : "#EA4D4D"}
                              backgroundColor={
                                item?.isActivated ? "#EBEEFA" : "#FDEDED"
                              }
                              _dark={{
                                color: item?.isActivated
                                  ? "#3454D1"
                                  : "customColor.dark.150",
                                backgroundColor: item?.isActivated
                                  ? "#EBEEFA"
                                  : "customColor.dark.bg",
                              }}
                            >
                              {item?.isActivated ? "Active" : "Deactive"}
                            </Tag>
                          </Td>

                          <Td
                            padding="15px"
                            fontWeight={500}
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <Text h={"13.5px"}>
                              {
                                chartOfAccounts.find(
                                  (chartOfAccount) =>
                                    chartOfAccount.id === item.chartOfAccountId
                                )?.name
                              }
                            </Text>
                          </Td>
                          <Td
                            padding="10px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <HStack alignItems="center" justifyContent="center">
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdDelete}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={() => {
                                  setSelectedId(item?.id);
                                  deleteModal.onOpen();
                                }}
                              />
                            </HStack>
                          </Td>
                          <Td
                            padding="10px"
                            fontSize="13.44px"
                            color={"customColor.black7"}
                            _dark={{
                              color: "customColor.dark.150",
                            }}
                            lineHeight={1.5}
                            borderTopWidth="1px"
                            borderBottomWidth="0px"
                            borderTopColor={"borders.tableBorder"}
                          >
                            <HStack alignItems="center" justifyContent="center">
                              <Icon
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="50%"
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                borderColor={"borders.tableBorder"}
                                bg={"#fff"}
                                w="25px"
                                h="25px"
                                px="5px"
                                py="5px"
                                as={MdOutlineRemoveRedEye}
                                fontSize="22px"
                                color="#6B7280"
                                _hover={{
                                  color: "#3454d1",
                                }}
                                onClick={() => {
                                  form.setValue("id", item.id);
                                  setSelectedId(item?.id);
                                  addTaxModal.onOpen();
                                }}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              {window.location.pathname ===
                "/app/payroll/OrganizationDetailParent" && (
                <Box
                  onClick={() => {
                    navigate("/app/payroll/Dashboard");
                  }}
                >
                  <Stack alignItems={"flex-end"} margin={"20px 20px 0px 0px"}>
                    <Button
                      backgroundColor={"#fff"}
                      borderRadius={"3px"}
                      border={"1px solid #dcdee4"}
                      fontSize={"13px"}
                      h={"40px"}
                      variant={"solid"}
                      textColor={"customColor.black7"}
                      padding={"12px 16px"}
                      fontWeight={600}
                      _hover={{
                        backgroundColor: "#fff",
                        color: "red",
                      }}
                    >
                      Save & Close
                    </Button>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Skeleton>
        </Box>
        <AddTaxSlabs
          isOpen={addTaxModal.isOpen}
          onClose={addTaxModal.onClose}
          selectedId={selectedId}
          setLastUpdated={setLastUpdated}
        />
        <DeleteTaxFinancialYear
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.onClose}
          id={selectedId}
          setLastUpdated={setLastUpdated}
        />
        <Stack
          marginX={"auto"}
          borderTop={"1px solid #d6dade"}
          _dark={{
            bgColor: "customColor.dark.50",
          }}
          _light={{
            bgColor: "customColor.white",
          }}
          marginRight={"auto"}
          marginLeft={"auto"}
          w="100%"
          marginTop={"20px"}
        >
          <HistoryAccordion />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
