import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineDrag } from "react-icons/ai";
import { IoAddSharp, IoSettings } from "react-icons/io5";
import "react-resizable/css/styles.css";
import { Analytics } from "../../Payroll/Api/DashboardAnalytics";
import { useDashboardContext } from "../Hooks/DashboardContext";
import BarChart from "./Components/BarChart";
import DeleteChartModal from "./Components/DeleteChartModal";
import DepartmentalStats from "./Components/DepartmentalStats";
import DynamicBarChart from "./Components/DynamicBarChart";
import DynamicPieChart from "./Components/DynamicPieChart";
import EmployeesTable from "./Components/EmployeesTable";
import { EmployementCategory } from "./Components/EmployementCategory";
import EmployementType from "./Components/EmployementType";
import ChartsModal from "./Components/Modals/ChartsModal";
import { Provinces } from "./Components/Provinces";
import ShiftRosters from "./Components/Roaster/ShiftRoster";

import { IoMdArrowDropdown } from "react-icons/io";
import { Attendence } from "./Components/Attendence";
import SocialSources from "./Components/SocialSources";
import TaxSalbs from "./Components/TaxSlabs";
import TotalStaff from "./Components/TotalStaff";
import TurnOver from "./Components/TurnOver";
import UpcomingBirthdays from "./Components/UpcomingBirthdays";
import UpgradeAccount from "./Components/UpgradeAccount";
interface ChartSettings {
  chartType: "BAR" | "PIE";
  calculation: "sum" | "average" | "median" | "min" | "max";
  label: "gender" | "department" | "province" | "employeementType";
  colors: string[];
  fontStyle: "normal" | "italic" | "oblique";
  fontWeight: "normal" | "bold";
  fontSize: number;
}
export default function ResizableDashboard() {
  const {
    editDashboard,
    setEditDashboard,
    dashboardAnalytics,
    isLoading,
    setIsLoading,
  } = useDashboardContext();
  const ResponsiveGridLayout = WidthProvider(RGL);
  const toast = useToast();
  const [saved, setSaved] = useState(false);
  const [editingChart, setEditingChart] = useState<null | {
    key: string;
    chartSettings: ChartSettings;
  }>(null);

  const [deletingChart, setDeletingChart] = useState<string | null>(null);
  const Components: {
    [key: string]: React.FC<any>;
  } = {
    totalStaff: TotalStaff,
    turnOver: TurnOver,
    employementType: EmployementType,
    employementCategory: EmployementCategory,
    upgradeAccount: UpgradeAccount,
    provinces: Provinces,
    taxSlabs: TaxSalbs,
    upcomingBirthdays: UpcomingBirthdays,
    socialSources: SocialSources,
    employeesTable: EmployeesTable,
    departmentalStats: DepartmentalStats,
    shiftRosters: ShiftRosters,
    barChart: BarChart,
    dynamicChartBAR: DynamicBarChart,
    dynamicChartPIE: DynamicPieChart,
    attendance: Attendence,
  };
  const defaultLayout = [
    {
      key: "totalStaff",
      component: TotalStaff,
      props: {},
      minW: 2,
      minH: 4,
      x: 0,
      y: 0,
      w: 3,
      h: 4,
      isVisible: true,
    },
    {
      key: "turnOver",
      component: TurnOver,
      props: {},
      minW: 2,
      minH: 4,
      x: 3,
      y: 0,
      w: 3,
      h: 4,
      isVisible: true,
    },
    {
      key: "employementType",
      component: EmployementType,
      props: {},
      minW: 2,
      minH: 4,
      x: 6,
      y: 0,
      w: 3,
      h: 4,
      isVisible: true,
    },
    {
      key: "employementCategory",
      component: EmployementCategory,
      props: {},
      minW: 2,
      minH: 4,
      x: 9,
      y: 0,
      w: 3,
      h: 4,
      isVisible: true,
    },
    {
      key: "upgradeAccount",
      component: UpgradeAccount,
      props: {},
      minW: 2,
      minH: 5,
      x: 0,
      y: 79,
      w: 4,
      h: 7,
      isVisible: false,
    },
    {
      key: "provinces",
      component: Provinces,
      props: {},
      minW: 2,
      minH: 8,
      x: 8,
      y: 14,
      w: 4,
      h: 8,
      isVisible: true,
    },
    {
      key: "taxSlabs",
      component: TaxSalbs,
      props: {},
      minW: 4,
      minH: 6,
      x: 0,
      y: 19,
      w: 4,
      h: 8,
      isVisible: true,
    },
    {
      key: "upcomingBirthdays",
      component: UpcomingBirthdays,
      props: {},
      minW: 2,
      minH: 6,
      x: 4,
      y: 19,
      w: 4,
      h: 8,
      isVisible: true,
    },
    {
      key: "socialSources",
      component: SocialSources,
      props: {},
      minW: 2,
      minH: 8,
      x: 8,
      y: 19,
      w: 4,
      h: 8,
      isVisible: true,
    },
    {
      key: "employeesTable",
      component: EmployeesTable,
      props: {},
      minW: 8,
      minH: 15,
      x: 0,
      y: 27,
      w: 12,
      h: 15,
      isVisible: true,
    },
    {
      key: "departmentalStats",
      component: DepartmentalStats,
      props: {},
      minW: 6,
      minH: 12,
      x: 0,
      y: 42,
      w: 12,
      h: 12,
      isVisible: true,
    },
    {
      key: "shiftRosters",
      component: ShiftRosters,
      props: {},
      minW: 8,
      minH: 15,
      x: 0,
      y: 54,
      w: 12,
      h: 15,
      isVisible: true,
    },
    // Uncomment and correct this part if necessary
    {
      key: "attendance",
      component: Attendence,
      props: {},
      minW: 2,
      minH: 7,
      x: 8,
      y: 6,
      w: 4,
      h: 7,
      isVisible: true,
    },
    {
      key: "barChart",
      component: BarChart,
      props: {},
      minW: 8,
      minH: 15,
      x: 0,
      y: 4,
      w: 8,
      h: 15,
      isVisible: true,
    },
  ];
  const form = useForm<Analytics.DashBoardData>({
    defaultValues: {
      id: "",

      layout: defaultLayout,
    },
  });
  const [menuButtonLabel, setMenuButtonLabel] = useState("View");
  const handleViewClick = () => {
    if (editDashboard) {
      form.handleSubmit((data) => {
        const cleanedLayout = (data.layout as any).filter(
          (item: any) =>
            item !== null &&
            Object.keys(item).some(
              (key) =>
                item[key] !== undefined &&
                item[key] !== null &&
                item[key] !== ""
            )
        );
        const updatedData = { ...data, layout: cleanedLayout };
        if (form.getValues("id")) {
          Analytics.UpdateDashboardConfig(
            form.getValues("id")!,
            updatedData,
            (data) => {
              toast({
                title: "Success",
                description: "Layout saved successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setEditDashboard(false);
              setMenuButtonLabel("View");
              setSaved(true);
            },
            (message) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          );
        } else {
          Analytics.PostDashboardConfig(
            updatedData,
            (data) => {
              toast({
                title: "Success",
                description: "Layout saved successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setEditDashboard(false);
              setMenuButtonLabel("View");
              setSaved(true);
            },
            (message) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          );
        }
      })();
    } else {
      toast({
        title: "Info",
        description: "You are already in view mode.",
        status: "info",
      });
    }
  };

  const handleEditClick = () => {
    if (editDashboard) {
      toast({
        title: "Info",
        description:
          "You are already in edit mode. Please switch to view mode to save changes.",
        status: "info",
      });
    } else {
      setEditDashboard(true);
      setMenuButtonLabel("Edit");
    }
  };
  useEffect(() => {
    setIsLoading(true);
    Analytics.GetDashboardConfig(
      (data) => {
        form.setValue("id", data?.id);
        if (data?.layout?.length > 0) {
          let layout = data.layout.map((item) => {
            const component = Components[item.key];
            if (!component) {
              console.error(`Component for key ${item.key} not found.`);
            }
            return {
              ...item,
              component: component || Box, // Use Box as fallback to prevent crashes
            };
          });
          form.setValue("layout", layout);
          setSaved(true);
        }
        setIsLoading(false);
        // form.setValue("newLayout", data?.newLayout);
      },
      (message) => {
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    );
  }, []);
  const [lastupdate, setLastUpdate] = useState(0);
  const layout = useMemo(() => {
    return form
      ?.getValues("layout")
      .filter((box) => box?.isVisible || editDashboard)
      .map((box) => ({
        i: box?.key,
        x: box?.x,
        y: box?.y,
        w: box?.w,
        h: box?.h + (editDashboard ? 1 : 0),
        minW: box?.minW,
        minH: box?.minH,
        props: box?.props,
      }));
  }, [form.getValues("layout"), editDashboard]);
  // const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  // const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
  const onLayoutChange = useCallback(
    (layout: any) => {
      const newGridBoxes = form.getValues("layout").map((box) => {
        const newBox = layout.find((item: any) => item.i === box.key);
        if (newBox) {
          return { ...box, x: newBox.x, y: newBox.y, w: newBox.w, h: newBox.h };
        }
        return box;
      });
      form.setValue("layout", newGridBoxes);
    },
    [form.getValues("layout")]
  );
  const handleDeleteChart = (key: string) => {
    const updatedLayout = form
      .getValues("layout")
      .filter((item) => item.key !== key);
    form.setValue("layout", updatedLayout);
    setLastUpdate(Date.now());
  };
  const handleConfirmDelete = () => {
    if (deletingChart) {
      handleDeleteChart(deletingChart);
      setDeletingChart(null);
    }
  };
  const handleEditChart = (key: string) => {
    const chartToEdit = form
      .getValues("layout")
      .find((item) => item.key === key);
    if (chartToEdit) {
      setEditingChart({
        key,
        chartSettings: {
          chartType: chartToEdit.key.includes("BAR") ? "BAR" : "PIE",
          calculation: chartToEdit.props.calculation,
          label: chartToEdit.props.attribute,
          colors: chartToEdit.props.colors,
          fontStyle: chartToEdit.props.fontStyle,
          fontWeight: chartToEdit.props.fontWeight,
          fontSize: chartToEdit.props.fontSize,
        },
      });
      onOpen();
    }
  };
  const handleUpdateChart = (chartSettings: ChartSettings) => {
    if (editingChart) {
      const updatedLayout = form.getValues("layout").map((item) =>
        item.key === editingChart.key
          ? {
              ...item,
              key: `dynamicChart${chartSettings.chartType}_${Date.now()}`,
              component:
                chartSettings.chartType === "BAR"
                  ? DynamicBarChart
                  : DynamicPieChart,
              props: {
                attribute: chartSettings.label,
                calculation: chartSettings.calculation,
                chartType: chartSettings.chartType,
                colors: chartSettings.colors,
                fontStyle: chartSettings.fontStyle,
                fontWeight: chartSettings.fontWeight,
                fontSize: chartSettings.fontSize,
              },
            }
          : item
      );
      form.setValue("layout", updatedLayout);
      setEditingChart(null);
      onClose();
      setLastUpdate(Date.now());
    }
  };
  const generateNodes = useMemo(() => {
    return form.getValues("layout").map((box, index) =>
      box?.isVisible || editDashboard ? (
        <Box
          key={box.key}
          display={box?.isVisible || editDashboard ? "block" : "none"}
          // display={editDashboard ? "block" : "none"}
          borderRadius="10px"
          bg={"#fff"}
          boxShadow="0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"
          _hover={{
            boxShadow:
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
          }}
          border={"1px solid #f6f6f6"}
          position="relative"
          // h="100%"
          opacity={editDashboard ? 0.5 : 1}
        >
          {/* {box.isVisible && box.component(box.props)} */}
          {(box?.isVisible || editDashboard) &&
            React.createElement(
              box?.key?.includes("dynamicChartBAR")
                ? DynamicBarChart
                : box?.key?.includes("dynamicChartPIE")
                ? DynamicPieChart
                : box.component,
              box.props
            )}

          {editDashboard && (
            <Flex
              h={editDashboard && "calc(-100% + 30px)"}
              mt="12px"
              px={4}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Icon
                as={AiOutlineDrag}
                className="drag-handle"
                cursor="move"
                mr={4}
                boxSize={5}
                zIndex={999}
                color="#000000"
              />
              <HStack>
                {(box?.key?.includes("dynamicChartBAR") ||
                  box?.key?.includes("dynamicChartPIE")) && (
                  <Menu placement="top-start">
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<IoSettings size={24} />}
                      variant="outline"
                      border="0px"
                    />
                    <MenuList minW="100px">
                      <MenuItem onClick={() => handleEditChart(box.key)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => setDeletingChart(box.key)}>
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
                <Controller
                  control={form.control}
                  name={`layout.${index}.isVisible`}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value}
                      colorScheme="blue"
                      // position="absolute"

                      value={field.value?.toString()}
                      onChange={(e) => {
                        form.setValue(
                          `layout.${index}.isVisible`,
                          e.target.checked
                        );
                      }}
                    >
                      Show
                    </Checkbox>
                  )}
                />
              </HStack>
            </Flex>
          )}
        </Box>
      ) : null
    );
  }, [editDashboard, saved, lastupdate]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleAddChart = (chartSettings: ChartSettings) => {
    const newKey = `dynamicChart${chartSettings.chartType}_${Date.now()}`;
    const currentLayout = form.getValues("layout");

    const maxY = currentLayout.reduce((max, item) => {
      // Check if item.y and item.h are defined and numbers
      if (typeof item.y !== "number" || typeof item.h !== "number") {
        console.error("Invalid item found in layout:", item);
        return max;
      }
      return Math.max(max, item.y + item.h);
    }, 0);
    const newChart = {
      key: newKey,
      component:
        chartSettings.chartType === "BAR" ? DynamicBarChart : DynamicPieChart,
      props: {
        attribute: chartSettings.label,
        calculation: chartSettings.calculation,
        chartType: chartSettings.chartType,
        colors: chartSettings.colors,
        fontStyle: chartSettings.fontStyle,
        fontWeight: chartSettings.fontWeight,
        fontSize: chartSettings.fontSize,
      },
      minW: 4,
      minH: 9,
      x: 0,
      y: maxY, // Position at the end of the current layout
      w: 7,
      h: 11,
      isVisible: true,
    };
    form.setValue("layout", [...form.getValues("layout"), newChart]);
  };
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [lastupdate]);
  return (
    <Box
      height="calc(100vh - 70px)"
      overflow="auto"
      w="100%"
      bg={"#f3f4f6"}
      ref={scrollRef}
    >
      <Stack
        w={"100%"}
        top={0}
        padding={"0px 30px"}
        position="sticky"
        h={"65px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        zIndex="1000"
        _dark={{
          bgColor: "customColor.dark.50",
        }}
        _light={{
          bgColor: "customColor.white",
        }}
        boxShadow={"0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)"}
      >
        <Stack>
          <Text
            fontSize={"16px"}
            fontWeight={"700"}
            margin={"0px 8px 0px 0px"}
            padding={"0px 10px 0px 0px"}
            _light={{
              color: "customColor.black7",
            }}
          >
            Dashboard
          </Text>
        </Stack>

        <ChartsModal
          isOpen={isOpen}
          onClose={onClose}
          onOk={editingChart ? handleUpdateChart : handleAddChart}
          setLastUpdate={setLastUpdate}
          chartSettings={editingChart ? editingChart.chartSettings : undefined}
        />
        <DeleteChartModal
          isOpen={!!deletingChart}
          onClose={() => setDeletingChart(null)}
          onConfirm={handleConfirmDelete}
        />
        <ButtonGroup>
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<IoAddSharp />}
              fontSize={"13px"}
              bgColor="#3454D1"
              h={"40px"}
              borderRadius={"3px"}
              borderWidth={"1px"}
              borderColor={"#3454d1!important"}
              variant={"solid"}
              padding={"12px 16px"}
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              _active={{
                bgColor: "#3454D1",
              }}
              isDisabled={editDashboard ? false : true}
            >
              Add Widget
            </MenuButton>
            <MenuList maxWidth="390px">
              <MenuItem
                minH="48px"
                py={0}
                onClick={() => {
                  setEditingChart(null);
                  onOpen();
                }}
              >
                <Image
                  h="50px"
                  borderRadius="full"
                  src="https://cdn.monday.com/images/column-store/overview-sections/ChartOverviewSection_1-icon-small.png"
                  alt="charts picture"
                  mr="12px"
                />
                <Box>
                  <Text
                    fontWeight={500}
                    fontSize="14px"
                    _light={{ color: "customColor.black7" }}
                  >
                    Chart
                  </Text>
                  <Text
                    fontWeight={400}
                    fontSize="14px"
                    _light={{ color: "customColor.black7" }}
                  >
                    Create chart widget to visually show data from your boards
                  </Text>
                </Box>
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={Button}
              fontSize="13px"
              bgColor="#3454D1"
              h="40px"
              borderRadius="3px"
              borderWidth="1px"
              borderColor="#3454d1!important"
              variant="solid"
              padding="12px 16px"
              fontWeight={700}
              _hover={{
                bgColor: "#3454D1",
              }}
              _active={{
                bgColor: "#3454D1",
              }}
              rightIcon={<IoMdArrowDropdown fontSize="13px" />}
            >
              {menuButtonLabel}
            </MenuButton>
            <MenuList maxWidth="390px">
              <MenuItem
                onClick={handleViewClick}
                fontWeight={500}
                fontSize={"13.3px"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                View
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  if (editDashboard === true) {
                    form.handleSubmit((data) => {
                      const cleanedLayout = (data.layout as any).filter(
                        (item: any) =>
                          item !== null &&
                          Object.keys(item).some(
                            (key) =>
                              item[key] !== undefined &&
                              item[key] !== null &&
                              item[key] !== ""
                          )
                      );
                      const updatedData = { ...data, layout: cleanedLayout };
                      if (form.getValues("id")) {
                        Analytics.UpdateDashboardConfig(
                          form.getValues("id")!,
                          updatedData,
                          (data) => {
                            toast({
                              title: "Success",
                              description: "Layout saved successfully",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });
                            setEditDashboard(false);
                            setSaved(true);
                          },
                          (message) => {
                            toast({
                              title: "Error",
                              description: message,
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                          }
                        );
                      } else {
                        Analytics.PostDashboardConfig(
                          updatedData,
                          (data) => {
                            toast({
                              title: "Success",
                              description: "Layout saved successfully",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });
                            setEditDashboard(false);
                            setSaved(true);
                          },
                          (message) => {
                            toast({
                              title: "Error",
                              description: message,
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            });
                          }
                        );
                      }
                    })();
                  } else {
                    toast({
                      title: "Info",
                      description: "You are already in view mode.",
                      status: "info",
                    });
                  }
                }}
              >
                View
              </MenuItem> */}
              <MenuItem
                onClick={handleEditClick}
                fontWeight={500}
                fontSize={"13.3px"}
                _light={{
                  color: "customColor.black7",
                }}
              >
                Edit
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  if (editDashboard) {
                    toast({
                      title: "Info",
                      description:
                        "You are already in edit mode. Please switch to view mode to save changes.",
                      status: "info",
                    });
                  } else {
                    setEditDashboard(true);
                  }
                }}
              >
                Edit
              </MenuItem> */}
              <MenuItem
                fontWeight={500}
                fontSize={"13.3px"}
                _light={{
                  color: "customColor.black7",
                }}
                onClick={() => {
                  if (editDashboard) {
                    form.reset({
                      ...form.getValues(),
                      layout: defaultLayout,
                    });
                  } else {
                    toast({
                      title: "Info",
                      description:
                        "You have to be in edit mode to reset the layout.",
                      status: "info",
                    });
                  }
                }}
              >
                Reset
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Stack>
      <Box w="100%" bg={"#f3f4f6"} padding={"10px 5px 10px 5px"}>
        {!isLoading && dashboardAnalytics ? (
          <Fragment>
            <ResponsiveGridLayout
              // layouts={layout}
              layout={layout}
              // breakpoints={breakpoints}
              // cols={cols}
              rowHeight={30}
              cols={12}
              className="layout"
              width={window.innerWidth}
              autoSize={true}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              draggableHandle=".drag-handle"
              onDragStop={onLayoutChange}
              onResizeStop={onLayoutChange}
              margin={[20, 20]}
            >
              {generateNodes}
            </ResponsiveGridLayout>
          </Fragment>
        ) : (
          <Center>
            <Spinner thickness="4px" />
          </Center>
        )}
      </Box>
    </Box>
  );
}
